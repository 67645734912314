import React from 'react';
import { createPortal } from 'react-dom';
import { withLoader } from '../../common/components';

import { appService } from '../../services';
import { MerchantSidebarComponent } from '../sidebar';
import MerchantContext from './MerchantContext';
import UpdateMerchantContext from './UpdateMerchantContext';

class MerchantRoot extends React.Component {
	state = {
		merchant: null,
	};
	async componentDidMount() {
		this.merchantSidebarPortalRef = document.getElementById('merchantSidebarPortal');
		this.forceUpdate();
		const {
			match: {
				params: { appid },
			},
			showLoader,
		} = this.props;
		showLoader(true);
		try {
			const [merchant, equipList] = await Promise.all([
				appService.getMerchantDetails(appid),
				appService.getMerchantEquipment(appid),
			]);
			let cardknoxEquip = equipList?.equipmentList.filter(
				equip => [2, 247, 238].includes(equip.equipmentId) && equip.isActiveEquipment
			);
			merchant.hasCardknoxEquipment = cardknoxEquip?.length > 0;
			this.setState({ merchant });
		} catch (err) {
			console.log('getMerchantDetails error', err);
			this.setState({ errorMessage: 'An error occurred: ' + err });
		}
		showLoader(false);
	}

	updateMerchant = newValue => {
		this.setState({ merchant: { ...this.state.merchant, ...newValue } });
	};

	render() {
		const {
			layoutRef,
			match: {
				params: { appid },
			},
		} = this.props;
		return (
			<UpdateMerchantContext.Provider value={this.updateMerchant}>
				<MerchantContext.Provider value={this.state.merchant}>
					{this.merchantSidebarPortalRef &&
						createPortal(
							<MerchantSidebarComponent layoutRef={layoutRef} appId={appid} />,
							this.merchantSidebarPortalRef
						)}
					{this.props.children}
				</MerchantContext.Provider>
			</UpdateMerchantContext.Provider>
		);
	}
}

export default withLoader(MerchantRoot);
