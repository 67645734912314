import React from 'react';
import USSetupForm from './USSetupForm';
import { CanadaProcessorDropdown } from '../../common/components/processor-dropdown';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { isFdCad } from '../../common/utilities';

export default class CanadaSetupForm extends USSetupForm {
	renderProcessorDropdown() {
		const { setup, processorList } = this.props;
		return (
			<CanadaProcessorDropdown
				className="input input--med input--select"
				id="processorId"
				value={setup.processorId}
				processorList={processorList}
				setup={setup}
				onChange={this.props.handleChange}
				merchId={setup.appId}
			/>
		);
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		if (setup.pricingStructure.shouldSetupPinDebit !== 'true') return null;
		return (
			<div className="row grid-align-middle spc--bottom--med">
				<div className="col col-sml-12 col-lrg-4">
					<div className="form__field">
						<label className="label">Interac Trans Fee </label>
						{this.renderNumberInput(
							setup.pricingStructure.pinDebitTransactionFee,
							'pricingStructure_pinDebitTransactionFee',
							'$'
						)}
					</div>
				</div>
				<div className="col col-sml-12 col-lrg-4">
					<div className="form__field">
						<label className="label">Interac Flash Trans Fee/Contactless </label>
						{this.renderNumberInput(
							setup.pricingStructure.pinDebitInteracFlashTransFee,
							'pricingStructure_pinDebitInteracFlashTransFee',
							'$'
						)}
					</div>
				</div>
			</div>
		);
	}

	renderPinDebit() {
		const { setup } = this.props;
		return (
			<div className="required">
				<label className="label">Setup Merchant With Pin Debit</label>
				<div className="flex flex--primary spc--bottom--sml">
					<div className="spc--right--med">
						<input
							className="input--radio"
							type="radio"
							id="pricingStructure_shouldSetupPinDebit_yes"
							name="pricingStructure_shouldSetupPinDebit_yes"
							checked={setup.pricingStructure.shouldSetupPinDebit === 'true'}
							value="true"
							onChange={this.props.handlePinDebitChange}
						/>
						<label className="label" htmlFor="pricingStructure_shouldSetupPinDebit_yes">
							Yes
						</label>
					</div>
					<div>
						<input
							className="input--radio"
							type="radio"
							id="pricingStructure_shouldSetupPinDebit_no"
							name="pricingStructure_shouldSetupPinDebit_no"
							value="false"
							checked={setup.pricingStructure.shouldSetupPinDebit === 'false'}
							onChange={this.props.handlePinDebitChange}
						/>
						<label className="label" htmlFor="pricingStructure_shouldSetupPinDebit_no">
							No
						</label>
					</div>
				</div>
				{this.renderPinDebitStructure()}
			</div>
		);
	}

	renderOptBlue() {
		const { setup } = this.props;
		return (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12">
						{this.renderRateStructure(
							'amexDetails',
							'AMEX ',
							true,
							setup.amexDetails.pricingStructureType,
							'Amex uses the same rate structure as Visa/MC'
						)}
					</div>
					<div className="col col-sml-12 col-med-6 form__field">
						<label className="label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc) </label>
						{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
					</div>
					<div id="amexDetails_yearlyAmexVolume_div" className="col col-sml-12 col-med-6 form__field">
						<label className="label">Opt-Blue Average Annual Volume </label>
						{this.renderNumberInput(setup.amexDetails.yearlyAmexVolume, 'amexDetails_yearlyAmexVolume', '$0')}
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderNonQual(rateObj, namePrefix) {
		return namePrefix.indexOf('amex') === 0 ? null : (
			<div className="col col-sml-12 col-lrg-3">
				<div className="spc--bottom--med form__field">
					<label className="label">Non-Qual </label>
					{this.renderNumberInput(
						rateObj.tieredRatePercentages.nonQualified,
						namePrefix + 'tieredRatePercentages_nonQualified',
						'%',
						[0, 100]
					)}
				</div>
			</div>
		);
	}

	renderTransactionInformation() {
		return (
			<div className="col col-sml-12 col-lrg-4">
				<div id="pricingStructure_debitRefundCap_div" className="form__field spc--bottom--med">
					<label className="label">Debit Refund Cap </label>
					{this.renderNumberInput(
						this.props.setup.pricingStructure.debitRefundCap,
						'pricingStructure_debitRefundCap',
						'$0'
					)}
				</div>
			</div>
		);
	}

	renderOnlineReportingSection() {
		return null;
	}

	renderEnhancedRate(value, labelPrefix, namePrefix) {
		const componentProps = {
			value: value,
			name: namePrefix + 'billBackRatePercentages_enhanced',
			placeholder: 'Enhanced Rate',
			className: 'input input--med',
			thousandSeparator: true,
			decimalScale: 2,
			onValueChange: this.handleChangeWithUpdateEnhanced,
			disabled: namePrefix.indexOf('amex') === 0 ? true : false,
		};

		return (
			<div className="form__field spc--bottom--med">
				<label className="label">{labelPrefix} Enhanced Rate </label>
				<ZeroAsEmptyNumberFormat {...componentProps} />
			</div>
		);
	}

	handleChangeWithUpdate = async e => {
		e.persist();
		await this.props.handleChangeAsync(e);
		await this.props.handleChangeAsync({
			target: {
				type: e.target.type,
				value: e.target.value,
				name: e.target.name.replace('pricingStructure_', 'amexDetails_'),
			},
		});
	};

	handleChangeWithUpdateEnhanced = async ({ floatValue }) => {
		await this.props.handleChangeAsync({
			target: { value: floatValue, name: 'pricingStructure_billBackRatePercentages_enhanced' },
		});
		await this.props.handleChangeAsync({
			target: { value: floatValue, name: 'amexDetails_billBackRatePercentages_enhanced' },
		});
	};

	renderProcessingMethodLabel() {
		return (
			<div
				className="required"
				data-tooltip="There must be one processing method that has a higher percentage than the other processing methods."
			>
				<label className="card--primary__subtitle">Processing method (Must equal 100%)</label>
			</div>
		);
	}

	renderEIDSEnrollmentSection() {
		return null;
	}

	renderRetrievalCharge() {
		return null;
	}

	renderPciFee(setup) {
		return (
			<React.Fragment>
				{this.renderProposedFee(setup, 'pciMonthlyFee', 'PCI Monthly Fee', !isFdCad(setup))}
				{this.renderProposedFee(setup, 'applicationFee', 'Application Fee', true)}
			</React.Fragment>
		);
	}
}
