import React from 'react';
import {
	includes,
	replace,
	split,
	each,
	isObject,
	cloneDeep,
	toLower,
	filter,
	groupBy,
	orderBy,
	keys,
	some,
	set,
	find,
	get,
	isEmpty,
	join,
} from 'lodash';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { decode } from 'jsonwebtoken';

import Schema from '../../validation/BaseSchema';
import { identifyFullStoryWithToken, openFile } from '../../common/utilities';
import withMpa from './withMpa';
import { appService, signatureService } from '../../services';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import RequestNewLink from './RequestNewLink';
import AppSummary from './AppSummary';
import ClickWrapComponent from './Clickwrap';

const upnKey = 'upn';

function getRequiredFieldsFromToken(token) {
	const { payload } = decode(token, { complete: true });
	const requiredFields = {};
	if (!payload[upnKey]) return requiredFields;
	const upnData = split(payload[upnKey], '|');
	each(upnData, field => {
		requiredFields[field] = true;
	});
	return requiredFields;
}

function getFlattenedKeys(object, parentKey = '') {
	const flattenedKeys = [];
	if (parentKey && object && (object.type || object.message)) return flattenedKeys;
	each(object, (val, key) => {
		if (!isObject(val)) return;
		const newKey = parentKey ? `${parentKey}.${key}` : key;
		flattenedKeys.push(newKey);
		flattenedKeys.push(...getFlattenedKeys(val, newKey));
	});
	return flattenedKeys;
}

export default function withMerchantMpa(Component, validationTemplate) {
	const ComponentWithMpa = withMpa(Component, validationTemplate, validationTemplate);
	return class ComponentWithMerchantMpa extends ComponentWithMpa {
		constructor(props) {
			super(props);
			const token = props.location.search.substr(1);
			identifyFullStoryWithToken(token);
			const requiredFields = getRequiredFieldsFromToken(token);
			const template = cloneDeep(validationTemplate);
			const flattenedKeys = getFlattenedKeys(template);
			each(requiredFields, (_, key) => {
				const lowerKey = toLower(key);
				const matchedKeys = filter(
					flattenedKeys,
					flattenedKey =>
						includes(toLower(flattenedKey), lowerKey) &&
						!includes(toLower(flattenedKey), 'corporateaddress') &&
						!some(
							[
								'secondarybankname',
								'secondaryaccountnumber',
								'secondaryroutingnumber',
								'alternatebankname',
								'alternateaccountnumber',
								'alternateroutingnumber',
							],
							field => includes(toLower(flattenedKey), field)
						)
				);

				const groupedKeys = groupBy(matchedKeys, matchedKey => split(matchedKey, '.').length);
				const sortedKeys = orderBy(keys(groupedKeys), [groupKey => parseInt(groupKey, 10)], ['desc']);
				const mappedKeys = [];
				each(sortedKeys, sortedKey => {
					each(groupedKeys[sortedKey], fieldKey => {
						if (some(mappedKeys, mappedKey => includes(mappedKey, fieldKey))) return;
						mappedKeys.push(fieldKey);
						requiredFields[fieldKey] = true;
						set(template, `${fieldKey}.required`, true);
					});
				});
			});
			requiredFields.dba = true;
			this.mpaSchema = new Schema(template, { strip: false, typecast: true });
			this.state = {
				...this.state,
				token: token,
				isExpired: props.isExpired,
				downloadUrl: '',
				ip: '',
				timestamp: '',
				showValidation: false,
				requiredFields: requiredFields,
			};
		}

		get mpaData() {
			this.isMerchant = true;
			return {
				...super.mpaData,
				requiredFields: this.state.requiredFields,
				isMerchant: true,
			};
		}
		get isFilesEmpty() {
			const { files, mpa } = this.state;
			const existingVoidedCheck = find(get(mpa, 'files'), ({ fileTag }) => toLower(fileTag) === 'voidedcheck');
			const hasVoidedCheck = !isEmpty(existingVoidedCheck) || !isEmpty(files.VoidedCheck);

			return !hasVoidedCheck;
		}
		validateField = name => {
			const { errorListPaths, showValidation } = this.state;
			if (showValidation && errorListPaths && includes(errorListPaths, replace(name, /_/gi, '.'))) {
				return ' is-invalid';
			}
			return '';
		};
		setInfoToApp = async (downloadUrl, ip, timestamp) => {
			this.setState({ downloadUrl, ip, timestamp });
		};
		handleMPADownload = forPrint => e => {
			// first save to make sure everything relevant will be on the downloaded document
			const errorList = this.mpaSchema.validate(Object.assign({}, this.state.mpa));
			const errorListPaths = errorList.map(e => e.path);
			if (errorList.length > 0) {
				this.setState({ errorList, errorListPaths, showValidation: true }, () => this.scrollTo('validation_messages'));
				return;
			}
			this.props.showLoader(true);
			if (this.state.dirty) {
				const mpa = cloneDeep(this.state.mpa);

				appService
					.saveEApp(mpa)
					.then(() => {
						this.setState({ dirty: false });
						this.downloadMPA(forPrint);
					})
					.catch(this.handleError);
			} else {
				this.downloadMPA(forPrint);
			}
		};
		downloadMPA(forPrint) {
			let fileName = 'mpa_' + this.state.appId + '.pdf';
			appService.downloadPrefilledMPA(this.state.mpa).then(url => {
				let msg = '';
				if (url.errorMessage) {
					msg = url.errorMessage;
				} else {
					openFile(url, fileName, forPrint, forPrint);
				}
				this.setState({ errorMessage: msg });
				this.props.showLoader(false);
			});
		}

		save = () => {
			const mpa = cloneDeep(this.state.mpa);
			mpa.signature = {
				ip: this.state.ip,
				timestamp: this.state.timestamp,
			};

			const errorList = this.mpaSchema.validate(Object.assign({}, mpa));
			const errorListPaths = errorList.map(e => e.path);
			if (errorList.length) {
				this.setState({ errorList, errorListPaths, showValidation: true });
				return;
			}

			this.props.showLoader(true);

			return signatureService
				.prepareSignatureFileForTransfer(this.state.mpa, ['ClickwrapMPA'], this.state.downloadUrl)
				.then(
					fileForTransfer =>
						new Promise((resolve, reject) => {
							this.saveFiles()
								.then(() => {
									appService
										.saveEApp(mpa, fileForTransfer)
										.then(resolve)
										.catch(err => {
											this.setState({ files: {} }, () => {
												this.props
													.loadMPAFromService()
													.then(() => reject(err))
													.catch(() => reject(err));
											});
										});
								})
								.catch(err => {
									this.setState({ files: {} }, () => {
										this.props
											.loadMPAFromService()
											.then(() => reject(err))
											.catch(() => reject(err));
									});
								});
						})
				)
				.then(() => {
					return appService.downloadPrefilledMPA(this.state.mpa, false).then(blob => {
						const prefilledMpa = { file: new File([blob], `mpa_${this.state.appId}.pdf`), fileDescription: '' };
						return new Promise((resolve, reject) => {
							this.setState(
								{
									files: {
										Other: [prefilledMpa],
									},
								},
								() => {
									this.saveFiles()
										.then(resolve)
										.catch(reject);
								}
							);
						});
					});
				})
				.then(() => {
					this.props.showLoader(false);
					const redirectPage = 'thankyou?noSign=true';
					this.props.history.push(`/merchant/${redirectPage}`);
				})
				.catch(err => {
					this.handleError(err);
				});
		};
		renderMpa() {
			return (
				<div className="details--content">
					<Component {...this.mpaData} />
				</div>
			);
		}
		renderHeader() {
			const { token, isExpired } = this.state;
			return (
				<React.Fragment>
					<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
					{isExpired && <RequestNewLink token={token} />}
				</React.Fragment>
			);
		}

		renderMpaTitle() {
			return null;
		}

		renderShareMPA() {
			return null;
		}

		renderUploadMPA() {
			return null;
		}

		renderAppSummary() {
			const { mpa } = this.state;
			return <AppSummary app={mpa} isEappWebForm={true} />;
		}

		renderFooter() {
			const { isExpired, downloadUrl, showValidation, errorList, errorListPaths, mpa } = this.state;
			const disableConfirmAndSignButton = this.isFilesEmpty || !downloadUrl;
			const confirmationErrors = [];
			if (this.isFilesEmpty) {
				confirmationErrors.push('Upload required files to save and proceed.');
			}
			if (!downloadUrl) {
				confirmationErrors.push('Terms and Conditions need to be acknowledged before you can save and proceed.');
			}
			const tooltip = join(confirmationErrors, '\n');
			const groupKey = get(mpa, 'processor') == 'ACH-CK21' ? 'group-profitstars' : 'fidelity-mpa';
			return (
				<React.Fragment>
					<ClickWrapComponent
						groupKey={groupKey}
						appId={this.state.appId.toString()}
						setInfoToApp={this.setInfoToApp}
						handleError={this.handleError}
						allowDisagreed={true}
					/>

					{showValidation && errorListPaths.length ? (
						<div className="spc--bottom--med note note--warning" id="validation_messages">
							<ul>
								{errorList.map((elem, i) => {
									return (
										<li key={i}>
											<div
												className="anchor"
												onClick={() => {
													let elemId = elem.path.replace(/[.]/g, '_');
													this.scrollTo(elemId + '_div');
													this.focusField(elemId);
												}}
											>
												<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>{' '}
												{defaultReactOutput(defaultImplicitParse(elem.message))}
											</div>
										</li>
									);
								})}
							</ul>
						</div>
					) : null}
					{!isExpired && (
						<div className="type--right">
							<button
								className="btn btn--primary btn--med datatooltip--top--left"
								onClick={this.save}
								data-tooltip={disableConfirmAndSignButton ? tooltip : null}
								disabled={disableConfirmAndSignButton || this.props.isLoading}
							>
								Confirm
							</button>
						</div>
					)}
				</React.Fragment>
			);
		}
	};
}
