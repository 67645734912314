import { getDefault } from './getDefault';
import { maxLength, min } from './validators';

const min0 = min(0);
const requiredCheckbox = val => val;
const requiredCanada = (val, ctx) => (ctx.market === 'Canada' ? !!val : true);
const percentages = (val, ctx) => ctx.percentageKeyed + ctx.percentageSwiped == 100;
export const EnumRushCredits = ['Use 25 Rush Points', 'Have Signed Apps'];

export const reviewPricingTemplate = {
	dba: {
		type: String,
		required: true,
		message: '[**Merchant DBA**](javascript:void) is required',
	},
	corporateName: {
		type: String,
		required: false,
		message: '[**Name On Statement**](javascript:void) is required',
	},
	descriptionOfGoods: {
		type: String,
		required: true,
		use: { length: maxLength(64) },
		message: {
			required: '[**Description Of Goods**](javascript:void) is required',
			length: '[**Description Of Goods**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
		},
	},
	percentageKeyed: {
		type: Number,
		required: true,
		use: { percentages },
		message: {
			required: '[**Percentage Keyed**](javascript:void) is required',
			percentages: '[**Percentage Keyed and Swiped**](javascript:void) must total 100%',
		},
	},
	percentageSwiped: {
		type: Number,
		required: true,
		message: {
			required: '[**Percentage Swiped**](javascript:void) is required',
		},
	},
	representsAverageMonth: {
		type: Boolean,
		required: true,
		message: '[**Represents Average Month**](javascript:void) is required',
	},
	averageMonthlyVolume: {
		type: Number,
		required: true,
		use: { min0 },
		message: {
			required: '[**Average monthly volume**](javascript:void) is required',
			min0: '[**Average monthly volume**](javascript:void) cannot be less than zero',
		},
	},
	preauthAndCapture: {
		type: Boolean,
		required: true,
		message: '[**Pre-authorization And Capture**](javascript:void) is required',
	},
	daysBetweenAuthAndCapture: {
		type: Number,
		required: true,
		use: { min0 },
		message: {
			required: '[**Days between Authorization and Capture**](javascript:void) is required',
			min0: '[**Days between Authorization and Capture**](javascript:void) cannot be less than zero',
		},
	},
	captureSameAsAuth: {
		type: Boolean,
		required: true,
		message: '[**Is Capture Same as Authorization**](javascript:void) is required',
	},
	isCaptureGreaterThanAuth: {
		type: Boolean,
		required: true,
		message: '[**Capture is Greater than Authorization**](javascript:void) is required',
	},
	doesProcessTips: {
		type: Boolean,
		required: true,
		message: '[**Process Tips**](javascript:void) is required',
	},
	areStatementsRecent: {
		type: Boolean,
		use: { requiredCheckbox },
		message: 'Please confirm that [**Statements Are Recent**](javascript:void)',
	},
	hasAllPagesOfStatement: {
		type: Boolean,
		use: { requiredCheckbox },
		message: 'Please confirm that [**All Pages Of Statement**](javascript:void) are attached',
	},
	notes: {
		type: String,
		required: false,
	},
	isRushRequest: {
		type: Boolean,
		required: true,
		message: '[**Rush**](javascript:void) is required',
	},
	rushReason: {
		type: String,
		use: {
			1: (val, ctx) => (ctx.isRushRequest ? EnumRushCredits.includes(val) : true),
		},
		message: {
			required: '[**Rush Credit**](javascript:void) is required',
			1: 'Please select a valid value for [**Rush Credit**](javascript:void)',
		},
	},
	currency: {
		type: String,
		use: { requiredCanada },
		message: { requiredCanada: '[**Currency**](javascript:void) is required' },
	},
};

export const defaultReviewPricingTemplate = getDefault(reviewPricingTemplate);
