import { withRouter } from 'react-router-dom';

import { withLoader } from '../../common/components';
import withBlock from '../../common/components/block/block-hoc';
import { merchantMPATemplate, MPAInvalidTemplate, MPARequiredTemplate } from '../../validation';
import BaseMPA from './BaseMPA';
import withMerchantMpa from './withMerchantMpa';
import withMpa from './withMpa';

class USMPA extends BaseMPA {}

export const USAgentMPA = withBlock(
	withLoader(
		withMpa(USMPA, MPAInvalidTemplate, MPARequiredTemplate, {
			'bankAccountInformation.bankName': true,
			'businessInformation.ownershipType': true,
		})
	)
);
export const USMerchantMPA = withRouter(withLoader(withMerchantMpa(USMPA, merchantMPATemplate)));
