import React from 'react';
import PropTypes from 'prop-types';
import { withLoader } from './../../common/components';
import { appService } from '../../services';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { Schema, ShareMpaFormTemplate } from '../../validation';
import { ClickableErrorMessage } from '../../common/components/error/ClickableErrorMessage';
import { includes, map, join, debounce, first, isEmpty, remove, some, find } from 'lodash';

const signerIdentificationTypeFields = [
	'IdentificationNumber',
	'IdentificationExpirationDate',
	'IdentificationIssueDate',
];

class ShareMPAForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			merchantEmail: this.props.merchantEmail,
			invalidErrorList: [],
			ccEmail: '',
			sendAgentCopy: true,
			documents: this.props.documents,
			saveResults: null,
			errorMessage: null,
			copied: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			fieldsSelected: [],
			fieldOptions: this.formatFieldOptions(this.props.fieldOptions, option => option.fieldName),
		};
		this.schema = new Schema(ShareMpaFormTemplate, { strip: false, typecast: true });
	}

	componentDidMount() {
		this.setState({ fieldsSelected: map(this.state.fieldOptions, option => option.fieldName) }, this.getEmailLink);
	}

	formatFieldOptions = fields => {
		if (
			!includes(fields, 'SignerIdentificationType') &&
			some(fields, field => includes(signerIdentificationTypeFields, field.fieldName))
		) {
			return [...fields, { fieldName: 'SignerIdentificationType', displayName: 'Signer Identification Type' }];
		}
		return fields;
	};

	getStyle = show => {
		return {
			display: show ? '' : 'none',
		};
	};

	onEmailChange = e => {
		//console.log('updating');
		this.setState({ merchantEmail: e.target.value }, this.formChangedCallback);
	};

	onCCEmailChange = e => {
		//console.log('updating');
		this.setState({ ccEmail: e.target.value }, this.formChangedCallback);
	};

	onSendCopyChange = e => {
		this.setState({ sendAgentCopy: e.target.checked }, this.formChangedCallback);
	};

	onFieldSelected = e => {
		const { id, checked } = e.target;
		let newFieldsSelected = [...this.state.fieldsSelected];

		if (id === 'SignerIdentificationType') {
			if (checked && !some(newFieldsSelected, field => includes(signerIdentificationTypeFields, field))) {
				newFieldsSelected = [...newFieldsSelected, ...signerIdentificationTypeFields];
			} else if (!checked) {
				remove(newFieldsSelected, field => includes(signerIdentificationTypeFields, field));
			}
		}

		checked ? newFieldsSelected.push(id) : (newFieldsSelected = newFieldsSelected.filter(item => item !== id));
		this.setState({ fieldsSelected: newFieldsSelected }, this.formChangedCallback);
	};

	handleSendClick = () => {
		this.onSend();
	};

	debounceGetEmailLink = debounce(() => this.getEmailLink(), 750);

	formChangedCallback = () => {
		const invalidErrorList = this.schema.validate(Object.assign({}, this.state));
		this.setState({ invalidErrorList, linkToCopy: null }, () => {
			if (!isEmpty(invalidErrorList)) return;
			this.debounceGetEmailLink();
		});
	};

	getEmailLink = async () => {
		const { appid, merchantName, dba } = this.props;
		const { merchantEmail, ccEmail, sendAgentCopy, documents, fieldsSelected } = this.state;
		const fieldString = join(fieldsSelected, '|');
		try {
			let appInfo = { appId: appid, merchantDba: dba, merchantEmail: merchantEmail, merchantName: merchantName };
			let result = await appService.shareMPAForm(appInfo, ccEmail, sendAgentCopy, documents, fieldString, true);
			this.setState({ linkToCopy: result.url });
		} catch (error) {
			this.setState({ errorMessage: error });
		}
	};

	copyEmailLink = () => {
		try {
			const { linkToCopy } = this.state;
			navigator.clipboard.writeText(linkToCopy);

			this.setState({ copied: true, errorMessage: null });

			setTimeout(() => {
				this.setState({ copied: false });
			}, 2000);
		} catch (error) {
			this.setState({ errorMessage: error });
		}
	};

	onSend = () => {
		this.setState({ copied: false });
		this.props.showLoader(true);
		const { appid, merchantName, dba } = this.props;
		const { merchantEmail, ccEmail, sendAgentCopy, documents, fieldsSelected } = this.state;
		const fieldString = join(fieldsSelected, '|');
		let saveResults = 'Sent successfully';
		let appInfo = { appId: appid, merchantDba: dba, merchantEmail: merchantEmail, merchantName: merchantName };
		return appService
			.shareMPAForm(appInfo, ccEmail, sendAgentCopy, documents, fieldString)
			.then(() => {
				this.props.showLoader(false);
				this.setState({ saveResults, errorMessage: null });
			})
			.catch(err => {
				this.props.showLoader(false);
				this.setState({ errorMessage: err });
			});
		//console.log(saveResults);
	};

	renderErrorMessage = () =>
		this.state.errorMessage && <div className="note note--warning spc--bottom--med">{this.state.errorMessage}</div>;

	renderSaveResults = () => {
		const { merchantEmail, ccEmail, sendAgentCopy, saveResults, documents } = this.state;
		const shareFileName = documents.includes('ProfitStarsConfirmation') ? 'eAch Worksheet' : 'Fidelity MPA';
		return saveResults ? (
			<div className="note note--success spc--bottom--no">{saveResults}</div>
		) : (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className="form__field spc--bottom--med">
							<label className="label">Merchant Email: </label>
							<input
								type="text"
								className="input input--med"
								name="merchantEmail"
								value={merchantEmail}
								onChange={this.onEmailChange}
							/>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6">
						<div className="form__field spc--bottom--med">
							<label className="label">CC: </label>
							<input
								type="text"
								className="input input--med"
								name="ccEmail"
								value={ccEmail}
								onChange={this.onCCEmailChange}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className="form__field spc--bottom--med">
							<input
								type="checkbox"
								className="input--check"
								id="chkSendAgentCopy"
								checked={sendAgentCopy}
								onChange={this.onSendCopyChange}
							/>
							<label className="label" htmlFor="chkSendAgentCopy">
								Send me a copy
							</label>
						</div>
					</div>
				</div>
				{!documents.includes('FDGoConfirmation') && (
					<React.Fragment>
						<h4 className="spc--bottom--med">Attached Documents</h4>

						<div className="row">
							<div className="col col-sml-12 col-med-6">
								<div className="form__field spc--bottom--med">
									<input type="checkbox" className="input--check" disabled id="chkFidelityMPA" checked />
									<label className="label" htmlFor="chkFidelityMPA">
										{shareFileName}
									</label>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
				{this.renderFieldsRequired()}
			</React.Fragment>
		);
	};
	setAllFieldsRequired = e => {
		if (e.target.checked) {
			this.setState(
				{ fieldsSelected: map(this.state.fieldOptions, option => option.fieldName) },
				this.formChangedCallback
			);
		} else {
			this.setState({ fieldsSelected: [] }, this.formChangedCallback);
		}
	};

	renderFieldsRequired = () => {
		const fields = this.state.fieldOptions;
		if (!fields) return;

		const fieldsCopy = [...fields];
		const signerIdentificationTypeField = find(fields, field => field.fieldName === 'SignerIdentificationType');
		if (signerIdentificationTypeField) {
			remove(fieldsCopy, field => includes(signerIdentificationTypeFields, field.fieldName));
		}
		return (
			<React.Fragment>
				<h4 className="spc--bottom--med">Select which fields are required for the merchant to submit</h4>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						className="input--check"
						id="selectAll"
						onChange={this.setAllFieldsRequired}
						checked={this.state.fieldsSelected.length == this.state.fieldOptions.length}
					/>
					<label className="type--wgt--bold" htmlFor="selectAll">
						Select All
					</label>
				</div>
				{map(fieldsCopy, displayField => (
					<div className="spc--bottom--tny" key={displayField.fieldName}>
						<input
							id={displayField.fieldName}
							className="input--check"
							type="checkbox"
							checked={includes(this.state.fieldsSelected, displayField.fieldName)}
							onChange={this.onFieldSelected}
						/>
						<label htmlFor={displayField.fieldName}>{displayField.displayName}</label>
					</div>
				))}
			</React.Fragment>
		);
	};

	scrollTo = id => {
		let elem = document.getElementById(id);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = name => {
		const elem = first(document.getElementsByName(name));
		if (!elem) return;
		elem.focus();
		elem.click();
	};

	renderInvalidFields() {
		const { invalidErrorList } = this.state;
		if (isEmpty(invalidErrorList)) return null;
		return (
			<div className="col-sml-12 note note--warning spc--bottom--med">
				<ul>
					{map(invalidErrorList, (elem, i) => (
						<ClickableErrorMessage
							key={i}
							errorPrefix=""
							elem={elem}
							scrollTo={this.scrollTo}
							focusField={this.focusField}
						/>
					))}
				</ul>
			</div>
		);
	}

	render() {
		const { saveResults, invalidErrorList } = this.state;
		const hasValidationErrors = !isEmpty(invalidErrorList);
		return (
			<React.Fragment>
				<div className="popup__header">
					<h2 className="popup__header__title">Share Form with Merchant</h2>
				</div>
				<div className="popup__body popup__body--lrg" ref={this.detailsRef}>
					<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
					{this.renderErrorMessage()}
					{this.renderSaveResults()}
				</div>
				{!saveResults ? (
					<div className="popup__footer popup__footer--styled ">
						{this.renderInvalidFields()}
						<div className="btn--copy align--h--left spc--right--tny">
							{this.state.copied ? <div className="btn--copy--message">Copied!</div> : null}
							<button
								disabled={this.state.linkToCopy == null || hasValidationErrors}
								onClick={this.copyEmailLink}
								className={`btn btn--primary btn--med ${
									this.state.linkToCopy == null && !hasValidationErrors ? 'btn--loader' : ''
								}`}
							>
								<i
									className={`icon icon--nano icon--${
										this.state.linkToCopy == null ? 'copy--grey' : 'copy--white'
									} spc--right--tny`}
								></i>
								Copy Form Link
							</button>
						</div>
						<button
							onClick={this.handleSendClick}
							disabled={hasValidationErrors}
							className="btn btn--primary btn--med spc--right--tny"
						>
							Send
						</button>
						<button onClick={this.props.closeModal} className="btn btn--ghost btn--med">
							Cancel
						</button>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

ShareMPAForm.propTypes = {
	merchantName: PropTypes.string.isRequired,
	merchantEmail: PropTypes.string.isRequired,
	appid: PropTypes.string.isRequired,
};

export default withLoader(ShareMPAForm);
