import { map } from 'lodash';

import {
	StringFilterComponent as StringFilter,
	MultiselectCardknoxFilterComponent as MultiselectCardknoxFilter,
} from '../../../common/column-filters';
import { LeadStatusComponent, ReviewPricingStatusComponent, LabelComponent } from '../formatters';
import {
	PhoneNumberComponent,
	ExpandableComponent,
	DisplayDateComponent,
	DisplayBooleanComponent,
} from '../../../common/formatters';
import createGridColumn from '../../../common/components/grid/createGridColumn';

export const LeadColumns = map(
	[
		createGridColumn('leadId', 'Lead I­D', 100, {
			hideable: false,
			visible: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('dba', 'DBA', 150, {
			formatter: ExpandableComponent,
			getRowMetaData: row => row,
			filterRenderer: StringFilter,
		}),
		createGridColumn('corporateName', 'Corporate Name', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('market', 'Business Country', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('agentName', 'Rep 1', 150, {
			visible: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('telemarketerName', 'Rep 2', 150, {
			visible: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('leadStatus', 'Status', 150, {
			formatter: LeadStatusComponent,
			getRowMetaData: row => row,
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('reviewStatus', 'Review Status', 150, {
			formatter: ReviewPricingStatusComponent,
			getRowMetaData: row => row,
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('isMPASigned', 'MPA Signed', 125, {
			formatter: DisplayBooleanComponent,
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('title', 'Title', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('firstName', 'First Name', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('lastName', 'Last Name', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('phoneNumber', 'Phone Number', 150, {
			formatter: PhoneNumberComponent,
			filterRenderer: StringFilter,
		}),
		createGridColumn('email', 'Email', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('processor', 'Processor', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('label', 'Label', 150, {
			formatter: LabelComponent,
			filterRenderer: StringFilter,
		}),
		createGridColumn('createdDate', 'Created Date', 150, {
			filterable: false,
			formatter: DisplayDateComponent,
			isDefaultSorter: true,
			defaultSortDirection: 'DESC',
		}),
		createGridColumn('isHidden', '', 75, {
			sortable: false,
			hideable: false,
			filterable: false,
			resizable: false,
			fixWidth: true,
			hideOnExport: true,
			getRowMetaData: row => row,
			order: 999,
		}),
	],
	(column, index) => ({
		...column,
		order: column.order || index,
	})
);
