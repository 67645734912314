import React from 'react';
import PropTypes from 'prop-types';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { AddressComponent } from '../../common/components/address';
import { DropdownContainer } from '../../common/components/dropdown-container';

const isExpand = true;

class BusinessInformation extends React.Component {
	handleChange = (nestedKey, prefix = 'businessInfo') => e => {
		const { name, value } = e.target;
		const parts = name.split('_');
		const lastPart = parts[parts.length - 1];
		this.props.onChange(prefix, lastPart, value, nestedKey);
	};

	renderCountryOption = () => {
		const { businessInfo, hideCanadaOption } = this.props;
		let countryOptions = [{ value: 'USA', text: 'USA' }];
		if (!hideCanadaOption) {
			countryOptions.push({ value: 'Canada', text: 'Canada' });
		}
		return (
			<React.Fragment>
				<div className="form__field">
					<label className="label">Country</label>
					<DropdownContainer
						type="radio"
						selectName="country"
						value={businessInfo.businessAddress.country}
						onChange={this.handleChange('businessAddress')}
						labelText=""
						options={countryOptions}
						labelClass="display--n"
						disabled={this.props.disabled}
					/>
				</div>
			</React.Fragment>
		);
	};

	render() {
		const { businessInfo, corporateName } = this.props;

		return (
			<div className="form--popup">
				<ToggleContainer>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<React.Fragment>
								<div className={`flex--primary ${isToggled ? 'separator--grey1 spc--bottom--med' : ''}`}>
									<h6 className="spc--bottom--sml cursor--pointer" onClick={handleToggle}>
										Additional Business Information
									</h6>
									<i
										className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
											isToggled ? 'rotate--180' : ''
										}`}
										onClick={handleToggle}
									></i>
								</div>
								<div className={isToggled ? 'details__content' : 'display--n'}>
									<div className="row">
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Corporate Name:</label>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="corporateName"
													id="corporateName"
													value={corporateName}
													onChange={this.handleChange(null, 'generalInfo')}
												></input>
											</div>
										</div>
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Website:</label>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="website"
													id="businessInfo_website"
													value={businessInfo.website}
													onChange={this.handleChange(null)}
												></input>
											</div>
										</div>
									</div>

									<AddressComponent
										address={businessInfo.businessAddress}
										namePrefix="businessInfo_businessAddress"
										onChange={this.handleChange('businessAddress')}
										isCanada={this.props.isCanada}
										disabled={this.props.disabled}
									/>

									<div className="row">
										<div className="col col-sml-12 col-lrg-6">{this.renderCountryOption()}</div>
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field">
												<label className="label">Fax:</label>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="businessFax"
													id="businessInfo_businessFax"
													value={businessInfo.businessFax}
													onChange={this.handleChange(null)}
												></input>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						)}
					</Toggle>
				</ToggleContainer>
			</div>
		);
	}
}

BusinessInformation.propTypes = {
	hideCanadaOption: PropTypes.bool,
};

BusinessInformation.defaultProps = {
	hideCanadaOption: false,
};

export default BusinessInformation;
