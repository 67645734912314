import React from 'react';
import PropTypes from 'prop-types';
import { map, get } from 'lodash';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { IdleTimerProvider } from 'react-idle-timer';

import { Error as ErrorPage } from '../components/error';
import principalService from '../services/principalService';

const layoutRef = React.createRef();

function isUserAllowed(principal, section) {
	if (!section) {
		return true;
	}
	return !!(principal && principal.hasAccess && principal.hasAccess[section]);
}

function renderWithChild(Component, children, props) {
	return Component ? (
		<Component layoutRef={layoutRef} {...props}>
			{map(children, (routeData, i) => (
				<Route key={i} {...routeData} />
			))}
		</Component>
	) : (
		map(children, (routeData, i) => <Route key={i} {...routeData} />)
	);
}

function renderWithLayout(Layout, Component, children, isPublic, props, principal) {
	const route = renderWithChild(Component, children, props);

	function pushHistory() {
		const { history } = props;
		history.push({
			pathname: '/logout',
			state: { inactivity: true },
		});
	}

	return (
		<React.Fragment>
			{!isPublic ? (
				<IdleTimerProvider
					crossTab={true}
					onIdle={pushHistory}
					timeout={1000 * 60 * (principalService.get() && principalService.get().isQaUser ? 1440 : 30)}
				/>
			) : null}
			{!!Layout ? (
				<Layout ref={layoutRef} {...props}>
					{route}
				</Layout>
			) : (
				route
			)}
		</React.Fragment>
	);
}

const Route = (
	{ component: Component, layout: Layout, section, defaultRoute, children, isPublic = false, ...rest },
	context
) => (
	<ReactRoute
		{...rest}
		render={props => {
			const { location } = props;

			if (context.errors && !(location.state && location.state.ignoreErrors)) {
				return renderWithLayout(Layout, ErrorPage, null, isPublic, { ...props, errors: context.errors });
			}

			if (isPublic) {
				return renderWithLayout(Layout, Component, children, isPublic, props);
			}

			const principal = principalService.get();
			if (
				(get(location, 'state.isMfaRequired', false) || get(principal, 'redirectToSecurity')) &&
				location.pathname !== '/security'
			) {
				return <Redirect to={{ pathname: '/security', state: { isMfaRequired: true } }} />;
			}

			if (!isUserAllowed(principal, section)) {
				if (location.pathname === (defaultRoute || '/')) return <Redirect to={{ pathname: '/login' }} />;
				else return <Redirect to={{ pathname: defaultRoute || '/' }} />;
			}

			return renderWithLayout(Layout, Component, children, isPublic, props);
		}}
	/>
);

Route.contextTypes = {
	errors: PropTypes.array,
};

export default Route;
