import emailjs from 'emailjs-com';

const serviceId = 'sendgrid';
const userId = 'user_pAV41tk5V6obsTYT5ELwD';

class EmailService {
	send = async (templateId, templateParams) => {
		try {
			return await emailjs.send(serviceId, templateId, templateParams, userId);
		} catch (e) {
			throw { message: e.text, displayMessage: e.text };
		}
	};
}

const emailService = new EmailService();

export default emailService;
