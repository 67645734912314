import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { some, isFunction } from 'lodash';

import { Row } from '../react-data-grid';
import { isComponent } from '../../utilities';
import { Link } from 'react-router-dom';

class ZebraRendererComponent extends Component {
	propsUpdateKeys = ['row', 'columns', 'isScrolling'];
	stateUpdateKeys = [];

	get zebraClass() {
		const {
			row: { gridRowNumber },
		} = this.props;
		return gridRowNumber % 2 ? 'grid-style-odd' : 'grid-style-even';
	}

	shouldComponentUpdate(nextProps, nextState) {
		const propsUpdated = this.isAnyUpdated(this.propsUpdateKeys, this.props, nextProps);
		const stateUpdated = this.isAnyUpdated(this.stateUpdateKeys, this.state, nextState);
		return propsUpdated || stateUpdated;
	}

	isAnyUpdated = (keys, current, next) => some(keys, key => current[key] !== next[key]);

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	renderRow = row => {
		if (!isFunction(this.props.createDetailsLink)) {
			return (
				<Fragment>
					<Row {...this.props} />
				</Fragment>
			);
		}
		return (
			<Link className="anchor--dark" to={this.props.createDetailsLink(row)} onClick={e => e.preventDefault()}>
				<Row {...this.props} />
			</Link>
		);
	};

	render() {
		const {
			row,
			columns,
			openModal,
			rowDetailsProps,
			gridHolder,
			setDetailsRef,
			resizeGrid,
			type,
			closeRow,
		} = this.props;
		return (
			<div className={this.zebraClass}>
				{this.renderRow(row)}
				{row.isDetails && this.props.rowDetailsRenderer && (
					<this.props.rowDetailsRenderer
						row={row}
						visibleColumns={columns}
						openModal={openModal}
						refreshGridData={this.refreshGridData}
						gridHolder={gridHolder}
						setDetailsRef={setDetailsRef}
						resizeGrid={resizeGrid}
						type={type}
						{...(row.expandedRowProps || {})}
						{...rowDetailsProps}
						closeRow={closeRow}
					/>
				)}
			</div>
		);
	}
}

ZebraRendererComponent.propTypes = {
	row: PropTypes.shape({
		isDetails: PropTypes.bool,
		gridRowNumber: PropTypes.number,
	}),
	columns: PropTypes.array,
	isScrolling: PropTypes.bool,
	refreshGridData: PropTypes.func,
	openModal: PropTypes.func,
	setDetailsRef: PropTypes.func,
	resizeGrid: PropTypes.func,
	gridHolder: PropTypes.object,
	type: PropTypes.string,
	rowDetailsRenderer: isComponent,
	rowDetailsProps: PropTypes.object,
	closeRow: PropTypes.func,
};

export default ZebraRendererComponent;
