import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ToggleFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterTerm: 'false',
		};
	}

	clearFilters = () => {
		const e = {
			target: {
				'data-val': undefined,
			},
		};
		this.handleChange(e);

		this.setState({
			filterTerm: 'false',
		});
	};

	handleChange = e => {
		const dataValue = e.target.getAttribute('data-val');
		let filterTerm = dataValue;
		if (!this.props.exclusiveOnTrue && filterTerm === 'true') {
			// include both true and false - equivalent to no filter
			filterTerm = '';
		}
		this.setState({ filterTerm });
		this.props.onChange({ filterTerm, column: this.props.column });
	};

	render() {
		return <div className={this.props.className}>{this.renderInput()}</div>;
	}
}

ToggleFilterComponent.defaultProps = {
	exclusiveOnTrue: true,
	className: '',
};

ToggleFilterComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	exclusiveOnTrue: PropTypes.bool,
	className: PropTypes.string,
};

export default ToggleFilterComponent;
