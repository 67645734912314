import { toNumber, includes } from 'lodash';

function getProcessor(merchant) {
	if (!merchant) return null;
	return toNumber(merchant.processorId);
}

export function isElavon(merchant) {
	return includes([21, 107], getProcessor(merchant));
}
export function isElavonCad(merchant) {
	return includes([84, 107], getProcessor(merchant));
}
export function isFd(merchant) {
	return includes([60, 108], getProcessor(merchant));
}
export function isFdCad(merchant) {
	return includes([111, 117], getProcessor(merchant));
}
export function isCanada(merchant) {
	return includes([84, 107, 1110, 111, 117, 122], getProcessor(merchant));
}
export function isGoPlus(merchant) {
	return includes([128], getProcessor(merchant));
}
export function isAch(merchant) {
	return includes([85], getProcessor(merchant));
}
export function isAchq(merchant) {
	return includes([130], getProcessor(merchant));
}
