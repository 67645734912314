import React from 'react';
import PropTypes from 'prop-types';

import ToggleFilterComponent from './ToggleFilter';

function getClass(curr, val) {
	return `tabs--primary__item${curr === val ? ' is-active' : ''}`;
}

class TabFilterComponent extends ToggleFilterComponent {
	renderInput = () => {
		const { filterTerm } = this.state;
		const { trueLabel, falseLabel } = this.props;

		return (
			<React.Fragment>
				<span className={getClass(filterTerm, 'false')} onClick={this.handleChange} data-val="false">
					{falseLabel}
				</span>
				<span className={getClass(filterTerm, 'true')} onClick={this.handleChange} data-val="true">
					{trueLabel}
				</span>
			</React.Fragment>
		);
	};

	componentDidMount() {
		this.handleChange({ target: { getAttribute: () => 'false' } });
	}
}

TabFilterComponent.propTypes = {
	trueLabel: PropTypes.string.isRequired,
	falseLabel: PropTypes.string.isRequired,
};

export default TabFilterComponent;
