import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import { isElavon, isFd, isGoPlus, isAch, isAchq } from '../../common/utilities';

class MerchantSidebarComponent extends Component {
	handleRouteChange = () => {
		if (!this.props.layoutRef.current) return;
		this.props.layoutRef.current.removeSubSidebar();
	};
	render() {
		const {
			merchant,
			match: {
				params: { appid: appId },
			},
		} = this.props;
		const displayBankingServices = isElavon(merchant) || isFd(merchant);
		const displayBankReports = false;
		const summaryLink = merchant && isAch(merchant) ? 'ach-summary' : 'summary';

		return (
			<Fragment>
				<div className="sidebar--secondary">
					<div className="sidebar--secondary__header">
						<NavLink className="sidebar--secondary__link" to="/merchants">
							<i className="icon icon--tiny icon--arrow--left spc--right--tny"></i>Merchant Accounts
						</NavLink>
					</div>
					<ul className="nav nav--secondary">
						<Fragment>
							<SecondaryNavItem
								appId={appId}
								onRouteChange={this.handleRouteChange}
								to="account"
								label="Account Overview"
							/>
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="equipment" label="Equipment" />
							{merchant && !isAch(merchant) && (
								<Fragment>
									<SecondaryNavItem
										appId={appId}
										onRouteChange={this.handleRouteChange}
										to="equipment-new"
										label={
											<Fragment>
												<i className="icon icon--xsml nav__icon nav__icon--add icon--middle"></i>
												<span>Add Equipment</span>
											</Fragment>
										}
									/>
								</Fragment>
							)}
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="tickets" label="Tickets" />
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="notes" label="Notes" />
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="files" label="Files" />
							{isGoPlus(merchant) ? (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="gosummary"
									label="Go App Summary"
								/>
							) : isAchq(merchant) ? (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="achq-summary"
									label="Achq App Summary"
								/>
							) : (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to={summaryLink}
									label="App Summary"
								/>
							)}
							{merchant && !isAch(merchant) && !isGoPlus(merchant) && !isAchq(merchant) && (
								<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="rates" label="Rates" />
							)}
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="invoices" label="Invoices" />
							{displayBankingServices && (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="banking-services"
									label="Banking Services"
								/>
							)}
							{displayBankReports && (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="bank-reports"
									label="Bank Reports"
								/>
							)}
						</Fragment>
					</ul>
				</div>
			</Fragment>
		);
	}
}

function SecondaryNavItem({ label, to, onRouteChange, appId }) {
	return (
		<li className="nav--secondary__item">
			<NavLink
				onClick={onRouteChange}
				className="nav--secondary__link"
				activeClassName="is-active"
				to={`/merchants/${appId}/${to}`}
			>
				{label}
			</NavLink>
		</li>
	);
}

export default withRouter(withContext(MerchantSidebarComponent, MerchantContext, 'merchant'));
