import React, { Component, Fragment, createRef } from 'react';
import { get, toLower, map, trim, isEmpty, orderBy } from 'lodash';
import { func, object } from 'prop-types';
import { appService } from '../../../services';
import { DisplayDateComponent } from '../../../common/formatters';
import { Toggle, ToggleContainer } from '../../../common/components/toggle';
import { RenderSanitizedHTML } from '../../../common/utilities';

const statusClass = {
	'closed - resolved': 'inactive',
	'closed - unresolved': 'inactive',
	open: 'active',
	new: 'active',
	'deleted [closed]': 'inactive',
};

const noteTabs = {
	new: 'new',
	archive: 'archive',
};

class TicketDetailsComponent extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			notes: [],
			details: null,
			newNote: '',
			noteTab: noteTabs.new,
			errorMessage: null,
			isTicketDetailsToggled: false,
		};

		this.errorRef = createRef();
	}

	componentDidMount() {
		this.showLoader(true);
		appService
			.getTicketDetails(get(this.props, 'row.ticketNumber'))
			.then(({ ticketDetails: { notes, details } }) => this.setState({ notes, details, isLoading: false }));
	}

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

	handleTicketDetailsToggle = () => this.setState({ isTicketDetailsToggled: !this.state.isTicketDetailsToggled });

	handleSave = () => {
		const {
			row: { ticketNumber },
		} = this.props;
		const { newNote } = this.state;

		this.showLoader(true);
		appService
			.addNoteToTicket(parseInt(ticketNumber), [trim(newNote)])
			.then(() =>
				appService
					.getTicketDetails(get(this.props, 'row.ticketNumber'))
					.then(({ ticketDetails: { notes, details } }) =>
						this.setState({ notes, details, isLoading: false, newNote: '' })
					)
			)
			.catch(err => {
				this.showLoader();
				this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	showLoader = (isLoading = false) => this.setState({ isLoading });

	renderWithToggle = (initialToggle, title, content) => (
		<Toggle initialToggle={initialToggle}>
			{({ isToggled, handleToggle }) => (
				<div>
					<div className="flex--tertiary cursor--pointer" onClick={handleToggle}>
						<span className="type--base type--wgt--semibold">{title}</span>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spc--left--sml ${isToggled ? 'rotate--90' : ''}`}
						></i>
					</div>
					<div className={isToggled ? 'spc--top--sml' : 'display--n'}>{content}</div>
				</div>
			)}
		</Toggle>
	);

	renderTicketDetails = () => {
		const {
			row: { dba, subject, issue, assignedTo, dateOpenedDisplay, modifiedDisplay, callerName, callerPhone },
		} = this.props;

		return (
			<Fragment>
				<div className="popup--ticket__sidebar__list">
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">DBA</label>
						<div className="anchor--primary">{dba}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Subject</label>
						<div>{subject}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Issue</label>
						<div>{issue}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Assigned to</label>
						<div>{assignedTo}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Opened</label>
						<div>{dateOpenedDisplay}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Modified</label>
						<div>{modifiedDisplay}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Caller Name</label>
						<div>{callerName}</div>
					</div>
					<div className="popup--ticket__sidebar__row">
						<label className="type--xsml type--wgt--medium">Caller Phone</label>
						<div>{callerPhone}</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderDetails = () =>
		this.renderWithToggle(
			false,
			'Original Ticket Note',
			<RenderSanitizedHTML content={this.state.details} title={'originalTicketNote'} />
		);

	renderNewNote = () => {
		const { newNote, isLoading } = this.state;

		return (
			<div className="popup--ticket__footer">
				<div className="popup--ticket__input__wrapper">
					<textarea
						className="input popup--ticket__input"
						type="text"
						name="newNote"
						id="newNote"
						placeholder="Write your note..."
						value={newNote}
						onChange={this.handleChange}
					/>
					<div className="popup--ticket__input__side"></div>
					<button
						className="btn btn--sml btn--primary popup--ticket__input__button"
						onClick={this.handleSave}
						disabled={!trim(newNote) || isLoading}
					>
						Save
					</button>
				</div>
			</div>
		);
	};

	renderArchiveOfNotes = () =>
		isEmpty(this.state.notes) ? (
			<p className="message message--default spc--top--sml">This ticket does not have any notes.</p>
		) : (
			<div>
				{map(
					orderBy(this.state.notes, [note => new Date(note.dateCreated)], ['desc']),
					({ createdBy, dateCreated, emailed, note }) => (
						<div className="popup--ticket__list__row" key={`${createdBy}.${dateCreated}`}>
							<div className="popup--ticket__list__header">
								<label className="type--color--text--dark type--wgt--semibold">{createdBy}</label>
								<div>·</div>
								<div>
									<DisplayDateComponent value={dateCreated} />
								</div>
							</div>
							<div className="spc--top--sml">{note}</div>
						</div>
					)
				)}
			</div>
		);

	renderNotes = () => (
		<div>
			<div className="tabs--secondary">
				<li className={'tabs--secondary__item is-active'}>All Notes</li>
			</div>
			{this.renderArchiveOfNotes()}
			{this.renderNewNote()}
		</div>
	);

	render() {
		const { isLoading, errorMessage, isTicketDetailsToggled } = this.state;
		const {
			row: { ticketNumber, assignedTo, status },
		} = this.props;

		return (
			<div className="popup--ticket">
				<div className="popup--ticket__main">
					<div className="popup__header popup__header--ticket">
						<div>
							<div className="flex--primary spc--bottom--nano">
								<h1 className="type--lrg type--color--grey4 type--wgt--semibold spc--right--xsml">
									Ticket <span className="type--color--text--dark">#{ticketNumber}</span>
								</h1>
								<div
									className={`type--status type--status--${statusClass[toLower(status)] || 'pending'} type--uppercase`}
								>
									{status}
								</div>
							</div>
							<div className="flex--primary">
								<div className="type--xsml type--color--grey4">
									Assigned to: <span className="type--color--text--dark">{assignedTo}</span>
								</div>
							</div>
						</div>
						<button className="btn btn--med btn--default spc--right--tny" onClick={this.handleTicketDetailsToggle}>
							{isTicketDetailsToggled ? 'Hide detail' : 'Show detail'}
							<i
								className={`icon icon--nano icon--arrow--${
									isTicketDetailsToggled ? 'left' : 'right'
								}--grey spc--left--xsml`}
							></i>
						</button>
					</div>
					<Fragment>
						{isLoading ? (
							<div className="loader">
								<div className="loader__spinner"></div>
							</div>
						) : (
							<Fragment>
								<div className="popup--ticket__subheader">
									<Fragment>
										{errorMessage ? (
											<div className="note note--warning" ref={this.errorRef}>
												{errorMessage}
											</div>
										) : null}
										<ToggleContainer>{this.renderDetails()}</ToggleContainer>
									</Fragment>
								</div>
								<div className="popup__body popup__body--ticket">{this.renderNotes()}</div>
							</Fragment>
						)}
					</Fragment>
				</div>
				{isTicketDetailsToggled && (
					<div className="popup--ticket__sidebar" style={{ 'max-height': '100%' }}>
						{this.renderTicketDetails()}
					</div>
				)}
			</div>
		);
	}
}

TicketDetailsComponent.defaultProps = {
	row: {},
};

TicketDetailsComponent.propTypes = {
	closeModal: func.isRequired,
	row: object.isRequired,
	addNotification: func.isRequired,
};

export default TicketDetailsComponent;
