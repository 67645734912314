import React, { Fragment } from 'react';
import { map, startCase, includes } from 'lodash';
import { func, object } from 'prop-types';
import moment from 'moment';

const EquipmentDetailsPopup = ({
	closeModal,
	row: {
		name,
		category,
		purchaseType,
		enteredDateDisplay,
		deactivatedDateDisplay,
		equipmentMid,
		serialNumber,
		planName,
		fees,
		freeTransactionAmount,
	},
}) => (
	<div>
		<div className="popup__header">
			<h4>Equipment Details</h4>
		</div>
		<div className="popup__body">
			<div className="equipment__details__table spc--bottom--med">
				<div className="equipment__details__table__item border">
					<div className="type--wgt--medium spc--bottom--tny">Equipment Name</div>
					<div>{name}</div>
				</div>
				<div className="equipment__details__table__item">
					<div className="type--wgt--medium spc--bottom--tny">Plan Name</div>
					<div>{planName}</div>
				</div>
				<div className="equipment__details__table__item border">
					<div className="type--wgt--medium spc--bottom--tny">Equipment Type</div>
					<div>{category}</div>
				</div>
				<div className="equipment__details__table__item">
					<div className="type--wgt--medium spc--bottom--tny">Purchase Type</div>
					<div>{startCase(purchaseType)}</div>
				</div>
				<div className="equipment__details__table__item border">
					<div className="type--wgt--medium spc--bottom--tny">Entered Date</div>
					<div>{enteredDateDisplay}</div>
				</div>
				<div className="equipment__details__table__item">
					<div className="type--wgt--medium spc--bottom--tny">Deactivated Date</div>
					<div>{deactivatedDateDisplay}</div>
				</div>
				<div className="equipment__details__table__item border">
					<div className="type--wgt--medium spc--bottom--tny">Equipment MID</div>
					<div>{equipmentMid}</div>
				</div>
				<div className="equipment__details__table__item">
					<div className="type--wgt--medium spc--bottom--tny">Serial #</div>
					<div>{serialNumber}</div>
				</div>
				{includes(['Cardknox', 'Fidelipay'], name) && (
					<div className="equipment__details__table__item">
						<div className="type--wgt--medium spc--bottom--tny">Free Transactions Included</div>
						<div>{freeTransactionAmount}</div>
					</div>
				)}
			</div>
			{fees && fees.length > 0 && (
				<Fragment>
					<h6 className="type--wgt--bold type--med">Fees</h6>
					<hr className="separator separator--grey1 separator--neg--24"></hr>
					<table className="table table--primary">
						<thead>
							<tr>
								<th>Description</th>
								<th className="type--right">Price</th>
								<th className="type--right">Activated</th>
							</tr>
						</thead>
						<tbody>
							{map(fees, ({ feeName, merchantPrice, activatedDate }, index) => (
								<tr key={`${feeName}.${index}`} className={index % 2 ? 'odd' : 'even'}>
									<td>{feeName}</td>
									<td className="type--right">${(isNaN(merchantPrice) ? 0 : merchantPrice).toFixed(4)}</td>
									<td className="type--right">{moment(activatedDate).format(process.env.REACT_APP_DATE_FORMAT)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</Fragment>
			)}
		</div>
	</div>
);

EquipmentDetailsPopup.defaultProps = {
	row: {},
};

EquipmentDetailsPopup.propTypes = {
	closeModal: func.isRequired,
	row: object.isRequired,
};

export default EquipmentDetailsPopup;
