// Poor man's context for holding the user value for MFA.
// This is only needed because we cannot pass in functions through local storage.
// If we need this functionality in more places with added reactivity, we should refactor public
// pages to use an actual React context.

const context = {
	user: null,
	setUser: user => {
		context.user = user;
	},
};

export default context;
