import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import withContext from '../context/withContext';
import ToggleContext from './ToggleContext';

class Toggle extends React.Component {
	static toggleId = 0;
	constructor(props) {
		super(props);
		this.id = ++Toggle.toggleId;
		this.props.toggleStates.create(this.id, this.props.initialToggle);
	}

	componentWillUnmount() {
		this.props.toggleStates.destroy(this.id);
	}

	render() {
		const toggleState = this.props.toggleStates.get(this.id);
		if (!toggleState) return null;
		if (isFunction(this.props.children)) return this.props.children(toggleState);
		return React.cloneElement(this.props.children, toggleState);
	}
}

Toggle.propTypes = {
	toggleStates: PropTypes.shape({
		create: PropTypes.func,
		destroy: PropTypes.func,
		get: PropTypes.func,
	}),
	children: PropTypes.func.isRequired,
};

export default withContext(Toggle, ToggleContext, 'toggleStates');
