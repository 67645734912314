import React, { Component, Fragment } from 'react';
import { renderIf } from '../../utilities';

function withLoader(WrappedComponent) {
	return class LoaderComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				isLoading: false,
			};
		}

		showLoader = value => {
			this.setState({
				isLoading: value,
			});
		};

		render() {
			const { isLoading } = this.state;
			return (
				<Fragment>
					{renderIf(isLoading)(<div className="loader--progress" />)}
					<WrappedComponent isLoading={isLoading} showLoader={this.showLoader} {...this.props} />
				</Fragment>
			);
		}
	};
}

export default withLoader;
