import moment from 'moment';

export function toJson(data, options) {
	const filename = options.filename || 'export.json';
	const contentType = 'application/json;charset=utf-8';

	let file = new File(filename);

	let jsonString = JSON.stringify(data);

	return contentType + ',' + encodeURIComponent(jsonString);
}

export function generateFileName(all = false, refNum = '') {
	let format = moment.utc().format('YYYY_MM_DD_HH:mm:ss');

	if (refNum !== '') {
		format += `_Cardknox_${refNum}`;
		return format;
	}

	if (all) {
		format += '_Cardknox_all';
	} else {
		format += '_Cardknox_current';
	}

	return format;
}
