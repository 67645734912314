import React from 'react';
import { string, bool } from 'prop-types';

import { renderIf } from './../../common/utilities';
import { DisplayBooleanComponent } from '../../common/formatters';

class ProfitBreakdown extends React.Component {
	render() {
		const { profit, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <h6 className="spc--bottom--med">Profit Breakdown </h6>}

					<div className="details__content">
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblBatchFee">
										Batch Fee{' '}
									</label>
									<label className="input input--med input--fake" name="lblBatchFee" id="lblBatchFee">
										{profit.batchFee}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblMiscProfit">
										MISC Profit
									</label>
									<label className="input input--med input--fake" name="lblMiscProfit" id="lblMiscProfit">
										{profit.miscProfit}
									</label>
								</div>
							</div>
						</div>
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Base Rate Profit</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardBaseRateProfit">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardBaseRateProfit"
												id="lblBankcardBaseRateProfit"
											>
												{profit.bankcardProfitBreakdown.baseRateProfit}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexBaseRateProfit">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexBaseRateProfit"
												id="lblAmexBaseRateProfit"
											>
												{profit.amexProfitBreakdown.baseRateProfit}
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Downgrade Profit</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardDowngradeProfit">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardDowngradeProfit"
												id="lblBankcardDowngradeProfit"
											>
												{profit.bankcardProfitBreakdown.downgradeProfit}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexDowngradeProfit">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexDowngradeProfit"
												id="lblAmexDowngradeProfit"
											>
												{profit.amexProfitBreakdown.downgradeProfit}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Transaction Fee Profit</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardTransactionFeeProfit">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardTransactionFeeProfit"
												id="lblBankcardTransactionFeeProfit"
											>
												{profit.bankcardProfitBreakdown.transactionFeeProfit}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexTransactionFeeProfit">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexTransactionFeeProfit"
												id="lblAmexTransactionFeeProfit"
											>
												{profit.amexProfitBreakdown.transactionFeeProfit}
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Statement Fee</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardStatementFee">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardStatementFee"
												id="lblBankcardStatementFee"
											>
												{profit.bankcardProfitBreakdown.statementFee}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexStatementFee">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexStatementFee"
												id="lblAmexStatementFee"
											>
												{profit.amexProfitBreakdown.statementFee}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Total Current Profit</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardTotalCurrentProfit">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardTotalCurrentProfit"
												id="lblBankcardTotalCurrentProfit"
											>
												{profit.bankcardProfitBreakdown.totalCurrentProfit}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexTotalCurrentProfit">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexTotalCurrentProfit"
												id="lblAmexTotalCurrentProfit"
											>
												{profit.amexProfitBreakdown.totalCurrentProfit}
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Additional Savings</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardAdditionalSavings">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardAdditionalSavings"
												id="lblBankcardAdditionalSavings"
											>
												{profit.bankcardProfitBreakdown.additionalSavings}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexAdditionalSavings">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexAdditionalSavings"
												id="lblAmexAdditionalSavings"
											>
												{profit.amexProfitBreakdown.additionalSavings}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Effective Rate</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardEffectiveRate">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardEffectiveRate"
												id="lblBankcardEffectiveRate"
											>
												{profit.bankcardProfitBreakdown.effectiveRate}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexEffectiveRate">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexEffectiveRate"
												id="lblAmexEffectiveRate"
											>
												{profit.amexProfitBreakdown.effectiveRate}
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="card--primary__subtitle">Effective Profit Margin</div>
								<div className="row grid-align-middle">
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblBankcardEffectiveProfitMargin">
												V/MC
											</label>
											<label
												className="input input--med input--fake"
												name="lblBankcardEffectiveProfitMargin"
												id="lblBankcardEffectiveProfitMargin"
											>
												{profit.bankcardProfitBreakdown.effectiveProfitMargin}
											</label>
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										<div className="spc--bottom--med form__field">
											<label className="label" htmlFor="lblAmexEffectiveProfitMargin">
												AMEX
											</label>
											<label
												className="input input--med input--fake"
												name="lblAmexEffectiveProfitMargin"
												id="lblAmexEffectiveProfitMargin"
											>
												{profit.amexProfitBreakdown.effectiveProfitMargin}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblCombinedEffectiveRate">
										Combined Effective Rate{' '}
									</label>
									<label
										className="input input--med input--fake"
										name="lblCombinedEffectiveRate"
										id="lblCombinedEffectiveRate"
									>
										{profit.combinedEffectiveRate}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblCombinedEffectiveProfitMargin">
										Combined Effective Profit Margin{' '}
									</label>
									<label
										className="input input--med input--fake"
										name="lblCombinedEffectiveProfitMargin"
										id="lblCombinedEffectiveProfitMargin"
									>
										{profit.combinedEffectiveProfitMargin}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ProfitBreakdown.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

ProfitBreakdown.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default ProfitBreakdown;
