import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

class ModalComponent extends Component {
	onKeyDown = e => {
		if (e.keyCode === 9) {
			// tab
			e.stopPropagation();
		}
	};

	render() {
		const { children, onClose, hideCloseButton, ...props } = this.props;
		const rootElement = document.getElementById('root');

		return (
			<span onKeyDown={this.onKeyDown}>
				<ReactModal onRequestClose={onClose} appElement={rootElement} {...props}>
					{!hideCloseButton && <button tabIndex="-1" className="popup__close" onClick={onClose} />}
					{children}
				</ReactModal>
			</span>
		);
	}
}

ModalComponent.defaultProps = {
	hideCloseButton: false,
	shouldCloseOnOverlayClick: true,
	overlayClassName: 'popup__overlay',
	className: 'popup__content',
};

ModalComponent.propTypes = {
	children: PropTypes.element.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	overlayClassName: PropTypes.string,
	className: PropTypes.string,
	shouldCloseOnOverlayClick: PropTypes.bool,
};

export default ModalComponent;
