import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DBASelectionComponent extends Component {
	get dbaValue() {
		return this.props.filter.values.dba;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'dba',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">DBA: </label>
				{this.dbaValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

DBASelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default DBASelectionComponent;
