export function getThumbnailOverlay(outOfStock, purchaseTypes, isAccessories = false) {
	const prefix = ` equipment__status${isAccessories ? ' equipment__status--accessories' : ''}`;
	const isAvailableForPurchase = purchaseTypes && purchaseTypes.purchase;

	if (outOfStock === undefined && !isAvailableForPurchase) {
		return prefix + ' not-available';
	} else if (outOfStock) {
		return prefix + ' out-of-stock';
	}

	return '';
}
