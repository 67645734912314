import React, { Fragment, useEffect, useState } from 'react';
import { appService } from '../../services/';
import { get, map, repeat, replace } from 'lodash';
import { Link } from 'react-router-dom';
import { isAch, isAchq, isGoPlus } from '../../common/utilities';

const CloneAppPopup = ({ appType, data, closeModal }) => {
	const [processor, setProcessor] = useState('');
	const [country, setCountry] = useState(get(data, 'businessCountry', '') || get(data, 'market', ''));
	const [isLoading, setIsLoading] = useState(false);
	const [response, setResponse] = useState(null);
	const [processors, setProcessors] = useState([]);
	const [allowAchqApplication, setAllowAchq] = useState(false);

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			let url = null;
			if (appType === 'Lead') {
				const response = await appService.saveAccountDetails({
					...data,
					leadId: null,
					businessInfo: {
						ownershipType: 'Unknown',
					},
				});
				url = `/leads/account/${response.leadId}`;
			} else {
				const appId = await appService.cloneEApp(data.appId, country, processor);
				if (isGoPlus({ processorId: processor })) {
					url = `/eapp/cardknox-go/${appId}`;
				} else if (isAchq({ processorId: processor })) {
					url = `/eapp/achq/${appId}`;
				} else if (isAch({ processorId: processor })) {
					url = `/eapp/ach/${appId}`;
				} else {
					url = `/eapp/mpa/${appId}`;
				}
			}
			setResponse({
				message: `${appType} successfuly cloned, you are being redirected.`,
				success: true,
				url,
				redirectionError: false,
			});
			handleRedirection(url);
		} catch (error) {
			setResponse({ message: `An error occurred while duplicating ${appType}.`, success: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleRedirection = url => {
		setTimeout(() => {
			const win = window.open(window.location.origin + url, '_blank');
			if (win === null) {
				setResponse({ message: `${appType} successfuly cloned.`, success: true, url, redirectionError: true });
			} else {
				closeModal();
			}
		}, 3000);
	};

	const handleProcessorChange = e => {
		const { value } = e.target;
		setProcessor(value);
	};

	const handleCountryChange = e => {
		const { value } = e.target;
		setCountry(value);
	};

	const fetchProcessorList = async () => {
		try {
			if (country === '') {
				setProcessors([]);
				return;
			}
			const { allowAchqApplication } = await appService.getAllowAchqApplication();
			setAllowAchq(allowAchqApplication);
			setProcessor('');
			const { processorList } = await appService.getProcessorListByCountry(country);
			setProcessors(processorList);
		} catch (error) {
			setResponse({ message: 'Error fetching processors', success: false });
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (appType !== 'Lead') {
			fetchProcessorList();
		}
	}, [country]);

	const renderBody = () => (
		<Fragment>
			{appType !== 'Lead' ? (
				<Fragment>
					<div className="spc--bottom--med">Select Country and Processor to continue.</div>
					<label className="label label--med spc--bottom--sml">Country</label>
					<div className="spc--bottom--tny">
						<input
							type="radio"
							className="input--radio"
							id="countryUSA"
							name="countryUSA"
							value="USA"
							checked={country === 'USA'}
							onChange={handleCountryChange}
						/>
						<label className="label" htmlFor="countryUSA">
							USA
						</label>
					</div>
					<div>
						<input
							type="radio"
							className="input--radio"
							id="countryCanada"
							name="countryCanada"
							value="Canada"
							checked={country === 'Canada'}
							onChange={handleCountryChange}
						/>
						<label className="label" htmlFor="countryCanada">
							Canada
						</label>
					</div>
					<label className="label label--med spc--bottom--sml spc--top--med">Processor</label>
					<select
						className="input input--med input--select"
						name="processors_list"
						value={processor}
						onChange={handleProcessorChange}
					>
						<option value={''}>Select Processor</option>
						{country === 'USA' && <option value={85}>ProfitStars Ach</option>}
						{country === 'USA' && allowAchqApplication && <option value={130}>ACHQ</option>}
						{map(processors, processor => (
							<option value={processor.key}>{processor.value}</option>
						))}
					</select>
				</Fragment>
			) : (
				<div className="spc--bottom--med">Are you sure you want to clone this lead?</div>
			)}
		</Fragment>
	);

	const renderResponse = () => (
		<Fragment>
			<div>
				<span className={`message message--${response.success ? 'success' : 'warning'}`}>{response.message}</span>
			</div>
			{response.success && (
				<h6 className="spc--top--med">
					{response.redirectionError === false ? (
						<LoaderWithDotsWrapper isLoading={true} template={'Redirecting[dots]'} renderSpinner={false} />
					) : (
						<div>
							Redirection failed. Make sure your browser has redirection enabled.
							<Link
								to={{
									pathname: response.url,
								}}
								href="javascript:void(0)"
								target="_blank"
								title={`Cloned ${appType}`}
							>
								<button className="btn btn--primary btn--med spc--top--xsml" onClick={closeModal}>
									Open {appType}
								</button>
							</Link>
						</div>
					)}
				</h6>
			)}
		</Fragment>
	);

	return (
		<div>
			{
				<Fragment>
					<div className="popup__header">
						<h5>
							Clone {appType}: {data.dba}
						</h5>
					</div>
					<div className="popup__body">
						{response === null ? (
							<Fragment>
								<LoaderWithDotsWrapper isLoading={isLoading} Children={renderBody} />
							</Fragment>
						) : (
							renderResponse()
						)}
					</div>
					<div className="popup__footer popup__footer--styled">
						<div className="type--left">
							<button className="btn btn--ghost btn--med" onClick={closeModal} disabled={isLoading}>
								Close
							</button>
						</div>
						<div className="type--right">
							<button
								className="btn btn--primary btn--med spc--left--xsml"
								onClick={handleSubmit}
								disabled={
									isLoading || (appType !== 'Lead' && (country === '' || processor === '')) || response !== null
								}
							>
								Clone {appType}
							</button>
						</div>
					</div>
				</Fragment>
			}
		</div>
	);
};

const LoaderWithDotsWrapper = ({
	isLoading,
	Children,
	template = 'Cloning in progress[dots]',
	renderSpinner = true,
}) => {
	const [count, setCount] = useState(1);

	useEffect(() => {
		if (isLoading) {
			const intervalId = setInterval(() => {
				if (count === 3) {
					setCount(1);
				} else {
					setCount(t => t + 1);
				}
			}, 500);
			return () => clearInterval(intervalId);
		}
	}, [count]);

	return (
		<Fragment>
			{isLoading ? (
				<div className="spc--bottom--lrg">
					<h3 className="spc--bottom--lrg">{replace(template, '[dots]', repeat('.', count))}</h3>
					{renderSpinner && <div className="loader__spinner"></div>}
				</div>
			) : (
				<Children />
			)}
		</Fragment>
	);
};

export default CloneAppPopup;
