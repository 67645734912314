import React, { Component, Fragment } from 'react';
import {
	find,
	sumBy,
	cloneDeep,
	map,
	get,
	groupBy,
	orderBy,
	keys,
	sortBy,
	toLower,
	startCase,
	every,
	compact,
	first,
} from 'lodash';
import { func, array, object, string, bool } from 'prop-types';
import { NumericFormat as NumberFormat } from 'react-number-format';

import { formatCurrency } from '../../helpers/currency-formatter';
import HardwareListItemComponent from './hardware-list-item';
import { Modal } from '../../common/components/modal';
import { AddressComponent } from '../../common/components/address';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { renderEquipmentOption } from '../../common/components/equipmentOption/renderEquipmentOption';
import { getThumbnailOverlay } from '../../common/utilities';

const outOfStockTooltip = 'Out of Stock';
const visibleEquipmentOptionsBySubcategory = {
	paper: 'CaseOrRoll',
	stands: 'Plate',
};
class AccessoriesListComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShippingOptionPopupOpen: false,
			filteredList: groupBy(orderBy(props.accessoriesList, 'subcategory'), 'subcategory'),
			subs: [],
			filterSettings: {
				name: '',
				subcategory: '',
				// sortBy: 'ASC'
			},
			modal: {
				name: modalNames.none,
				data: null,
			},
		};
	}

	mapToOption = (label, name) => ({ name, label });

	accessoriesSelectToggle = equipmentId => e => {
		const { merchantEquipment, accessoriesList } = this.props;
		let accessories = cloneDeep(merchantEquipment.find(merch => merch.equipmentId === equipmentId));

		if (!!accessories) {
			accessories.isSelected = !accessories.isSelected;

			if (!accessories.isSelected && accessories.accessories) {
				accessories.accessories = map(accessories.accessories, item => {
					return { ...item, isSelected: false, quantity: 0 };
				});
			}
		} else {
			accessories = this.props.createNewEquipment(equipmentId, true, false, true);
		}

		const packageType = toLower(get(accessories, 'equipmentOptions.CaseOrRoll', ''));

		if (packageType && accessories.quantity == 1) {
			const accessory = find(accessoriesList, a => a.equipmentId == equipmentId);

			if (accessory) {
				const option = find(accessory.equipmentOptions, o => toLower(o.name).indexOf(`${packageType} quantity`) > -1);
				accessories.quantity = parseInt(get(first(option.options), 'value', accessories.isSelected ? 1 : 0));
			}
		}

		this.props.onChange(accessories);
	};

	onViewEquipmentDetail = equipmentId => e => {
		this.props.onShowEquipment(equipmentId);
	};

	subcategoryClick = subcategory => e => {
		this.setFilter('subcategory', subcategory, false);
	};

	setFilter(fieldName, valueToFilter, clearOtherFilters) {
		const { accessoriesList } = this.props;
		const { filterSettings } = this.state;

		let query = cloneDeep(filterSettings);
		let filteredList = [];
		let subs = [];

		if (clearOtherFilters) query = {};

		query[fieldName] = valueToFilter.toLowerCase();

		// const sortByValue = query.sortBy;
		// delete query.sortBy;

		filteredList = accessoriesList.filter(item => {
			for (let key in query) {
				if (item[key] === undefined || !item[key].toLowerCase().includes(query[key])) {
					return false;
				}
			}
			return true;
		});

		filteredList = sortBy(filteredList, ({ purchasePlans }) => {
			const plan = get(purchasePlans[0], 'fees');
			const fee = find(plan, { feeType: 'OneTimeFee' });
			const retailPrice = get(fee, 'retailPrice', 0);

			return retailPrice;
		});

		// if (sortByValue === 'desc') {
		//     filteredList.reverse();
		// }

		// query.sortBy = toUpper(sortByValue);

		this.setState({ filterSettings: query, filteredList: groupBy(filteredList, 'subcategory'), subs });
	}

	handleFiltersChange = ({ target: { name, value } }) => this.setFilter(name, value, false);

	handleFeeChange = (equipmentId, feeId, outOfStock) => ({ floatValue }) => {
		const { merchantEquipment, onChange } = this.props;
		let accessories = cloneDeep(find(merchantEquipment, { equipmentId }));

		if (!accessories) {
			accessories = this.props.createNewEquipment(equipmentId, true, false, true);
		}

		const fee = find(accessories.fees, { feeId });

		fee.merchantPrice = floatValue || 0;
		this.selectAccesory(accessories, outOfStock);

		onChange(accessories);
	};

	selectAccesory = (accessory, outOfStock) => {
		if (outOfStock) return;
		const existingAccessory = find(this.props.merchantEquipment, { equipmentId: accessory.equipmentId });
		if (accessory.quantity > 0 || !existingAccessory || existingAccessory.quantity === 0) {
			accessory.isSelected = true;
			accessory.quantity = Math.max(accessory.quantity, 1);
		} else {
			accessory.isSelected = false;
		}
	};

	defaultAccessoryQuantity = (accessories, type, equipmentOptions) => {
		const option = find(equipmentOptions, o => toLower(o.name).indexOf(`${toLower(type)} quantity`) > -1);
		const defaultValue = accessories.isSelected ? 1 : 0;
		if (!option) {
			accessories.quantity = defaultValue;
			return;
		}
		const defaultOption = option.defaultValue || first(option.options);
		accessories.quantity = parseInt(get(defaultOption, 'value', defaultValue), 10);
	};

	handleChange = (equipmentId, equipmentOptions, outOfStock) => e => {
		const { merchantEquipment, onChange } = this.props;
		let accessories = cloneDeep(find(merchantEquipment, { equipmentId }));

		if (!accessories) {
			accessories = this.props.createNewEquipment(equipmentId, true, false, true);
		}

		if (equipmentOptions) {
			this.defaultAccessoryQuantity(accessories, e.target.value, equipmentOptions);
		}

		let accessoriesName = get(accessories, 'name');

		let itemToSet, itemKey;
		let strName = e.target.name;
		if (strName.startsWith(accessoriesName + '_')) strName = strName.substr(accessoriesName.length + 1);

		if (strName.indexOf('_') > 0) {
			let keyList = compact(strName.split('_'));
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, accessories);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = accessories;
			itemKey = strName;
		}
		let newVal = e.target.value;
		if (e.target.type && e.target.type === 'checkbox') newVal = e.target.checked;

		itemToSet[itemKey] = newVal;

		if (toLower(itemKey) === 'shippingoption' && toLower(newVal) === 'pickup') {
			itemToSet.shippingSpeed = '';
			itemToSet.saturdayDelivery = false;
		}
		this.selectAccesory(accessories, outOfStock);

		onChange(accessories);
	};

	handleClickShowOther = e => {
		if (e.detail === 0 && e.target.value === 'Other') {
			this.setState({ isShippingOptionPopupOpen: true });
		}
	};
	handleNoteChange = equipmentId => notes => {
		const { merchantEquipment, onChange } = this.props;
		let accessories = find(merchantEquipment, { equipmentId });

		if (!accessories) {
			accessories = this.props.createNewEquipment(equipmentId, true);
		}
		this.selectAccesory(accessories);

		onChange({ ...accessories, notes });
	};

	openCloseShippingOptionPopup = value => () => this.setState({ isShippingOptionPopupOpen: value });

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	openCloseNotesModal = (note, equipmentId) => () => {
		this.openCloseModal({
			name: modalNames.gatewayNote,
			data: {
				note: note,
				handleChange: this.handleNoteChange(equipmentId),
			},
		});
	};

	adjustQuantity = (equipmentId, outOfStock = false) => e => {
		const { merchantEquipment, onChange } = this.props;
		let accessories = cloneDeep(find(merchantEquipment, { equipmentId }));

		if (!accessories) {
			accessories = this.props.createNewEquipment(equipmentId, !outOfStock, false, true, undefined, undefined, 0);
		}

		if (!accessories.quantity) {
			accessories.quantity = 0;
		}

		if (e.target.name === 'increase') {
			accessories.quantity += 1;
		} else if (e.target.name === 'decrease' && accessories.quantity > 0) {
			accessories.quantity -= 1;
		}

		accessories.isSelected = parseInt(accessories.quantity) > 0;

		onChange(accessories);
	};

	renderPurchasePlan = (purchasePlans, merchantAccessories, equipmentId, subcategory, equipmentOptions, outOfStock) => {
		const plan = purchasePlans[0];
		let fee = find(get(merchantAccessories, 'fees'), { feeType: 'OneTimeFee' });
		const quantity = get(merchantAccessories, 'quantity', 0);
		let accessoriesTotal = 0;
		const isPaper = toLower(subcategory) === 'paper';
		let isBillAgentPaymentSchedule = get(merchantAccessories, 'paymentSchedule') === 'BillAgent';
		let tooltip = isBillAgentPaymentSchedule
			? `Merchant Cost is not editable when Payment Schedule is Bill Agent.`
			: null;

		if (!fee) {
			fee = find(plan.fees, { feeType: 'OneTimeFee' });
		}
		let merchantPrice = get(fee, 'retailPrice');
		if (Object.keys(fee).includes('merchantPrice')) {
			merchantPrice = fee.merchantPrice;
		}
		if (merchantAccessories) {
			accessoriesTotal = sumBy(merchantAccessories.accessories, ({ isSelected, quantity, purchasePlans }) => {
				const plan = purchasePlans[0];
				if (isSelected && plan) {
					const fee = find(plan.fees, {
						feeType: 'OneTimeFee',
					});
					return (fee.merchantPrice || fee.retailPrice) * quantity;
				}
				return 0;
			});
		}

		return (
			<Fragment>
				<div className="equipment__list__item retail-price">
					<div className="equipment__list__th">Agent Cost</div>
					<div className="equipment__list__td type--wgt--bold type--base">{formatCurrency(fee.agentCost)}</div>
				</div>
				<div className="equipment__list__item merchant-price">
					<div className="equipment__list__th">Merchant Cost</div>
					<div className="equipment__list__td">
						<div className="datatooltip--250 datatooltip--left" data-tooltip={tooltip}>
							<NumberFormat
								className="input input--med input--currency input--currency--sml type--right w--90p"
								value={isBillAgentPaymentSchedule ? 0 : merchantPrice}
								name="merchantPrice"
								placeholder="0"
								disabled={isBillAgentPaymentSchedule}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={false}
								onValueChange={this.handleFeeChange(equipmentId, fee.feeId, outOfStock)}
							/>
						</div>
					</div>
				</div>
				<div className="equipment__list__item quantity">
					<div className="equipment__list__th">Quantity</div>
					<div className="equipment__list__td">
						<div className="inputgroup accessories__list__item__quantity">
							{isPaper ? (
								renderEquipmentOption(
									'Quantity',
									equipmentOptions,
									merchantAccessories,
									this.handleChange(equipmentId, undefined, outOfStock)
								)
							) : (
								<Fragment>
									<div className="inputgroup--aside">
										<button
											type="button"
											className="btn btn--handler spc--left--1 datatooltip--150"
											name="decrease"
											onClick={this.adjustQuantity(equipmentId, outOfStock)}
											disabled={quantity == 0 && outOfStock}
											data-tooltip={quantity == 0 && outOfStock ? outOfStockTooltip : null}
										>
											&#8211;
										</button>
									</div>
									<div className="inputgroup--main inputgroup--main--double">
										<input
											className="input input--med type--center"
											disabled
											type="number"
											min="1"
											name="quantity"
											onChange={this.handleChange(equipmentId, undefined, outOfStock)}
											value={quantity}
										/>
									</div>
									<div className="inputgroup--aside inputgroup--aside--double">
										<button
											type="button"
											className="btn btn--handler spc--right--1 datatooltip--150"
											name="increase"
											onClick={this.adjustQuantity(equipmentId, outOfStock)}
											disabled={outOfStock}
											data-tooltip={outOfStock ? outOfStockTooltip : null}
										>
											+
										</button>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</div>
				<div className="equipment__list__item total-price">
					<div className="equipment__list__th">Total price</div>
					<div className="equipment__list__td">
						<div className="type--wgt--bold type--base">
							{formatCurrency(
								quantity * (isBillAgentPaymentSchedule ? fee.agentCost : fee.merchantPrice) + accessoriesTotal
							)}
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderVisibleEquipmentOption = (
		equipmentId,
		merchantAccessories,
		equipmentOptions,
		optionName,
		subcategory,
		outOfStock
	) => {
		if (!equipmentOptions[optionName]) return null;
		const renderEmptyOption = subcategory === 'stands';

		return (
			<Fragment>
				<div className="equipment__list__th">{equipmentOptions[optionName].friendlyName}</div>
				<div className="equipment__list__td">
					{renderEquipmentOption(
						optionName,
						equipmentOptions,
						merchantAccessories,
						this.handleChange(equipmentId, equipmentOptions, outOfStock),
						renderEmptyOption
					)}
				</div>
			</Fragment>
		);
	};

	renderAccessoryName(row) {
		const { equipmentId, name, outOfStock, purchaseTypes, description } = row;
		return (
			<div className="equipment__list__item name">
				<div className="equipment__list__th">Name & model</div>
				<div className="equipment__list__td cursor--pointer">
					<div
						onClick={this.onViewEquipmentDetail(equipmentId)}
						className={`accessories__list__item__thumbnail${getThumbnailOverlay(outOfStock, purchaseTypes, true)}`}
						style={{
							backgroundImage:
								'url(' +
								process.env.REACT_APP_CDN_URL +
								name
									.toLowerCase()
									.replace(' ', '_')
									.replace(/[^a-z0-9_-]/gi, '') +
								'/thumbnail.png' +
								')',
						}}
					></div>
					<a className="type--wgt--bold type--base" onClick={this.onViewEquipmentDetail(equipmentId)}>
						{name}
						{description && (
							<span className="datatooltip--base">
								<i className="icon icon--tiny align--v--middle icon--info spc--left--sml" data-tooltip={description} />
							</span>
						)}
					</a>
				</div>
			</div>
		);
	}

	renderTableBody = row => {
		const {
			equipmentId,
			purchasePlans,
			paymentSchedules,
			shippingOptions,
			shippingSpeeds,
			subcategory,
			equipmentOptions,
			outOfStock,
		} = row;
		const { merchantEquipment } = this.props;
		let merchantAccessories = merchantEquipment.find(e => e.equipmentId === equipmentId);
		const loweredSubcategory = toLower(subcategory);
		const visibleEquipmentOptionName = visibleEquipmentOptionsBySubcategory[loweredSubcategory];
		const isStand = loweredSubcategory === 'stands';
		const isPickup = toLower(get(merchantAccessories, 'shippingOption', '')) === 'pickup';

		return (
			<Fragment>
				<div className="equipment__list">
					<div className="equipment__list__item select">
						<div className="equipment__list__th"></div>
						<div className="equipment__list__td">
							{merchantAccessories && merchantAccessories.isSelected ? (
								<div
									className="accessories__list__item__added-check datatooltip--150 datatooltip--right"
									data-tooltip={outOfStock ? outOfStockTooltip : null}
								>
									<input
										type="checkbox"
										className="input input--check"
										id={`added.${equipmentId}`}
										checked={!!merchantAccessories.isSelected}
										value={!!merchantAccessories.isSelected}
										onChange={this.accessoriesSelectToggle(equipmentId)}
										disabled={outOfStock}
									/>
									<label htmlFor={`added.${equipmentId}`}>Added</label>
								</div>
							) : (
								<div
									className="accessories__list__item__add-check datatooltip--150 datatooltip--right"
									data-tooltip={outOfStock ? outOfStockTooltip : null}
								>
									<input
										type="checkbox"
										className="input input--check"
										id={`select.${equipmentId}`}
										checked={false}
										value={false}
										onChange={this.accessoriesSelectToggle(equipmentId)}
										disabled={outOfStock}
									/>
									<label htmlFor={`select.${equipmentId}`}>Add</label>
								</div>
							)}
						</div>
					</div>
					{this.renderAccessoryName(row)}
					{visibleEquipmentOptionName && (
						<div className="equipment__list__item case-option">
							{this.renderVisibleEquipmentOption(
								equipmentId,
								merchantAccessories,
								equipmentOptions,
								visibleEquipmentOptionName,
								loweredSubcategory,
								outOfStock
							)}
						</div>
					)}
					{this.renderPurchasePlan(
						purchasePlans,
						merchantAccessories,
						equipmentId,
						subcategory,
						equipmentOptions,
						outOfStock
					)}
					<div className="equipment__list__item basis-140">
						<div className="equipment__list__th">Schedule</div>
						<div className="equipment__list__td">
							<select
								className="input input--med input--select"
								name="paymentSchedule"
								id="paymentSchedule"
								onChange={this.handleChange(equipmentId, undefined, outOfStock)}
								value={merchantAccessories && merchantAccessories.paymentSchedule}
							>
								<option value="">Please select...</option>
								{map(paymentSchedules, (opt, key) => (
									<option key={key} value={key}>
										{opt}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="equipment__list__item basis-140">
						<div className="equipment__list__th">
							Shipping
							{get(merchantAccessories, 'shippingOption', '') === 'Other' && (
								<Fragment>
									<i
										className="icon icon--tiny align--v--middle icon--info spc--left--tny datatooltip--200 datatooltip--top--right"
										onClick={this.openCloseShippingOptionPopup(true)}
										style={{ 'white-space': 'pre-line' }}
										data-tooltip={this.renderShippingOptionTooltip(merchantAccessories.shippingAddress || {})}
									/>
									{this.renderShippingOptionPopup(merchantAccessories, outOfStock)}
								</Fragment>
							)}
						</div>
						<div className="equipment__list__td">
							<select
								className="input input--med input--select"
								name="shippingOption"
								id="shippingOption"
								onChange={this.handleChange(equipmentId, undefined, outOfStock)}
								onClick={this.handleClickShowOther}
								value={merchantAccessories && merchantAccessories.shippingOption}
							>
								<option value="">Please select...</option>
								{map(shippingOptions, (opt, key) => (
									<option key={key} value={key}>
										{opt}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="equipment__list__item basis-140">
						<div className="equipment__list__th">Shipping Speed</div>
						<div className="equipment__list__td">
							<select
								disabled={isPickup}
								className="input input--med input--select"
								name="shippingSpeed"
								id="shippingSpeed"
								onChange={this.handleChange(equipmentId, undefined, outOfStock)}
								value={merchantAccessories && merchantAccessories.shippingSpeed}
							>
								<option value="">Please select...</option>
								{map(shippingSpeeds, (opt, key) => (
									<option key={key} value={key}>
										{opt}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="equipment__list__item note-column">
						<div className="equipment__list__th">
							<div className="display--ib spc--right--tny">
								Note{isStand && <span className="type--color--primary">*</span>}
							</div>
							{isStand && (
								<i
									className="icon icon--tiny align--v--middle icon--info datatooltip--250 datatooltip--top--left"
									data-tooltip="Please specify which terminal or pin pad the stand is requested for"
								/>
							)}
						</div>
						<div className="equipment__list__td">
							<div
								onClick={this.openCloseNotesModal(get(merchantAccessories, 'notes'), equipmentId)}
								className="gateway__note__wrap datatooltip--nowrap"
								data-tooltip="Add Note"
							>
								<i className="icon icon--sml icon--addnote"></i>
								<div className={get(merchantAccessories, 'notes') ? 'gateway--has-note' : ''}></div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderRow = row => (
		<div key={get(row, 'equipmentId')}>
			<div key={row.equipmentId} className="accessories__list__item">
				{this.renderTableBody(row)}
			</div>
		</div>
	);

	renderShippingOptionTooltip = shippingAddress => {
		if (every(shippingAddress, value => !value)) {
			return 'Shipping Address';
		}

		const { streetAddress = '', city = '', state = '', zip = '' } = shippingAddress;
		return `Shipping Address

            Street Address: ${streetAddress}
            City: ${city}
            State: ${state}
            Zip: ${zip}`;
	};

	renderShippingOptionPopup = ({ shippingAddress, equipmentId }, outOfStock) => {
		const { isShippingOptionPopupOpen } = this.state;

		return (
			<Modal
				isOpen={isShippingOptionPopupOpen}
				onClose={this.openCloseShippingOptionPopup(false)}
				className="popup__content"
			>
				<div>
					<div className="popup__header">
						<h6>Shipping Option</h6>
					</div>
					<div className="popup__body">
						<AddressComponent
							address={shippingAddress || {}}
							namePrefix="shippingAddress"
							required={true}
							onChange={this.handleChange(equipmentId, undefined, outOfStock)}
							showName={true}
						/>
					</div>
					<div className="popup__footer popup__footer--styled">
						<button
							className="btn btn--ghost btn--med spc--left--tny"
							onClick={this.openCloseShippingOptionPopup(false)}
						>
							Close
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		let { filteredList, filterSettings } = this.state;
		const {
			physicalAddress,
			corporateAddress,
			accessoriesList,
			merchantEquipment,
			onChange,
			createNewEquipment,
			selectedEquipmentId,
			isEApp,
		} = this.props;

		return (
			<div className={`l--twocols${isEApp ? '' : ' accessories--merchant'}`}>
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				{!selectedEquipmentId ? (
					<Fragment>
						<div>
							<div>
								<div className="accessories__filter">
									<label className="type--nowrap label spc--right--med type--color--primary spc--bottom--xsml">
										Filter by
									</label>
									<div className="row">
										<div className="col col-sml-12 col-med-6 col-lrg-3">
											<div className="inputgroup spc--bottom--sml spc--right--med">
												<div className="inputgroup--aside">
													<label className="type--nowrap label spc--right--sml">Category</label>
												</div>
												<div className="inputgroup--main">
													<select
														className="input input--med input--select"
														name="subcategory"
														id="subcategory"
														onChange={this.handleFiltersChange}
														value={filterSettings.subcategory}
													>
														<option value="">All</option>
														{map(keys(groupBy(accessoriesList, 'subcategory')), option => (
															<option key={option} value={toLower(option)}>
																{startCase(option)}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 col-lrg-3">
											<div className="spc--bottom--sml">
												<input
													className="input input--med input--search"
													placeholder="Search accessories"
													name="name"
													id="name"
													value={filterSettings.name}
													onChange={this.handleFiltersChange}
												/>
											</div>
										</div>
									</div>
									{/*<div className="col col-sml-4">
                                        <div>
                                            <div>Sort by</div>
                                            <select
                                                className="input input--med input--select"
                                                name="sortBy"
                                                id="sortBy"
                                                onChange={this.handleFiltersChange}
                                                value={filterSettings.sortBy}
                                            >
                                                <option value="ASC">Lowest Price</option>
                                                <option value="DESC">Highest Price</option>
                                            </select>
                                        </div>
                                    </div>*/}
								</div>

								<div>
									{filteredList.length === 0 ? (
										<div className="note note--default">No equipment found for the current filter</div>
									) : (
										map(filteredList, (group, subcategory) => (
											<div className="accessories__list" key={subcategory}>
												<div className="accessories__list__header">{startCase(subcategory)}</div>
												<div className="accessories__list__group">
													{map(orderBy(group, 'name'), row => this.renderRow(row))}
												</div>
											</div>
										))
									)}
								</div>
							</div>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<HardwareListItemComponent
							hardware={accessoriesList.filter(h => h.equipmentId === selectedEquipmentId)[0]}
							merchantHardware={merchantEquipment.find((e, i) => e.equipmentId === selectedEquipmentId)}
							physicalAddress={physicalAddress}
							corporateAddress={corporateAddress}
							onChange={onChange}
							createNewEquipment={createNewEquipment}
							optionIsAvailableForRules={this.props.optionIsAvailableForRules}
						/>
					</Fragment>
				)}
			</div>
		);
	}
}

AccessoriesListComponent.propTypes = {
	accessoriesList: array,
	merchantEquipment: array,
	appId: string,
	physicalAddress: object,
	corporateAddress: object,
	onChange: func,
	createNewEquipment: func,
	openCloseModal: func,
	onShowEquipment: func,
	isEApp: bool,
};

export default AccessoriesListComponent;
