import React, { Fragment } from 'react';
import {
	get,
	toLower,
	isEmpty,
	transform,
	find,
	findIndex,
	map,
	noop,
	first,
	last,
	cloneDeep,
	isFunction,
	split,
	each,
	set,
} from 'lodash';
import { bool, func } from 'prop-types';
import moment from 'moment';
import { decode } from 'jsonwebtoken';
import { appService } from '../../services/appService';
import Notification from '../../common/components/notifications/notifications';
import withLoader from '../../common/components/loader/loader-hoc';
import {
	Schema,
	goPlusMerchantMpaBusinessInfoAPIFormTemplate,
	goPlusMerchantMpaBusinessInfoFormTemplate,
	goPlusMerchantMpaBankingInfoFormTemplate,
	goPlusMerchantMpaBankingInfoApiFormTemplate,
	goPlusMerchantMpaSignerAndProcessingInfoFormTemplate,
} from '../../validation';
import { defaultInvalidDates, identifyFullStoryWithToken } from '../../common/utilities';
import { signatureService } from '../../services';
import GoPlusBaseClass from './GoPlusBaseClass';
import RequestNewLink from './RequestNewLink';
import ClickWrapComponent from './Clickwrap';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import PlaidApp from './Plaid';

const submitErrorCustomHandlers = [
	{
		stepId: 'businessInfo',
		errorKey:
			'According to the IRS, the Legal Corp Name and Tax ID/EIN provided does not match. Please check your SS4 document and update the information accordingly.',
	},
	{
		stepId: 'bankingInfo',
		errorKey: 'Account and/or Routing Number is invalid. Please re-enter the information and resubmit.',
	},
];

const achqMerchantMpaSignerInfoFormTemplate = {
	downloadUrl: goPlusMerchantMpaSignerAndProcessingInfoFormTemplate.downloadUrl,
	signerInformationList: goPlusMerchantMpaSignerAndProcessingInfoFormTemplate.signerInformationList,
    processingInformation: {
        averageTicket: goPlusMerchantMpaSignerAndProcessingInfoFormTemplate.processingInformation.averageTicket,
        monthlySalesVolume: goPlusMerchantMpaSignerAndProcessingInfoFormTemplate.processingInformation.monthlySalesVolume,
    },
};

const prefillPdfFields = {
	businessStartDate: 'BusinessStartDate',
	dbaName: 'BusinessDBA',
	corporateName: 'BusinessCorporateName',
	website: 'Website',
	productSold: 'Description',
	businessAddress_streetAddress: 'BusinessAddress',
	businessAddress_city: 'City',
	businessAddress_state: 'State',
	businessAddress_zip: 'Zip',
	businessPhone: 'BusinessPhone',
	businessEmail: 'BusinessEmail',
	bankingInformation_bankName: 'BusinessAccountName',
	bankingInformation_routingNumber: 'BankRoutingNumber',
	bankingInformation_accountNumber: 'AccountNumber',
	processingInformation_ccRate: 'CCRate',
	processingInformation_ccRateAmex: 'CCRateAmex',
	processingInformation_perTranFee: 'PerTranFee',
	processingInformation_perTranAmexFee: 'PerTranFeeAmex',
	processingInformation_averageTicket: 'AverageTicket',
	processingInformation_highTicket: 'HighTicket',
	processingInformation_rateStructure: 'RateStructure',
	processingInformation_annualCreditCardSales: 'AnnualVolume',
	processingInformation_percentCardNotPresent: 'KeyedPercentage',
	processingInformation_percentCardPresent: 'SwipedPercentage',
};

const initialStepsData = [
	{
		id: 'businessInfo',
		title: 'Business Info',
		formSchema: new Schema(goPlusMerchantMpaBusinessInfoFormTemplate, { strip: false, typecast: true }),
		apiFormSchema: new Schema(goPlusMerchantMpaBusinessInfoAPIFormTemplate, { strip: false, typecast: true }),
		apiFormSchemaAdditionalRequest: null,
		isFormValid: false,
		fieldPaths: [
			'agentName',
			'agentEmail',
			'telemarketerName',
			'corporateName',
			'dbaName',
			'taxId',
			'website',
			'ownershipType',
			'businessPhone',
			'businessEmail',
			'businessStartDate',
			'businessAddress.streetAddress',
			'businessAddress.city',
			'businessAddress.state',
			'businessAddress.zip',
			'mailingAddress.streetAddress',
			'mailingAddress.city',
			'mailingAddress.state',
			'mailingAddress.zip',
			'productSold',
		],
		render: noop,
	},
	{
		id: 'bankingInfo',
		title: 'Banking Info',
		formSchema: new Schema(goPlusMerchantMpaBankingInfoFormTemplate, { strip: false, typecast: true }),
		apiFormSchema: new Schema(goPlusMerchantMpaBankingInfoApiFormTemplate, { strip: false, typecast: true }),
		apiFormSchemaAdditionalRequest: null,
		isFormValid: false,
		fieldPaths: [
			'bankingInformation.bankName',
			'bankingInformation.accountType',
			'bankingInformation.routingNumber',
			'bankingInformation.accountNumber',
			'bankingInformation.confirmAccountNumber',
		],
		render: noop,
	},
	{
		id: 'signerAndProcessingInfo',
		title: 'Signer & Processing Info',
		formSchema: new Schema(goPlusMerchantMpaSignerAndProcessingInfoFormTemplate, { strip: false, typecast: true }),
		apiFormSchema: null,
		apiFormSchemaAdditionalRequest: null,
		isFormValid: false,
		render: noop,
	},
];

class GoPlusMerchantMpaComponent extends GoPlusBaseClass {
	constructor(props) {
		super(true, props);
		this.formSchema = null;
		this.state = {
			IP: '',
			Timestamp: '',
			...this.initialState,
			token: this.props.location.search.substr(1),
			isExpired: false,
			currentStep: initialStepsData[0],
			steps: initialStepsData,
			totalSales: 0,
			showPlaid: null,
			plaidLinkToken: null,
		};
		identifyFullStoryWithToken(this.state.token);
	}

	handleNextStep = async () => {
		const { currentStep, steps } = this.state;
		const stepsClone = cloneDeep(steps);
		const currentStepIndex = findIndex(stepsClone, step => step.id === currentStep.id);

		this.removeValidationErrors();
		if (await this.validateFields(true, true)) {
			return;
		}

		if (currentStepIndex < steps.length - 1) {
			try {
				let result = await this.saveCurrentStep();
				if (
					currentStep.apiFormSchema === null ||
					(currentStep.apiFormSchema != null && !(await this.validateFields(true, true, result)))
				) {
					const nextStep = stepsClone[currentStepIndex + 1];
					this.setState(
						{ currentStep: nextStep, steps: [...stepsClone], isSubmit: false },
						() => (this.formSchema = nextStep.formSchema)
					);
				}
			} catch (error) {
				this.handleSubmitError(error);
			} finally {
				this.props.showLoader(false);
			}
		}
	};

	handlePreviousStep = async () => {
		const { currentStep, steps } = this.state;
		const stepsClone = cloneDeep(steps);
		const currentStepIndex = findIndex(stepsClone, step => step.id === currentStep.id);

		if (currentStepIndex > 0) {
			this.removeValidationErrors();
			const nextStep = steps[currentStepIndex - 1];
			this.setState({ currentStep: nextStep }, () => (this.formSchema = nextStep.formSchema));
		}
		this.setState({ showPlaid: null });
	};

	setStepsRenderData = () => {
		const { steps } = this.state;

		let businessInfo = find(steps, step => step.id === 'businessInfo');
		businessInfo.render = () => {
			return (
				<Fragment>
					{this.renderSalesRep()}
					{this.renderBusinessInfo()}
				</Fragment>
			);
		};
		let bankingInfo = find(steps, step => step.id === 'bankingInfo');
		bankingInfo.render = () => {
			return <Fragment>{this.renderBanking()}</Fragment>;
		};
		let signerAndProcessingInfo = find(steps, step => step.id === 'signerAndProcessingInfo');
		if (this.isAchqOnly()) {
			signerAndProcessingInfo.formSchema = new Schema(achqMerchantMpaSignerInfoFormTemplate, {
				strip: false,
				typecast: true,
			});
			this.setState({ signerAndProcessingInfo });
		}
		signerAndProcessingInfo.render = () => {
			const { appId } = this.state;
			return (
				<Fragment>
					{this.renderSignerInfo(true)}
					{this.renderProcessingInfo(true)}
					{this.renderDocumentsSection()}
					<ClickWrapComponent
						groupKey="group-goplus"
						appId={appId.toString()}
						setInfoToApp={this.setInfoToApp}
						handleError={this.handleSubmitError}
						allowDisagreed={true}
					/>
				</Fragment>
			);
		};
	};
	renderBanking = () => {
		const { showPlaid } = this.state;
		if (this.state.isIntegratedWithBank) {
			return (
				<div className="message message--success fullwidth spc--bottom--med">
					<div className="flex--center">
						<i className="icon icon--sml icon--check--success spc--right--tny"></i>
						<span>Your account is successfully connected to Plaid</span>
					</div>
				</div>
			);
		}
		if (showPlaid === null) {
			return this.renderBankingOptions();
		}
		return this.renderBankingInfo();
	};
	renderBankingOptions = () => {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				<div className="card--primary__header is-expanded">
					<h2 className="type--base spc--bottom--sml">Banking Information</h2>
				</div>
				<div className="w--537p align--h--center type--center">
					<h3 className="type--xlrg type--wgt--regular spc--bottom--sml spc--top--xxlrg">Instant Bank Verification</h3>
					<p className="type--base type--color--text--regular spc--bottom--lrg">
						We use Plaid to instantly verify your account and provide you with a seamless user experience.
					</p>
					<button
						className="btn btn--primary btn--med fullwidth spc--bottom--sml"
						onClick={() => {
							this.setState({ showPlaid: true });
						}}
					>
						Next
					</button>
					<button
						className="btn btn--clear btn--med fullwidth spc--bottom--xxxlrg"
						onClick={() => {
							this.setState({ showPlaid: false });
						}}
					>
						Add Manually Instead
					</button>
				</div>
			</div>
		);
	};

	renderSalesRep = () => {
		const { agentName, agentEmail, telemarketerName, submittedByName, submittedByEmail } = this.state.fields;

		return (
			<ToggleContainer>
				<Toggle initialToggle={false}>
					{({ isToggled, handleToggle }) => (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<div className="flex--primary spc--bottom--sml">
										<h6>Sales Rep</h6>
										<i
											className="icon icon--tiny icon--info datatooltip--200 datatooltip--right spc--left--tny"
											data-tooltip="If no sales agent associated with this lead, please input your own contact information"
										></i>
									</div>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`row ${isToggled ? '' : 'display--n'}`}>
									{this.renderField(
										'agentName',
										submittedByName ? submittedByName : agentName,
										'Sales Rep Name',
										null,
										null,
										true
									)}
									{this.renderField(
										'agentEmail',
										submittedByEmail ? submittedByEmail : agentEmail,
										'Sales Rep Email Address',
										null,
										null,
										true
									)}
									{telemarketerName &&
										this.renderField('telemarketerName', telemarketerName, 'Rep 2', null, null, true)}
								</div>
							</div>
						</Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	};

	renderProcessingInfo = isMerchantMPA => {
		const { processingInformation, isLinkedToAchq } = this.state;

		return (
			<ToggleContainer>
				<Toggle initialToggle={true}>
					{({ isToggled, handleToggle }) => (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">
										Processing Information
										{isMerchantMPA && (
											<i
												className="icon icon--tiny icon--info datatooltip--annual-volume spc--left--tny align--v--middle"
												data-tooltip="Annual Volume is the total amount you can process on an annual basis. You do not need to commit to processing these volumes."
											/>
										)}
									</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`row ${isToggled ? '' : 'display--n'}`}>
									{!this.isAchqOnly() && this.renderCCProcessingInfoFields({ ...processingInformation, isMerchantMPA })}
								</div>
								<div className={`row ${isToggled ? '' : 'display--n'}`}>
									{!isLinkedToAchq && this.renderACHProcessingInfoFields({ ...processingInformation, isMerchantMPA })}
								</div>
							</div>
						</Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	};

	removeValidationErrors() {
		this.setState({
			errorMessage: null,
			errorList: [],
			errorListPaths: [],
			errorApiMessage: null,
			errorApiList: [],
			errorApiListPaths: [],
		});
	}

	async componentDidMount() {
		await this.setUnderwritingLevels(this.state.token);
		this.fetchData();
		this.setStepsRenderData();
	}

	fetchData = (setCurrentStep = true) => {
		this.props.showLoader(true);
		this.addEventListenersToAccountNumberInputs();

		return appService
			.getEAppForMerchant(this.state.token)
			.then(mpa => {
				this.props.showLoader(false);
				const fields = { ...this.state.fields };
				this.setState({ fields });
				if (mpa.signatureDoc.status === 'Signed') {
					this.setState({ errorMessage: 'Signature documents have already been submitted successfully' });
				} else if (get(mpa, 'goPlusEApp.wasSubmitted')) {
					this.setState({ errorMessage: 'Form has already been submitted.' });
				} else {
					if (mpa.signatureDoc.includedContracts.includes('FDGoConfirmation')) {
						this.mapGoAppToState(defaultInvalidDates(mpa, ''), () => {
							this.setState({ fieldsCopy: JSON.parse(JSON.stringify(this.state.fields)) });
							const updatedSteps = this.updateValidationStatusOnAllSteps();
							if (setCurrentStep) {
								const currentStep = find(updatedSteps, step => step.isFormValid === false) || last(updatedSteps);
								this.setState({ currentStep }, () => (this.formSchema = currentStep.formSchema));
							}
						});
					} else {
						this.mapAppToState(defaultInvalidDates(mpa, ''));
					}
				}
			})
			.catch(err => {
				this.props.showLoader(false);
				let errorMessage = 'An error occurred: ' + err;
				if (toLower(err) === 'unauthorized' || err == 401) {
					if (decode(this.state.token, { complete: true }).payload.actort) {
						this.setState({
							isExpired: true,
						});
						return;
					}
					errorMessage =
						'The link you followed has expired. To complete your application, please reach out to your agent to request a new eApp link.';
				}
				this.handleSubmitError(errorMessage);
			})
			.finally(() => {});
	};

	handleValidationErrorOnSubmit = errorHandler => {
		const { steps } = this.state;
		const stepsClone = cloneDeep(steps);
		let rollbackStep = stepsClone.find(x => x.id === errorHandler.stepId);
		rollbackStep.isFormValid = false;

		this.setState({ currentStep: rollbackStep, steps: [...stepsClone], isSubmit: false }, () => {
			this.formSchema = rollbackStep.formSchema;
			this.handleSubmitError(errorHandler.errorKey);
		});
	};

	mapAppToState = app => {
		const newState = {
			mpa: app,
			fields: { ...this.state.fields },
			appId: app.appId,
			processingInformation: { ...this.state.processingInformation },
		};

		const businessInfo = get(app, 'businessInformation');
		const businessAddress = get(businessInfo, 'businessAddress');
		const mailingAddress = get(app, 'mailingAddress', {});
		const signerInformationList = get(app, 'signerInformationList');
		const bankingInfo = get(app, 'bankAccountInformation');
		const ownershipType = get(businessInfo, 'ownershipType', '');
		const businessStartDate = get(app, 'businessStartDate', '');

		newState.processorName = get(app.goPlusEApp, 'processorName', '');
		newState.processingInformation.monthlySalesVolume = get(app, 'monthlySalesVolume', '');
		newState.processingInformation.percentCardNotPresent = get(app, 'percentCardNotPresent', '');
		newState.processingInformation.percentCardPresent = get(app, 'percentCardPresent', '');
		newState.processingInformation.averageTicket = get(app, 'averageTicket', '');
		newState.processingInformation.ccRate = get(app, 'ccRate', '');
		newState.processingInformation.ccRateAmex = get(app, 'ccRateAmex', '');
		newState.processingInformation.highTicket = get(app, 'highTicket', '');
		newState.processingInformation.perTranAmexFee = get(app, 'perTranAmexFee', '');
		newState.processingInformation.perTranFee = get(app, 'perTranFee', '');
		newState.processingInformation.sicCode = get(app, 'sicCode', '');
		newState.processingInformation.annualCreditCardSales = get(app, 'annualCreditCardSales', '');
		newState.processingInformation.achMonthlyFee = get(app, 'achMonthlyFee', '');
		newState.processingInformation.achTransactionFee = get(app, 'achTransactionFee', '');
		newState.processingInformation.achPercentFee = get(app, 'achPercentFee', '');
		newState.processingInformation.achReturnFee = get(app, 'achReturnFee', '');
		newState.processingInformation.achReturnFee = get(app, 'achReturnFee', '');
		newState.processingInformation.achSetupFee = get(app, 'achSetupFee', '');

		newState.fields.agentName = get(app, 'agentName', '');
		newState.fields.agentEmail = get(app, 'agentEmail', '');
		newState.fields.telemarketer = get(app, 'telemarketer', '');
		newState.fields.corporateName = get(businessInfo, 'corporateName', '');
		newState.fields.dbaName = get(app, 'dba', '');
		if (businessStartDate != '') {
			newState.fields.businessStartDate = moment(businessStartDate).format('MM/DD/YYYY');
		}
		newState.fields.website = get(businessInfo, 'website', '');
		newState.fields.taxId = get(app, 'taxID', '');
		newState.fields.businessEmail = get(app, 'businessEmail', '');
		newState.fields.businessPhone = get(businessInfo, 'corporatePhone', '');
		newState.fields.businessAddress = businessAddress;
		newState.fields.mailingAddress = mailingAddress;
		newState.fields.productSold = get(businessInfo, 'productSold', '');

		if (
			businessAddress.streetAddress === mailingAddress.streetAddress &&
			businessAddress.city === mailingAddress.city &&
			businessAddress.state === mailingAddress.state &&
			businessAddress.zip === mailingAddress.zip
		) {
			newState.sameAsBusiness = true;
		}

		this.mapSignerInformationListToState(signerInformationList, newState);

		newState.fields.bankingInformation.bankName = get(bankingInfo, 'bankName', '');
		newState.fields.bankingInformation.routingNumber = get(bankingInfo, 'routingNumber', '');
		this.updateMaskedValue(newState, 'accountNumber', get(bankingInfo, 'accountNumber', ''));
		newState.fields.bankingInformation.accountNumber = get(bankingInfo, 'accountNumber');
		newState.accountNumberMaskedVisible = true;
		this.updateMaskedValue(newState, 'confirmAccountNumber', get(bankingInfo, 'accountNumber', ''));
		newState.fields.bankingInformation.confirmAccountNumber = get(bankingInfo, 'accountNumber');
		newState.confirmAccountNumberMaskedVisible = true;
		newState.fields.bankingInformation.accountType = toLower(get(bankingInfo, 'accountType', ''));
		if (ownershipType) {
			newState.fields.ownershipType = ownershipType;
		}

		this.setState(newState, () => {
			const updatedSteps = this.updateValidationStatusOnAllSteps();
			const currentStep = find(updatedSteps, step => !step.isFormValid) || last(updatedSteps);
			this.setState(
				{ currentStep, fieldsCopy: JSON.parse(JSON.stringify(newState.fields)) },
				() => (this.formSchema = currentStep.formSchema)
			);
		});
	};

	setInfoToApp = async (downloadUrl, IP, Timestamp) => {
		const { isSubmit } = this.state;
		this.removeValidationErrors();
		this.setState({ downloadUrl, IP, Timestamp }, async () => {
			if (isSubmit) {
				await this.validateFields();
			}
			this.validateFieldOnBlur({ target: { name: 'downloadUrl' } });
		});
	};

	checkIfShouldDisplayAsterisk = name => {
		if (name === 'website') return this.state.isECommerce;
		return true;
	};

	getAllFieldsForValidation = () => {
		const { downloadUrl, totalSales, totalOwnershipPercentage, isIntegratedWithBank } = this.state;
		return { ...this.getFieldsForValidation, totalSales, downloadUrl, totalOwnershipPercentage, isIntegratedWithBank };
	};

	updateValidationStatusOnAllSteps = () => {
		const { steps } = this.state;
		const stepsClone = cloneDeep(steps);
		const fieldsToValidate = this.getAllFieldsForValidation();
		map(stepsClone, step => {
			let validationErrors = step.formSchema.validate(Object.assign({}, fieldsToValidate));
			let validationApiErrors = [];
			if (step.apiFormSchema) {
				validationApiErrors = step.apiFormSchema.validate(Object.assign({}, fieldsToValidate));
			}
			if (validationErrors.length === 0 && validationApiErrors.length === 0) {
				step.isFormValid = true;
			} else {
				step.isFormValid = false;
			}
		});
		this.setState({ steps: stepsClone });
		return stepsClone;
	};
	validateFields = async (scrollToTop = false, isSubmit = false, apiSideResponse = null) => {
		let errorList,
			errorListPaths = [];
		const { currentStep } = this.state;

		if (apiSideResponse) {
			if (isFunction(currentStep.apiFormSchemaAdditionalRequest)) {
				let additionalValidationResponse = await currentStep.apiFormSchemaAdditionalRequest();
				apiSideResponse = { ...apiSideResponse, ...additionalValidationResponse };
			}
			errorList = currentStep.apiFormSchema.validate(Object.assign({}, apiSideResponse));
		} else {
			errorList = currentStep.formSchema.validate(Object.assign({}, this.getAllFieldsForValidation()));
		}

		const isLastStep = last(this.state.steps).id === currentStep.id; //documents are visible only on last step; subject to change
		if (isLastStep) {
			const { errors } = this.getRequiredDocumentsErrors();
			if (errors.length > 0) {
				errorList.push(...errors);
			}
		}

		errorListPaths = errorList.map(e => e.path);

		const hasErrors = !isEmpty(errorList);
		const newState = apiSideResponse
			? { errorApiList: errorList, errorApiListPaths: errorListPaths }
			: { errorList, errorListPaths };

		if (scrollToTop) {
			newState.isNewError = hasErrors;
		}

		newState.isSubmit = isSubmit;
		this.setState({ ...newState }, () => {
			const { steps } = this.state;
			const stepsClone = cloneDeep(steps);
			const currentStepIndex = findIndex(stepsClone, step => step.id === currentStep.id);
			stepsClone.at(currentStepIndex).isFormValid = !hasErrors;
			this.setState({ steps: [...stepsClone] });
		});

		return hasErrors;
	};
	save = async () => {
		const { downloadUrl, fields, processingInformation, IP, Timestamp, localFiles, processorName } = this.state;
		this.removeValidationErrors();
		if (await this.validateFields(true, true)) {
			return;
		}

		this.props.showLoader(true);

		const request = {
			...transform(fields, (acc, value, key) => {
				if (key !== 'agentEmail') {
					acc[key] = value;
				}
			}),
			processingInformation,
			processorName,
			Signature: { IP, Timestamp },
		};

		new Promise((resolve, reject) => {
			appService
				.saveMerchantFiles(this.state.appId || this.state.fields.appId, { ...localFiles })
				.then(() => this.setState({ localFiles: {} }, resolve))
				.catch(err => {
					console.log('save files error', err);
					this.props.showLoader(false);
					this.setState({
						errorMessage: 'MPA form saved, but Mpa File could not be uploaded - an error occurred: ' + err,
						isNewError: true,
					});
					reject(err);
				});
		})
			.then(() => {
				return (
					appService
						.submitGoPlus(request)
						//.then(({ pendingAdditionalValidation }) => {
						//    //return appService.prefillDocumentFields({ ...fields, processingInformation }, 'CardknoxGo_Mpa', prefillPdfFields, false)
						//    //    .then((blob) => {
						//    //        return new Promise((resolve, reject) => {
						//    //            const prefilledMpa = { file: new File([blob], `CardknoxGo_Application_${this.state.appId}.pdf`), fileDescription: '' };
						//    //            appService.saveMerchantFiles(this.state.appId || this.state.fields.appId, { SignedMPA: [prefilledMpa] })
						//    //.then(
						//    return new Promise((resolve, reject) => { resolve({ pendingAdditionalValidation })
						//                    .catch(err => {
						//                        console.log('save files error', err);
						//                        this.props.showLoader(false);
						//                        this.setState({ errorMessage: 'MPA form saved, but Mpa File could not be uploaded - an error occurred: ' + err, isNewError: true });
						//                        reject(err);
						//                    });
						//            })
						//        })
						//})
						.then(({ pendingAdditionalValidation }) => {
							signatureService
								.downloadAndSaveSignedDocument(fields, ['FDGoConfirmation'], downloadUrl, undefined, true)
								.then(() => {
									this.props.showLoader(false);
									this.redirectToThankYouPage(pendingAdditionalValidation);
								});
						})
				);
			})
			.catch(err => {
				const customErrorHandler = submitErrorCustomHandlers.find(x => x.errorKey === first(split(err, ' (')));
				this.fetchData(false)
					.then(() => {
						if (customErrorHandler) {
							this.handleValidationErrorOnSubmit(customErrorHandler);
						} else {
							this.handleSubmitError(err);
						}
					})
					.catch()
					.finally(() => {
						this.props.showLoader(false);
					});
			});
	};

	saveCurrentStep = async () => {
		const { currentStep, fields, fieldsCopy, processingInformation, appId, processorName } = this.state;

		if (await this.validateFields(true, true)) {
			return;
		}

		this.props.showLoader(true);

		const fieldsToUpdate = {};
		each(currentStep.fieldPaths, path => {
			set(fieldsToUpdate, path, get(fields, path));
		});

		const request = {
			...fieldsCopy,
			...fieldsToUpdate,
			appId,
			processorName,
			processingInformation,
		};

		return appService.saveGoPlus(request).then(result => {
			this.setState({ fieldsCopy: JSON.parse(JSON.stringify({ ...fieldsCopy, ...fieldsToUpdate })) });
			if (get(result, 'accountVerificationStatus') !== 'E' && get(result, 'isValidTin') !== false) {
				this.mapMaskedValues(fieldsToUpdate, fieldsCopy);
			}
			this.props.showLoader(false);
			return result;
		});
	};

	redirectToThankYouPage = pendingAdditionalValidation => {
		let logoUrl = document.getElementById('logoImg').src;
		logoUrl = encodeURIComponent(logoUrl);
		this.props.history.push(
			`/merchant/thankyou?goApp=true&logoUrl=${logoUrl}&pendingAdditionalValidation=${pendingAdditionalValidation}`
		);
	};
	onPlaidConnected = async publicToken => {
		try {
			this.removeValidationErrors();
			await appService.exchangePlaidAccessToken(this.state.appId, publicToken, this.state.plaidLinkToken);
			this.setState(
				{ showPlaid: null, isIntegratedWithBank: true, errorMessage: null },
				async () => await this.handleNextStep()
			);
		} catch (e) {
			this.setState({ showPlaid: null, errorMessage: 'Something went wrong, please try again.' });
		}
		this.props.showLoader(false);
	};
	onPlaidLoading = () => {
		this.props.showLoader(true);
	};
	onPlaidExit = () => {
		this.setState({ showPlaid: null });
		this.props.showLoader(false);
	};
	setPlaidLinkToken = plaidLinkToken => {
		this.setState({ plaidLinkToken });
	};
	render() {
		const { appId, downloadUrl, isExpired, token, currentStep, steps, showPlaid } = this.state;
		const { isLoading } = this.props;

		const isFirstStep = first(steps).id === currentStep.id;
		const isLastStep = last(steps).id === currentStep.id;

		return (
			<Fragment>
				{showPlaid ? (
					<div>
						<PlaidApp
							appId={this.state.appId}
							onConnected={this.onPlaidConnected}
							onLoading={this.onPlaidLoading}
							onExit={this.onPlaidExit}
							setLinkToken={this.setPlaidLinkToken}
						/>
					</div>
				) : (
					<div id="main-div" className="l--content l--content--med" ref={this.topRef}>
						{!isExpired && this.renderHeader()}
						{isExpired && <RequestNewLink token={token} />}
						{this.renderErrors()}
						<Notification ref={this.notificationRef} />
						<fieldset disabled={isLoading}>{appId && currentStep.render()}</fieldset>

						{this.renderErrors(true)}

						{!isExpired && (
							<div className="flex--primary">
								{!isFirstStep && (
									<button
										className="btn btn--ghost btn--med spc--top--xsml"
										onClick={this.handlePreviousStep}
										disabled={isLoading || isFirstStep}
									>
										Back
									</button>
								)}

								<div className="flex--grow--1 type--right">
									{!isLastStep ? (
										<button
											className="btn btn--primary btn--med spc--top--xsml"
											onClick={this.handleNextStep}
											disabled={isLoading}
										>
											Next
										</button>
									) : (
										<button
											className="btn btn--primary btn--med spc--top--xsml"
											onClick={this.save}
											disabled={isLoading || !downloadUrl}
										>
											Submit Application
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</Fragment>
		);
	}

	renderHeader() {
		const { steps, currentStep } = this.state;

		return (
			<React.Fragment>
				<div className="wizard--primary__wrap spc--bottom--lrg">
					<ul className="wizard--primary">
						<React.Fragment>
							{map(steps, step => {
								return (
									<li
										className={step.id === currentStep.id ? 'wizard--primary__item is-active' : 'wizard--primary__item'}
									>
										<div className="wizard--primary__icon">
											{step.isFormValid ? (
												<i className="icon icon--check icon--xsml" />
											) : (
												<i className="wizard--primary__icon--warning datatooltip--wizard-warn" />
											)}
										</div>
										<div className="wizard--primary__link" activeClassName="is-active">
											{step.title}
										</div>
									</li>
								);
							})}
						</React.Fragment>
					</ul>
				</div>
			</React.Fragment>
		);
	}
}

GoPlusMerchantMpaComponent.propTypes = {
	isLoading: bool,
	showLoader: func.isRequired,
};

export default withLoader(GoPlusMerchantMpaComponent);
