import React, { Component, createRef } from 'react';
import { func, bool } from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Modal } from '../../common/components/modal';
import { withLoader } from '../../common/components';
import { appService } from '../../services';
import { Schema, partnerSettingsTemplate } from '../../validation';
import { ValidationError } from '../error';
import principalService from '../../services/principalService';
import { Notification } from '../../common/components/notifications';

class PartnerSettings extends Component {
	constructor(props) {
		super(props);
		this.notificationRef = createRef();
		this.state = {
			errorMessage: null,
			apiWebhookUrl: '',
			sandboxKey: null,
			defaultEappType: '0',
			skipChargebackEmail: false,
			skipDebitBlockEmail: false,
			skipPaymentSiteSetupEmail: false,
			bccSolaOnMerchantEmails: false,
			skipPostTicketSurveyEmail: false,
			errorList: [],
			errorListPaths: [],
			isSubmit: false,
			copied: false,
			canBoardGoPlus: false,
		};
		this.formSchema = new Schema(partnerSettingsTemplate, { strip: false, typecast: true });
	}

	get fieldsForValidation() {
		const { apiWebhookUrl } = this.state;
		return { apiWebhookUrl };
	}

	async componentDidMount() {
		this.props.showLoader(true);
		const canBoardGoPlus = get(principalService.get(), 'canBoardGoPlus', false);
		const {
			settings: {
				apiWebhookUrl,
				defaultEappType,
				skipChargebackEmail,
				skipDebitBlockEmail,
				skipPaymentSiteSetupEmail,
				bccSolaOnMerchantEmails,
				skipPostTicketSurveyEmail,
			},
		} = await appService.getAgentSettings();
		this.setState(
			{
				apiWebhookUrl,
				defaultEappType,
				canBoardGoPlus,
				skipChargebackEmail,
				skipDebitBlockEmail,
				skipPaymentSiteSetupEmail,
				bccSolaOnMerchantEmails,
				skipPostTicketSurveyEmail,
			},
			() => {
				this.props.showLoader();
			}
		);
	}

	closeSandboxModal = () => {
		this.setState({
			sandboxKey: null,
		});
	};

	copySandboxKey = () => {
		navigator.clipboard.writeText(this.state.sandboxKey);
		this.setState({ copied: true, errorMessage: null });

		setTimeout(() => {
			this.setState({ copied: false });
		}, 2000);
	};

	createSandboxKey = async () => {
		try {
			this.props.showLoader(true);
			const { sandboxKey } = await appService.getAccountBoardingSandboxKey();
			this.setState({ sandboxKey });
			this.props.showLoader(false);
		} catch (e) {
			this.handleError(e);
		}
	};

	handleInputChange = e => {
		const { id } = e.target;
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		this.setState(
			{
				[id]: value,
			},
			this.validateFieldsIfSubmitted
		);
	};

	handleError = err => {
		this.props.showLoader(false);
		this.setState({ errorMessage: 'An error occurred: ' + err });
	};

	scrollTo = id => {
		const elem = document.getElementById(id);
		if (!elem) return;
		elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		const elem = document.getElementById(id);
		if (!elem) return;
		elem.focus();
	};

	validateFieldsIfSubmitted = () => {
		if (this.state.isSubmit) {
			this.validateFields(true);
		}
	};

	validateFieldOnBlur = () => {
		const { isSubmit } = this.state;
		if (isSubmit) return;
		this.validateFields();
	};

	validateFields = (isSubmit = false) => {
		const errorList = this.formSchema.validate(this.fieldsForValidation);
		const errorListPaths = errorList.map(e => e.path);
		const hasErrors = !isEmpty(errorList);
		const newState = { errorList, errorListPaths };
		if (isSubmit) {
			newState.isSubmit = isSubmit;
		}
		this.setState(newState);

		return hasErrors;
	};

	save = async () => {
		if (this.validateFields(true)) {
			return;
		}
		this.props.showLoader(true);

		try {
			const {
				canBoardGoPlus,
				apiWebhookUrl,
				defaultEappType,
				skipChargebackEmail,
				skipDebitBlockEmail,
				skipPaymentSiteSetupEmail,
				bccSolaOnMerchantEmails,
				skipPostTicketSurveyEmail,
			} = this.state;
			const payload = { apiWebhookUrl };
			if (canBoardGoPlus) {
				payload.defaultEappType = defaultEappType;
				payload.skipChargebackEmail = skipChargebackEmail;
				payload.skipDebitBlockEmail = skipDebitBlockEmail;
				payload.skipPaymentSiteSetupEmail = skipPaymentSiteSetupEmail;
				payload.bccSolaOnMerchantEmails = bccSolaOnMerchantEmails;
				payload.skipPostTicketSurveyEmail = skipPostTicketSurveyEmail;
			}
			const { refNum } = await appService.saveAgentSettings(payload);

			this.props.showLoader(false);
			this.notificationRef.current.addNotification({
				message: 'Settings saved successfully',
				ref: refNum,
				success: true,
			});
		} catch (e) {
			this.handleError(e);
			this.props.showLoader();
		}
	};

	render() {
		return (
			<div className="settings--main settings--main--alt">
				<Modal isOpen={!!this.state.sandboxKey} onClose={this.closeSandboxModal}>
					<div>
						<div className="popup__header">
							<h3 className="popup__header__title">Key created</h3>
						</div>
						<div className="popup__body popup__body--lrg">
							<div className="spc--bottom--med">
								<label htmlFor="sandboxKey">Key</label>
								<div className="inputgroup">
									<div className="inputgroup--main">
										<input id="sandboxKey" value={this.state.sandboxKey} className="input input--med" />
									</div>
									<div className="inputgroup--aside">
										{this.state.copied && <div className="btn--copy--message">Copied!</div>}
										<button className="btn btn--med btn--primary spc--left--tny" onClick={this.copySandboxKey}>
											<i className="icon icon--nano icon--copy--white spc--right--xsml"></i>
											Copy code
										</button>
									</div>
								</div>
							</div>
							<div className="message message--info fullwidth spc--bottom--med">
								<p className="type--color--text--dark type--wgt--bold spc--bottom--med">
									Please save this key securely to prevent unauthorized access
								</p>
								<p>
									For security purposes you will not be able to recover this again. However, you can create new keys at
									any time.
								</p>
							</div>
							<div className="type--right">
								<button className="btn btn--med btn--primary" type="button" onClick={this.closeSandboxModal}>
									Done
								</button>
							</div>
						</div>
					</div>
				</Modal>
				<Notification ref={this.notificationRef} />
				<div className="l--content--security">
					<div className="settings__header">
						<div className="settings__header__title">General Settings</div>
					</div>
					{this.state.errorMessage && (
						<div className="spc--bottom--sml message message--warning">
							<p>{this.state.errorMessage}</p>
						</div>
					)}
					<div className="spr__card clearfix">
						<div className="spr__heading">
							<div>
								<label className="label spc--bottom--sml">Create Sandbox Key</label>
								<p className="type--small--plus type--color--text--regular">
									Click to create a Cardknox Sandbox Key for Cardknox Go Boarding API
								</p>
							</div>
							<button
								disabled={this.props.isLoading}
								type="button"
								className="btn btn--med btn--primary"
								onClick={this.createSandboxKey}
							>
								Create Sandbox Key
							</button>
						</div>
					</div>
					<div className="spr__card spc--bottom--med clearfix">
						<div className="form__field">
							<div className="form__field__header">
								<label htmlFor="apiWebhookUrl" className="label">
									Webhook URL
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
									data-tooltip="Enter the URL where you would like to receive Cardknox Go account updates."
								></i>
							</div>
							<input
								id="apiWebhookUrl"
								disabled={this.props.isLoading}
								className="input input--med"
								value={this.state.apiWebhookUrl}
								onChange={this.handleInputChange}
								onBlur={this.validateFieldOnBlur}
							/>
						</div>
						{this.state.canBoardGoPlus && (
							<div className="row">
								<div className="col col-sml-6 col-lrg-6 spc--bottom--med">
									<label className="label">Default e-App type</label>
									<select
										className="input input--med input--select"
										name={'defaultEappType'}
										value={this.state.defaultEappType}
										onChange={this.handleInputChange}
										id={'defaultEappType'}
									>
										<option value={'0'}>Traditional</option>
										<option value={'1'}>Cardknox Go</option>
										<option value={'2'}>ProfitStars Ach</option>
									</select>
								</div>
							</div>
						)}
					</div>
				</div>
				{this.state.canBoardGoPlus && (
					<div className="l--content--security">
						<div className="spr__card clearfix">
							<div className="spr__heading clearfix">
								<div className="group--clear cursor--pointer">
									<h4 className="type--base type--wgt--bold pull">Email Settings</h4>
								</div>
							</div>
							<div className="spr__content">
								<div className="flex--primary h--min--24">
									<input
										type="checkbox"
										className="input input--check"
										id="skipChargebackEmail"
										name="skipChargebackEmail"
										checked={this.state.skipChargebackEmail}
										value={this.state.skipChargebackEmail}
										onChange={this.handleInputChange}
									/>
									<label className="label" htmlFor="skipChargebackEmail">
										Skip chargeback emails?
									</label>
									<i
										className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
										data-tooltip="If checked, you will not be copied on any chargeback notification emails that are sent to your merchants."
									></i>
								</div>
								<div className="flex--primary h--min--24 spc--top--sml">
									<input
										type="checkbox"
										className="input input--check"
										id="skipDebitBlockEmail"
										name="skipDebitBlockEmail"
										checked={this.state.skipDebitBlockEmail}
										value={this.state.skipDebitBlockEmail}
										onChange={this.handleInputChange}
									/>
									<label className="label" htmlFor="skipDebitBlockEmail">
										Skip debit block emails to merchants?
									</label>
									<i
										className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
										data-tooltip="If enabled, then your merchants will no longer receive the debit block email that is sent to them when their account is approved."
									></i>
								</div>
								<div className="flex--primary h--min--24 spc--top--sml">
									<input
										type="checkbox"
										className="input input--check"
										id="skipPaymentSiteSetupEmail"
										name="skipPaymentSiteSetupEmail"
										checked={this.state.skipPaymentSiteSetupEmail}
										value={this.state.skipPaymentSiteSetupEmail}
										onChange={this.handleInputChange}
									/>
									<label className="label" htmlFor="skipPaymentSiteSetupEmail">
										Skip PaymentSite setup emails to merchants?
									</label>
									<i
										className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
										data-tooltip="If enabled and if PaymentSite is enabled on the merchant account, then your merchants will no longer receive the PaymentSite setup email that is sent to them when their account is approved."
									></i>
								</div>
								<div className="flex--primary h--min--24 spc--top--sml">
									<input
										type="checkbox"
										className="input input--check"
										id="skipPostTicketSurveyEmail"
										name="skipPostTicketSurveyEmail"
										checked={this.state.skipPostTicketSurveyEmail}
										value={this.state.skipPostTicketSurveyEmail}
										onChange={this.handleInputChange}
									/>
									<label className="label" htmlFor="skipPostTicketSurveyEmail">
									Skip ticket closing survey emails to merchants?
									</label>
									<i
										className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
										data-tooltip="If enabled, then your merchants will no longer receive ticket survey emails after a ticket on their account is closed."
									></i>
								</div>
								<div className="flex--primary h--min--24 spc--top--sml">
									<input
										type="checkbox"
										className="input input--check"
										id="bccSolaOnMerchantEmails"
										name="bccSolaOnMerchantEmails"
										checked={this.state.bccSolaOnMerchantEmails}
										value={this.state.bccSolaOnMerchantEmails}
										onChange={this.handleInputChange}
									/>
									<label className="label" htmlFor="bccSolaOnMerchantEmails">
										BCC Sola on Merchant Emails?
									</label>
									<i
										className="icon icon--tiny icon--info datatooltip--200 spc--left--tny spc--bottom--tny"
										data-tooltip="When this setting is enabled, any Sola email addresses that are CC'ed on communications to your merchants will be BCC'ed instead so that these email addresses are not visible to your merchant."
									></i>
								</div>
							</div>
						</div>
						<ValidationError
							errorList={this.state.errorList}
							errorListPaths={this.state.errorListPaths}
							scrollTo={this.scrollTo}
							focusField={this.focusField}
						/>
						<div className="type--right">
							<button
								className="btn btn--med btn--primary"
								type="button"
								disabled={this.props.isLoading}
								onClick={this.save}
							>
								Save
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

PartnerSettings.propTypes = {
	showLoader: func.isRequired,
	isLoading: bool,
};

export default withLoader(PartnerSettings);
