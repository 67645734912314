// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export function formatCurrency(num) {
	if (isNaN(num)) {
		return formatter.format(0);
	}
	return formatter.format(num);
}
