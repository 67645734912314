import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { split, get, map } from 'lodash';
import { parse } from 'query-string';

import { principalService, appService } from '../../services';

import { Notification } from '../../common/components/notifications';
import { withLoader } from '../../common/components';
import { parseError } from '../../common/utilities';
import { withCancelable } from '../../common/components/cancelable';
import { decode } from 'jsonwebtoken';

class SamlLoginComponent extends Component {
	constructor(props) {
		super(props);
		const queryParams = parse(window.location.search);
		let redirectParam = queryParams['redirectTo'];
		let redirectToPath,
			redirectToSearch = '';
		if (!!redirectParam) {
			redirectParam = decodeURIComponent(redirectParam);
			[redirectToPath, redirectToSearch] = split(redirectParam, '?');
			redirectToSearch = redirectToSearch || '';
			sessionStorage.setItem('saml-redirect-path', redirectToPath);
			sessionStorage.setItem('saml-redirect-search', redirectToSearch);
		}

		this.state = {
			errorMessage: null,
			redirectToPath: redirectToPath,
			redirectToSearch: redirectToSearch,
		};
	}

	isTokenExpired(token) {
		const decodedToken = decode(token, { complete: true });
		const dateNow = Math.floor(Date.now() / 1000);
		return decodedToken.payload.exp < dateNow;
	}

	deleteCookies() {
		const cookies = split(document.cookie, ';');
		map(cookies, cookie => {
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		});
	}

	async componentWillMount() {
		try {
			let currentAuthUser = await Auth.currentAuthenticatedUser();
			if (principalService.get() && currentAuthUser) {
				principalService.clear();
				// cleared the local storage - put back redirect url
				if (!!this.state.redirectToPath || !!this.state.redirectToSearch) {
					sessionStorage.setItem('saml-redirect-path', this.state.redirectToPath);
					sessionStorage.setItem('saml-redirect-search', this.state.redirectToSearch);
				}
				const jwt = get(currentAuthUser, 'signInUserSession.idToken.jwtToken');
				if (jwt && this.isTokenExpired(jwt)) {
					throw new Error('Cognito token expired');
				}
			}

			if (!principalService.get() && currentAuthUser) {
				try {
					const {
						signInUserSession: {
							idToken: {
								jwtToken,
								payload: { email },
							},
						},
					} = currentAuthUser;

					this.props.showLoader(true);
					try {
						await appService.login(jwtToken, email);
						this.props.showLoader(false);
						this.redirect();
					} catch (err) {
						if (err && err.isCanceled) {
							return;
						}
						const { stack } = parseError(err);
						this.props.showLoader(false);
						if (
							err &&
							err.ex &&
							err.ex.response &&
							(err.ex.response.status === 401 || err.ex.response.status === 403)
						) {
							this.setState({
								errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
							});
						} else {
							this.setState({ errorMessage: stack });
						}
					}
				} catch (err) {
					Auth.signOut();
					this.deleteCookies();
					await Auth.federatedSignIn({ provider: 'prd-fps' });
				}
			}
		} catch (ex) {
			Auth.signOut();
			this.deleteCookies();
			await Auth.federatedSignIn({ provider: 'prd-fps' });
		}
	}

	async redirect() {
		const { history, location } = this.props;
		let redirectToPath = sessionStorage.getItem('saml-redirect-path');
		let redirectToSearch = sessionStorage.getItem('saml-redirect-search');
		let search = '';
		let additionalState;

		// hide loader before redirect
		this.props.showLoader(false);
		if (location.hash) {
			[redirectToPath, search] = split(location.hash, '?');
			const queryParams = parse(location.hash);
			redirectToPath = (queryParams && queryParams['state']) || redirectToPath;
		}
		if (search && redirectToSearch) {
			search = `${search}&${redirectToSearch}`;
		}
		history.push({
			pathname: redirectToPath,
			search: search ? search : redirectToSearch,
			...(additionalState || {}),
		});
	}

	render() {
		const { errorMessage } = this.state;
		return (
			<div>
				Logging in...
				<Notification ref={el => (this.notification = el)} />
				{errorMessage ? (
					<div className="spc--top--sml membership__spacer type--color--warning">{errorMessage}</div>
				) : null}
			</div>
		);
	}
}

SamlLoginComponent.propTypes = {
	showLoader: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default withCancelable(withLoader(SamlLoginComponent));
