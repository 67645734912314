import { get, split, toLower, map, isEmpty } from 'lodash';

export const scrollTo = item => id => {
	let elem = document.getElementById(id);

	if (isEmpty(elem)) {
		if (toLower(id).indexOf('settingssource') > -1) {
			const index = split(id, '_')[1];
			const equipmentOptions = map(item.equipmentOptions, e => e);
			const optionName = get(equipmentOptions, `[${index}].name`);
			const fieldId = `${item.name}_equipmentOptions__${optionName}`;

			elem = document.getElementById(fieldId) || document.getElementById(`${fieldId}_${index || 1}`);
		} else {
			elem = document.getElementById(`${item.name}_${id}`);
		}
	}

	elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const focusField = item => name => {
	const index = split(name, '_')[1];
	let elem = document.getElementById(`${item.name}_${name}_${index || 1}`) || document.getElementsByName(name);

	if (isEmpty(elem)) {
		if (toLower(name).indexOf('settingssource') > -1) {
			const equipmentOptions = map(item.equipmentOptions, e => e);
			const optionName = get(equipmentOptions, `[${index}].name`);
			const fieldName = `${item.name}_equipmentOptions__${optionName}`;

			elem = document.getElementsByName(fieldName);
		} else {
			elem = document.getElementsByName(`${item.name}_${name}`);
		}
	}

	elem && elem[0] && elem[0].focus();
};
