import { some, each } from 'lodash';
import { createFilters, getFilterValues, getDate, getFilter } from '../../../common/utilities';

export const equipmentFilter = createFilters([]);

export const compileFilter = filters => {
	const { appId } = getFilterValues(filters);
	const filter = {};
	filter.appId = (appId && appId.appId) || 0;
	return filter;
};

const parseListFilter = values => {
	const listFilterArr = [];

	each(values, (value, stat) => {
		if (value) {
			listFilterArr.push(stat);
		}
	});

	return listFilterArr.join(',');
};
