import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { map } from 'lodash';

class NotificationComponent extends Component {
	constructor(props) {
		super(props);
		this.container = document.createElement('div');
		document.body.appendChild(this.container);

		this.state = {
			visible: false,
			notification: {},
		};
	}

	componentWillUnmount() {
		document.body.removeChild(this.container);
	}

	showNotification = () => {
		this.setState({
			visible: true,
		});
	};

	closeNotification = () => {
		this.setState({
			visible: false,
		});
	};

	hideNotification = () => {
		let cb = () => {};
		const {
			notification: { success, forceCloseHandler, onClose },
		} = this.state;
		if ((success || forceCloseHandler) && typeof onClose === 'function') {
			cb = onClose;
		}

		this.setState(
			{
				visible: false,
			},
			() => {
				cb();
			}
		);
	};

	addNotification = notification => {
		this.setState({
			visible: true,
			notification: notification,
		});
	};

	renderNotification() {
		const { visible, notification } = this.state;
		const { additional, style } = this.props;

		let title = null;
		let message = null;
		let ref = null;

		const notifications = notification.children ? [notification, ...notification.children] : [notification];

		if (notification.title) {
			title = (
				<div className="popup__header">
					<div className="popup__header__title">{notification.title}</div>
					{map(additional, ({ Title }, index) => Title && <Title key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.message) {
			message = (
				<div className="popup__body">
					<div className="fullwidth w--max--394">
						{notifications.map((item, index) => {
							return (
								<div
									key={item.ref || index}
									className={
										(item.success ? 'type--check' : 'type--cross') +
										' type--lrg type--color--black display--t align--h--center'
									}
								>
									{item.message}
								</div>
							);
						})}
						{map(additional, ({ Message }, index) => Message && <Message key={index} notification={notification} />)}
					</div>
				</div>
			);
		}

		if (notification.ref) {
			ref = (
				<Fragment>
					<div className="popup__footer flex--center">
						<div className="type--center type--color--text--regular">(Reference Number {notification.ref})</div>
					</div>
					{map(additional, ({ Ref }, index) => Ref && <Ref key={index} notification={notification} />)}
				</Fragment>
			);
		}

		return (
			<div className="notification-wrapper pull">
				{visible ? (
					<div>
						<div className="popup__overlay" onClick={this.hideNotification} />
						<div className="popup__content" style={style}>
							<button className="popup__close" onClick={this.hideNotification} />
							{title}
							{message}
							{ref}
						</div>
					</div>
				) : null}
				{map(additional, ({ Body }, index) => Body && <Body key={index} notification={notification} />)}
			</div>
		);
	}

	render() {
		return createPortal(this.renderNotification(), this.container);
	}
}

NotificationComponent.propTypes = {
	additional: PropTypes.arrayOf(
		PropTypes.shape({
			Ref: PropTypes.func,
			Title: PropTypes.func,
			Message: PropTypes.func,
			Body: PropTypes.func,
		})
	),
	style: PropTypes.object,
};

export default NotificationComponent;
