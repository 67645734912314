import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DescriptionFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.setDescriptionRef = React.createRef();
	}

	setDescriptionRef = el => {
		this.description = el;
	};

	filterChange = e => {
		const value = e.target.value ? e.target.value : null;

		this.onFilterChange(value);
	};

	onFilterChange(value) {
		this.props.onFilterChanged({
			id: 'description',
			values: [{ key: 'description', value: value }],
			emptyValue: null,
		});
	}

	componentDidUpdate = () => {
		if (this.setDescriptionRef.current) {
			this.setDescriptionRef.current.focus();
		}
	};

	render() {
		const description = this.props.filter.values.description;

		return (
			<div>
				<input
					type="text"
					placeholder="Description"
					className="input input--number input--med"
					value={description === null ? '' : this.props.filter.values.description}
					id="description" //TODO: check
					onChange={this.filterChange}
					ref={this.setDescriptionRef}
					autoFocus={true}
				/>
			</div>
		);
	}
}

DescriptionFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
};

export default DescriptionFilterComponent;
