import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LabelSelectionComponent extends Component {
	get labelValue() {
		return this.props.filter.values.label;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'label',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Label: </label>
				{this.labelValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

LabelSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default LabelSelectionComponent;
