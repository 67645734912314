import React, { Fragment } from 'react';
import {
	cloneDeep,
	map,
	toLower,
	find,
	sumBy,
	some,
	has,
	findIndex,
	isEmpty,
	get,
	each,
	compact,
	transform,
	first,
	filter,
	split,
	noop,
} from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';

import { AddressComponent, AddressDisplayComponent } from '../../common/components/address';
import { formatCurrency } from '../../helpers/currency-formatter';
import { getThumbnailOverlay, focusField, renderIf, scrollTo } from './../../common/utilities';
import { appService } from '../../services/appService';
import HardwarePurchasePlanComponent from './hardware-purchase-plan';
import FrontendProcessorComponent from './frontend-processor';
import EquipmentOption from './equipment-option';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { Modal } from '../../common/components/modal';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import EquipmentFileUpload from './EquipmentFileUpload';
import { MerchantContext } from '../MerchantDetails';
import { withContext } from '../../common/components';

const isExpand = true;
const required = (
	<span data-tooltip="Required" className="type--color--primary">
		{' '}
		*
	</span>
);
const outOfStockTooltip = 'Out of Stock';

class HardwareListItemComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shippingResults: null,
			isAccessoriesPopupOpen: false,
			accessories: cloneDeep(get(this.props.merchantHardware, 'accessories', [])),
			showExistingAppInput: false,
			showReasonInput: false,
			isSerialNumberValid: true,
			serialNumberValidationMessage: '',
		};

		this.scrollTo = scrollTo(props.hardware);
		this.focusField = focusField(props.hardware);
		this.setDefaults();
	}

	get canSelectPlatform() {
		const merchantHardware = this.props.merchantHardware;
		let routeToCardknox = get(merchantHardware, 'equipmentOptions.RouteToCardknox', false);

		return routeToCardknox == 0 || routeToCardknox == 'false';
	}

	get shouldValidateSerialNumber() {
		if (
			(this.props.hardware.category === 'Terminal' && this.props.hardware.subcategory.startsWith('Wireless')) ||
			this.props.hardware.category === 'Clover'
		)
			return true;

		return false;
	}

	get equipmentFiles() {
		const {
			hardware: { equipmentOptions },
			merchantHardware,
		} = this.props;
		return transform(equipmentOptions, (acc, item, key) => {
			if (item.dataType !== 'file') return;
			if (!this.props.optionIsAvailableForRules(item, merchantHardware)) return;
			if (
				item.dependentOnName &&
				(!merchantHardware.equipmentOptions[item.dependentOnName] ||
					merchantHardware.equipmentOptions[item.dependentOnName] !== item.dependentOnValue)
			) {
				return;
			}
			acc[key] = item;
		});
	}

	validateSerialNumber = e => {
		const merchantHardware = cloneDeep(this.props.merchantHardware);
		merchantHardware.subcategory = this.props.hardware.subcategory;
		appService
			.validateSerialNumber(merchantHardware)
			.then(validationResult => {
				let {
					isSerialNumberValid,
					errorMessage,
					requireAppOfExisitingSerial,
					requireReasonForTransfer,
				} = validationResult.validation;
				let needHardwareChange = false;

				if (isSerialNumberValid) errorMessage = 'Serial number is valid';
				if (!requireAppOfExisitingSerial && !!merchantHardware.appWithActiveSerialNumber) {
					merchantHardware.appWithActiveSerialNumber = '';
					needHardwareChange = true;
				}
				if (!requireReasonForTransfer && !!merchantHardware.reasonToTransferFromAnotherAgent) {
					merchantHardware.reasonToTransferFromAnotherAgent = '';
					needHardwareChange = true;
				}

				this.setState({
					showExistingAppInput: requireAppOfExisitingSerial,
					showReasonInput: requireReasonForTransfer,
					isSerialNumberValid: isSerialNumberValid,
					serialNumberValidationMessage: errorMessage,
				});
				if (needHardwareChange) this.props.onChange(merchantHardware);
			})
			.catch(e =>
				this.setState({
					serialNumberValidationMessage: e,
					isSerialNumberValid: false,
				})
			);
	};

	openAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: true }, this.defaultAllPaperAccessories);

	closeAccessoriesPopup = () =>
		this.setState({
			isAccessoriesPopupOpen: false,
			accessories: cloneDeep(get(this.props.merchantHardware, 'accessories', [])),
		});

	defaultAllPaperAccessories = () => {
		const { hardware } = this.props;
		const { accessories } = this.state;

		each(hardware.accessories, ({ subcategory, equipmentId, equipmentOptions }) => {
			const isPaper = toLower(subcategory) === 'paper';
			const hasCaseOrRollOption = some(equipmentOptions, e =>
				some(['caseorroll', 'case/roll'], item => item === toLower(e.name))
			);

			if (isPaper && hasCaseOrRollOption && !find(accessories, a => a.equipmentId == equipmentId)) {
				this.defaultAccessoryQuantity(true, equipmentId);
			}
		});
	};

	toggleAccessory = (equipmentId, isSelected = false) => {
		let accessories = cloneDeep(this.state.accessories);
		let acc = find(accessories, { equipmentId });

		if (!acc) {
			this.toggleNewAccessory(isSelected, acc, accessories, equipmentId);
		} else {
			this.toggleExistingAccessory(isSelected, acc);

			if (!isSelected) {
				accessories = filter(this.state.accessories, a => a.equipmentId != equipmentId);
			}
		}

		this.setState({ accessories }, () => {
			if (!isSelected) {
				this.saveHardwareAccessories();
			}
		});
	};

	toggleNewAccessory = (isSelected, acc, accessories, equipmentId) => {
		if (isSelected) {
			const sourceAcc = this.props.hardware.accessories.find(a => a.equipmentId == equipmentId);
			acc = this.props.createNewEquipment(equipmentId, isSelected, false, true);
			acc.name = sourceAcc ? sourceAcc.name : '';
			accessories.push(acc);
		}
	};

	toggleExistingAccessory = (isSelected, acc) => {
		if (isSelected) {
			acc.quantity = isSelected ? (acc.quantity || 0) + 1 : 0;
			acc.isSelected = isSelected;
		}
	};

	addAccessoriesToHardware = () => {
		this.setState({ isAccessoriesPopupOpen: false });
		this.saveHardwareAccessories();
	};

	saveHardwareAccessories = () => {
		const hardware = { ...this.props.merchantHardware, accessories: this.state.accessories };
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	hardwareRemove = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		hardware.isSelected = false;
		hardware.quantity = 1;

		if (!isEmpty(hardware.accessories)) {
			hardware.accessories = [];
			this.setState({ accessories: [] });
		}

		this.props.onChange(hardware);
	};

	purchaseTypeSelect = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.checked) {
			hardware.purchaseType = e.target.value;
		} else if (hardware.purchaseType == e.target.value) {
			hardware.purchaseType = ''; // clear
		}
		// get fees for the new purchase type
		let purchasePlan = this.props.hardware.purchasePlans.find((p, i) => p.planId == hardware.purchasePlanId);
		this.copyFees(purchasePlan, hardware, hardware.purchaseType);
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	copyFees(sourceObj, destObj, purchaseType) {
		if (!sourceObj || !destObj) {
			return;
		}
		purchaseType = purchaseType || this.props.merchantHardware.purchaseType;

		destObj.fees = cloneDeep(sourceObj.fees.filter(f => f.purchaseTypes.includes(purchaseType)));
		each(destObj.fees, fee => {
			if (!has(fee, 'merchantPrice')) fee.merchantPrice = fee.retailPrice;
		});
	}

	optionSelect = e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.checked) {
			hardware.equipmentOptions[e.target.value] = '1';
		} else if (Object.keys(hardware.equipmentOptions).includes(e.target.value)) {
			hardware.equipmentOptions[e.target.value] = '0';
		}
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	optionSetMoreInfo = (optKey, optValue) => e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		hardware.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	handleFeeChange = (planId, feeId) => ({ floatValue }) => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let fee = hardware.fees.find((fee, i) => fee.feeId === feeId);
		fee.merchantPrice = floatValue;
		this.props.onChange(hardware);
	};

	handleTimeChange = time => {
		let hardware = cloneDeep(this.props.merchantHardware);
		hardware.batchoutTime = time;
		this.props.onChange(hardware);
	};

	handleShippingOptionChange = e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let opt = e.target.value;
		if (opt.toLowerCase() == 'merchantdba') {
			hardware.shippingAddress = this.props.physicalAddress;
		} else if (opt.toLowerCase() == 'merchant') {
			hardware.shippingAddress = this.props.corporateAddress;
		} else if (opt.toLowerCase() == 'agent' || opt.toLowerCase() == 'pickup') {
			hardware.shippingSpeed = '';
			hardware.shippingAddress = null;
			hardware.saturdayDelivery = false;
		} else {
			// hardware.shippingaddress = {streetAddress: '', city: '', state: '', zip: ''}
		}
		hardware.shippingOption = opt;
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	handleAddressChange = ({ target: { value, name } }) => {
		const { merchantHardware } = this.props;
		const hardware = { ...merchantHardware };
		const [section, key] = split(name, '_');

		hardware[section] = {
			...hardware[section],
			[key]: value,
		};
		this.selectHardware(hardware);

		this.props.onChange(hardware);
	};

	calculateShipping = () => {
		const hardware = this.props.merchantHardware;
		let fromAddress = {
			streetAddress: '465 Oak Glen Road',
			city: 'Howell Township',
			state: 'NJ',
			zip: '07731',
		};
		appService
			.getShippingRates(
				fromAddress,
				hardware.shippingAddress,
				this.props.hardware.shippingWeight * hardware.quantity,
				hardware.satDelivery,
				hardware.shippingSpeed
			)
			.then(res => {
				this.setState({ shippingResults: res });
			})
			.catch(ex => {
				console.log('Error calculating shipping rate ' + ex);
				this.setState({ shippingResults: 'Error calculating shipping rate.' });
			});
	};

	handleChange = (e, packageType) => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let hardwareName = this.props.hardware.name;

		let itemToSet, itemKey;
		let strName = e.target.name;
		if (strName.startsWith(hardwareName + '_')) strName = strName.substr(hardwareName.length + 1);

		if (strName.indexOf('_') > 0) {
			let keyList = compact(strName.split('_'));
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, hardware);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = hardware;
			itemKey = strName;
		}
		//console.log('form item was ' + e.target.name);
		//console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
		let newVal = e.target.value;
		if (e.target.type && e.target.type == 'checkbox') newVal = e.target.checked;

		itemToSet[itemKey] = newVal;

		if (packageType) {
			const option = find(
				this.props.hardware.equipmentOptions,
				o => toLower(o.name).indexOf(`${toLower(newVal)} quantity`) > -1
			);

			if (option) {
				hardware.quantity = parseInt(get(first(option.options), 'value', 1));
			}
		}
		this.selectHardware(hardware);

		this.props.onChange(hardware);
	};

	adjustQuantity = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.name === 'increase') {
			hardware.quantity += 1;
			this.selectHardware(hardware);
		} else if (e.target.name === 'decrease' && hardware.quantity > 0) {
			hardware.quantity -= 1;
			this.selectHardware(hardware);
		}
		this.props.onChange(hardware);
	};

	selectHardware = hardware => {
		if (this.props.hardware.outOfStock && hardware.purchaseType === 'purchase') return;
		if (hardware.quantity > 0 || this.props.merchantHardware.quantity === 0) {
			hardware.isSelected = true;
			hardware.quantity = Math.max(hardware.quantity, 1);
		} else {
			hardware.isSelected = false;
		}
	};

	handleAccessoriesPriceChange = equipmentId => ({ floatValue }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex === -1) {
			const acc = this.props.createNewEquipment(equipmentId, false, false, true);
			acc.quantity = 0;
			const feeIndex = findIndex(acc.fees, ({ feeType }) => toLower(feeType) === 'onetimefee');

			if (feeIndex === -1) {
				return;
			}

			acc.fees[feeIndex].merchantPrice = floatValue;
			newState.accessories.push(acc);
		} else {
			const feeIndex = findIndex(accessories[accIndex].fees, ({ feeType }) => toLower(feeType) === 'onetimefee');

			if (feeIndex === -1) {
				return;
			}

			newState.accessories[accIndex].fees[feeIndex] = {
				...newState.accessories[accIndex].fees[feeIndex],
				merchantPrice: floatValue,
			};
		}

		this.setState(newState);
	};

	handleAccessoriesEquipmentOptionChange = (isQuantity, equipmentId) => e => {
		const {
			target: { value, name },
		} = e;
		const accessories = [...this.state.accessories];
		const [index, key, optionKey] = name.split('_');
		const hasChanged = isQuantity
			? value != get(this.state.accessories, `${index}.${key}`)
			: value != get(this.state.accessories, `${index}.${key}.${optionKey}`);
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex == -1) {
			const acc = this.props.createNewEquipment(equipmentId, true, false, true);

			if (isQuantity) {
				acc.quantity = parseInt(value || 0);
			} else {
				acc.quantity = 0;
				acc[key][optionKey] = value;
			}

			accessories.push(acc);
		} else {
			if (!hasChanged) {
				return;
			}

			if (isQuantity) {
				accessories[index][key] = parseInt(value || 0);
			} else {
				accessories[index].quantity = 0;
				accessories[index][key] = {
					...accessories[index][key],
					[optionKey]: value,
				};
			}

			accessories[index].isSelected = true;
		}

		this.setState({ accessories }, () => this.defaultAccessoryQuantity(!isQuantity, equipmentId));
	};

	defaultAccessoryQuantity = (isQuantity, equipmentId) => {
		if (isQuantity) {
			const { accessories } = this.state;
			const hardwareAccessory = find(this.props.hardware.accessories, a => a.equipmentId == equipmentId);

			if (hardwareAccessory) {
				const { equipmentOptions } = hardwareAccessory;
				const stateAccessory = find(accessories, a => a.equipmentId == equipmentId);
				const accIndex = findIndex(accessories, { equipmentId });
				const type = toLower(get(stateAccessory, 'equipmentOptions.CaseOrRoll', 'case'));
				const option = find(equipmentOptions, o => toLower(o.name).indexOf(`${type} quantity`) > -1);
				let value = 0;

				if (option) {
					value = get(option.defaultValue || first(option.options), 'value', 0);
				}

				this.handleAccessoriesEquipmentOptionChange(
					isQuantity,
					equipmentId
				)({ target: { name: accIndex + '_quantity', value } });
			}
		}
	};

	adjustAccessoriesQuantity = equipmentId => ({ target: { name } }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex < 0) {
			const acc = this.props.createNewEquipment(equipmentId, name === 'increase', false, true);
			newState.accessories.push({ ...acc, quantity: name === 'increase' ? 1 : 0 });
		} else {
			newState.accessories[accIndex] = {
				...accessories[accIndex],
				isSelected: true,
				quantity: name === 'increase' ? accessories[accIndex].quantity + 1 : accessories[accIndex].quantity - 1,
			};

			if (newState.accessories[accIndex].quantity < 0) {
				return;
			}
		}

		this.setState(newState);
	};

	setDefaults() {
		let { hardware } = this.props;

		let merchantHardware;
		let changed = false;
		if (this.props.merchantHardware) {
			merchantHardware = cloneDeep(this.props.merchantHardware);
		} else {
			merchantHardware = this.props.createNewEquipment(
				hardware.equipmentId,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				0
			);
			changed = true;
		}

		if (!Object.keys(merchantHardware).includes('shippingAddress') || !merchantHardware.shippingAddress) {
			merchantHardware.shippingAddress = { streetAddress: '', city: '', state: '', zip: '' };
			changed = true;
		}
		if (!Object.keys(merchantHardware).includes('satDelivery')) {
			merchantHardware.satDelivery = false;
			changed = true;
		}
		const platforms = hardware.frontendProcessors ? Object.keys(hardware.frontendProcessors) : [];
		if (platforms.length === 1) {
			merchantHardware.platform = platforms[0];
			changed = true;
		}
		each(hardware.equipmentOptions, ({ dataType, isReadOnly, options }, option) => {
			if (dataType === 'boolean' || isReadOnly || options.length !== 1 || options[0].value === '') return;
			merchantHardware.equipmentOptions[option] = options[0].value;
			changed = true;
		});
		if (changed) {
			this.props.onChange(merchantHardware);
		}
	}

	optionIsAvailableForPurchaseType(opt, hardware, merchantHardware) {
		let optionIsAvailable = true;
		hardware.purchasePlans.forEach(p => {
			let dependentFee = p.fees.find(fee => fee.dependencySettings && fee.dependencySettings[opt.name]);
			//console.log(p.purchaseTypes, dependentFee.purchaseTypes, merchantHardware.purchaseType);
			if (
				dependentFee &&
				(!p.purchaseTypes.includes(merchantHardware.purchaseType) ||
					!dependentFee.purchaseTypes.includes(merchantHardware.purchaseType))
			)
				optionIsAvailable = false;
		});
		//console.log(optionIsAvailable);
		return optionIsAvailable;
	}

	handleRemoveFile = (fileType, i) => {
		let item = cloneDeep(this.props.merchantHardware);
		item.attachment.file = {};
		this.props.onChange(item);
	};

	onDropFile = (fileType, acceptedFiles) => {
		let item = cloneDeep(this.props.merchantHardware);
		// will really be only a single file, but param type is an array
		item.attachment = {};
		each(acceptedFiles, f => (item.attachment.file = f));
		console.log(item.attachment.file);
		this.props.onChange(item);
	};

	checkIfShouldRenderPaperQuantityModule = (isPaper, equipmentId, equipmentOptions) => {
		const { accessories } = this.state;
		const accessory = find(accessories, a => a.equipmentId == equipmentId);
		const caseOrRoll = get(accessory, 'equipmentOptions.CaseOrRoll');

		return (
			isPaper &&
			some(equipmentOptions, ({ name, dependentOnName, dependentOnValue }) => {
				const loweredDependentOnName = toLower(dependentOnName);
				return (
					toLower(name) === 'quantity' &&
					(loweredDependentOnName === 'case/roll' || loweredDependentOnName === 'caseorroll') &&
					toLower(caseOrRoll) === toLower(dependentOnValue)
				);
			})
		);
	};

	renderEquipmentOption = (accessoryName, equipmentId, equipmentOptions, isHardware = false) => {
		const { accessories } = this.state;
		let loweredAccessoryName = toLower(accessoryName);
		const stateAccessory = isHardware
			? this.props.merchantHardware
			: find(accessories, a => a.equipmentId == equipmentId);
		const isQuantity = loweredAccessoryName.indexOf('quantity') > -1;
		if (isQuantity) {
			const relevantQuantityOption = find(
				equipmentOptions,
				e =>
					toLower(e.friendlyName) === loweredAccessoryName &&
					e.dependentOnValue === get(stateAccessory, 'equipmentOptions.CaseOrRoll')
			);
			if (!relevantQuantityOption) {
				return;
			}
			loweredAccessoryName = toLower(relevantQuantityOption.name);
		}
		const option = find(equipmentOptions, e => toLower(e.name) === loweredAccessoryName);
		const accessoryIndex = findIndex(accessories, a => a.equipmentId == equipmentId);
		const value = get(stateAccessory, isQuantity ? 'quantity' : `equipmentOptions.${accessoryName}`, '');

		if (!option) {
			return;
		}

		const { name, isRequired, friendlyName, options } = option;
		let id = `${accessoryIndex}${isQuantity ? '' : '_equipmentOptions'}_${isQuantity ? 'quantity' : name}`;

		if (isQuantity && isHardware) {
			id = 'quantity';
		}

		return (
			<div className="fullwidth spc--bottom--sml">
				<label htmlFor={id} className="label">
					{friendlyName || name} {isRequired && required}
				</label>
				<select
					id={id}
					name={id}
					className="input input--med input--select"
					onChange={
						isHardware ? this.handleChange : this.handleAccessoriesEquipmentOptionChange(isQuantity, equipmentId)
					}
					value={value}
				>
					{map(options, o => (
						<option key={o.value} value={o.value}>
							{o.friendlyName || o.value}
						</option>
					))}
				</select>
			</div>
		);
	};

	renderAccessoriesPopup = () => {
		const {
			hardware,
			merchantHardware: { paymentSchedule },
		} = this.props;
		const { isAccessoriesPopupOpen, accessories } = this.state;
		const hasSelectedItems = some(accessories, ({ isSelected }) => isSelected);
		const hardwareAccessories = hardware.accessories;
		const isBillAgentPaymentSchedule = toLower(paymentSchedule) === 'billagent';
		const tooltip = isBillAgentPaymentSchedule ? 'Price is not editable when Payment Schedule is Bill Agent.' : null;
		return (
			<Modal shouldCloseOnOverlayClick={false} isOpen={isAccessoriesPopupOpen} onClose={this.closeAccessoriesPopup}>
				<div>
					<div className="popup__header">
						<h6>{hardware.name} Add-ons</h6>
					</div>
					<div className="popup__body addon--popup__body">
						<div className="addon--popup__wrap">
							{map(
								hardwareAccessories,
								({ equipmentId, name, description, purchasePlans, subcategory, equipmentOptions, outOfStock }) => {
									const accessory = accessories.find(a => a.equipmentId == equipmentId);
									let quantity = accessory ? accessory.quantity : 0;
									const plan = purchasePlans[0];
									const fee = find(plan.fees, { feeType: 'OneTimeFee' });
									let price = accessory
										? get(find(accessory.fees, { feeType: 'OneTimeFee' }), 'merchantPrice')
										: fee.retailPrice;
									const isPaper = toLower(subcategory) === 'paper';

									return (
										<div className="addon--popup__wrap__item" key={`${equipmentId}.${name}`}>
											<div className="addon--popup">
												<div
													className={`spc--bottom--sml checkout__img${getThumbnailOverlay(
														outOfStock,
														hardware.purchaseTypes
													)}`}
													style={{
														backgroundImage:
															'url(' +
															process.env.REACT_APP_CDN_URL +
															toLower(name)
																.replace(' ', '_')
																.replace(/[^a-z0-9_-]/gi, '') +
															'/thumbnail.png' +
															')',
													}}
													alt={name}
												></div>
												<div className="addon--popup__wrap__title">
													{name}
													{description && (
														<span className="datatooltip--base">
															<i
																className="icon icon--tiny align--v--middle icon--info spc--left--sml"
																data-tooltip={description}
															/>
														</span>
													)}
												</div>
												<div
													className="datatooltip--250 datatooltip--bottom"
													data-tooltip={isBillAgentPaymentSchedule ? tooltip : null}
												>
													<NumberFormat
														className="input input--med input--currency input--currency--sml w--200p type--right spc--bottom--sml"
														value={isBillAgentPaymentSchedule ? fee.agentCost : price}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														disabled={isBillAgentPaymentSchedule}
														onValueChange={
															isBillAgentPaymentSchedule ? noop : this.handleAccessoriesPriceChange(equipmentId)
														}
													/>
												</div>
												<div className="w--200p">
													{isPaper && this.renderEquipmentOption('CaseOrRoll', equipmentId, equipmentOptions)}
												</div>
												<div className="inputgroup w--200p">
													{this.renderQuantityModule(
														isPaper,
														equipmentId,
														equipmentOptions,
														false,
														<Fragment>
															<div className="inputgroup--aside">
																<button
																	type="button"
																	className="btn btn--handler spc--left--1"
																	name="decrease"
																	onClick={this.adjustAccessoriesQuantity(equipmentId)}
																	disabled={outOfStock}
																	data-tooltip={outOfStock ? outOfStockTooltip : null}
																>
																	&#8211;
																</button>
															</div>
															<div className="inputgroup--main inputgroup--main--double">
																<input
																	className="input input--med type--center"
																	disabled
																	type="number"
																	min="1"
																	name="quantity"
																	value={quantity}
																/>
															</div>
															<div className="inputgroup--aside inputgroup--aside--double">
																<button
																	type="button"
																	className="btn btn--handler spc--right--1"
																	name="increase"
																	onClick={this.adjustAccessoriesQuantity(equipmentId)}
																	disabled={outOfStock}
																	data-tooltip={outOfStock ? outOfStockTooltip : null}
																>
																	+
																</button>
															</div>
														</Fragment>
													)}
												</div>
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
					<div className="popup__footer popup__footer--styled">
						<button className="btn btn--ghost btn--med spc--left--tny" onClick={this.closeAccessoriesPopup}>
							Cancel
						</button>
						<button
							className="btn btn--success btn--med spc--left--tny"
							onClick={this.addAccessoriesToHardware}
							disabled={!hasSelectedItems}
						>
							Add-on items ({hasSelectedItems ? sumBy(accessories, ({ quantity }) => parseInt(quantity || 0)) : 0})
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderDependentOptions = (mainOptionName, hardware, merchantHardware, index, errorListPaths) => {
		// console.log(mainOptionName);
		return (
			<Fragment>
				{Object.values(hardware.equipmentOptions)
					.filter(o => mainOptionName == o.dependentOnName && o.dataType !== 'file')
					.map((dependentOpt, idx) => {
						let disableOption =
							!merchantHardware.equipmentOptions[mainOptionName] ||
							merchantHardware.equipmentOptions[mainOptionName] != dependentOpt.dependentOnValue;
						if (disableOption) return null;
						return (
							<Fragment>
								<div>
									<EquipmentOption
										merchantEquipment={merchantHardware}
										equipment={hardware}
										option={dependentOpt.name}
										index={index}
										errorListPaths={errorListPaths}
										listType="radio"
										checkboxHandler={this.optionSelect}
										moreInfoHandler={this.optionSetMoreInfo}
										inputHandler={this.handleChange}
										disableOption={disableOption}
									/>
								</div>
								{this.renderDependentOptions(dependentOpt.name, hardware, merchantHardware, index, errorListPaths)}
							</Fragment>
						);
					})}
			</Fragment>
		);
	};

	renderQuantityModule = (isPaper, equipmentId, equipmentOptions, isHardware, renderHardwareQuantity) =>
		isPaper
			? this.renderEquipmentOption('Quantity', equipmentId, equipmentOptions, isHardware)
			: renderHardwareQuantity;

	render() {
		let { hardware, merchantHardware, downloadPrefilledAuthorizationForm, getAuthorizationFormName } = this.props;
		const { isSerialNumberValid, showReasonInput, showExistingAppInput, serialNumberValidationMessage } = this.state;
		const file = get(merchantHardware, 'attachment.file');
		let errorList, errorListPaths;
		if (merchantHardware) {
			errorList = merchantHardware.errors;
			// make sure error object is valid -- otherwise ignore it
			// this is because it seems JSON.stringify doesn't handle this object properly and saves it empty
			errorListPaths = errorList && errorList.length > 0 && errorList.map(e => e.path);
		}

		const isPaper = get(merchantHardware, 'equipmentOptions.CaseOrRoll');
		const isPickup = toLower(get(merchantHardware, 'shippingOption', '')) === 'pickup';
		const outOfStock = hardware.outOfStock;
		const disableAddButton = outOfStock && get(merchantHardware, 'purchaseType') === 'purchase';
		const isCanadianRental = this.props.merchant.isCanadian && get(merchantHardware, 'purchaseType') === 'rental';

		return merchantHardware ? (
			<React.Fragment>
				{this.renderAccessoriesPopup()}
				<div className="l--twocols--main spc--bottom--med">
					<div className="header spc--bottom--med--on-sml flex--align--center header--underline">
						<div className="header__title spc--bottom--no">{hardware.name}</div>
						<button
							type="button"
							className="btn btn--ghost btn--med spc--left--auto"
							onClick={this.props.handleBackClick}
						>
							Back
						</button>
					</div>
					{merchantHardware.isSelected && errorListPaths ? (
						<React.Fragment>
							<div className="note note--warning spc--bottom--sml">
								<ul>
									{' '}
									{errorList.map((elem, i) => {
										return (
											<li key={i}>
												<div
													className="anchor"
													onClick={() => {
														let elemId = elem.path.replace(/[.]/g, '_');
														this.scrollTo(elemId);
														this.focusField(elemId);
													}}
												>
													<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
													{defaultReactOutput(defaultImplicitParse(elem.message))}
												</div>
											</li>
										);
									})}
								</ul>
							</div>
						</React.Fragment>
					) : null}
					<div className="hardware__preview">
						<div className="hardware__preview__main">
							<div
								className="checkout__img"
								style={{
									backgroundImage:
										'url(' +
										process.env.REACT_APP_CDN_URL +
										hardware.name
											.toLowerCase()
											.replace(' ', '_')
											.replace(/[^a-z0-9_-]/gi, '') +
										'/thumbnail.png' +
										')',
								}}
								alt={hardware.name}
							></div>
							<div className="checkout__description spc--bottom--med">{hardware.description}</div>
							{renderIf(hardware.datasheetUrl)(
								<div className="form__field spc--bottom--med">
									<input
										className="input--check"
										type="checkbox"
										name="includeDataSheet"
										id="includeDataSheet"
										onChange={this.handleChange}
									/>
									<label className="label" htmlFor="includeDataSheet">
										Include{' '}
										<a className="anchor anchor--primary" href={hardware.datasheetUrl} target="_blank">
											Datasheet
										</a>
									</label>
								</div>
							)}
							{/* Purchase type */}

							<ToggleContainer>
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
										<React.Fragment>
											<div className={`title--tertiary__wrap ${isToggled ? '' : 'spc--bottom--sml'}`}>
												<label className="title--tertiary">Purchase type</label>
												<i
													className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
														isToggled ? 'rotate--180' : ''
													}`}
													onClick={handleToggle}
												></i>
											</div>
											<div className={isToggled ? 'form__content' : ''}>
												<div className={isToggled ? '' : 'display--n'}>
													{Object.keys(hardware.purchaseTypes).map((pt, idx) => {
														const checked = merchantHardware.purchaseType == pt;
														const isPurchaseAndDisabled = hardware.outOfStock && toLower(pt) === 'purchase';
														const isCustomerOwnedOption =
															toLower(pt) === 'existing' && checked && hardware.category !== 'Clover';
														let tooltip = null;

														if (isPurchaseAndDisabled) {
															tooltip = 'Out of Stock';
														}

														return (
															<div
																key={idx}
																className="spc--right--med spc--bottom--sml datatooltip--200 datatooltip--top--right"
																data-tooltip={tooltip}
															>
																<input
																	className="input--radio"
																	type="radio"
																	name={hardware.name + '_' + pt + '_pt'}
																	id={hardware.name + '_' + pt + '_pt'}
																	onChange={this.purchaseTypeSelect}
																	value={pt}
																	checked={checked}
																	disabled={isPurchaseAndDisabled}
																/>
																<label className="label" htmlFor={hardware.name + '_' + pt + '_pt'}>
																	{hardware.purchaseTypes[pt]}
																</label>
																{isCustomerOwnedOption && (
																	<div className="message message--default spc--top--sml">
																		<p className="spc--bottom--tny">
																			Depending on the device and specific scenario, there may be various steps that
																			need to be taken to properly set up the hardware, which may including loading OS
																			and/or XPI files and encrypting the device. Occasionally, the device may need to
																			be shipped to a third party vendor for encryption.
																		</p>
																		<p className="spc--bottom--tny">
																			Please note, even with the appropriate actions taken, not all customer owned
																			devices may work properly. And in certain circumstances, a device might not be
																			able to revert back to it original state.
																		</p>
																		<p>Kindly consult technical support with any additional questions.</p>
																	</div>
																)}
																{toLower(pt) === 'rollover' && checked && (
																	<Fragment>
																		<div className="gateway__top__separator"></div>
																		<div>
																			<div className="row row-align-middle">
																				<div className="col col-sml-12 col-med-5">
																					<label>Original EquipmentID</label>
																				</div>
																				<div className="col col-sml-12 col-med-7">
																					<input
																						type="text"
																						className="input input--med w--max--300"
																						name={hardware.name + '_originalEquipmentId'}
																						placeholder="EquipmentId"
																						value={hardware.originalEquipmentId}
																						onChange={this.handleChange}
																					/>
																				</div>
																			</div>
																		</div>
																	</Fragment>
																)}
															</div>
														);
													})}
												</div>
												{(merchantHardware.purchaseType === 'existing' ||
													merchantHardware.purchaseType === 'agent_owned') && (
													<div className="row">
														<div className="col col-sml-12 col-lrg-4 spc--bottom--med">
															<label className="label" htmlFor="serialNumber">
																Serial Number
															</label>
															<input
																className="input input--med spc--bottom--sml"
																type="text"
																id="serialNumber"
																name="serialNumber"
																value={merchantHardware.serialNumber}
																onChange={this.handleChange}
															/>
															{this.shouldValidateSerialNumber && (
																<Fragment>
																	{(!!showExistingAppInput || !!merchantHardware.appWithActiveSerialNumber) && (
																		<Fragment>
																			<label className="label" htmlFor="appWithActiveSerialNumber">
																				App ID or MID this serial number is currently active on
																			</label>
																			<input
																				className="input input--med spc--bottom--sml"
																				type="text"
																				name="appWithActiveSerialNumber"
																				value={merchantHardware.appWithActiveSerialNumber}
																				onChange={this.handleChange}
																			/>
																		</Fragment>
																	)}
																	{(!!showReasonInput || !!merchantHardware.reasonToTransferFromAnotherAgent) && (
																		<Fragment>
																			<label className="label" htmlFor="reasonToTransferFromAnotherAgent">
																				Reason for transferring serial to another agent
																			</label>
																			<input
																				className="input input--med spc--bottom--sml"
																				type="text"
																				name="reasonToTransferFromAnotherAgent"
																				value={merchantHardware.reasonToTransferFromAnotherAgent}
																				onChange={this.handleChange}
																			/>
																		</Fragment>
																	)}
																	{!isSerialNumberValid ? (
																		<div className="note note--warning spc--bottom--sml">
																			{serialNumberValidationMessage}
																		</div>
																	) : (
																		serialNumberValidationMessage && (
																			<div className="note note--success spc--bottom--sml">
																				{serialNumberValidationMessage}
																			</div>
																		)
																	)}
																	<button className="btn btn--primary btn--med" onClick={this.validateSerialNumber}>
																		Validate
																	</button>
																</Fragment>
															)}
														</div>
													</div>
												)}
											</div>
										</React.Fragment>
									)}
								</Toggle>

								{/* Platform and Settlement */}
								{hardware.frontendProcessors && Object.keys(hardware.frontendProcessors).length > 0 && (
									<Toggle initialToggle={isExpand}>
										{({ isToggled, handleToggle }) => (
											<React.Fragment>
												<div className={`title--tertiary__wrap ${isToggled ? '' : 'spc--bottom--sml'}`}>
													<label className="title--tertiary">Platform and Settlement</label>
													<i
														className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
															isToggled ? 'rotate--180' : ''
														}`}
														onClick={handleToggle}
													></i>
												</div>
												<div className={isToggled ? 'spc--bottom--med' : 'display--n'}>
													<FrontendProcessorComponent
														equipment={hardware}
														merchantEquipment={merchantHardware}
														handleChange={this.handleChange}
														handleTimeChange={this.handleTimeChange}
														optionSelect={this.optionSelect}
														optionSetMoreInfo={this.optionSetMoreInfo}
														enabled={this.canSelectPlatform}
														errorListPaths={errorListPaths}
													/>
												</div>
											</React.Fragment>
										)}
									</Toggle>
								)}
								{/* Features */}
								{Object.values(hardware.equipmentOptions).filter(o => !o.dependentOnName).length > 0 ? (
									<Toggle initialToggle={isExpand}>
										{({ isToggled, handleToggle }) => (
											<React.Fragment>
												<div className={`title--tertiary__wrap ${isToggled ? '' : 'spc--bottom--sml'}`}>
													<label className="title--tertiary">Features and Setup</label>
													<i
														className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
															isToggled ? 'rotate--180' : ''
														}`}
														onClick={handleToggle}
													></i>
												</div>
												<div className={isToggled ? 'form__content' : 'display--n'}>
													<div className="row">
														{Object.values(hardware.equipmentOptions)
															.filter(o => !o.dependentOnName && o.dataType !== 'file')
															.map((opt, idx) => {
																if (opt.name == 'BatchSettlementType') return null; // handled separately
																if (!this.optionIsAvailableForPurchaseType(opt, hardware, merchantHardware)) return;
																if (!this.props.optionIsAvailableForRules(opt, merchantHardware)) return;

																return (
																	<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml" key={idx}>
																		<EquipmentOption
																			merchantEquipment={merchantHardware}
																			equipment={hardware}
																			option={opt.name}
																			index={idx}
																			errorListPaths={errorListPaths}
																			listType="radio"
																			checkboxHandler={this.optionSelect}
																			moreInfoHandler={this.optionSetMoreInfo}
																			inputHandler={e => this.handleChange(e, isPaper)}
																		/>
																		<div>
																			{this.renderDependentOptions(
																				opt.name,
																				hardware,
																				merchantHardware,
																				idx,
																				errorListPaths
																			)}
																		</div>
																	</div>
																);
															})}

														{!isEmpty(this.equipmentFiles) && (
															<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml">
																<section>
																	<label className="label display--b spc--bottom--sml">File Attachment</label>
																	{!!getAuthorizationFormName() && (
																		<button
																			onClick={downloadPrefilledAuthorizationForm}
																			className="btn btn--med btn--primary spc--bottom--med"
																			style={{ cursor: !merchantHardware.isSelected ? 'not-allowed' : 'pointer' }}
																			data-tooltip={
																				!merchantHardware.isSelected
																					? 'Equipment must be Added to Cart before downloading.'
																					: null
																			}
																		>
																			Download Authorization Form
																		</button>
																	)}
																	<EquipmentFileUpload
																		equipmentFiles={this.equipmentFiles}
																		file={file}
																		onDropFile={this.onDropFile}
																		handleRemoveFile={this.handleRemoveFile}
																	/>
																</section>
															</div>
														)}
													</div>
												</div>
											</React.Fragment>
										)}
									</Toggle>
								) : null}
								{/* Shipping */}
								{merchantHardware.purchaseType != 'existing' && merchantHardware.purchaseType != 'agent_owned' && (
									<Toggle initialToggle={isExpand}>
										{({ isToggled, handleToggle }) => (
											<React.Fragment>
												<div className={`title--tertiary__wrap ${isToggled ? '' : 'spc--bottom--sml'}`}>
													<label className="title--tertiary required">Shipping Details</label>
													<i
														className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
															isToggled ? 'rotate--180' : ''
														}`}
														onClick={handleToggle}
													></i>
												</div>
												<div id="shippingOption" className={isToggled ? 'form__content' : 'display--n'}>
													{Object.keys(hardware.shippingOptions).map((opt, idx) => {
														return (
															<React.Fragment key={idx}>
																<div className="spc--bottom--sml">
																	<input
																		className="input--radio"
																		type="radio"
																		key={idx}
																		value={opt}
																		checked={merchantHardware.shippingOption === opt}
																		name="shippingOption"
																		id={'selectedShippingOption_' + opt}
																		onChange={this.handleShippingOptionChange}
																	/>
																	<label className="label" htmlFor={'selectedShippingOption_' + opt}>
																		{hardware.shippingOptions[opt]}
																		<div>
																			{opt.toLowerCase() === 'merchantdba' && (
																				<AddressDisplayComponent address={this.props.physicalAddress} />
																			)}
																			{opt.toLowerCase() === 'merchant' && (
																				<AddressDisplayComponent address={this.props.corporateAddress} />
																			)}
																			<div className="spc--top--xsml">
																				{opt.toLowerCase() === 'other' && merchantHardware.shippingOption === opt && (
																					<AddressComponent
																						address={merchantHardware.shippingAddress || {}}
																						namePrefix="shippingAddress"
																						required={true}
																						onChange={this.handleAddressChange}
																						showName={true}
																					/>
																				)}
																			</div>
																		</div>
																	</label>
																</div>
															</React.Fragment>
														);
													})}

													<div className={`spc--bottom--sml ${isPickup ? '' : 'required'}`}>
														<label className="label">Shipping Speed</label>
													</div>
													<div className="row row-align-middle">
														<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
															<div className="form__field spc--bottom--med--on-sml">
																<select
																	className="input input--med input--select"
																	name="shippingSpeed"
																	id="shippingSpeed"
																	onChange={this.handleChange}
																	value={merchantHardware.shippingSpeed}
																	disabled={isPickup}
																>
																	<option value="">Please select...</option>
																	{Object.keys(hardware.shippingSpeeds).map((opt, idx) => {
																		return (
																			<option key={idx} value={opt}>
																				{hardware.shippingSpeeds[opt]}
																			</option>
																		);
																	})}
																</select>
															</div>
														</div>
														{!this.props.merchant.isCanadian && (
															<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
																<input
																	className="input input--check"
																	type="checkbox"
																	id="saturdayDelivery"
																	name="saturdayDelivery"
																	checked={merchantHardware.saturdayDelivery}
																	onChange={this.handleChange}
																	disabled={isPickup}
																/>
																<label htmlFor="saturdayDelivery" className="label">
																	Saturday Delivery
																</label>
															</div>
														)}
													</div>
													{/*shipping cost*/}
													{!!hardware.shippingWeight && (
														<div className="spc--bottom--sml">
															<div
																className="anchor anchor--primary anchor--underline"
																onClick={this.calculateShipping}
															>
																Calculate Shipping Costs
															</div>
															{this.state.shippingResults && this.state.shippingResults.serviceCosts && (
																<div className="note note--default spc--top--sml">
																	{Object.keys(this.state.shippingResults.serviceCosts).map((key, i) => {
																		return (
																			<div key={i}>
																				{key} Price: {formatCurrency(this.state.shippingResults.serviceCosts[key])}
																			</div>
																		);
																	})}
																</div>
															)}

															{this.state.shippingResults && !this.state.shippingResults.serviceCosts && (
																<div className="note note--warning spc--top--sml">{this.state.shippingResults}</div>
															)}
														</div>
													)}
												</div>
											</React.Fragment>
										)}
									</Toggle>
								)}
							</ToggleContainer>
							{merchantHardware.purchaseType != 'existing' &&
								merchantHardware.purchaseType != 'agent_owned' &&
								!isCanadianRental && (
									<div>
										<div className="title--tertiary__wrap">
											<label className="title--tertiary required">Payment Schedule</label>
										</div>
										<div className="form__content">
											<div className="row">
												<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
													<select
														className="input input--med input--select"
														name="paymentSchedule"
														id="paymentSchedule"
														value={merchantHardware.paymentSchedule}
														onChange={this.handleChange}
													>
														<option value="">Please select...</option>
														{Object.keys(hardware.paymentSchedules).map((opt, idx) => {
															return (
																<option key={idx} value={opt}>
																	{hardware.paymentSchedules[opt]}
																</option>
															);
														})}
													</select>
												</div>
											</div>
										</div>
									</div>
								)}
							<div>
								<div className="title--tertiary__wrap">
									<label className="title--tertiary">Notes</label>
								</div>
								<div className="form__content">
									<textarea
										className="input input--textarea spc--bottom--sml"
										type="text"
										placeholder="Write note here..."
										name="notes"
										value={merchantHardware.notes}
										onChange={this.handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="hardware__preview__aside">
							{/* <img className="icon icon--xlrg" src={"/static/media/hardware/" + hardware.name.toLowerCase() + ".svg"} alt={hardware.name} /> */}
							<div
								className={`checkout__img${getThumbnailOverlay(outOfStock, hardware.purchaseTypes)}`}
								style={{
									backgroundImage:
										'url(' +
										process.env.REACT_APP_CDN_URL +
										hardware.name
											.toLowerCase()
											.replace(' ', '_')
											.replace(/[^a-z0-9_-]/gi, '') +
										'/thumbnail.png' +
										')',
								}}
								alt={hardware.name}
							></div>
							<div className="checkout__list">
								{hardware.purchasePlans
									.filter((plan, i) => plan.planId == merchantHardware.purchasePlanId)
									.map((plan, idx) => {
										return (
											<HardwarePurchasePlanComponent
												key={idx}
												plan={plan}
												merchantFees={merchantHardware.fees}
												purchaseType={merchantHardware.purchaseType}
												quantity={merchantHardware.quantity}
												options={merchantHardware.equipmentOptions}
												onFeeChange={this.handleFeeChange}
												accessories={merchantHardware.accessories}
												toggleAccessory={this.toggleAccessory}
												displayAddonsButton={!isEmpty(hardware.accessories)}
												openAccessoriesPopup={this.openAccessoriesPopup}
												outOfStock={outOfStock}
												purchaseTypes={hardware.purchaseTypes}
												paymentSchedule={merchantHardware.paymentSchedule}
											/>
										);
									})}
								<div className="checkout__list__item flex--primary flex--align--bottom flex--nowrap">
									{/* <div className="checkout__list__label">Quantity:</div>  */}
									<div className="checkout__list__item--counter flex--shrink--0">
										{this.renderQuantityModule(
											isPaper,
											get(merchantHardware, 'equipmentId'),
											get(hardware, 'equipmentOptions'),
											true,
											<Fragment>
												<div className="inputgroup--aside">
													<button
														type="button"
														data-tooltip={disableAddButton ? outOfStockTooltip : null}
														disabled={disableAddButton}
														className="btn btn--handler spc--left--1"
														name="decrease"
														onClick={this.adjustQuantity}
													>
														&#8211;
													</button>
												</div>
												<div className="inputgroup--main inputgroup--main--double">
													<input
														className="input input--med type--center"
														disabled
														type="number"
														min="1"
														name="quantity"
														value={merchantHardware.quantity}
													/>
												</div>
												<div className="inputgroup--aside inputgroup--aside--double">
													<button
														type="button"
														data-tooltip={disableAddButton ? outOfStockTooltip : null}
														disabled={disableAddButton}
														className="btn btn--handler spc--right--1"
														name="increase"
														onClick={this.adjustQuantity}
													>
														+
													</button>
												</div>
											</Fragment>
										)}
									</div>
									{merchantHardware.isSelected && (
										<div className="note note--success">This item has been added to your order</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		) : null;
	}
}

export default withContext(HardwareListItemComponent, MerchantContext, 'merchant');
