import { decode } from 'jsonwebtoken';
import { isFunction, get } from 'lodash';

function identifyFullStoryWithToken(token) {
	const { payload } = decode(token, { complete: true });
	if (!get(payload, 'email') || !isFunction(get(window, 'FS.setUserVars'))) return;
	window.FS.setUserVars({ email: payload.email });
}

export default identifyFullStoryWithToken;
