import { forEach, findIndex, some, each, startsWith, split } from 'lodash';
import {
	EAppStatusFilterComponent,
	EAppStatusSelectionComponent,
	GeneralFilterComponent,
	OwnerNameSelectionComponent,
	BusinessEmailSelectionComponent,
} from '../../../common/filters';
import { createFilters, getFilterValues } from '../../../common/utilities';
import { leadsFilter } from '../../LeadGrid/filter/leadsFilter';
import ProcessorListFilterComponent from '../../../common/filters/processor-list/processor-list-filter';
import ProcessorListSelectionComponent from '../../../common/filters/processor-list/processor-list-filter-selection';

const leadFilterReplacement = [
	{
		toReplaceKey: 'leadStatus',
		replacement: createFilters([
			{
				key: 'merchantStatus',
				name: 'Status',
				component: EAppStatusFilterComponent,
				selectionComponent: EAppStatusSelectionComponent,
				values: {
					Incomplete: false,
					Pending: false,
					PendingFdSig: false,
					PendingElavonSig: false,
					PendUnderwriting: false,
					PendBankApproval: false,
				},
			},
		])[0],
	},
	{
		toReplaceKey: 'email',
		replacement: createFilters([
			{
				key: 'businessEmail',
				name: 'Email',
				component: GeneralFilterComponent,
				selectionComponent: BusinessEmailSelectionComponent,
				values: {
					businessEmail: null,
					fieldName: 'businessEmail',
				},
			},
		])[0],
	},
	{
		toReplaceKey: 'PLACE_AT_INDEX_4',
		replacement: createFilters([
			{
				key: 'processorList',
				name: 'Processor',
				component: ProcessorListFilterComponent,
				selectionComponent: ProcessorListSelectionComponent,
				values: {
					'Go Plus': false,
					ACHQ: false,
					'First Data': false,
					'FD Canada': false,
					'Elavon Canada': false,
					'Elavon Canada USD': false,
					'ACH-CK21': false,
					'FD North FACS': false,
					Elavon: false,
					Vantiv: false,
					Developer: false,
				},
			},
		])[0],
	},
	{
		toReplaceKey: 'lastName',
		replacement: createFilters([
			{
				key: 'ownerName',
				name: 'Owner Name',
				component: GeneralFilterComponent,
				selectionComponent: OwnerNameSelectionComponent,
				values: {
					ownerName: null,
					fieldName: 'ownerName',
				},
			},
		])[0],
	},
	{
		toReplaceKey: 'firstName',
		replacement: null,
	},
	{
		toReplaceKey: 'reviewStatus',
		replacement: null,
	},
	{
		toReplaceKey: 'label',
		replacement: null,
	},
	{
		toReplaceKey: 'title',
		replacement: null,
	},
];

const leadFilterCopy = [...leadsFilter];
forEach(leadFilterReplacement, ({ toReplaceKey, replacement }) => {
	if (startsWith(toReplaceKey, 'PLACE_AT_INDEX_')) {
		const newIndex = parseInt(split(toReplaceKey, 'PLACE_AT_INDEX_')[1]);
		leadFilterCopy.splice(newIndex, 0, replacement);
		return;
	}
	let index = findIndex(leadFilterCopy, ({ key }) => key === toReplaceKey);
	if (index !== -1) {
		if (replacement) {
			leadFilterCopy[index] = replacement;
		} else {
			leadFilterCopy.splice(index, 1);
		}
	}
});

export const eAppsFilter = leadFilterCopy;

export const compileFilter = filters => {
	const {
		dba,
		corporateName,
		merchantStatus,
		ownerName,
		businessEmail,
		phoneNumber,
		processorList,
		businessCountry,
	} = getFilterValues(filters);

	const filter = {};

	if (dba.dba != null) {
		filter.DBA = dba.dba;
	}
	if (corporateName.corporateName != null) {
		filter.CorporateName = corporateName.corporateName;
	}
	if (some(merchantStatus, p => p)) {
		filter.StatusList = parseListFilter(merchantStatus);
	}
	if (ownerName.ownerName != null) {
		filter.OwnerName = ownerName.ownerName;
	}

	if (businessEmail.businessEmail != null) {
		filter.BusinessEmail = businessEmail.businessEmail;
	}

	if (phoneNumber.phoneNumber != null) {
		filter.PhoneNumber = phoneNumber.phoneNumber;
	}

	if (some(processorList, p => p)) {
		filter.ProcessorList = parseListFilter(processorList);
	}

	if (businessCountry.businessCountry != null) {
		filter.BusinessCountry = businessCountry.businessCountry;
	}

	return filter;
};

const parseListFilter = values => {
	const filters = [];

	each(values, (value, stat) => {
		if (value) {
			filters.push(stat);
		}
	});

	return filters.join(',');
};
