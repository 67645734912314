import React, { Component } from 'react';
import { func, array, string, any } from 'prop-types';
import { map } from 'lodash';
import PosSystemAPIListItemComponent from './pos-api-system-list-item';

class PosSystemAPIListComponent extends Component {
	onChange = (equipment, autoSelect = true) => {
		if (autoSelect) {
			equipment.isSelected = true;
		}
		this.props.onChange(equipment);
	};

	render() {
		const { equipmentList, merchantEquipmentList, selectedEquipmentId } = this.props;

		return (
			<div>
				{map(equipmentList, (equipment, idx) => {
					let merchantEquipment = merchantEquipmentList.find(e => e.equipmentId == equipment.equipmentId);
					if (equipmentList.length === 1 || (!merchantEquipment && idx === 0)) {
						equipment.defaultSelected = true;
					}

					return (
						<div key={idx} className="card--shaded card--sml spc--bottom--med">
							<PosSystemAPIListItemComponent
								equipment={equipment}
								isExpanded={equipmentList.length === 1 || selectedEquipmentId == equipment.equipmentId}
								merchantEquipment={merchantEquipment}
								onChange={this.onChange}
								createNewEquipment={this.props.createNewEquipment}
								openCloseModal={this.props.openCloseModal}
								equipmentSelect={this.equipmentSelect}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

PosSystemAPIListComponent.propTypes = {
	equipmentList: array.isRequired,
	merchantEquipmentList: array.isRequired,
	onChange: func.isRequired,
	onListChange: func.isRequired,
	openCloseModal: func.isRequired,
	createNewEquipment: func.isRequired,
	onShowEquipment: func.isRequired,
	selectedEquipmentId: any,
	appId: string,
};

export default PosSystemAPIListComponent;
