import { get, noop, cloneDeep } from 'lodash';
import { appService } from '../../services';
import openFile from './openFile';

export function handleFileDownload(id, noteId, fileName, showLoader, isDownload = false) {
	return () => {
		showLoader(true);
		appService.getMerchantFile(id, noteId, fileName).then(url => {
			let msg = '';
			if (url.errorMessage) {
				msg = url.errorMessage;
			} else {
				openFile(url, fileName, !isDownload, false);
				if (isDownload) {
					const addNotification = get(this.notificationRef, 'current.addNotification', noop);
					addNotification({
						message: 'Successfully downloaded',
						success: true,
					});
				}
			}
			this.setState({ downloadErrorMessage: msg });
			showLoader();
		});
	};
}

export function handleRemoveFile(fileType, i, callback = noop) {
	let fullFileList = cloneDeep(this.state.files);
	fullFileList[fileType].splice(i, 1);
	this.setState({ files: fullFileList }, callback);
}

export function getFilesForTag(tag) {
	const fullFileList = get(this.state, 'mpa.files');
	let matchTag = [];

	if (!fullFileList) {
		return null;
	}

	if (Array.isArray(tag)) {
		matchTag = tag;
	} else {
		matchTag.push(tag);
	}
	if (matchTag.includes('BankStatements')) matchTag.push('BankingStatement'); //this line can be removed in a year from now - sweinstock 4/02/2024

	return fullFileList.filter(file => {
		const fileTag = file.fileTag.split('_');
		return fileTag.filter((ft, i) => matchTag.includes(ft)).length > 0;
	});
}
