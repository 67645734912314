export const fileTypes = {
	jpeg: 'image/jpeg',
	pdf: 'application/pdf',
	gif: 'image/gif',
	png: 'image/png',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	doc: 'application/msword',
	txt: 'text/plain',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	xls: 'application/vnd.ms-excel',
	log: '.log',
	bmp: 'image/bmp',
	odt: 'application/vnd.oasis.opendocument.text',
};

const fileTypesValues = {
	[fileTypes.pdf]: ['.pdf'],
	[fileTypes.gif]: ['.gif'],
	[fileTypes.png]: ['.png'],
	[fileTypes.doc]: ['.doc'],
	[fileTypes.xls]: ['.xls'],
	[fileTypes.log]: ['.log'],
	[fileTypes.odt]: ['.odt'],
	[fileTypes.xlsx]: ['.xlsx'],
	[fileTypes.docx]: ['.docx'],
	[fileTypes.bmp]: ['.bmp', '.dip'],
	[fileTypes.txt]: ['.txt', '.text'],
	[fileTypes.jpeg]: ['.jpg', '.jpeg', '.jfif', '.pjpeg'],
};

export const getFileTypeExtensions = types => {
	const fileExtensions = {};

	for (const type of types) {
		if (fileTypesValues[type]) {
			fileExtensions[type] = fileTypesValues[type];
		}
	}
	return fileExtensions;
};
