import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat as NumberFormat } from 'react-number-format';

export default class CheckboxTxtInput extends React.Component {
	handleCheckbox = e => {
		if (this.props.onCheckChanged) this.props.onCheckChanged(e);
	};

	handleInput = e => {
		if (this.props.onValueChanged) this.props.onValueChanged(e);
	};

	render() {
		const {
			divClass,
			checkboxClass,
			inputClass,
			labelClass,
			labelText,
			placeholder,
			checkboxName,
			checkboxValue,
			inputName,
			checked,
			value,
			disabled,
			format,
			decimalScale,
			allowNegative,
		} = this.props;
		let checkboxId = this.props.checkboxId || checkboxName;
		return (
			<div className={divClass}>
				<div>
					<label className={labelClass}>{labelText}</label>
				</div>
				<div className="inputgroup">
					<div className="inputgroup--aside">
						<input
							type="checkbox"
							name={checkboxName}
							id={checkboxId}
							onChange={this.handleCheckbox}
							checked={!!checked}
							className={checkboxClass}
							value={checkboxValue}
						/>
						<label htmlFor={checkboxId}></label>
					</div>
					<div className="inputgroup--main">
						{format === 'NumberFormat' ? (
							<NumberFormat
								type={'text'}
								decimalScale={decimalScale}
								name={inputName}
								value={value || ''}
								disabled={disabled || !checked}
								onChange={this.handleInput}
								className={inputClass}
								placeholder={placeholder}
								allowNegative={allowNegative}
							/>
						) : (
							<input
								type={'text'}
								name={inputName}
								value={value || ''}
								disabled={disabled || !checked}
								onChange={this.handleInput}
								className={inputClass}
								placeholder={placeholder}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

CheckboxTxtInput.propTypes = {
	checkboxName: PropTypes.string.isRequired,
	checkboxId: PropTypes.string,
	checkboxValue: PropTypes.string,
	inputName: PropTypes.string.isRequired,
	onCheckChanged: PropTypes.func,
	onValueChanged: PropTypes.func,
	checked: PropTypes.bool,
	value: PropTypes.string,
	labelText: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	divClass: PropTypes.string,
	checkboxClass: PropTypes.string,
	labelClass: PropTypes.string,
	inputClass: PropTypes.string,
	disabled: PropTypes.bool,
	format: PropTypes.string,
	allowNegative: PropTypes.bool,
};

CheckboxTxtInput.defaultProps = {
	checked: false,
	value: '',
	divClass: '',
	checkboxClass: 'input--check',
	labelClass: 'label label--inset',
	inputClass: 'input input--med',
	disabled: false,
	allowNegative: true,
};
