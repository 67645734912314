import React from 'react';

import USMpaConfirm from './USMpaConfirm';

export default class CanadaMpaConfirm extends USMpaConfirm {
	renderStateAndZip(mpa, addressField) {
		return (
			<React.Fragment>
				{this.renderField(mpa, `${addressField}.state`, 'Province')}
				{this.renderField(mpa, `${addressField}.zip`, 'Postal Code')}
			</React.Fragment>
		);
	}

	renderRoutingNumber(mpa, fieldName) {
		if (fieldName.indexOf('second') > -1) {
			return (
				<div className="col col-sml-12">
					<div className="row grid-align-middle">
						{this.renderField(
							mpa,
							'bankAccountInformation.secondaryInstitutionNumber',
							'Secondary Institution Number',
							undefined,
							'fs-mask'
						)}
						{this.renderField(
							mpa,
							'bankAccountInformation.secondaryTransitNumber',
							'Secondary Transit Number',
							undefined,
							'fs-mask'
						)}
					</div>
				</div>
			);
		} else {
			return (
				<div className="col col-sml-12">
					<div className="row grid-align-middle">
						{this.renderField(
							mpa,
							'bankAccountInformation.institutionNumber',
							'Institution Number',
							undefined,
							'fs-mask'
						)}
						{this.renderField(mpa, 'bankAccountInformation.transitNumber', 'Transit Number', undefined, 'fs-mask')}
					</div>
				</div>
			);
		}
	}

	renderSocialSecurityNumber(mpa) {
		if (!this.props.showSSN) return null;
		return this.renderField(
			mpa,
			'signerInformation.socialSecurityNumber',
			'Social Insurance Number',
			undefined,
			'fs-mask'
		);
	}

	renderAdditionalSignerInfo(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row">
					{this.renderField(mpa, 'signerInformation.identificationType', 'Identification Type')}
					{this.renderField(mpa, 'signerInformation.identificationNumber', 'Identification Number')}
					{this.renderField(mpa, 'signerInformation.identificationIssueDate', 'Identification Issue Date')}
					{this.renderField(mpa, 'signerInformation.identificationExpirationDate', 'Identification Expiration Date')}
				</div>
			</div>
		);
	}

	renderEbtNumber() {
		return null;
	}
}
