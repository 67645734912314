import { ActionsComponent } from '../../../components/MerchantGrid/formatters';
import { HiddenFilterComponent } from '../../column-filters';

export const actionsGridColumn = [
	{
		key: 'isHidden',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: true,
		fixWidth: true,
		formatter: ActionsComponent,
		filterRenderer: HiddenFilterComponent,
		getRowMetaData: row => row,
		initWidth: 75,
		order: 1,
	},
];
