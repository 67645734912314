import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, get, noop, concat, toLower, includes } from 'lodash';
import { EAppColumns as Columns } from './column-filter/eAppColumns';
import { eAppsFilter as Filter, compileFilter } from './filter/eAppsFilter';
import { appService } from '../../services/appService';
import withError from '../../common/components/error/error-hoc';
import { withLoader } from '../../common/components';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { ZebraRenderer } from '../../common/components/row';
import { actionsGridColumn } from '../../common/components/actionsGrid/eAppActionsGridColumn';
import { Notification } from '../../common/components/notifications';
import { LoadMoreOptions } from '../../common/utilities';
import { GridTooltip } from '../../common/components/tooltips';
import {
	formatColumns,
	getPage,
	hasMoreData,
	mapCellArgs,
	mapDataToGridCommon,
	onInfoHover,
	onLoadMoreLimitChange,
	openActions,
	queryFilterValues,
	parseQueriesToFilters,
	saveCurrentView,
	deleteAgentView,
	applyAgentView,
	clearAgentView,
	fetchAgentViews,
} from '../../common/components/grid/commonGridMethods';
import { SavedFilterComponent } from '../../common/components/filter';
import { principalService } from '../../services';
import { TabFilter } from '../../common/column-filters';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

const loadMoreOptionsAndAll = concat(LoadMoreOptions, [0]);

class EAppGrid extends Component {
	constructor(props) {
		super(props);
		this.state = cloneDeep(this.initialState);
		this.viewType = 'EApps';
		this.gridRef = createRef();
		this.notificationRef = createRef();
		this.setState = this.setState.bind(this);
		this.applyAgentView = applyAgentView.bind(this);
		this.clearAgentView = clearAgentView.bind(this);
		this.saveCurrentView = saveCurrentView.bind(this);
		this.deleteAgentView = deleteAgentView.bind(this);
		this.fetchAgentViews = fetchAgentViews.bind(this);
		this.mapDataToGrid = mapDataToGridCommon.bind(this);
		this.queryFilterValues = queryFilterValues.bind(this);
		this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
		this.parseQueriesToFilters = parseQueriesToFilters.bind(this);
	}

	get initialState() {
		const principal = principalService.get();
		const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
		return {
			data: null,
			expanded: {},
			activePage: 1,
			rowsPerPage: 20,
			filteredRows: [],
			filters: filters,
			maxGridHeight: 0,
			totalRowCount: 0,
			columns: Columns,
			showFilters: true,
			fetchingData: true,
			lastApiRefNum: null,
			fullFilteredRows: [],
			fetchAdditionalData: false,
			activeFilters: cloneDeep(filters),
			defaultColumns: cloneDeep(Columns),
			modal: {
				name: modalNames.none,
				data: null,
			},
			inlineFilters: {
				isHidden: {
					column: this.getCollectionItem(Columns, 'isHidden'),
					filterTerm: 'false',
				},
			},
			filterProps: {
				agentViews: [],
				isLoadingAgentViews: false,
				clearAgentView: (...params) => this.clearAgentView(...params),
				deleteAgentView: viewName => this.deleteAgentView(viewName),
				saveCurrentView: viewName => this.saveCurrentView(viewName),
				applyAgentView: (...params) => this.applyAgentView(...params),
			},
			isLinkedUserSupportRep: principal.isLinkedUserSupportRep,
		};
	}
	componentDidUpdate = prevProps => {
		if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
			this.clearAgentView();
		}
	};
	componentDidMount() {
		this.parseQueriesToFilters();
	}
	handlePageChange = page => {
		this.handleChange([{ key: 'activePage', value: page }]);
	};
	fetchData = async filters => {
		const filter = compileFilter(filters);
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			fullFilteredRows: [],
			expanded: {},
			lastApiRefNum: null,
			totalRowCount: 0,
		});

		let lastApiRefNum = null;
		const { rowsPerPage, activePage } = this.state;
		let data = await appService.getEApps(filter);
		if (this.gridRef.current) {
			lastApiRefNum = data.refNum;
			let dataObj = { xReportData: data.merchants };
			if (get(dataObj, 'xReportData', false)) {
				dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
			}
			const formattedColumns = formatColumns(this.state.columns, cloneDeep(filter));
			this.mapData(dataObj);
			this.mapDataToGrid(dataObj, data.merchants, activePage, rowsPerPage, formattedColumns, lastApiRefNum);
		}
	};
	refetchData = () => {
		this.fetchData(this.state.activeFilters);
	};
	mapData = data => {
		let i = 0;
		if (get(data, 'xReportData', false) && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.index = i;
				item.gridRowNumber = i;
				item.openActions = openActions((e, d = noop) => this.setState(e, d), this.gridRef);
				i++;
			});
		}
	};
	getCollectionItem = (collection, key) => {
		return find(collection, { key });
	};
	mapRow = row => ({
		...row,
		isExpandable: true,
		showLoader: this.props.showLoader,
		openCloseModal: this.openCloseModal,
		onInfoHover: onInfoHover((e, d = noop) => this.setState(e, d), this.gridRef),
		refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
		processorName: getProcessorDisplayName(row.processorName),
	});
	onRowClick = (...params) => {
		if (params[0] < 0 || params[2].key === 'isHidden') {
			return;
		}
		const { history } = this.props;
		const row = params[1];
		history.push(this.createDetailsLink(row));
	};
	createDetailsLink = row => {
		const eAppStarted =
			toLower(row.merchantStatus) === 'incomplete' || toLower(row.merchantStatus) === 'pendbankapproval';
		if (eAppStarted) {
			if (row.processorName === 'CK-Go') {
				return `/eapp/cardknox-go/${row.appId}`;
			} else if (row.processorName === 'ACHQ') {
				return `/eapp/achq/${row.appId}`;
			} else if (row.processorName === 'ProfitStars Ach') {
				return `/eapp/ach/${row.appId}`;
			} else {
				return `/eapp/mpa/${row.appId}`;
			}
		} else {
			return `/merchants/${row.appId}/account`;
		}
	};
	calculatePopupLeft = () => {
		return this.state.infoDimensions.width - 36;
	};
	calculatePopupTop = () => {
		let offset = 0;
		if (get(this.gridRef, 'current.gridHolderRef.current', null)) {
			offset =
				this.gridRef.current.gridHolderRef.current.getBoundingClientRect().y -
				this.gridRef.current.gridHolderRef.current.scrollTop -
				25;
		}
		return this.state.infoDimensions.height - offset;
	};
	renderTooltip = () =>
		this.state.tooltip ? (
			<div
				style={{
					zIndex: 99,
					backgroundColor: '#fff',
					position: 'absolute',
					top: this.calculatePopupTop(),
					left: this.calculatePopupLeft(),
				}}
			>
				{this.state.tooltip}
			</div>
		) : null;
	handleChange = items => {
		const newState = {};
		each(items, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters' || key === 'activePage') {
				let filters, data, activePage;
				if (key === 'data') {
					data = value;
					activePage = 1;
					filters = this.state.inlineFilters;
				} else if (key === 'inlineFilters') {
					activePage = 1;
					filters = value;
					data = this.state.data;
				} else {
					activePage = value;
					data = this.state.data;
					filters = this.state.inlineFilters;
				}
				let filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								filters,
								rows: data.xReportData,
						  })
						: [];
				const pagedFilteredRows = getPage(filteredRows, activePage, this.state.rowsPerPage);
				newState.activePage = activePage;
				newState.fullFilteredRows = filteredRows;
				newState.filteredRows = pagedFilteredRows;
				newState.totalRowCount = filteredRows.length;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};
	renderHeader = () => <div className="push">{this.renderNewLeadPopup()}</div>;
	renderGridHeader = () => <Fragment>{this.renderNewLeadPopup()}</Fragment>;
	renderTitle = ({ className, handleInlineFilter }) => (
		<div>
			<TabFilter
				className={className}
				column={find(this.state.columns, { key: 'isHidden' })}
				trueLabel="Archived EApps"
				falseLabel="EApps"
				onChange={handleInlineFilter}
			/>
		</div>
	);
	renderNewLeadPopup = () => {
		const { isLinkedUserSupportRep } = this.state;
		return (
			<Fragment>
				{!isLinkedUserSupportRep && (
					<button
						type="button"
						className="btn btn--primary btn--med"
						onClick={() =>
							this.openCloseModal({
								name: modalNames.newLead,
								data: {
									history: this.props.history,
									addNotification: get(this.notificationRef, 'current.addNotification', noop),
								},
							})
						}
					>
						Add <span className="hide--to--sml--inline spc--left--tny">New Account</span>
					</button>
				)}
			</Fragment>
		);
	};
	render = () => {
		const {
			data,
			modal,
			columns,
			filters,
			expanded,
			activePage,
			filterProps,
			rowsPerPage,
			tooltipProps,
			fetchingData,
			filteredRows,
			totalRowCount,
			inlineFilters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			fullFilteredRows,
			fetchAdditionalData,
		} = this.state;

		return (
			<div className="l--content--grid">
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<GridComponent
					data={data}
					type="EApps"
					title="EApps"
					rowKey="AppId"
					fixHeader={true}
					hasPaging={true}
					filters={filters}
					columns={columns}
					enablePrint={true}
					showFilters={true}
					showResults={true}
					ref={this.gridRef}
					isExpandable={true}
					expanded={expanded}
					enableExport={true}
					initialFetch={false}
					enableFilters={true}
					filterColumns={true}
					useInlineFilters={true}
					syncQueryFilters={true}
					canReorderColumns={true}
					mapCellArgs={mapCellArgs}
					filterProps={filterProps}
					showPrintDropdown={false}
					showExportDropdown={false}
					tooltipProps={tooltipProps}
					fetchingData={fetchingData}
					filteredRows={filteredRows}
					loadMoreLimit={rowsPerPage}
					onChange={this.handleChange}
					fetchData={this.refetchData}
					onRowClick={this.onRowClick}
					inlineFilters={inlineFilters}
					lastApiRefNum={lastApiRefNum}
					activeFilters={activeFilters}
					defaultColumns={defaultColumns}
					fullFilteredRows={fullFilteredRows}
					actionsGridColumn={actionsGridColumn}
					loadMoreOptions={loadMoreOptionsAndAll}
					handlePageChange={this.handlePageChange}
					createDetailsLink={this.createDetailsLink}
					queryFilterValues={this.queryFilterValues}
					fetchingAdditionalData={fetchAdditionalData}
					onLoadMoreLimitChange={this.onLoadMoreLimitChange}
					emptyMessage="You should change your filter options"
					hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
					pagination={{ activePage, rowsPerPage, totalRowCount }}
					components={{
						toolbar: ToolbarComponent,
						header: this.renderHeader,
						gridHeader: this.renderGridHeader,
						filter: SavedFilterComponent,
						rowRenderer: ZebraRenderer,
						tooltip: GridTooltip,
						title: this.renderTitle,
					}}
				/>
			</div>
		);
	};
}

EAppGrid.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	showLoader: PropTypes.func,
};

export default withError(withLoader(EAppGrid));
