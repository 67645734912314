import { map } from 'lodash';

import {
	StringFilterComponent as StringFilter,
	MultiselectCardknoxFilterComponent as MultiselectCardknoxFilter,
} from '../../../common/column-filters';
import { MerchantStatusComponent, PciStatusComponent } from '../formatters';
import { DisplayDateComponent, PhoneNumberComponent, ExpandableComponent } from '../../../common/formatters';
import createGridColumn from '../../../common/components/grid/createGridColumn';

export const MerchantColumns = map(
	[
		createGridColumn('appId', 'Merchant I­D', 100, {
			// alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
			visible: false,
			hideable: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('dba', 'DBA', 200, {
			formatter: ExpandableComponent,
			getRowMetaData: row => row,
			filterRenderer: StringFilter,
			isDefaultSorter: true,
			defaultSortDirection: 'ASC',
		}),
		createGridColumn('mid', 'MID #', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('corporateName', 'Corporate Name', 200, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('agentName', 'Rep 1', 150, {
			visible: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('telemarketerName', 'Rep 2', 150, {
			visible: false,
			filterRenderer: StringFilter,
		}),
		createGridColumn('merchantStatus', 'Status', 150, {
			formatter: MerchantStatusComponent,
			getRowMetaData: row => row,
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('statusTitle', 'Status Title', 150, {
			sortable: false,
			hideable: false,
			visible: false,
			filterable: false,
		}),
		createGridColumn('processorName', 'Processor', 150, {
			getRowMetaData: row => row,
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('isPciCompliant', 'PCI Compliant', 150, {
			formatter: row => row && new PciStatusComponent(row),
			filterRenderer: MultiselectCardknoxFilter,
		}),
		createGridColumn('businessContactName', 'Business Contact Name', 195, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('ownerName', 'Owner Name', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('zip', 'Zip', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('businessCountry', 'Business Country', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('phoneNumber', 'Phone Number', 150, {
			formatter: PhoneNumberComponent,
			filterRenderer: StringFilter,
		}),
		createGridColumn('businessEmail', 'Business Email', 150, {
			filterRenderer: StringFilter,
		}),
		createGridColumn('dateSigned', 'Date Signed', 150, {
			formatter: DisplayDateComponent,
			filterRenderer: StringFilter,
		}),
		createGridColumn('dateApproved', 'Approval Date', 150, {
			formatter: DisplayDateComponent,
			filterRenderer: StringFilter,
		}),
		createGridColumn('address', 'Address', 150, {
			visible: false,
			isAdvancedField: true,
			filterRenderer: StringFilter,
		}),
		createGridColumn('city', 'City', 150, {
			visible: false,
			isAdvancedField: true,
			filterRenderer: StringFilter,
		}),
		createGridColumn('state', 'State', 150, {
			visible: false,
			isAdvancedField: true,
			filterRenderer: StringFilter,
		}),
		createGridColumn('isHidden', '', 75, {
			sortable: false,
			hideable: false,
			filterable: false,
			resizable: false,
			fixWidth: true,
			hideOnExport: true,
			getRowMetaData: row => row,
			order: 999,
		}),
		createGridColumn('cardknoxMids', 'CK MID', 150, {
			visible: false,
			filterRenderer: StringFilter,
		}),
	],
	(column, index) => ({
		...column,
		order: column.order || index,
	})
);
