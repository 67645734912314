import React from 'react';
import { cloneDeep, get, noop, startCase } from 'lodash';

import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { modalNames } from './../../common/components/modal-wrapper';
import CreatableSelect from 'react-select/creatable';
import { PredefinedLeadLabels, default as LabelComponent } from '../LeadGrid/formatters/label';
import { appService } from '../../services/appService';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { Notification } from '../../common/components/notifications';
import { handleFileDownload } from '../../common/utilities/commonFileHandlingMethods';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';

const isExpand = true;

class AdditionalInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { downloadErrorMessage: '' };

		this.notificationRef = React.createRef();
		this.handleFileDownload = handleFileDownload.bind(this);
	}

	handleChange = (nestedKey, arrayIdx) => e => {
		const { name, value } = e.target;
		this.props.onChange('addlInfo', name, value, nestedKey, arrayIdx);
	};

	handleLabelChange = (newValue, actionMeta) => {
		this.props.onChange('addlInfo', 'label', actionMeta.action == 'clear' ? '' : newValue.value, null, null);
	};

	renderLabelOption = data => {
		return <LabelComponent value={data.value} />;
	};

	handleRemoveFile = (tag, fileIndx) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList.splice(fileIndx, 1);
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleUpdateFileDescription = (tag, i, description) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList[i].fileDescription = description;
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleUpdateFileTag = (tag, i, newTag) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList[i].fileTag = newTag;
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleFileDelete = (leadId, fileId, fileName) => e => {
		this.props.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(leadId, fileId),
			},
		});
	};

	deleteFile = (leadId, fileId) => () => {
		console.log('deleting...');
		appService
			.deleteLeadFile(leadId, fileId)
			.then(() => {
				console.log('successfully deleted!');
				this.props.reload(leadId);
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Successfully deleted',
					success: true,
				});
			})
			.catch(ex => {
				console.log('Error deleting file');
				console.log(ex);
				this.setState({ downloadErrorMessage: ex });
			});
	};

	onDrop = (tag, acceptedFiles) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);

		let newFilesList = acceptedFiles.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList) fullFileList.push.apply(fullFileList, newFilesList);
		else fullFileList = newFilesList;

		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	render() {
		const info = this.props.addlInfo;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<ToggleContainer>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<React.Fragment>
								<div className="spc--bottom--med">
									<div className={`flex--primary ${isToggled ? 'separator--grey1 spc--bottom--med' : ''}`}>
										<h6 className="spc--bottom--sml cursor--pointer" onClick={handleToggle}>
											Files
										</h6>
										<i
											className={`cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand spc--left--auto spc--bottom--sml ${
												isToggled ? 'rotate--180' : ''
											}`}
											onClick={handleToggle}
										></i>
									</div>
									<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
										{info.files && info.files.length > 0 ? (
											<div className="row">
												<div className="col col-sml-12 col-lrg-12">
													<div className="spc--bottom--med">
														{/* <label className="label spc--bottom--sml">Existing Files: </label> */}
														{this.state.downloadErrorMessage ? (
															<span className="note note--warning">{this.state.downloadErrorMessage}</span>
														) : null}
														<div className="table--files__wrap">
															<table className="table--files">
																{info.files.map((file, i) => (
																	<tr key={i}>
																		<td>
																			<strong>{file.fileName}</strong>
																		</td>
																		<td className="is-note">
																			<label>
																				{file.fileTag && file.fileDescription
																					? startCase(file.fileTag) + ' - ' + file.fileDescription
																					: startCase(file.fileTag)}
																			</label>
																		</td>
																		<td>
																			{canPreviewFile(file.fileName) && (
																				<a
																					className="btn btn--med btn--icon datatooltip--left"
																					data-tooltip="View"
																					href="javascript:void(0);"
																					onClick={this.handleFileDownload(
																						file.parentId,
																						file.fileId,
																						file.fileName,
																						this.props.showLoader
																					)}
																				>
																					<i className="icon icon--xsml icon--view align--v--sub"></i>
																				</a>
																			)}
																			<a
																				className="btn btn--med btn--icon datatooltip--left"
																				data-tooltip="Download"
																				href="javascript:void(0);"
																				onClick={this.handleFileDownload(
																					file.parentId,
																					file.fileId,
																					file.fileName,
																					this.props.showLoader,
																					true
																				)}
																				download
																			>
																				<i className="icon icon--xsml icon--download align--v--sub"></i>
																			</a>
																			{/* TODO: Add delete functionality */}
																			{!this.props.disabled && (
																				<a
																					href="javascript:void(0);"
																					data-tooltip="Delete"
																					className="btn btn--med btn--icon datatooltip--left"
																					onClick={this.handleFileDelete(file.parentId, file.fileId, file.fileName)}
																				>
																					<i className="icon icon--tiny icon--wastebasket align--v--sub"></i>
																				</a>
																			)}
																		</td>
																	</tr>
																))}
															</table>
														</div>
													</div>
												</div>
											</div>
										) : null}
										{!this.props.disabled && (
											<div className="form__field">
												<label className="label">Upload File:</label>
												<FileDropzoneComponent
													multiple={true}
													showDescription={true}
													showTagDropdown={true}
													fileList={info.fileUploads}
													onDrop={this.onDrop}
													tag="MerchantStatement"
													onRemoveFile={this.handleRemoveFile}
													onUpdateDescription={this.handleUpdateFileDescription}
													onUpdateTag={this.handleUpdateFileTag}
													validateFileSize={false}
												/>
											</div>
										)}
									</div>
								</div>
							</React.Fragment>
						)}
					</Toggle>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<React.Fragment>
								<div className="spc--bottom--med">
									<div className={`flex--primary ${isToggled ? 'separator--grey1 spc--bottom--med' : ''}`}>
										<h6 className="spc--bottom--sml cursor--pointer" onClick={handleToggle}>
											Labels
										</h6>
										<i
											className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
												isToggled ? 'rotate--180' : ''
											}`}
											onClick={handleToggle}
										></i>
									</div>
									<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
										<div className="form__field">
											<label className="label">Label:</label>
											<CreatableSelect
												isDisabled={this.props.disabled}
												isClearable
												onChange={this.handleLabelChange}
												options={PredefinedLeadLabels}
												value={{ label: info.label, value: info.label }}
												formatOptionLabel={this.renderLabelOption}
											/>
										</div>
									</div>
								</div>
							</React.Fragment>
						)}
					</Toggle>

					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<React.Fragment>
								<div className="card--primary card--sml spc--bottom--med">
									<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
										<h6 className="card--primary__header__title">Notes</h6>
										<div className="card--primary__header__expand" onClick={handleToggle}>
											<i
												className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
													isToggled ? 'rotate--180' : ''
												}`}
											></i>
										</div>
									</div>
									<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
										<div className="form__field">
											<label className="label">Note:</label>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="notes"
												id="notes"
												value={info.notes}
												onChange={this.handleChange(null, null)}
											></input>
											{
												//notesToRender.map((note, idx) => {
												//    if (!note.canEdit) {
												//        return (
												//            <div key={note.noteId}>
												//                <label className="label">Note: </label>
												//                <label className="label">{note.noteText} </label>
												//                <label className="label"><DisplayDateComponent value={note.enteredDate} /> </label>
												//            </div>
												//        );
												//    }
												//    else {
												//        return (
												//            <div key={note.noteId || -1}>
												//                <label className="label">Note: </label>
												//                <input type="text" className="input input--med" name="noteText" id="noteText" value={note.noteText} onChange={this.handleChange('notes', idx)}></input>
												//            </div>
												//        );
												//    }
												//})
											}
										</div>
									</div>
								</div>
							</React.Fragment>
						)}
					</Toggle>
				</ToggleContainer>
			</React.Fragment>
		);
	}
}

export default AdditionalInfo;
