import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PhoneNumberComponent extends Component {
	formatPhoneNumber = phoneNumberString => {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
		return null;
	};

	renderItemValue = () => {
		const { value } = this.props;

		return this.formatPhoneNumber(value);
	};

	render() {
		return <div>{this.renderItemValue()}</div>;
	}
}

PhoneNumberComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.any,
};

export default PhoneNumberComponent;
