import PropTypes from 'prop-types';
import { BaseStatusComponent } from '../../../common/formatters';

class MerchantStatusComponent extends BaseStatusComponent {
	constructor(props) {
		super(props);

		this.statusClasses = {
			active: this.styleActive,

			inactive: this.styleInactive,

			pending: this.stylePending,
			inreview: this.stylePending,
			underwriting: this.stylePending,
			seasonalhold: this.stylePending,
			conditionallyapproved: this.stylePending,

			incomplete: this.styleIncomplete,
		};
	}
}

export default MerchantStatusComponent;

MerchantStatusComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.shape({
		statusTitle: PropTypes.string,
	}),
};
