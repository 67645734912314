import React from 'react';
import { noop, orderBy, isEmpty } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
import AddonListItemComponent from './addon-list-item';
import EquipmentOption from './equipment-option';

const disabledTooltip = 'To adjust this field, you must first check the box to the left of the add-on.';

const AddonListComponent = props => {
	const softwareSubequipment =
		props.gateway.subequipment && props.gateway.subequipment.filter(e => e.category.toLowerCase() !== 'plugin');
	// const pluginSubequipment = props.gateway.subequipment && props.gateway.subequipment.filter((e) => e.category.toLowerCase() == 'plugin');

	const {
		gateway,
		merchantGateway,
		availablePurchasePlans,
		selectSubequipment,
		handleSubOptionSelect,
		handleSubOptionChange,
		handleSubOptionMoreInfo,
		handleSubFeeChange,
		handleSubNote,
		openCloseModal,
		handleGatewayOptionSelect,
		handleGatewayChange,
		handleOptionMoreInfo,
		handleFeeChange,
		classNames,
		hideEquipmentOptions,
		hideDisabledPurchasePlans,
		suffix,
		renderAddonsPaymentScheduleOptions,
		onMerchantSubEqpChange,
		isPopup,
	} = props;

	let availableAddonPlans = availablePurchasePlans.filter(
		p =>
			softwareSubequipment &&
			softwareSubequipment.filter(
				s =>
					!s.hasPlanMapping || // no mapping
					!s.planMapping.find((pln, i) => pln.parentEquipmentPlanId == p.planId) || // no mapping for this plan
					(!!s.planMapping.find((pln, i) => pln.parentEquipmentPlanId == p.planId) && // mapped for plan, but not excluded
						!s.planMapping.find((pln, i) => pln.parentEquipmentPlanId == p.planId).excludeSubequipment)
			).length > 0
	);

	const mergedFees = [].concat.apply(
		[],
		availablePurchasePlans.map((p, i) => p.fees)
	);
	const optionsWithDependentFees = Object.keys(gateway.equipmentOptions).filter((opt, idx) => {
		return mergedFees.filter(f => !!f.dependencySettings && Object.keys(f.dependencySettings).includes(opt)).length > 0;
	});
	const hasNoAddonPlans = isEmpty(availableAddonPlans);

	return (
		<React.Fragment>
			{!hideEquipmentOptions && Object.keys(gateway.equipmentOptions).length > 0 && (
				<React.Fragment>
					<h3 className="spc--bottom--med">Details</h3>
					<div className="card--tertiary spc--bottom--med">
						<div className="gateway__details">
							{renderEquipmentOptions(
								gateway,
								merchantGateway,
								availablePurchasePlans,
								handleGatewayOptionSelect,
								handleGatewayChange,
								handleOptionMoreInfo,
								suffix
							)}
						</div>
					</div>
				</React.Fragment>
			)}
			{softwareSubequipment && softwareSubequipment.length > 0 ? (
				<div className="table--gateway--secondary__wrapper">
					<table className={`table--gateway table--gateway--${isPopup ? 'add-ons' : 'secondary'}`}>
						<thead>
							<tr>
								<th colSpan={4} className={isPopup ? 'sticky-popup' : 'sticky'}>
									Add-ons
								</th>
								{availableAddonPlans.map((plan, i) => {
									const hideDisabledPurchasePlan =
										merchantGateway.purchasePlanId != plan.planId && hideDisabledPurchasePlans;
									return (
										!hideDisabledPurchasePlan && (
											<th
												colSpan={2}
												className={`table--gateway__fields${isPopup ? ' sticky-popup centered' : ' centered sticky'}`}
												key={i}
											>
												{plan.planName}
											</th>
										)
									);
								})}
								{hasNoAddonPlans && (
									<th
										colSpan={2}
										className={`table--gateway__fields${isPopup ? ' centered sticky-popup' : ' centered sticky'}`}
									></th>
								)}
								<th colSpan={2} className={`table--gateway__actions${isPopup ? ' sticky-popup' : ' sticky'}`}></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="table--gateway__group" colSpan={4}></th>
								{availableAddonPlans.map((plan, i) => {
									if (merchantGateway.purchasePlanId != plan.planId && hideDisabledPurchasePlans) return null;
									return (
										<React.Fragment key={i}>
											<th className="table--gateway__group__wrapper">
												<div className="table--gateway__group">
													<span className="title">Agent Cost</span>
												</div>
											</th>
											<th className="table--gateway__group__wrapper">
												<div className="table--gateway__group">
													<span className="title">Merchant Cost</span>
												</div>
											</th>
										</React.Fragment>
									);
								})}
								{hasNoAddonPlans && (
									<React.Fragment>
										<th className="table--gateway__group__wrapper">
											<div className="table--gateway__group">
												<span class="title">Agent Cost</span>
											</div>
										</th>
										<th className="table--gateway__group__wrapper">
											<div className="table--gateway__group">
												<span class="title">Merchant Cost</span>
											</div>
										</th>
									</React.Fragment>
								)}
								<th className="table--gateway__group"></th>
							</tr>
							{orderBy(softwareSubequipment, 'name').map((sub, i) => {
								return (
									<AddonListItemComponent
										key={i}
										sub={sub}
										gateway={gateway}
										merchantGateway={merchantGateway}
										availablePurchasePlans={availableAddonPlans}
										selectSubequipment={selectSubequipment}
										handleSubOptionSelect={handleSubOptionSelect}
										handleSubOptionChange={handleSubOptionChange}
										handleSubFeeChange={handleSubFeeChange}
										handleSubOptionMoreInfo={handleSubOptionMoreInfo}
										handleSubNote={handleSubNote}
										renderEquipmentOptions={renderEquipmentOptions}
										openCloseModal={openCloseModal}
										suffix={suffix}
										hideDisabledPurchasePlans={hideDisabledPurchasePlans}
										renderAddonsPaymentScheduleOptions={renderAddonsPaymentScheduleOptions}
										onMerchantSubEqpChange={onMerchantSubEqpChange}
										existingAddons={props.existingAddons}
									/>
								);
							})}
							{!hideDisabledPurchasePlans &&
								renderOptionsWithFees(
									gateway,
									merchantGateway,
									availableAddonPlans,
									handleGatewayOptionSelect,
									handleGatewayChange,
									handleFeeChange,
									handleOptionMoreInfo,
									suffix,
									classNames
								)}
						</tbody>
					</table>
				</div>
			) : (
				optionsWithDependentFees.length > 0 && (
					<div
						className="table--gateway--primary__wrapper
                    "
					>
						<table className="table--gateway table--gateway--secondary spc--bottom--med">
							<thead>
								<tr>
									<th colSpan={4}>Add-ons</th>
									{availablePurchasePlans.map((plan, i) => {
										return (
											<th
												colSpan={2}
												className={`table--gateway__fields${
													merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''
												}`}
												key={i}
											>
												{plan.planName}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								<tr>
									<th colSpan={2} className="table--gateway__group__wrapper"></th>
									<th colSpan={2} className="table--gateway__group__wrapper"></th>
									{availablePurchasePlans.map((plan, i) => (
										<th
											key={i}
											colSpan={2}
											className={`table--gateway__group__wrapper${
												merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''
											}`}
										>
											<div className="table--gateway__group">
												<span className="title">Agent Cost</span>
												<span className="title">Merchant Cost</span>
											</div>
										</th>
									))}
								</tr>
								{renderOptionsWithFees(
									gateway,
									merchantGateway,
									availablePurchasePlans,
									handleGatewayOptionSelect,
									handleGatewayChange,
									handleFeeChange,
									handleOptionMoreInfo,
									suffix
								)}
							</tbody>
						</table>
					</div>
				)
			)}
		</React.Fragment>
	);
};

const renderOptionsWithFees = (
	gateway,
	merchantGateway,
	availablePurchasePlans,
	checkboxHandler,
	inputHandler,
	handleFeeChange,
	handleOptionMoreInfo,
	suffix,
	classNames
) => {
	const mergedFees = [].concat.apply(
		[],
		availablePurchasePlans.map((p, i) => p.fees)
	);
	//console.log(mergedFees);
	return (
		<React.Fragment>
			{Object.keys(gateway.equipmentOptions).map((opt, idx) => {
				const dependentFees = mergedFees.filter(
					f => !!f.dependencySettings && Object.keys(f.dependencySettings).includes(opt)
				);
				//console.log(dependentFees);
				const distinctFeeNames = [...new Set(dependentFees.map(item => item.feeName))];
				//console.log(dependentFees);
				//console.log(distinctFeeNames);
				if (!!dependentFees && dependentFees.length > 0) {
					return distinctFeeNames.map((feeName, i) => {
						return (
							<React.Fragment key={idx}>
								<tr>
									{i == 0 && (
										<React.Fragment>
											<td colSpan={2} className="is-first" rowSpan={distinctFeeNames.length}>
												<div className="gateway__addon">
													{renderSingleOption(
														merchantGateway,
														gateway,
														opt,
														checkboxHandler,
														inputHandler,
														handleOptionMoreInfo,
														false,
														suffix,
														classNames,
														disabledTooltip
													)}
												</div>
												{renderEquipmentDependentOptions(
													merchantGateway,
													gateway,
													opt,
													checkboxHandler,
													inputHandler,
													handleOptionMoreInfo,
													suffix,
													classNames,
													disabledTooltip
												)}
											</td>
										</React.Fragment>
									)}
									<td colSpan={2} className="is-second">
										<span className="gateway__text">{feeName}</span>
									</td>
									{availablePurchasePlans.map((plan, i) => {
										// default logic
										let fee = plan.fees.find(fee => fee.feeName === feeName);
										if (!fee) {
											return (
												<React.Fragment key={i}>
													<td></td>
													<td></td>
												</React.Fragment>
											);
										}
										let renderingSelectedPlan = merchantGateway.purchasePlanId == plan.planId;
										let merchantFee =
											renderingSelectedPlan && merchantGateway.fees.find((f, i) => f.feeName == feeName);

										let merchantPrice = fee.retailPrice;
										let agentCost = fee.agentCost;
										if (merchantFee) {
											if (Object.keys(merchantFee).includes('merchantPrice')) {
												merchantPrice = merchantFee.merchantPrice;
											}
											if (Object.keys(merchantFee).includes('agentCost')) {
												agentCost = merchantFee.agentCost;
											}
										}
										const disabled = merchantGateway.purchasePlanId != plan.planId;
										const originalPrice = `$${fee.retailPrice}`;

										return (
											<React.Fragment key={i}>
												<td className={disabled ? '' : 'is-selected'}>
													<NumberFormat
														value={agentCost}
														placeholder={originalPrice}
														thousandSeparator={true}
														prefix={'$'}
														decimalScale={3}
														fixedDecimalScale={false}
														className="input input--med type--center"
														name={'agentCost_' + fee.feeId}
														disabled={true}
													/>
												</td>
												<td className={disabled ? '' : 'is-selected'}>
													<span
														className="datatooltip--250 datatooltip--bottom"
														data-tooltip={
															disabled ? `To adjust this field, you must change the plan to ${plan.planName}.` : null
														}
													>
														<NumberFormat
															id={`${gateway.name}_planId_${plan.planId}_fee_${fee.feeId}`}
															value={merchantPrice}
															placeholder={originalPrice}
															allowNegative={false}
															thousandSeparator={true}
															prefix={'$'}
															decimalScale={3}
															fixedDecimalScale={false}
															className="input input--med type--center"
															name={'fee_' + fee.feeId}
															disabled={disabled}
															onValueChange={values => {
																handleFeeChange(plan.planId, fee.feeId, values);
															}}
														/>
													</span>
												</td>
											</React.Fragment>
										);
									})}
								</tr>
							</React.Fragment>
						);
					});
				}
			})}
		</React.Fragment>
	);
};

const renderEquipmentOptions = (
	gateway,
	merchantGateway,
	availablePurchasePlans,
	checkboxHandler,
	inputHandler,
	moreInfoHandler,
	suffix
) => {
	const mergedFees = [].concat.apply(
		[],
		availablePurchasePlans.map((p, i) => p.fees)
	);
	return (
		<React.Fragment>
			{Object.keys(gateway.equipmentOptions).map((opt, idx) => {
				const dependentFees = mergedFees.filter(
					f => !!f.dependencySettings && Object.keys(f.dependencySettings).includes(opt)
				);
				const dependentOnName = gateway.equipmentOptions[opt].dependentOnName;
				if ((!!dependentFees && dependentFees.length > 0) || !!dependentOnName) {
					return null; // options with fees or are dependent on other settings are rendered separately
				}
				if (opt == 'BatchSettlementType') return null; // handled separately
				if (gateway.equipmentOptions[opt].dataType == 'file') return null;

				return (
					<React.Fragment key={idx}>
						{renderSingleOption(
							merchantGateway,
							gateway,
							opt,
							checkboxHandler,
							inputHandler,
							moreInfoHandler,
							false,
							suffix,
							undefined,
							disabledTooltip
						)}
						{renderEquipmentDependentOptions(
							merchantGateway,
							gateway,
							opt,
							checkboxHandler,
							inputHandler,
							moreInfoHandler,
							suffix,
							undefined
						)}
					</React.Fragment>
				);
			})}
		</React.Fragment>
	);
};

const renderEquipmentDependentOptions = (
	merchantGateway,
	gateway,
	opt,
	checkboxHandler,
	inputHandler,
	handleOptionMoreInfo,
	suffix,
	classNames,
	disabledTooltip
) => {
	return (
		<React.Fragment>
			{Object.values(gateway.equipmentOptions)
				.filter(o => o.dependentOnName == opt)
				.map((dependentOpt, idx) => {
					let disableOption =
						!merchantGateway.equipmentOptions[opt] ||
						merchantGateway.equipmentOptions[opt] != dependentOpt.dependentOnValue;
					if (disableOption) return null;
					const dependentValue = dependentOpt.dependentOnValue === '1' ? 'checked' : dependentOpt.dependentOnValue;
					const tooltip =
						merchantGateway.equipmentOptions[opt] != dependentOpt.dependentOnValue
							? `To adjust this field, ${
									gateway.equipmentOptions[dependentOpt.dependentOnName].friendlyName
							  } must be ${dependentValue}`
							: disabledTooltip;
					return (
						<div className="single-option" key={idx}>
							{renderSingleOption(
								merchantGateway,
								gateway,
								dependentOpt.name,
								checkboxHandler,
								inputHandler,
								handleOptionMoreInfo,
								disableOption,
								suffix,
								classNames,
								tooltip
							)}
						</div>
					);
				})}
		</React.Fragment>
	);
};

const renderSingleOption = (
	merchantGateway,
	gateway,
	opt,
	checkboxHandler,
	inputHandler,
	moreInfoHandler,
	disableOption,
	suffix,
	classNames,
	disabledTooltip
) => {
	return (
		<EquipmentOption
			merchantEquipment={merchantGateway}
			equipment={gateway}
			option={opt}
			checkboxHandler={checkboxHandler}
			inputHandler={inputHandler}
			moreInfoHandler={moreInfoHandler}
			disableOption={disableOption}
			disabledTooltip={disabledTooltip}
			classNames={classNames}
			suffix={suffix}
		/>
	);
};

AddonListComponent.defaultProps = {
	suffix: '',
	hideDisabledPurchasePlans: false,
	renderAddonsPaymentScheduleOptions: noop,
	isPopup: false,
};

export default AddonListComponent;
