import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import DropdownContainer from '../dropdown-container/dropdown-container';

const GridFooter = ({
	gridFooterRef,
	isLoadMoreEnabled,
	loadMoreOptions,
	fetchingAdditionalData,
	fetchingData,
	filteredRows,
	type,
	openCloseModal,
	CustomComponent,
	onLoadMoreLimitChange,
	loadMoreLimit,
	renderResultsAndReference,
}) => {
	const handleChange = ({ target: { value } }) => onLoadMoreLimitChange(value);

	return (
		<div ref={gridFooterRef} className="grid__footer">
			{isLoadMoreEnabled && (
				<div className="grid__footer__action">
					<div className="loadmore">
						<DropdownContainer
							value={loadMoreLimit}
							onChange={handleChange}
							options={map(loadMoreOptions, value => ({ value, text: value === 0 ? 'All' : value, default: false }))}
							disabled={fetchingAdditionalData}
							selectClass="input input--med input--select w--70p"
							labelClass="display--n"
						/>
					</div>
				</div>
			)}
			{renderResultsAndReference()}
			{fetchingAdditionalData && <div className="loader--progress" />}
			{!fetchingData && filteredRows.length > 0 && (
				<CustomComponent openCloseModal={openCloseModal} type={type} data={filteredRows} />
			)}
		</div>
	);
};

GridFooter.propTypes = {
	gridFooterRef: PropTypes.any,
	isLoadMoreEnabled: PropTypes.bool,
	loadMoreOptions: PropTypes.any,
	fetchingAdditionalData: PropTypes.bool,
	fetchingData: PropTypes.bool,
	filteredRows: PropTypes.array,
	type: PropTypes.string,
	openCloseModal: PropTypes.func,
	CustomComponent: PropTypes.any,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.any,
	renderResultsAndReference: PropTypes.func,
};

export default GridFooter;
