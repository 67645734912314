import React from 'react';
import { noop } from 'lodash';

export default function createGridColumn(
	key,
	name,
	initWidth,
	{
		sortable = true,
		hideable = true,
		visible = true,
		filterable = true,
		filterRenderer = null,
		isAdvancedField = false,
		resizable = true,
		formatter = null,
		getRowMetaData = noop,
		order = null,
		isDefaultSorter = false,
		defaultSortDirection = 'ASC',
		fixWidth = false,
		hideOnExport = false,
	}
) {
	return {
		key,
		name,
		sortable,
		hideable,
		visible,
		filterable,
		filterRenderer,
		isAdvancedField,
		initWidth,
		resizable,
		formatter,
		getRowMetaData,
		isDefaultSorter,
		defaultSortDirection,
		order,
		fixWidth,
		hideOnExport,
	};
}
