import React from 'react';
import { withContext, withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import { renderIf } from './../../common/utilities';
import { cloneDeep, find, get, noop } from 'lodash';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { UserAccountPanel } from './../../common/components/user-account-panel';
import { DisplayDateComponent } from '../../common/formatters';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';

class NotesView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notesList: null,
			editingNoteId: -1,
			editingNote: {},
			modal: {
				name: modalNames.none,
				data: null,
			},
		};

		this.notificationRef = React.createRef();
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		const { appid } = this.props.match.params;

		this.props.showLoader(true);
		//console.log('getting notes');
		appService
			.getMerchantNotes(appid)
			.then(notesObj => {
				this.props.showLoader(false);
				this.setState({ notesList: notesObj.notes });
			})
			.catch(err => {
				console.log('getMerchantNotes error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	onEdit = note => () => {
		note.originalNoteText = note.noteText;
		this.setState({ editingNoteId: note.noteId, editingNote: note });
	};

	onTextChange = e => {
		let note = cloneDeep(this.state.editingNote);
		note.noteText = e.target.value;

		this.setState({ editingNote: note });
		//console.log('new note text ' + note.noteText);
	};

	onSaveEdit = () => {
		//console.log('saving');
		this.props.showLoader(true);
		const { appid } = this.props.match.params;
		const note = this.state.editingNote;

		appService
			.editNote(appid, note.noteId, note.noteText)
			.then(() => {
				this.props.showLoader(false);
				let newNotesList = cloneDeep(this.state.notesList);
				let editedNote = find(newNotesList, item => item.noteId == note.noteId);
				editedNote.noteText = note.noteText;
				this.setState({ notesList: newNotesList, editingNoteId: -1, editingNote: {} });
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Saved successfully',
					success: true,
				});
			})
			.catch(err => {
				console.log('editNote error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	onCancelEdit = note => () => {
		//console.log('cancelling');

		let newNotesList = cloneDeep(this.state.notesList);
		let editedNote = find(newNotesList, item => item.noteId == note.noteId);
		editedNote.noteText = note.originalNoteText;
		this.setState({ notesList: newNotesList, editingNoteId: -1, editingNote: {} });
	};

	onDelete = note => () => {
		//console.log('deleting');
		this.props.showLoader(true);
		const { appid } = this.props.match.params;

		appService
			.deleteNote(appid, note.noteId)
			.then(() => {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				this.props.showLoader(false);
				let newNotesList = this.state.notesList.filter(item => item.noteId !== note.noteId);
				this.setState({ notesList: newNotesList });
				addNotification({
					message: 'Deleted successfully',
					success: true,
				});
			})
			.catch(err => {
				console.log('deleteNote error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
		if (modalObj.name === modalNames.none) {
			this.fetchData();
		}
	};

	renderNewNote = () => {
		const { appid } = this.props.match.params;
		return (
			<button
				type="button"
				className="btn btn--med btn--ghost spc--left--sml align--v--middle"
				onClick={() => this.openCloseModal({ name: modalNames.newNote, data: { appid: appid, hideViewAll: true } })}
			>
				<i className="icon icon--xsml icon--addnote align--v--middle"></i>
			</button>
		);
	};

	renderNotesList(notes) {
		return !notes || !notes.length ? (
			<tr>
				<td className="is-message">
					<div className="note note--default note--no-margin">
						No notes found. Add a new note to view it in the list.
					</div>
				</td>
			</tr>
		) : (
			notes.map(note => {
				return (
					<tr key={note.noteId}>
						<td>
							<DisplayDateComponent value={note.enteredDate} />
						</td>
						<td>{note.enteredBy}</td>
						{renderIf(this.state.editingNoteId == note.noteId)(
							<React.Fragment>
								<td>
									<input
										type="text"
										className="input input--med w--200p"
										value={this.state.editingNote.noteText}
										onChange={this.onTextChange}
										id={'txtNote_' + note.noteId}
									/>
								</td>
								<td>
									<button onClick={this.onSaveEdit} className="btn btn--med btn--icon" title="Save">
										<i className="icon icon--tiny icon--confirm align--v--sub" />
									</button>
									<button onClick={this.onCancelEdit(note)} className="btn btn--med btn--icon" title="Cancel">
										<i className="icon icon--tiny icon--cancel--gray align--v--sub" />
									</button>
								</td>
							</React.Fragment>
						)}
						{renderIf(this.state.editingNoteId != note.noteId)(<td className="is-note">{note.noteText}</td>)}
						{this.state.editingNoteId != note.noteId && (note.canEdit === 1 || note.canEdit === true) && (
							<React.Fragment>
								<td>
									<button onClick={this.onEdit(note)} className="btn btn--med btn--icon" title="Edit">
										<i className="icon icon--tiny align--v--sub icon--edit" />
									</button>
									<button onClick={this.onDelete(note)} className="btn btn--med btn--icon" title="Delete">
										<i className="icon icon--tiny align--v--sub icon--wastebasket" />
									</button>
								</td>
							</React.Fragment>
						)}
					</tr>
				);
			})
		);
	}

	render() {
		const { notesList, errorMessage } = this.state;
		const { merchant } = this.props;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				<div className="l--content" ref={this.detailsRef}>
					{errorMessage ? <div className="note note--warning">{errorMessage}</div> : null}
					{notesList ? (
						<React.Fragment>
							<header className="header">
								<div className="header__title__wrap">
									<div className="header__title">
										{merchant && merchant.dba && merchant.appId && (
											<div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
												{`${merchant.dba} - ${merchant.appId}`}
											</div>
										)}
										<span>Notes</span>
										{this.renderNewNote()}
									</div>
								</div>
							</header>

							<div className="table--files__wrap table--files__feature">
								<div className="table--files">
									<table className="table">
										<tbody>{this.renderNotesList(notesList)}</tbody>
									</table>
								</div>
							</div>
						</React.Fragment>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

export default withLoader(withContext(NotesView, MerchantContext, 'merchant'));
