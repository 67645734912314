import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TicketNumberFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

	componentDidUpdate() {
		if (this.ticketNumber) {
			this.ticketNumber.focus();
		}
	}
	filterChange = e => {
		const value = e.target.value ? e.target.value : null;

		this.onFilterChange(value);
	};

	onFilterChange(value) {
		this.props.onFilterChanged({
			id: 'ticketNumber',
			values: [{ key: 'ticketNumber', value: value }],
			emptyValue: null,
		});
	}

	render() {
		const {
			goButtonHandler,
			filter: {
				values: { ticketNumber },
			},
		} = this.props;

		return (
			<div className="inputgroup">
				<div className="inputgroup--main inputgroup--main--bordered">
					<input
						type="text"
						placeholder="Ticket Number"
						className="input input--number input--med"
						value={ticketNumber === null ? '' : ticketNumber}
						id="ticketNumber"
						onChange={this.filterChange}
						ref={this.inputRef}
						autoFocus={true}
					/>
				</div>
				{goButtonHandler && (
					<div className="inputgroup--aside inputgroup--aside--bordered">
						<button className="btn btn--med btn--primary" onClick={goButtonHandler}>
							Apply
						</button>
					</div>
				)}
			</div>
		);
	}
}

TicketNumberFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
};

export default TicketNumberFilterComponent;
