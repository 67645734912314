import React from 'react';
import PropTypes from 'prop-types';

import { default as GeneralInformation } from './general-information';
import { default as PricingBreakdown } from './pricing-breakdown';
import { default as ProfitBreakdown } from './profit-breakdown';
import { default as AdditionalNotes } from './additional-notes';
import { default as AccountRecap } from './account-recap';

import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';

import { withLoader } from './../../common/components';
import { appService } from '../../services/appService';

class ReviewPricingSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			summary: null,
		};
	}

	componentDidMount() {
		const leadId = this.props.leadId;
		this.props.showLoader(true);

		appService
			.getReviewPricingSummary(leadId)
			.then(lead => {
				this.props.showLoader(false);
				this.setState({ summary: lead });
			})
			.catch(err => {
				console.log('getPricingAnalysisSummary error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	}

	render() {
		const { summary, errorMessage } = this.state;
		return (
			<React.Fragment>
				<React.Fragment>
					<header className="popup__header popup__header--secondary">
						<h3 className="popup__header__title">Pricing Analysis Results</h3>
					</header>
					{errorMessage ? <div className="note note--warning">{errorMessage}</div> : null}

					{summary ? (
						<div className="popup__body">
							<div>
								<GeneralInformation lead={summary} />
							</div>
							<header className="header">
								<div className="spc--right--auto">
									<div className="header__title">Total Potential Profit:{summary.totalPotentialProfit}</div>
								</div>
							</header>
							<div className="spc--bottom--med">
								<PricingBreakdown pricing={summary.pricingBreakdown} />
							</div>
							<div className="spc--bottom--med">
								<ProfitBreakdown profit={summary.profitBreakdown} />
							</div>
							<div className="spc--bottom--med">
								<AdditionalNotes notes={summary} />
							</div>
							<div>
								<AccountRecap accountInfo={summary.accountInformation} />
							</div>
						</div>
					) : null}
					<div className="popup__footer popup__footer--styled"></div>
				</React.Fragment>
			</React.Fragment>
		);
	}
}
export default withLoader(ReviewPricingSummary);
