import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { findIndex, cloneDeep, every, map, some, get, find, filter, isEmpty, each } from 'lodash';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';

import { isComponent } from '../../utilities';
import { withContainer } from '../drag-and-drop';

class ColumnFilterComponent extends Component {
	get columns() {
		return this.props.state.columns.filter(c => c.hideable === true);
	}

	componentDidUpdate = prevProps => {
		if (some(prevProps.columns, ({ key, visible }) => get(find(this.props.columns, { key }), 'visible') !== visible)) {
			this.props.updateState({
				columns: cloneDeep(this.props.columns),
			});
		}
	};

	onChange = e => {
		// filter entire list, not just the columns that are hideable
		const columns = [...this.props.state.columns];

		const key = e.target.name;
		const value = e.target.checked;

		if (key === 'allColumns') {
			columns.map(i => (i.hideable ? (i.visible = value) : null));
		} else {
			const idx = findIndex(columns, { key: key });
			columns[idx] = {
				...columns[idx],
				visible: value,
			};
		}

		this.props.updateState({ columns });
	};

	closeDropdown = () => {
		this.props.updateState({ activeKeys: [] });
	};

	onApply = () => {
		this.props.filteredColumns(this.props.state.columns);

		this.closeDropdown();
	};

	onReset = () => {
		this.props.updateState({ columns: cloneDeep(this.props.defaultColumns), activeKeys: [] });
		this.props.filteredColumns(cloneDeep(this.props.defaultColumns));
	};

	moveColumn = (sourceId, targetId) => {
		this.pendingUpdateFunction = () => {
			const { columns } = this.props.state;
			const sourceItem = find(columns, { key: sourceId });
			const sourceIndex = findIndex(columns, { key: sourceId });
			const targetIndex = findIndex(columns, { key: targetId });
			const newColumns = columns.slice();
			newColumns[sourceIndex].isAdvancedField = newColumns[targetIndex].isAdvancedField;
			newColumns.splice(sourceIndex, 1);
			newColumns.splice(targetIndex, 0, sourceItem);
			each(newColumns, (field, index) => {
				if (!field.hideable) return;
				field.order = index + 1;
			});
			this.props.updateState({
				columns: newColumns,
			});
		};
		if (!this.requestedFrame) {
			this.requestedFrame = requestAnimationFrame(this.drawFrame);
		}
	};

	drawFrame = () => {
		this.pendingUpdateFunction();
		this.pendingUpdateFunction = null;
		this.requestedFrame = null;
	};

	renderColumnSelector = () => {
		const selectAllValue = every(
			map(this.columns, i => i.visible),
			i => i === true
		);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';
		const nonAdvancedFields = filter(this.columns, ({ isAdvancedField }) => !isAdvancedField);
		const advancedFields = filter(this.columns, ({ isAdvancedField }) => isAdvancedField);
		return (
			<Fragment>
				<div className="border--bottom spc--bottom--nano padd--bottom--nano type--wgt--bold">
					<input
						type="checkbox"
						name="allColumns"
						className="input input--check"
						checked={selectAllValue}
						id="allColumns"
						onChange={this.onChange}
					/>
					<label htmlFor="allColumns">{selectAllLabel}</label>
				</div>
				<ReorderableColumnContainer
					items={nonAdvancedFields}
					onChange={this.onChange}
					onHover={this.moveColumn}
					disable={!this.props.reorderable}
				/>
				{!isEmpty(advancedFields) && (
					<Fragment>
						<label key="adv-header" className="filter__settings__subtitle spc--top--tny">
							Advanced
						</label>
						<ReorderableColumnContainer
							items={advancedFields}
							onChange={this.onChange}
							onHover={this.moveColumn}
							disable={!this.props.reorderable}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	};

	onOpenChange = activeKeys => {
		this.props.updateState({ activeKeys: activeKeys });
	};

	render() {
		const { activeKeys, columns } = this.props.state;
		const { header: HeaderComponent, footer: FooterComponent, isDisabled, type } = this.props;

		const isFilterButtonDisabled = !some(columns, column => column.visible && column.hideable);

		return (
			<div className="filter__settings datatooltip--bottom" data-tooltip="Settings">
				<Menu
					disabledOverflow={true}
					mode={'horizontal'}
					motion={'slide-up'}
					triggerSubMenuAction={'click'}
					onOpenChange={this.onOpenChange}
					openKeys={activeKeys}
				>
					<SubMenu disabled={isDisabled} key="columns">
						<MenuItem key="header" disabled>
							<HeaderComponent />
							{this.renderColumnSelector()}
						</MenuItem>
						<MenuItem key="footer" disabled className="rc-menu-abs-footer">
							<div className="rc-menu-footer">
								<FooterComponent
									onReset={this.onReset}
									onApply={this.onApply}
									type={type}
									columns={columns}
									filterButtonDisabled={isFilterButtonDisabled}
								/>
							</div>
						</MenuItem>
					</SubMenu>
				</Menu>
			</div>
		);
	}
}

ColumnFilterComponent.propTypes = {
	defaultColumns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			visible: PropTypes.bool.isRequired,
			hideable: PropTypes.bool.isRequired,
		})
	),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			visible: PropTypes.bool.isRequired,
			hideable: PropTypes.bool.isRequired,
		})
	),
	filteredColumns: PropTypes.func.isRequired,
	header: isComponent,
	footer: isComponent,
	isDisabled: PropTypes.bool,
	type: PropTypes.string,
	state: PropTypes.shape({
		columns: PropTypes.array,
		activeKeys: PropTypes.array,
	}).isRequired,
	updateState: PropTypes.func.isRequired,
	reorderable: PropTypes.bool,
};

function ColumnContainer({ item: { key, name, visible }, onChange }) {
	return (
		<div>
			<div className="border--bottom spc--bottom--nano padd--bottom--nano">
				<input
					type="checkbox"
					name={key}
					className="input input--check"
					checked={visible}
					id={`column-${key}`}
					onChange={onChange}
				/>
				<label htmlFor={`column-${key}`}>{name}</label>
			</div>
		</div>
	);
}

function mapColumnToId({ key }) {
	return key;
}

const ReorderableColumnContainer = withContainer(ColumnContainer, mapColumnToId, {
	dragStyle: {
		height: '25px',
		border: '1px dashed #c7ced5',
		backgroundColor: '#f8fafd',
		borderRadius: '6px',
		marginBottom: '3px',
	},
});

export default ColumnFilterComponent;
