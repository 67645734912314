import { toLower, each, isObject } from 'lodash';
import moment from 'moment';

const isInvalidDate = (value, key) =>
	toLower(key).indexOf('date') > -1 &&
	((moment(value).isValid() && parseInt(moment(value).format('YYYY')) === 1) || !value);

const mapData = (data, mappedData, defaultValue) => {
	each(data, (value, key) => {
		if (isObject(value)) {
			mapData(value, mappedData[key], defaultValue);
		} else if (isInvalidDate(value, key)) {
			mappedData[key] = defaultValue;
		}
	});
};

export const defaultInvalidDates = (data, defaultValue = '0001-01-01T00:00:00') => {
	const mappedData = { ...data };

	mapData(data, mappedData, defaultValue);

	return mappedData;
};
