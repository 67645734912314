import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProcessorSelectionComponent extends Component {
	get processorValue() {
		return this.props.filter.values.processorName;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'processorName',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Processor: </label>
				{this.processorValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

ProcessorSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default ProcessorSelectionComponent;
