import React, { Fragment } from 'react';
import { each, cloneDeep, size, concat, get, map } from 'lodash';
import moment from 'moment';

import { GridComponent } from '../../common/components/grid';
import { getPage, hasMoreData, onLoadMoreLimitChange } from '../../common/components/grid/commonGridMethods';
import MonthSelector from './MonthSelector';
import { settlementColumns } from './settlementColumns';
import { ZebraRenderer } from '../../common/components/row';
import { LoadMoreOptions } from '../../common/utilities';

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class ElavonSettlements extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: cloneDeep(settlementColumns),
			activePage: 1,
			rowsPerPage: 20,
			totalRowCount: 0,
			data: null,
			filteredRows: [],
			fullFilteredRows: [],
		};
		this.gridRef = React.createRef();
		this.setState = this.setState.bind(this);
		this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
		this.classes = {
			content: 'grid__holder--fullwidth',
			header: '',
		};
	}
	componentDidMount() {
		this.setData();
	}
	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setData();
		}
	}
	mapData = data => {
		if (!data) return { settlements: [] };
		return {
			...data,
			settlements: map(data.settlements, settlement => ({
				...settlement,
				date: moment(
					new Date(settlement.settlementYear, settlement.settlementMonth - 1, settlement.settlementDay)
				).format(process.env.REACT_APP_DISPLAY_DATE_FORMAT),
			})),
		};
	};
	setData = () => {
		const data = this.mapData(this.props.data);
		this.setState({
			data: data,
			fullFilteredRows: data.settlements,
			filteredRows: getPage(data.settlements, this.state.activePage, this.state.rowsPerPage),
		});
	};
	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'activePage') {
				const pagedFilteredRows = getPage(this.state.fullFilteredRows, value, this.state.rowsPerPage);
				newState.filteredRows = pagedFilteredRows;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
	handlePageChange = pageNumber => {
		this.handleChange([{ key: 'activePage', value: pageNumber }]);
	};
	render() {
		const { months, currentMonth, handleMonthSelect, isLoading } = this.props;
		const { columns, data, activePage, rowsPerPage, filteredRows, fullFilteredRows } = this.state;
		return (
			<Fragment>
				<div className="accessories__filter">
					<MonthSelector months={months} currentMonth={currentMonth} onChange={handleMonthSelect} />
				</div>
				<GridComponent
					ref={this.gridRef}
					emptyMessage="You should change your filter options"
					fetchingData={isLoading}
					filteredRows={filteredRows}
					fullFilteredRows={fullFilteredRows}
					columns={columns}
					fixHeader={true}
					data={data}
					components={{
						rowRenderer: ZebraRenderer,
					}}
					onChange={this.handleChange}
					type="settlements"
					showGridHeader={false}
					initialFetch={false}
					useInlineFilters={false}
					showResults={true}
					lastApiRefNum={get(data, 'refNum')}
					hasPaging={true}
					loadMoreOptions={loadMoreOptionsWithAll}
					onLoadMoreLimitChange={this.onLoadMoreLimitChange}
					hasMoreData={hasMoreData(rowsPerPage, size(fullFilteredRows))}
					handlePageChange={this.handlePageChange}
					pagination={{ activePage, rowsPerPage, totalRowCount: size(fullFilteredRows) }}
					classes={this.classes}
				/>
			</Fragment>
		);
	}
}

export default ElavonSettlements;
