import React from 'react';
import { trim, toLower } from 'lodash';
import { withContext, withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import principalService from '../../services/principalService';
import PaymentPointsUpdateContext from '../../common/components/user-account-panel/PaymentPointsUpdateContext';

const { REACT_APP_AGENT_SUPPORT_EMAIL } = process.env;

class DropinPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			principal: null,
			dropinUser: '',
			errorMessage: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		const data = principalService.get();
		if (!data.isAdmin && !data.isDropinUser) this.redirect();

		this.setState({
			principal: data,
		});
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	reloadPageNewUser() {
		this.props.showLoader(false);
		this.props.getPoints();
		this.redirect();
	}

	handleSubmit(e) {
		let { dropinUser, principal } = this.state;
		dropinUser = trim(toLower(dropinUser));

		e.preventDefault();

		// stop here if form is invalid
		if (!dropinUser) {
			this.setState({ errorMessage: 'Please enter username to impersonate' });
			return;
		}

		this.setState({ errorMessage: '' });
		this.props.showLoader(true);

		appService
			.dropin(dropinUser)
			.then(user => {
				this.reloadPageNewUser();
			})
			.catch(err => {
				console.log('dropIn error', err);
				let message = 'Something went wrong. Please try again.';
				if (err && err.ex && err.ex.response && (err.ex.response.status === 401 || err.ex.response.status === 403)) {
					message = 'You are not authorized to access the page. Contact customer support: cs@cardknox.com';
				} else if (err && err.startsWith && err.startsWith('User not found')) {
					message = (
						<span>
							Email address is not registered for the Partner Portal. For assistance, reach out to{' '}
							<a className="anchor anchor--primary type--underline" href={`mailto:${REACT_APP_AGENT_SUPPORT_EMAIL}`}>
								Agent Support.
							</a>
						</span>
					);
				}
				this.setState({ errorMessage: message });
				this.props.showLoader(false);
			});
	}

	handleCancelDropin = () => {
		this.setState({ errorMessage: '' });
		this.props.showLoader(true);

		appService
			.cancelDropin()
			.then(user => {
				this.reloadPageNewUser();
			})
			.catch(err => {
				console.log('cancel dropIn error', err);
				let message = 'Something went wrong. Please try again.';
				this.setState({ errorMessage: message });
				this.props.showLoader(false);
			});
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		// hide loader before redirect
		this.props.showLoader(false);

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { dropinUser, errorMessage, principal } = this.state;

		return (
			<div className="l--content">
				{principal.isDropinUser ? (
					<React.Fragment>
						<div className="spc--top--sml spc--bottom--sml note note--warning">
							Currently impersonating {principal.name}.
						</div>
						{errorMessage ? (
							<div className="spc--top--sml spc--bottom--sml note note--warning">{errorMessage}</div>
						) : null}
						<button className="btn btn--primary btn--med membership__btn" onClick={this.handleCancelDropin}>
							Cancel Dropin
						</button>
					</React.Fragment>
				) : null}
				{!principal.isDropinUser ? (
					<form className="form" onSubmit={this.handleSubmit}>
						<div className="membership__section">
							<h2 className="membership__title">Impersonate Another Agent</h2>
							<div className="membership__spacer">
								<label className="membership__label">Username</label>
								<input
									name="dropinUser"
									type="text"
									className="input input--med"
									placeholder="Enter username"
									value={dropinUser}
									onChange={this.handleChange}
									autoFocus
									tabIndex="1"
								/>
							</div>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom-sml note note--warning">{errorMessage}</div>
							) : null}
							<button type="submit" className="btn btn--primary btn--med membership__btn" tabIndex="3">
								Drop in
							</button>
						</div>
					</form>
				) : null}
			</div>
		);
	}
}

export default withLoader(withContext(DropinPage, PaymentPointsUpdateContext, 'getPoints'));
