import React, { useState } from 'react';
import PropTypes from 'prop-types';

function YesNoRadio({ name, onChange, yes, yesLabel, noLabel, radioClass, labelClass }) {
	const [id] = useState((Math.random() * 10000).toFixed(0));
	return (
		<div className="flex--primary">
			<div className="spc--right--med spc--bottom--med">
				<input
					className={radioClass}
					type="radio"
					id={'yes' + id}
					name={name}
					onChange={e => onChange({ target: { name, checked: e.target.checked, value: true, type: 'radio' } })}
					checked={!!yes}
				/>
				<label className={labelClass} htmlFor={'yes' + id}>
					{yesLabel}
				</label>
			</div>
			<div className="spc--right--med spc--bottom--med">
				<input
					className={radioClass}
					type="radio"
					id={'no' + id}
					name={name}
					onChange={e => onChange({ target: { name, checked: !e.target.checked, value: false, type: 'radio' } })}
					checked={yes === undefined || yes === null ? false : !yes}
				/>
				<label className={labelClass} htmlFor={'no' + id}>
					{noLabel}
				</label>
			</div>
		</div>
	);
}

YesNoRadio.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	yesLabel: PropTypes.string,
	noLabel: PropTypes.string,
	radioClass: PropTypes.string,
	labelClass: PropTypes.string,
};

YesNoRadio.defaultProps = {
	yesLabel: 'Yes',
	noLabel: 'No',
	radioClass: 'input--radio',
	labelClass: 'label',
};

export default YesNoRadio;
