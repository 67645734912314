import React, { Component, createRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { replace, get, some } from 'lodash';
import { AddressComponent } from '../../common/components/address';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import { OwnershipDropdownComponent } from '../../common/components/ownership-dropdown';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';

const allowedFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp, fileTypes.txt, fileTypes.pdf];
class BaseMPA extends Component {
	dotRegex = /\./g;
	startDateRef = createRef();
	dateOfBirthRef = createRef();
	fixFieldName = field => replace(field, this.dotRegex, '_');

	render() {
		const { mpa, mpafiles } = this.props;
		return (
			<ToggleContainer>
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderBusinessInfo(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderMailingInfo(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderAdditionalInformation(mpa, isToggled, handleToggle)
				)}
				{this.props.isMerchant &&
					this.renderUploadSingleDocumentType('VoidedCheck', 'Add Voided Check', false, 'Check Name', true)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderSignerInformation(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderAdditionalPaymentDetails(mpa, isToggled, handleToggle)
				)}
				{this.props.isMerchant &&
					this.renderUploadSingleDocumentType('BankingStatement', 'Attach Bank Statements', true)}
				{!this.props.isMerchant &&
					this.renderToggleableSection((isToggled, handleToggle) =>
						this.renderUploadDocuments(mpafiles, isToggled, handleToggle)
					)}
				{this.renderToggleableSection((isToggled, handleToggle) => this.renderOtherNotes(mpa, isToggled, handleToggle))}
			</ToggleContainer>
		);
	}

	renderFilesForTag(tag) {
		let files = this.props.getFilesForTag(tag);
		if (files && files.length > 0) {
			return (
				<React.Fragment>
					<label className="label">Already Uploaded:</label>
					<div className="table--files__wrap">
						<table className="table--files">
							<tbody>
								{files.map((file, idx) => {
									return (
										<tr key={idx}>
											<td>
												<p className="table--files__name">{file.fileName}</p>
											</td>
											<td>
												{canPreviewFile(file.fileName) && (
													<button
														className="btn btn--med btn--icon datatooltip--left"
														data-tooltip="View"
														onClick={this.props.handleFileDownload(
															file.parentId,
															file.fileId,
															file.fileName,
															this.props.showLoader
														)}
														disabled={this.props.disableDelete}
													>
														<i className="icon icon--xsml icon--view align--v--sub"></i>
													</button>
												)}
												<button
													className="btn btn--med btn--icon datatooltip--left"
													data-tooltip="Download"
													onClick={this.props.handleFileDownload(
														file.parentId,
														file.fileId,
														file.fileName,
														this.props.showLoader,
														true
													)}
													disabled={this.props.disableDelete}
													download
												>
													<i className="icon icon--xsml icon--download align--v--sub"></i>
												</button>
												<button
													className="btn btn--med btn--icon datatooltip--left"
													data-tooltip="Delete"
													onClick={this.props.handleFileDelete(file.parentId, file.fileId, file.fileName, file.fileTag)}
													disabled={this.props.disableDelete}
												>
													<i className="icon icon--tiny icon--wastebasket align--v--sub"></i>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</React.Fragment>
			);
		}
	}

	renderCardHeader(title, isToggled, handleToggle, tooltip) {
		return (
			<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
				<div className="flex--primary">
					<h6 className="spc--bottom--sml">{title}</h6>
					{tooltip && (
						<i
							class="icon icon--tiny icon--info spc--left--tny spc--bottom--sml datatooltip--right datatooltip--150"
							data-tooltip={tooltip}
						></i>
					)}
				</div>
				{!this.props.isMerchant && (
					<div className="card--primary__header__expand" onClick={handleToggle}>
						<i
							className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${isToggled ? 'rotate--180' : ''}`}
						></i>
					</div>
				)}
			</div>
		);
	}

	renderFileDropZone(tag) {
		return (
			<FileDropzoneComponent
				tag={tag}
				multiple={true}
				fileList={this.props.files}
				onDrop={this.props.onDropFile}
				onRemoveFile={this.props.handleRemoveFile}
				validateFileSize={false}
				allowedFileTypes={allowedFileTypes}
			/>
		);
	}

	renderToggleableSection(renderSection) {
		return (
			<Toggle initialToggle={true}>{({ isToggled, handleToggle }) => renderSection(isToggled, handleToggle)}</Toggle>
		);
	}

	renderOtherNotes(mpa, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader(
					'Other Notes',
					isToggled,
					handleToggle,
					this.props.isMerchant ? undefined : 'This will be visible to merchants.'
				)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					<textarea
						placeholder="Notes"
						className="input input--textarea spc--bottom--med"
						cols={50}
						rows={5}
						name="accountNotes"
						value={mpa.accountNotes}
						onChange={this.props.handleChange}
					></textarea>
				</div>
			</div>
		);
	}

	renderUploadDocumentRow(tag, label, tooltip, showSeparator = true, id = null) {
		const requiredDocs = this.props.getRequiredDocs();
		const required = some(requiredDocs, file => file.fileTag === tag);
		return (
			<React.Fragment>
				<div className="row">
					<div className={`col col-sml-12 col-lrg-6 spc--bottom--sml${required ? ' required' : ''}`} id={id}>
						<label className="display--b type--color--text--dark type--wgt--bold">
							{label}{' '}
							{tooltip && (
								<i className="icon icon--tiny align--v--middle icon--info datatooltip--200" data-tooltip={tooltip} />
							)}
						</label>
					</div>
					<div className="col col-sml-12 col-lrg-6">
						<div className="form__field spc--bottom--sml">
							<div className="file-upload--sml">{this.renderFileDropZone(tag)}</div>
						</div>
						<div className="card--no-expand">{this.renderFilesForTag(tag)}</div>
					</div>
				</div>
				{showSeparator && <div className="separator separator--grey1 spc--bottom--sml" />}
			</React.Fragment>
		);
	}

	renderUploadDocuments(mpafiles, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Upload Documents', isToggled, handleToggle)}
				{mpafiles && mpafiles.length > 0 ? (
					<div>
						<div className="spc--bottom--sml">
							<strong>Signature Documents</strong>
						</div>
						<div className="card--no-expand">{this.renderFilesForTag(['SignedMPA', 'SignaturePages'])}</div>
					</div>
				) : null}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					{this.renderUploadDocumentRow(
						'BankStatements',
						'Bank Statements',
						'Please include your three most recent bank statements'
					)}
					{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
					{this.renderUploadDocumentRow('ProofOfAddress', 'Proof of Address', 'Utility bill or Lease Agreement')}
					{this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs')}
					{this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs', undefined, undefined, 'corporatedocs_div')}
					{this.renderUploadDocumentRow('MerchantStatement', 'Recent Processing Statement')}
					{this.renderUploadDocumentRow('CompanyLogo', 'Marketing', 'Flyer, Brochure or Company Website', false)}
				</div>
			</div>
		);
	}

	renderUploadSingleDocumentType(tag, label, multiple, descriptionPlaceholder, required) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader(label, true)}
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className={`form__field spc--bottom--med file-upload--sml${required ? ' required' : ''}`}>
							<label className="label">Upload File: </label>
							<FileDropzoneComponent
								multiple={multiple}
								showDescription={!!descriptionPlaceholder}
								descriptionPlaceholder={descriptionPlaceholder}
								tag={tag}
								fileList={this.props.files}
								onDrop={this.props.onDropFile}
								onRemoveFile={this.props.handleRemoveFile}
								onUpdateDescription={this.props.handleUpdateFileDescription}
								validateFileSize={false}
								allowedFileTypes={allowedFileTypes}
							/>
						</div>
					</div>
				</div>
				<div className="row">{this.renderFilesForTag(tag)}</div>
			</div>
		);
	}

	renderTransactionInformationField(form, field, label) {
		const name = this.fixFieldName(field);
		let value = get(form, field);

		let componentProps = {
			className: 'input input--med',
			name: name,
			value: value,
			placeholder: '$0',
			thousandSeparator: true,
			fixedDecimalScale: false,
			prefix: '$',
			decimalScale: 2,
			onValueChange: ({ floatValue }) =>
				this.props.handleChange({
					target: {
						value: floatValue,
						name: name,
					},
				}),
		};
		return (
			<div className="col col-sml-12 col-lrg-4">
				<div className="form__field spc--bottom--med">
					<label className="label">{label} </label>
					<ZeroAsEmptyNumberFormat {...componentProps} />
				</div>
			</div>
		);
	}

	renderPaymentDetailField(form, checkboxName, inputName, label, placeholder) {
		return (
			<div id={`${inputName}_div`} className="col col-sml-12 col-med-6 spc--bottom--med">
				<CheckboxTxtInput
					labelText={label}
					checkboxName={checkboxName}
					inputName={inputName}
					placeholder={placeholder}
					checked={get(form, checkboxName)}
					value={get(form, inputName)}
					checkboxClass="input--check input--flex-fix"
					inputClass={`input input--med${this.props.validateField(inputName)}`}
					format="NumberFormat"
					decimalScale={0}
					onCheckChanged={this.props.handleChange}
					onValueChanged={this.props.handleChange}
					allowNegative={false}
				/>
			</div>
		);
	}

	handleAmexEsaChange(e) {
		if (e.target.checked) {
			this.props.handleChange({ target: { name: 'amexDetails_status', value: 'Existing' } }, () => {
				this.props.handleChange({ target: { name: 'amexDetails_program', value: 'ESA' } });
			});
		} else {
			this.props.handleChange({ target: { name: 'amexDetails_status', value: '' } }, () => {
				this.props.handleChange({ target: { name: 'amexDetails_program', value: 'Unknown' } });
			});
		}
	}

	renderAmexEsa(mpa) {
		return (
			<div id={`amexDetails_esaNumber_div`} className="col col-sml-12 col-med-6 spc--bottom--med">
				<label className="label label--inset">Do you have an existing Amex ESA number?</label>
				<div className="inputgroup--aside">
					<input
						type="checkbox"
						name={'amexDetails_status'}
						id={'existingEsaNumber'}
						onChange={e => this.handleAmexEsaChange(e)}
						checked={get(mpa, 'amexDetails.status') === 'Existing'}
						className={'input--check'}
					/>
					<label htmlFor={'existingEsaNumber'}></label>
				</div>
				<div className="inputgroup--main">
					<NumericFormat
						type={'text'}
						decimalScale={0}
						name={'amexDetails_esaNumber'}
						value={get(mpa, 'amexDetails.esaNumber')}
						disabled={get(mpa, 'amexDetails.status') !== 'Existing'}
						onChange={this.props.handleChange}
						className={`input input--med${this.props.validateField('amexDetails_esaNumber')}`}
						placeholder={'Enter ESA Number'}
						allowNegative={false}
					/>
				</div>
			</div>
		);
	}

	renderAdditionalPaymentDetails(mpa, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Additional Payment Details', isToggled, handleToggle)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					<div className="row flex--align--bottom">
						{this.renderAmexEsa(mpa)}
						{this.renderPaymentDetailField(
							mpa,
							'doesAcceptEbt',
							'ebtNumber',
							'Do you have an existing EBT account number?',
							'Enter EBT Number'
						)}
					</div>
				</div>
			</div>
		);
	}

	renderSignerInformation(mpa, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Signer Information', isToggled, handleToggle)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					<div className="row grid-align-middle spc--bottom--sml">
						<div className="col col-sml-12 col-lrg-12">
							<div className="form__field">
								<input
									id="sameAsContactInformation"
									type="checkbox"
									className="input--check"
									onClick={this.props.copyContactInformation}
								/>
								<label htmlFor="sameAsContactInformation" className="label">
									Same as Contact Info
								</label>
							</div>
						</div>
					</div>
					<div className="row grid-align-middle">
						{this.renderField(mpa, 'signerInformation.firstName', 'Signer First Name')}
						{this.renderField(mpa, 'signerInformation.lastName', 'Signer Last Name')}
					</div>

					<AddressComponent
						requiredFields={this.props.requiredFields}
						validateField={this.props.validateField}
						streetPrefix="Signer Home"
						address={mpa.signerInformation.address}
						namePrefix={'signerInformation_address'}
						onChange={this.props.handleChange}
						isCanada={mpa.isCanadian}
					/>

					<div className="row">
						{this.renderField(mpa, 'signerInformation.phoneNumber', 'Phone Number')}
						{this.renderField(mpa, 'signerInformation.cellPhoneNumber', 'Signer Cell Number')}
						{this.renderSocialSecurityNumber(mpa)}
						{this.renderDateOfBirth(mpa)}
						{this.renderIdentificationInfo(mpa)}
					</div>
				</div>
			</div>
		);
	}

	renderAdditionalInfo(mpa) {
		return (
			<div className="row grid-align-middle">
				{this.renderField(mpa, 'goodsOrServicesDescription', 'Goods/Services Sold')}
				{this.renderField(mpa, 'businessInformation.website', 'Website')}
				<div className="col col-sml-12 col-med-6">
					<div
						className={`form__field spc--bottom--med ${this.props.requiredFields['businessInformation.ownershipType'] &&
							'required'}`}
					>
						<label className="label">Ownership Type </label>
						<OwnershipDropdownComponent
							className={`input input--med input--select${this.props.validateField(
								'businessInformation.ownershipType'
							)}`}
							id="businessInformation_ownershipType"
							value={mpa.businessInformation.ownershipType}
							onChange={this.props.handleChange}
							ownershipOptions={[
								{ value: 'Unknown', label: 'Please select' },
								{ value: 'Partnership', label: 'Partnership' },
								{ value: 'Corporation', label: 'Corporation' },
								{ value: 'SoleOwnership', label: 'Sole Ownership' },
								{ value: 'LLC', label: 'LLC' },
								{ value: 'NonProfit', label: 'Non Profit / Tax Exempt' },
								{ value: 'Other', label: 'Other' },
							]}
						/>
					</div>
				</div>
				{this.renderField(mpa, 'taxID', 'Tax ID (9 digits)', undefined, 'fs-mask')}
				{this.renderField(mpa, 'bankAccountInformation.bankName', 'Bank Name')}
				{this.renderField(mpa, 'bankAccountInformation.accountNumber', 'Bank Account #', undefined, 'fs-mask')}
				{this.renderRoutingNumber(mpa)}
			</div>
		);
	}

	renderAdditionalInformation(mpa, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Additional Information', isToggled, handleToggle)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>{this.renderAdditionalInfo(mpa)}</div>
			</div>
		);
	}

	renderMailingInfo(mpa, isToggled, handleToggle) {
		const sameAsBusiness = get(this.state, 'sameAsBusiness', false);
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Mailing Address', isToggled, handleToggle)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					{!this.props.isMerchant ? (
						<div className="row grid-align-middle spc--bottom--sml">
							<div className="col col-sml-12 col-lrg-12">
								<div className="form__field">
									<input
										id="same-as"
										type="checkbox"
										className="input--check"
										onClick={e => {
											this.props.copyAddress(e, this.setState({ sameAsBusiness: e.target.checked }));
										}}
									/>
									<label htmlFor="same-as" className="label">
										Same as Business Address
									</label>
								</div>
							</div>
						</div>
					) : (
						<div className="form__field">
							<input id="same-as" type="checkbox" className="input--check" onClick={this.props.copyAddress} />
							<label htmlFor="same-as">Mailing address same as location address</label>
						</div>
					)}
					<fieldset disabled={sameAsBusiness}>
						<AddressComponent
							requiredFields={this.props.requiredFields}
							validateField={this.props.validateField}
							streetPrefix="Business Mailing"
							address={mpa.corporateAddress}
							namePrefix={'corporateAddress'}
							onChange={this.props.handleChange}
							isCanada={mpa.isCanadian}
						/>
					</fieldset>
				</div>
			</div>
		);
	}

	renderField(form, field, label, inputProps = {}, className = '') {
		const name = this.fixFieldName(field);
		return (
			<div className="col col-sml-12 col-med-6">
				<div
					id={`${name}_div`}
					className={`form__field spc--bottom--med${this.props.requiredFields[field] ? ' required' : ''}`}
				>
					<label className="label">{label} </label>
					<input
						type="text"
						className={`input input--med${this.props.validateField(field)} ${className}`}
						name={name}
						value={get(form, field)}
						onChange={this.props.handleChange}
						autoComplete="off"
						{...inputProps}
					/>
				</div>
			</div>
		);
	}

	renderBusinessPhoneNumber(mpa) {
		return this.renderField(mpa, 'businessInformation.businessPhone', 'Business Phone Number');
	}

	renderBusinessInfo(mpa, isToggled, handleToggle) {
		return (
			<div className="card--primary card--sml spc--bottom--med">
				{this.renderCardHeader('Business Information', isToggled, handleToggle)}
				<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
					<div className="row grid-align-middle">
						{this.renderField(mpa, 'corporateName', 'Corporate Name')}
						{this.renderField(mpa, 'dba', 'DBA')}
						{this.renderBusinessPhoneNumber(mpa)}
						{this.renderField(mpa, 'contactPhoneNumber', 'Contact Phone Number')}
						{this.renderField(mpa, 'businessInformation.businessFax', 'Business Fax Number')}
						{this.renderField(
							mpa,
							'businessInformation.businessEmail',
							<React.Fragment>
								Business Email{' '}
								<i
									className="icon icon--tiny align--v--middle icon--info datatooltip--200 spc--left--tny"
									data-tooltip="Separate multiple email addresses with a comma."
								/>
							</React.Fragment>
						)}
						{this.renderBusinessStartDate(mpa)}
					</div>

					<AddressComponent
						streetPrefix="Business"
						requiredFields={this.props.requiredFields}
						validateField={this.props.validateField}
						address={mpa.businessInformation.businessAddress}
						namePrefix={'businessInformation_businessAddress'}
						onChange={this.props.handleChange}
						isCanada={mpa.isCanadian}
					/>
				</div>
			</div>
		);
	}

	renderBusinessStartDate(mpa) {
		return (
			<div className="col col-sml-12 col-med-6">
				<div
					id="businessStartDate_div"
					className={`form__field spc--bottom--med${this.props.requiredFields['businessStartDate'] ? ' required' : ''}`}
				>
					<label className="label">Business Start Date </label>
					<SingleDatePicker
						name={'businessStartDate'}
						onChange={date => this.props.handleChange({ target: { name: 'businessStartDate', value: date } })}
						validateField={() => this.props.validateField('businessStartDate')}
						value={get(mpa, 'businessStartDate', '')}
					/>
				</div>
			</div>
		);
	}

	renderRoutingNumber(mpa) {
		return this.renderField(mpa, 'bankAccountInformation.routingNumber', 'Bank Routing #', undefined, 'fs-mask');
	}

	renderDateOfBirth(mpa) {
		return (
			<div className="col col-sml-12 col-med-6">
				<div
					id="signerInformation_dateOfBirth_div"
					className={`form__field spc--bottom--med ${
						this.props.requiredFields['signerInformation.dateOfBirth'] ? ' required' : ''
					}`}
				>
					<label className="label">Date of Birth </label>
					<SingleDatePicker
						name={'signerInformation_dateOfBirth'}
						className="fs-mask"
						onChange={date =>
							this.props.handleChange({ target: { name: 'signerInformation_dateOfBirth', value: date } })
						}
						validateField={() => this.props.validateField('signerInformation.dateOfBirth')}
						value={get(mpa, 'signerInformation.dateOfBirth', '')}
					/>
				</div>
			</div>
		);
	}

	renderSocialSecurityNumberLabel() {
		return 'Social Security Number';
	}

	renderSocialSecurityNumber(mpa) {
		return (
			<div className="col col-sml-12 col-med-6">
				<div
					id="signerInformation_socialSecurityNumber_div"
					className={`form__field spc--bottom--med${
						this.props.requiredFields['SocialSecurityNumber'] ? ' required' : ''
					}`}
				>
					<label className="label">{this.renderSocialSecurityNumberLabel()} </label>
					<NumericFormat
						className={`input input--med fs-mask${this.props.validateField('signerInformation.socialSecurityNumber')}`}
						name="signerInformation_socialSecurityNumber"
						value={mpa.signerInformation.socialSecurityNumber}
						thousandSeparator={false}
						fixedDecimalScale={false}
						allowLeadingZeros={true}
						decimalScale={0}
						onValueChange={({ formattedValue }) =>
							this.props.handleChange({
								target: {
									value: formattedValue,
									name: 'signerInformation_socialSecurityNumber',
								},
							})
						}
					/>
				</div>
			</div>
		);
	}

	renderIdentificationInfo() {
		return null;
	}
}

export default BaseMPA;
