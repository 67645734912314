import { get, each } from 'lodash';

export const equipmentDatabaseKeys = {
	equipmentList: 'equipment-list',
	merchantEquipment: 'merchant-equipment',
	merchantPosSystemList: 'merchant-pos-system-list',
	varList: 'var-list',
};

//databases
const eqpDatabase = 'equipmentDB';
const databases = {
	[eqpDatabase]: null,
};

const initializeEqpDB = () =>
	new Promise((resolve, reject) => {
		if (databases[eqpDatabase] !== null) {
			resolve();
		}
		const eqpDBRequest = indexedDB.open(eqpDatabase, 1);
		eqpDBRequest.onerror = event => {
			console.error('Access denied to indexedDB.');
			reject();
		};
		eqpDBRequest.onupgradeneeded = event => {
			const db = event.target.result;
			const equipmentListOS = db.createObjectStore(equipmentDatabaseKeys.equipmentList, { keyPath: 'appId' });
			const merchantEquipmentOS = db.createObjectStore(equipmentDatabaseKeys.merchantEquipment, { keyPath: 'appId' });
			const merchantPosSystemListOS = db.createObjectStore(equipmentDatabaseKeys.merchantPosSystemList, {
				keyPath: 'appId',
			});
			const varListOS = db.createObjectStore(equipmentDatabaseKeys.varList, { keyPath: 'appId' });

			equipmentListOS.createIndex('index-appId', 'appId', { unique: true });
			merchantEquipmentOS.createIndex('index-appId', 'appId', { unique: true });
			merchantPosSystemListOS.createIndex('index-appId', 'appId', { unique: true });
			varListOS.createIndex('index-appId', 'appId', { unique: true });
		};
		eqpDBRequest.onsuccess = event => {
			databases[eqpDatabase] = event.target.result;
			databases[eqpDatabase].onerror = event => {
				console.error(`Database error: ${event.target.errorCode}`);
			};
			resolve();
		};
	});

const initializeIDB = async () => {
	const initializePromises = [initializeEqpDB()];
	await Promise.all(initializePromises);
};

const getObjectStore = dbName => async (tableName, mode) => {
	if (databases[dbName] === null) {
		await initializeIDB();
	}
	const transaction = databases[dbName].transaction([tableName], mode);
	return transaction.objectStore(tableName);
};

const saveItem = dbName => (tableName, data) =>
	new Promise((resolve, reject) => {
		try {
			if (getItem(dbName)(tableName, data.appId) !== null) {
				updateItem(dbName)(tableName, data).then(result => {
					resolve(result);
				});
			} else {
				getObjectStore(dbName)(tableName, 'readwrite').then(objectStore => {
					const request = objectStore.add(data);
					request.onsuccess = event => {
						resolve(true);
					};
					request.onerror = event => {
						resolve(false);
					};
				});
			}
		} catch (error) {
			resolve(false);
		}
	});

const updateItem = dbName => (tableName, data) =>
	new Promise((resolve, reject) => {
		try {
			getObjectStore(dbName)(tableName, 'readwrite').then(objectStore => {
				const request = objectStore.put(data);
				request.onsuccess = event => {
					resolve(true);
				};
				request.onerror = event => {
					resolve(false);
				};
			});
		} catch (error) {
			resolve(false);
		}
	});

const getItem = dbName => (tableName, id, pathToReturn = '') =>
	new Promise((resolve, reject) => {
		try {
			getObjectStore(dbName)(tableName, 'readonly').then(objectStore => {
				const request = objectStore.get(id);
				request.onsuccess = event => {
					if (pathToReturn !== '') {
						resolve(get(request, pathToReturn, null));
					}
					resolve(get(request, 'result', null));
				};
				request.onerror = event => {
					resolve(null);
				};
			});
		} catch (error) {
			resolve(null);
		}
	});

const deleteItem = dbName => (tableName, id) =>
	new Promise((resolve, reject) => {
		try {
			getObjectStore(dbName)(tableName, 'readwrite').then(objectStore => {
				const request = objectStore.delete(id);
				request.onsuccess = event => {
					resolve(true);
				};
				request.onerror = event => {
					resolve(false);
				};
			});
		} catch (error) {
			resolve(false);
		}
	});

const clearObjectStore = dbName => tableName =>
	new Promise((resolve, reject) => {
		try {
			getObjectStore(dbName)(tableName, 'readwrite').then(objectStore => {
				const request = objectStore.clear();
				request.onsuccess = event => {
					resolve(true);
				};
				request.onerror = event => {
					resolve(false);
				};
			});
		} catch (error) {
			resolve(false);
		}
	});
export const deleteIDB = () => {
	each(equipmentDatabaseKeys, val => {
		clearObjectStore(eqpDatabase)(val);
	});
};

export const equipmentDB = {
	setEquipment: async (key, appId) => await saveItem(eqpDatabase)(key, appId),
	getEquipment: async (key, appId) => await getItem(eqpDatabase)(key, appId, 'result.equipment'),
	deleteEquipment: async (key, appId) => await deleteItem(eqpDatabase)(key, appId),
};
