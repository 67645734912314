import React from 'react';
import { get, toLower } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

const isExpand = true;

class AppSummary extends React.Component {
	viewLeadDetails = async () => {
		const leadId = get(this.props.app, 'leadId', false);
		if (leadId) {
			this.props.history.push('/leads/account/' + leadId);
		}
	};

	render() {
		const { app, showLeadDetailsLink, isEappWebForm } = this.props;

		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<React.Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<div className="flex--primary">
										<h6 className="spc--right--sml spc--bottom--sml">Account Information</h6>
										{showLeadDetailsLink && (
											<button className="btn btn--sml btn--primary spc--bottom--sml" onClick={this.viewLeadDetails}>
												View Lead Details
											</button>
										)}
									</div>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row">
										<div className="col col-sml-12 col-med-6">
											{!isEappWebForm && (
												<div className="row spc--bottom--med">
													<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
														<label className="label" htmlFor="lblAppId">
															App ID{' '}
														</label>
													</div>
													<div className="col col-lrg-6 col-xlrg-7">
														<label className="label--value" name="lblAppId" id="lblAppId">
															{app.appId}
														</label>
													</div>
												</div>
											)}
											<div className="row spc--bottom--med">
												<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
													<label className="label" htmlFor="lblDBA">
														DBA/Merchant Name{' '}
													</label>
												</div>
												<div className="col col-lrg-6 col-xlrg-7">
													<label className="label--value" name="lblDBA" id="lblDBA">
														{app.dba}
													</label>
												</div>
											</div>
											{!isEappWebForm && (
												<div className="row spc--bottom--med">
													<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
														<label className="label" htmlFor="lblProcessor">
															Processor{' '}
														</label>
													</div>
													<div className="col col-lrg-6 col-xlrg-7">
														<label className="label--value" name="lblProcessor" id="lblProcessor">
															{getProcessorDisplayName(app.processorName)}
														</label>
													</div>
												</div>
											)}
											{!isEappWebForm && (
												<React.Fragment>
													<div className="row spc--bottom--med">
														<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
															<label className="label" htmlFor="lblOwner">
																Owner Name{' '}
															</label>
														</div>
														<div className="col col-lrg-6 col-xlrg-7">
															<label className="label--value" name="lblOwner" id="lblOwner">
																{app.ownerName}
															</label>
														</div>
													</div>
												</React.Fragment>
											)}
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="row spc--bottom--med">
												<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
													<label className="label" htmlFor="lblContact">
														Main Contact{' '}
													</label>
												</div>
												<div className="col col-lrg-6 col-xlrg-7">
													<label className="label--value" name="lblContact" id="lblContact">
														{app.businessContactName}
													</label>
												</div>
											</div>
											<div className="row spc--bottom--med">
												<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
													<label className="label" htmlFor="lblAddress">
														Address{' '}
													</label>
												</div>
												<div className="col col-lrg-6 col-xlrg-7">
													<label className="label--value" name="lblAddress" id="lblAddress">
														{app.businessInformation.businessAddress.streetAddress}
													</label>
												</div>
											</div>
											<div className="row spc--bottom--med">
												<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
													<label className="label" htmlFor="lblPhone">
														Phone{' '}
													</label>
												</div>
												<div className="col col-lrg-6 col-xlrg-7">
													<label className="label--value" name="lblPhone" id="lblPhone">
														{app.businessInformation.businessPhone}
													</label>
												</div>
											</div>
											<div className="row spc--bottom--med">
												<div className="col col-sml-6 col-lrg-6 col-xlrg-5">
													<label className="label" htmlFor="lblEmail">
														Email{' '}
													</label>
												</div>
												<div className="col col-lrg-6 col-xlrg-7">
													<label className="label--value type--break--word" name="lblEmail" id="lblEmail">
														{app.businessInformation.businessEmail}
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

export default withRouter(AppSummary);
