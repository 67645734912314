import { _sharedFunctions } from './_sharedFunctions';
import { mapValues } from 'lodash';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _residualsFunctions = {
	getAgentResidualsPermissions,
	getAssociatedAgentsResidualsData,
	getResidualDataForAMonth,
	getLastAvailableMonth,
};

function getAgentResidualsPermissions() {
	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: null, action: '/r/GetAgentRPermissions' })
		.then(handleResponse);
}

function getAssociatedAgentsResidualsData(filter) {
	const requestBody = JSON.stringify({
		Year: filter.year,
		Month: filter.month,
	});

	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/r/GetAssociatedAgentsRData' })
		.then(handleResponse)
		.then(res => {
			res.agentsData = mapValues(res.agentsData, data => JSON.parse(data));
			return res;
		});
}

function getResidualDataForAMonth(filter) {
	const requestBody = JSON.stringify({
		Year: filter.year,
		Month: filter.month,
		SharepointId: filter.agentId,
		AgentNumber: filter.agentNumber,
	});

	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: `/r/GetRDataForMonth` })
		.then(handleResponse)
		.then(res => {
			res.data = JSON.parse(res.data);
			return res;
		});
}

function getLastAvailableMonth(sharepointId) {
	const requestBody = JSON.stringify({
		SharepointId: sharepointId,
	});
	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/r/GetLastAvailableMonth' })
		.then(handleResponse);
}
