import React from 'react';
import { toString, isString, map } from 'lodash';

import { HttpServiceError } from '../../services';

export const UNEXPECTED_ERROR_MSG = 'An unexpected error has occured, please contact support.';
export const SERVER_COMM_ERROR_MSG = 'A server communication error has occured, please contact support.';

class DisplayError extends React.Component {
	constructor(props) {
		super(props);
		this.state = { errorObjects: null };
	}

	async componentWillMount() {
		const { errors } = this.props;
		const errorObjects = await this.generateErrorMessages(errors);
		this.setState({ errorObjects });
	}

	async generateErrorMessages(errors) {
		const errorObjects = [];
		for (let e of errors) {
			let errorObj = null;
			if (e instanceof PromiseRejectionEvent) {
				console.log('log PromiseRejectionEvent', e);
				if (e.reason instanceof HttpServiceError) {
					const { ex, request, response } = e.reason;
					const { message: errorMessage, stack: stackTrace } = ex;

					let requestInfo, responseInfo;

					if (request) {
						const { url, method } = request;

						requestInfo = {
							url: this.convertToText(url),
							method: this.convertToText(method),
						};
					}

					if (response) {
						const { status: statusCode, statusText } = response;
						const status = `${statusText} (${statusCode})`;

						let responseException, exceptionType, exceptionMessage, stackTrace;

						const contentType = response.headers.get('Content-Type');

						if (contentType) {
							if (contentType.indexOf('application/json') !== -1) {
								let jsonBody;
								try {
									jsonBody = await response.json();
								} catch (e) {
									return { status };
								}

								({ exceptionType, exceptionMessage, stackTrace } = jsonBody);

								if (!!exceptionType || !!exceptionMessage || !!stackTrace) {
									responseException = `${exceptionType}\n${exceptionMessage}\n${stackTrace}`;
								}
							} else {
								try {
									responseException = await response.text();
								} catch (e) {}
							}
						}

						responseInfo = {
							status,
							exception: this.convertToText(responseException),
						};
					}

					errorObj = {
						message: SERVER_COMM_ERROR_MSG,
						errorMessage: this.convertToText(errorMessage),
						stackTrace: this.convertToText(stackTrace),
						requestInfo,
						responseInfo,
					};
				} else {
					const { message: errorMessage, stack: stackTrace } = e.reason;

					errorObj = {
						message: UNEXPECTED_ERROR_MSG,
						errorMessage,
						stackTrace,
					};
				}
			} else if (e instanceof ErrorEvent) {
				console.log('log ErrorEvent', e);
				let errorMessage, stackTrace;
				if (e && e.error && e.error !== null) {
					errorMessage = e.error.message;
					stackTrace = e.error.stack;
				} else {
					errorMessage = e.message;
					stackTrace = e;
				}

				errorObj = {
					message: UNEXPECTED_ERROR_MSG,
					errorMessage: this.convertToText(errorMessage),
					stackTrace: this.convertToText(stackTrace),
				};
			} else {
				const { message: errorMessage } = e;
				errorObj = {
					message: UNEXPECTED_ERROR_MSG,
					errorMessage: this.convertToText(errorMessage),
				};
			}

			errorObjects.push(errorObj);
		}

		return errorObjects;
	}

	convertToText(content) {
		if (isString(content)) return content;
		try {
			return JSON.stringify(content);
		} catch (e) {
			return toString(content);
		}
	}

	render() {
		const { errorObjects } = this.state;
		return (
			<div>
				<section>
					{map(errorObjects, (errorObj, ix) => (
						<div key={ix}>
							<h2 className="spc--top--med spc--right--med spc--bottom--med spc--left--med">{errorObj.message}</h2>
							{errorObj.errorMessage && <div>{errorObj.errorMessage}</div>}
							{errorObj.stackTrace && (
								<div>
									<code>{errorObj.stackTrace}</code>
								</div>
							)}
							{errorObj.requestInfo && (
								<div>
									<h3>Request</h3>
									{errorObj.requestInfo.method && (
										<div>
											<code>Method: {errorObj.requestInfo.method}</code>
										</div>
									)}
									{errorObj.requestInfo.url && (
										<div>
											<code>URL: {errorObj.requestInfo.url}</code>
										</div>
									)}
								</div>
							)}
							{errorObj.responseInfo && (
								<div>
									<h3>Response</h3>
									{errorObj.responseInfo.status && (
										<div>
											<code>Status: {errorObj.responseInfo.status}</code>
										</div>
									)}
									{errorObj.responseInfo.exception && (
										<div>
											<code>{errorObj.responseInfo.exception}</code>
										</div>
									)}
								</div>
							)}
						</div>
					))}
				</section>
			</div>
		);
	}
}

export default DisplayError;
