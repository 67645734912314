import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { identity } from 'lodash';

import { itemTypes } from './itemTypes';

const withListItem = (
	WrappedComponent,
	{
		dragStyle,
		useDefaultDrop = true,
		useDefaultDrag = true,
		hideWhileDragging = true,
		dragOverride,
		dragType,
		dropType,
	} = {}
) => {
	function WrappedDraggableItem(props) {
		const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag(
			() => ({
				type: dragType || itemTypes.LIST_ITEM,
				collect: monitor => ({
					isDragging: monitor.isDragging(),
				}),
				item: {
					id: props.id,
				},
				canDrag: ({ disable }) => !disable,
			}),
			[]
		);
		function callCallbackMethod(monitor, callback) {
			if (!callback) return;
			const { id } = monitor.getItem();
			if (props.id === id) return;
			callback(id, props.id);
		}
		const [, connectDropTarget] = useDrop(
			() => ({
				accept: dropType || itemTypes.LIST_ITEM,
				hover: (_, monitor) => callCallbackMethod(monitor, props.onHover),
				drop: (_, monitor) => callCallbackMethod(monitor, props.onDrop),
			}),
			[]
		);
		connectDragPreview(getEmptyImage(), {
			captureDraggingState: true,
		});
		const { disable, children, className, ...rest } = props;
		const isDraggingOverride = isDragging || (dragOverride && dragOverride(props));

		return (useDefaultDrag ? connectDragSource : identity)(
			(useDefaultDrop ? connectDropTarget : identity)(
				<div
					className={`${className || ''}`}
					style={
						isDraggingOverride
							? {
									...dragStyle,
									transition: 'all 0.01s',
							  }
							: { height: '100%', transition: 'all 0.01s' }
					}
				>
					<div style={{ opacity: isDraggingOverride && hideWhileDragging ? 0 : 1 }}>
						<WrappedComponent
							isDragging={isDraggingOverride}
							isDisabled={disable}
							connectDragSource={useDefaultDrag ? undefined : connectDragSource}
							connectDropTarget={useDefaultDrop ? undefined : connectDropTarget}
							{...rest}
						/>
					</div>
				</div>
			)
		);
	}

	WrappedDraggableItem.propTypes = {
		connectDragPreview: PropTypes.func,
		isDragging: PropTypes.bool,
		connectDragSource: PropTypes.func,
		connectDropTarget: PropTypes.func,
		disable: PropTypes.bool,
		children: PropTypes.any,
		className: PropTypes.string,
	};

	return WrappedDraggableItem;
};

export default withListItem;
