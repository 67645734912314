import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { useState } from 'react';

const RenderSanitizedHTML = ({ content, title }) => {
	const [iframeBody, setIframeBody] = useState(null);
	const sanitizedContent = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></div>;

	const handleLoad = event => {
		const iframe = event.target;
		if (iframe?.contentDocument) {
			setIframeBody(iframe.contentDocument.body);
		}
	};

	return (
		<iframe style={{ border: 'none', width: '100%' }} srcDoc={'<!DOCTYPE html>'} title={title} onLoad={handleLoad}>
			{iframeBody && createPortal(sanitizedContent, iframeBody)}
		</iframe>
	);
};

RenderSanitizedHTML.propTypes = {
	content: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
};

export default RenderSanitizedHTML;
