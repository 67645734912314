import { _sharedFunctions } from './_sharedFunctions';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _mfaStepUpFunctions = {
	respondToChallenge,
};

function respondToChallenge(mfaType, challenge, origin) {
	const requestBody = JSON.stringify({ stepUpType: mfaType, challengeResponseCode: challenge, stepUpOrigin: origin });
	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/mfaStepUp/RespondToChallenge' })
		.then(handleResponse);
}
