import React from 'react';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

class ValidationError extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { errorListPaths, errorList } = this.props;
		return (
			<React.Fragment>
				{errorListPaths.length ? (
					<div className="spc--bottom--med note note--warning">
						<ul>
							{errorList.map((elem, i) => {
								return (
									<li key={i}>
										<div
											className="anchor"
											onClick={() => {
												let elemId = elem.path;
												this.props.scrollTo(elemId);
												this.props.focusField(elemId);
											}}
										>
											<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default ValidationError;
