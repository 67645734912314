import React, { Component, Fragment, createRef } from 'react';
import { map, get, set, clone, isEmpty, toLower, noop } from 'lodash';
import { object, func, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { appService } from '../../services';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withLoader } from '../../common/components';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { CheckboxContainer } from '../../common/components/checkbox-container';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import AmexRates from './amex-rates';
import { isGoPlus } from '../../common/utilities/processorType';

const isExpand = true;

class RatesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = clone(this.initialState);

		this.errorRef = createRef();
	}

	get initialState() {
		return {
			errorMessage: null,
			appId: this.props.match.params.appid,
			setup: {},
		};
	}

	componentDidMount() {
		this.loadDataFromService();
	}

	loadDataFromService = () => {
		this.props.showLoader(true);
		appService
			.getEApp(this.state.appId)
			.then(setup => {
				if (isGoPlus(setup)) {
					appService.getGoPlus(this.state.appId).then(goPlus => {
						const { goPlusEApp } = goPlus;
						set(setup, 'achProcessingInformation', goPlusEApp.achProcessingInformation);
						this.setState({ setup });
						this.props.showLoader(false);
					});
				} else {
					this.setState({ setup });
					this.props.showLoader(false);
				}
			})
			.catch(error => {
				this.props.showLoader(false);
				this.setState({
					errorMessage: 'An error occurred: ' + error,
					isNewError: true,
				});
			});
	};

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	renderFakeInput = (
		{ name, value, labelPrefix = '', shouldRenderInput = () => true, className = 'col col-sml-12 col-med-6' },
		index
	) =>
		shouldRenderInput() && (
			<div key={`${name}.${value}`} className={className}>
				<div key={`${name}.${index}`} className="form__field">
					<label className="label">
						{labelPrefix ? `${labelPrefix} ` : ''}
						{name}{' '}
					</label>
					<div className="input input--med input--fake">{value}</div>
				</div>
			</div>
		);

	renderRateStructure = (rateObjectName, labelPrefix, displayCustom = false) => {
		const { setup } = this.state;
		const namePrefix = rateObjectName + '_';
		let rateObj = setup[rateObjectName];
		const divClass = displayCustom ? 'row' : '';
		const wrapperClassName = `form__field ${displayCustom ? 'col col-sml-6' : ''}`;

		return (
			<DropdownContainer
				hideSelect={true}
				labelText={labelPrefix + ' Rate Structure'}
				labelClass="label"
				divClass={divClass}
				wrapperClass={wrapperClassName}
				selectClass="input input--med input--select"
				selectName={namePrefix + 'pricingStructureType'}
				value={rateObj.pricingStructureType}
				onChange={() => {}}
				options={[
					{
						value: 'PassThrough',
						text: 'Pass Through',
						children: map(
							[
								{
									name: 'Pass Through',
									value: rateObj.passThroughPercentage,
									className: 'col col-sml-6',
									labelPrefix,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'FlatRate',
						text: 'Flat Rate',
						children: map(
							[
								{
									name: 'Flat Rate',
									value: rateObj.flatRatePercentage,
									labelPrefix,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'Tiered',
						text: 'Tiered',
						children: (
							<div className="col col-sml-12">
								<div className="row">
									{map(
										[
											{
												name: 'Qual',
												value: rateObj.tieredRatePercentages.qualified,
												className: 'col col-sml-12 col-med-3',
											},
											{
												name: 'Mid-Qual',
												value: rateObj.tieredRatePercentages.midQualified,
												className: 'col col-sml-12 col-med-3',
											},
											{
												name: 'Non-Qual',
												value: rateObj.tieredRatePercentages.nonQualified,
												className: 'col col-sml-12 col-med-3',
											},
											{
												name: 'Rewards',
												value: rateObj.tieredRatePercentages.rewards,
												shouldRenderInput: () => rateObjectName.indexOf('amex') !== 0,
												className: 'col col-sml-12 col-med-3',
											},
										],
										item => this.renderFakeInput(item)
									)}
								</div>
							</div>
						),
					},
					{
						value: 'BillBack',
						text: 'Bill Back',
						children: map(
							[
								{
									name: 'Base Rate',
									value: rateObj.billBackRatePercentages.base,
									labelPrefix,
								},
								{
									name: 'Enhanced Rate',
									value: rateObj.billBackRatePercentages.enhanced,
									labelPrefix,
									shouldRenderInput: () => namePrefix.indexOf('amex') !== 0,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
				]}
			/>
		);
	};

	renderRatesForm = () => {
		const { setup } = this.state;

		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(setup) && (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Rates Form</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="card--primary__subheader">
										<strong>Pricing Structure</strong>
									</div>
									<div className="row">
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Bank</label>
												<div className="input input--med input--fake">{setup.processorName}</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col col-sml-12 col-lrg-6 form__field">
											{this.renderRateStructure('pricingStructure', '')}
										</div>
									</div>
									<div className="separator separator--grey1 spc--bottom--med"></div>
									{setup.pricingStructure.otherVolumePercent && (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__field spc--bottom--med">
													<label className="label">Other Volume Percent Type</label>
													<div className="input input--med input--fake">
														{setup.pricingStructure.otherVolumePercentType}
													</div>
												</div>
											</div>
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__field spc--bottom--med">
													<label className="label">Other Volume Percent</label>
													<div className="input input--med input--fake">
														{setup.pricingStructure.otherVolumePercent}
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="row">
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Authorization Fee</label>
												<div className="input input--med input--fake input--currency input--currency--sml">
													{setup.pricingStructure.authorizationFee}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-lrg-6">
											<div className="form__field spc--bottom--med">
												<label className="label">V/MC/Disc Transaction Fee:</label>
												<div className="input input--med input--fake input--currency input--currency--sml">
													{setup.pricingStructure.bankCardTransactionFee}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 spc--bottom--med">
											<CheckboxContainer
												labelText="Setup merchant with Pin Debit "
												checkboxClass="input--check input--check--preview"
												checkboxName="pricingStructure_shouldSetupPinDebit"
												checked={setup.pricingStructure.shouldSetupPinDebit}
												onCheckChanged={this.handleChange}
											>
												<div className="row spc--top--sml grid-align-middle spc--bottom--med">
													{this.renderFakeInput({
														name: 'Proposed Structure',
														value: setup.pricingStructure.pinDebitStructureType,
														className: 'col col-sml-12 col-med-4',
													})}
													{this.renderFakeInput({
														name: 'PIN Based Debit Trans Fee',
														value: setup.pricingStructure.pinDebitTransactionFee,
														className: 'col col-sml-12 col-med-4',
													})}
													{this.renderFakeInput({
														name: 'PIN Based Debit Markup',
														value: setup.pricingStructure.pinDebitMarkupAmount,
														className: 'col col-sml-12 col-med-4',
													})}
												</div>
											</CheckboxContainer>
										</div>
									</div>
									{get(setup, 'achProcessingInformation.achEnabled') && (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__field spc--bottom--med">
													<label className="label">ACH Transaction Fee</label>
													<div className="input input--med input--fake">
														{setup.achProcessingInformation.achTransactionFee}
													</div>
												</div>
											</div>
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__field spc--bottom--med">
													<label className="label">ACH Percent Fee</label>
													<div className="input input--med input--fake">
														{setup.achProcessingInformation.achPercentFee}
													</div>
												</div>
											</div>
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__field spc--bottom--med">
													<label className="label">ACH Return Fee</label>
													<div className="input input--med input--fake">
														{setup.achProcessingInformation.achReturnFee}
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="row">
										{!setup ||
											!setup.processorName ||
											(toLower(setup.processorName).indexOf('firstdata') === 0 && (
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--sml">
														<label className="label">Discount Method</label>
													</div>
													<div className="form__field spc--bottom--med">
														<input
															name="discountMethod"
															id="discountMethod"
															type="checkbox"
															className="input--radio input--radio--preview"
															checked={setup.pricingStructure.discountMethod}
															value={setup.pricingStructure.discountMethod}
															onChange={noop}
														/>
														<label htmlFor="discountMethod">{setup.pricingStructure.discountMethod}</label>
													</div>
												</div>
											))}
										{setup.pricingStructure &&
											setup.pricingStructure.bankName &&
											toLower(setup.processorName) === 'elavon' && (
												<Fragment>
													<div className="row">
														<div className="col col-sml-12 col-med-6">
															<input
																className="input--check input--check--preview"
																type="checkbox"
																id="pricingStructure_shouldRequireNextDayFunding"
																name="pricingStructure_shouldRequireNextDayFunding"
																value={setup.pricingStructure.shouldRequireNextDayFunding}
																onChange={noop}
															/>
															<label htmlFor="pricingStructure_shouldRequireNextDayFunding" className="label">
																Next Day Funding Required ($5 monthly fee applies)
															</label>
														</div>
													</div>
													<div className="separator separator--grey1 spc--bottom--med"></div>
												</Fragment>
											)}
									</div>

									{!isGoPlus(setup) && (
										<Fragment>
											<div className="card--primary__subheader">
												<strong>Proposed Fees</strong>
											</div>
											<div className="row">
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Batch Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.batchFee}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Retrival Charge</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.retrievalCharge}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Statement Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.statementFee}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Monthly Minimum Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.monthlyMinimumFee}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Annual Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.annualFee}
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Chargeback Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.chargebackFee}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6">
													<div className="form__field spc--bottom--med">
														<label className="label">PCI Annual Fee</label>
														<div className="input input--med input--fake input--currency input--currency--sml">
															{setup.proposedFees.pciAnnualFee}
														</div>
													</div>
												</div>
												{setup.doesAcceptEbt ? (
													<div className="col col-sml-12 col-lrg-6">
														<div className="form__field spc--bottom--med">
															<label className="label">EBT Transaction Fee</label>
															<div className="input input--med input--fake input--currency input--currency--sml">
																{setup.proposedFees.ebtTransactionFee}
															</div>
														</div>
													</div>
												) : null}
											</div>
											<div className="card--primary__subheader">
												<strong>Early Termination Fee (ETF)</strong>
											</div>
											<div className="row">
												<div className="col col-sml-12 col-med-6 spc--bottom--sml">
													<CheckboxTxtInput
														disabled={true}
														labelText="Not Waived Fee"
														checkboxClass="input--check input--check--preview"
														checkboxName="proposedFees_isEarlyTerminationFeeWaived"
														inputName="proposedFees_isEarlyTerminationFee"
														inputPlaceholder=""
														checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
														value={String(setup.proposedFees.earlyTerminationFee)}
														onCheckChanged={this.handleChange}
														onValueChanged={this.handleChange}
														format="NumberFormat"
													/>
												</div>
												{this.renderFakeInput({
													name: 'Waived',
													value: setup.proposedFees.isEarlyTerminationFeeWaived,
												})}
											</div>
										</Fragment>
									)}
									<div className="row">
										{!isEmpty(setup) && (
											<AmexRates amexDetails={setup.amexDetails} renderRateStructure={this.renderRateStructure} />
										)}
									</div>
								</div>
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	render() {
		const { appId, errorMessage, setup } = this.state;
		const merchantDba = get(setup, 'dba', '');

		return (
			<div id="main-div" className="l--content l--content--lrg">
				{errorMessage ? (
					<div className="note note--warning" ref={this.errorRef}>
						{errorMessage}
					</div>
				) : null}
				<div className="l--content--med">
					<ToggleContainer>
						<div className="header header--mpa">
							<div className="header__title spc--right--auto">
								{merchantDba && appId && <div className="spc--bottom--sml">{`${merchantDba} - ${appId}`}</div>}
								<h3>Rates</h3>
							</div>
						</div>
						{this.renderRatesForm()}
					</ToggleContainer>
				</div>
			</div>
		);
	}
}

RatesComponent.propTypes = {
	match: object,
	history: object,
	showLoader: func,
	isLoading: bool,
};

export default withLoader(withRouter(RatesComponent));
