import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import { Row } from '../react-data-grid';

class WhiteRendererComponent extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		const { propsUpdateKeys, stateUpdateKeys } = nextProps;
		const propsUpdated = this.isAnyUpdated(propsUpdateKeys, this.props, nextProps);
		const stateUpdated = this.isAnyUpdated(stateUpdateKeys, this.state, nextState);
		return propsUpdated || stateUpdated;
	}

	isAnyUpdated = (keys, current, next) => some(keys, key => current[key] !== next[key]);

	render() {
		const { row } = this.props;

		return (
			<div className={`grid-style-odd${row.cardType === 'Grand Total' ? ' type--wgt--medium' : ''}`}>
				<Row {...this.props} />
			</div>
		);
	}
}

WhiteRendererComponent.defaultProps = {
	propsUpdateKeys: ['row', 'columns', 'isScrolling'],
	stateUpdateKeys: [],
};

WhiteRendererComponent.propTypes = {
	row: PropTypes.object,
	columns: PropTypes.array,
	propsUpdateKeys: PropTypes.arrayOf(PropTypes.string),
	stateUpdateKeys: PropTypes.arrayOf(PropTypes.string),
};

export default WhiteRendererComponent;
