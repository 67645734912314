//import config from 'config';
import { _sharedFunctions } from './_sharedFunctions';
import { departmentOptions } from '../common/utilities';
import { find } from 'lodash';
import { authHeader } from '../helpers/auth-header';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _ticketFunctions = {
	getTickets,
	getTicketDetails,
	addNoteToTicket,
	createNewTicket,
};

function getTickets(ticketFilter, rowsPerPage, activePage) {
	const pageNumber = activePage || 1;
	const pageSize = rowsPerPage || 20;
	const requestBody = JSON.stringify({
		TicketFilter: ticketFilter,
		PageSize: pageSize,
		PageNumber: pageNumber,
	});
	/*return {
        refNum: 9999,
        status: 'S',
        tickets: [{
            ticketId: 1,
            appId: 1234,
            dba: 'Test DBA',
            mid: 123123123,
            openDate: new Date(2020, 1, 15).toString(),
            modificationDate: new Date().toString(),
            status: 'New',
            subject: 'This is a test ticket response',
            issue: 'Check Services',
            assignedTo: 'Billing Department',
            submittedBy: 'Test Agent',
            followupDate: null,
            callerName: '',
            callerPhone: ''
        },
            {
                ticketId: 2,
                appId: 1234,
                dba: 'Test DBA',
                mid: 123123123,
                openDate: new Date(2020, 1, 15).toString(),
                modificationDate: new Date().toString(),
                status: 'New',
                subject: 'This is a test ticket response',
                issue: 'Check Services',
                assignedTo: 'Billing Department',
                submittedBy: 'Test Agent',
                followupDate: null,
                callerName: '',
                callerPhone: ''
            },
            {
                ticketId: 3,
                appId: 1234,
                dba: 'Test DBA',
                mid: 123123123,
                openDate: new Date(2020, 1, 15).toString(),
                modificationDate: new Date().toString(),
                status: 'New',
                subject: 'This is a test ticket response',
                issue: 'Check Services',
                assignedTo: 'Billing Department',
                submittedBy: 'Test Agent',
                followupDate: null,
                callerName: '',
                callerPhone: ''
            },
            {
                ticketId: 4,
                appId: 1234,
                dba: 'Test DBA',
                mid: 123123123,
                openDate: new Date(2020, 1, 15).toString(),
                modificationDate: new Date().toString(),
                status: 'New',
                subject: 'This is a test ticket response',
                issue: 'Check Services',
                assignedTo: 'Billing Department',
                submittedBy: 'Test Agent',
                followupDate: null,
                callerName: '',
                callerPhone: ''
            },
            {
                ticketId: 5,
                appId: 1234,
                dba: 'Test DBA',
                mid: 123123123,
                openDate: new Date(2020, 1, 15).toString(),
                modificationDate: new Date().toString(),
                status: 'New',
                subject: 'This is a test ticket response',
                issue: 'Check Services',
                assignedTo: 'Billing Department',
                submittedBy: 'Test Agent',
                followupDate: null,
                callerName: '',
                callerPhone: ''
            },
            {
                ticketId: 6,
                appId: 2222,
                dba: 'Test Another DBA',
                mid: 56785678567,
                openDate: new Date(2020, 9, 10).toString(),
                modificationDate: new Date().toString(),
                status: 'ClosedResolved',
                subject: 'Here is another ticket',
                issue: 'Update Equipment',
                assignedTo: 'Deployments',
                submittedBy: 'Test Agent',
                followupDate: null,
                callerName: '',
                callerPhone: ''
            },

        ]
    }*/
	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetTickets' })
		.then(handleResponse);
}

function getTicketDetails(ticketId) {
	const requestBody = JSON.stringify({ ticketNumber: ticketId });

	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/ticket/GetTicketDetails' })
		.then(handleResponse);
}

function addNoteToTicket(TicketNumber, Notes) {
	const requestBody = JSON.stringify({ TicketNumber, Notes });

	return _sharedFunctions
		.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/ticket/AddTicketNotes' })
		.then(handleResponse);
}

function createNewTicket({ appId, subject, issue, toEmail, fileAttachment }) {
	const data = {
		appId: appId,
		subject,
		details: issue,
		assignedToDepartmentEmail: find(departmentOptions, { label: toEmail }).value,
		attachment: fileAttachment,
		ticketIssue: 92, //Agent Inquire
		ticketSubIssue: 0,
	};
	let formData = _sharedFunctions.objectToFormData(data);

	return _sharedFunctions
		.callApi({ headerMethod: authHeader, body: formData, action: '/ticket/CreateNewTicket' })
		.then(handleResponse);
}
