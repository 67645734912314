import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PhoneNumberSelectionComponent extends Component {
	get phoneNumberValue() {
		return this.props.filter.values.phoneNumber;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'phoneNumber',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Phone Number: </label>
				{this.phoneNumberValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

PhoneNumberSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default PhoneNumberSelectionComponent;
