import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CustomerNameFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.setCardholderNameRef = React.createRef();
	}

	setCardholderNameRef = el => {
		this.cardholderName = el;
	};

	filterChange = e => {
		const value = e.target.value ? e.target.value : null;

		this.onFilterChange(value);
	};

	onFilterChange(value) {
		this.props.onFilterChanged({
			id: 'cardholderName',
			values: [{ key: 'cardholderName', value: value }],
			emptyValue: null,
		});
	}

	componentDidUpdate = () => {
		if (this.setCardholderNameRef.current) {
			this.setCardholderNameRef.current.focus();
		}
	};

	render() {
		const cardholderName = this.props.filter.values.cardholderName;

		return (
			<div>
				<input
					type="text"
					placeholder={this.props.placeholder}
					className="input input--number input--med"
					value={cardholderName === null ? '' : cardholderName}
					id="cardholderName"
					onChange={this.filterChange}
					ref={this.setCardholderNameRef}
					autoFocus={true}
				/>
			</div>
		);
	}
}

CustomerNameFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
};

CustomerNameFilterComponent.defaultProps = {
	placeholder: 'Cardholder name',
};

export default CustomerNameFilterComponent;
