import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import { modalNames } from '../../../common/components/modal-wrapper';
import { appService, principalService } from '../../../services';
import { BaseGridActionsComponent } from '../../../common/formatters';

class LeadGridActionsComponent extends BaseGridActionsComponent {
	static instances = [];
	constructor(props) {
		super(props, LeadGridActionsComponent.instances);
		this.shouldRenderActions = toLower(this.props.dependentValues.leadStatus) !== 'converted';
		this.menuItems = [
			{
				isVisible: () => !this.isEappStarted && !this.isEappSubmitted,
				title: 'Submit to Pricing Analysis',
				onClickHandler: () => this.submitToReviewPricing(true),
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.displayNewGoPlusEApp,
				title: 'New Cardknox Go eApp',
				onClickHandler: () => this.createNewEapp('cardknox-go'),
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.displayNewAchqEApp,
				title: 'New ACHQ eApp',
				onClickHandler: () => this.createNewEapp('achq'),
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.displayNewProfitStarsEApp,
				title: 'New Profit Stars eApp',
				onClickHandler: () => this.createNewEapp('ach'),
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.displayNewRegularEappOption,
				title: () => `New${this.canBoardGoPlus ? ' regular' : ''} eApp`,
				onClickHandler: () => this.createNewEapp('mpa'),
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.isEappStarted,
				title: 'View Lead',
				onClickHandler: this.viewLead,
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => !this.props.dependentValues.isHidden,
				title: 'Archive Lead',
				onClickHandler: this.archiveLead,
				iconClassName: '',
				href: '',
			},
			{
				isVisible: () => this.props.dependentValues.isHidden,
				title: 'Unarchive Lead',
				onClickHandler: this.unarchiveLead,
				iconClassName: '',
				href: '',
			},
			{
				isVisible: true,
				title: 'Clone Lead',
				onClickHandler: this.cloneLead,
				iconClassName: '',
				href: '',
			},
		];
	}

	get isEappStarted() {
		const { leadStatus } = this.props.dependentValues;
		return (
			toLower(leadStatus) === 'eapp started' ||
			toLower(leadStatus) === 'pend underwriting' ||
			toLower(leadStatus) === 'pend bank approval'
		);
	}

	get isEappSubmitted() {
		const { leadStatus } = this.props.dependentValues;
		return toLower(leadStatus) === 'eapp submitted';
	}

	get displayNewEappOption() {
		const { leadStatus } = this.props.dependentValues;
		return toLower(leadStatus) !== 'converted' && !this.isEappStarted && !this.isEappSubmitted && !this.isCanadaLead;
	}

	get displayNewRegularEappOption() {
		const { processor } = this.props.dependentValues;
		return this.displayNewEappOption && !processor;
	}

	get displayNewGoPlusEApp() {
		const { processor } = this.props.dependentValues;
		return this.displayNewEappOption && this.canBoardGoPlus && processor === 'CK-Go';
	}

	get displayNewAchqEApp() {
		const { processor } = this.props.dependentValues;
		return this.displayNewEappOption && this.canBoardAchq && processor === 'ACHQ';
	}

	get displayNewProfitStarsEApp() {
		const { processor } = this.props.dependentValues;
		return this.displayNewEappOption && processor === 'ProfitStars Ach';
	}

	get canBoardGoPlus() {
		const { agentSugarId } = this.props.dependentValues;
		const { agentGoPlusLookup } = principalService.get();
		return agentSugarId && agentGoPlusLookup[agentSugarId] && agentGoPlusLookup[agentSugarId].canBoardGoPlus;
	}

	submitToReviewPricing = () => {
		const {
			dependentValues: { openCloseModal, dba, leadId },
		} = this.props;
		this.closeDisplayMenu();
		openCloseModal({
			name: modalNames.reviewPricing,
			data: {
				merchantInfo: {
					leadId,
					dba,
				},
			},
		});
	};

	createNewEapp = redirectTo => {
		const {
			dependentValues: { leadId, showLoader },
		} = this.props;
		const { history } = this.props;
		showLoader(true);
		this.closeDisplayMenu();

		appService
			.convertLeadToApp(leadId)
			.then(resp => {
				showLoader(false);
				history.push(`/eapp/${redirectTo}/${resp.newAppId}`);
			})
			.catch(err => {
				console.log('convert error', err);
				showLoader(false);
				this.setState({ successMessage: '', errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	viewLead = () => {
		const {
			dependentValues: { leadId },
			history,
		} = this.props;
		this.closeDisplayMenu();
		history.push('/leads/account/' + leadId);
	};

	archiveLead = async () => {
		const {
			dependentValues: { leadId, showLoader, refreshGridData },
		} = this.props;
		try {
			showLoader(true);
			this.closeDisplayMenu();
			await appService.archiveLead(leadId);
			refreshGridData();
		} catch (error) {
			console.log('archive lead error', error);
			this.setState({ successMessage: '', errorMessage: 'An error occurred: ' + error, isNewError: true });
		} finally {
			showLoader(false);
		}
	};

	unarchiveLead = async () => {
		const {
			dependentValues: { leadId, showLoader, refreshGridData },
		} = this.props;
		try {
			showLoader(true);
			this.closeDisplayMenu();
			await appService.unarchiveLead(leadId);
			refreshGridData();
		} catch (error) {
			console.log('archive lead error', error);
			this.setState({ successMessage: '', errorMessage: 'An error occurred: ' + error, isNewError: true });
		} finally {
			showLoader(false);
		}
	};

	cloneLead = () => {
		const {
			dependentValues: { openCloseModal },
			row,
		} = this.props;
		this.closeDisplayMenu();
		openCloseModal({
			name: modalNames.cloneEApp,
			data: {
				data: row,
				appType: 'Lead',
			},
		});
	};

	closeDisplayMenu = () => {
		setTimeout(this.closeDisplayMenuNow, 70);
	};

	closeDisplayMenuNow = () => {
		this.setState({ isDisplayMenuOpen: false }, this.displayMenu);
	};
}

LeadGridActionsComponent.propTypes = {
	dependentValues: PropTypes.object,
	isScrolling: PropTypes.bool,
	history: PropTypes.object.isRequired,
};

export default withRouter(LeadGridActionsComponent);
