import { filter, includes, some } from 'lodash';

function categorizeEquipment(equipmentList) {
	const gatewayList = filter(equipmentList, { category: 'Gateway' });
	const softwareList = filter(equipmentList, { category: 'Software' });
	const posList = filter(equipmentList, { category: 'PosSystems' });
	const hardwareList = filter(
		equipmentList,
		e =>
			!includes(['Gateway', 'Software', 'Var', 'PosSystems'], e.category) &&
			some(e.purchasePlans, plan => some(plan.fees, fee => fee.feeType === 'OneTimeFee'))
	);
	return { gatewayList, softwareList, hardwareList, posList };
}

export default categorizeEquipment;
