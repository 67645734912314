import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class DBALinkComponent extends Component {
	render() {
		return (
			<span>
				<Link
					to={{
						pathname: '/leads/account/' + this.props.dependentValues.leadId,
					}}
					className="lead-to-details-link"
					href="javascript:void(0)"
					title="View lead details"
				>
					{this.props.value}
				</Link>
			</span>
		);
	}
}

DBALinkComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.shape({
		leadId: PropTypes.string,
	}),
};

export default DBALinkComponent;
