import { map } from 'lodash';

import {
	StringFilterComponent as StringFilter,
	MultiselectCardknoxFilterComponent as MultiselectCardknoxFilter,
} from '../../../common/column-filters';
import { TicketStatusComponent } from '../formatters';
import { PhoneNumberComponent, ExpandableComponent } from '../../../common/formatters';

export const TicketColumns = map(
	[
		{
			key: 'ticketNumber',
			name: 'Ticket #', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
			sortable: true,
			hideable: false,
			visible: true,
			filterable: true,
			formatter: ExpandableComponent,
			filterRenderer: StringFilter,
			getRowMetaData: ({ onInfoHover }) => ({ onInfoHover, className: 'anchor anchor--primary' }),
			isDefaultSorter: true,
			isAdvancedField: false,
			defaultSortDirection: 'DESC',
			initWidth: 200,
			resizable: true,
		},
		{
			key: 'dba',
			name: 'DBA',
			sortable: true,
			hideable: true,
			visible: true,
			// formatter: DBALinkComponent,
			getRowMetaData: row => row,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 200,
			resizable: true,
		},
		{
			key: 'mid',
			name: 'MID',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'dateOpenedDisplay',
			name: 'Date Opened',
			sortable: true,
			hideable: true,
			visible: true,
			// formatter: DisplayDateComponent,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'modifiedDisplay',
			name: 'Modified',
			sortable: true,
			hideable: true,
			visible: true,
			//formatter: DisplayDateComponent,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'status',
			name: 'Status',
			sortable: true,
			hideable: true,
			visible: true,
			formatter: TicketStatusComponent,
			getRowMetaData: row => row,
			filterable: true,
			filterRenderer: MultiselectCardknoxFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'subject',
			name: 'Subject',
			sortable: true,
			hideable: true,
			visible: true,
			formatter: ExpandableComponent,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 195,
			resizable: true,
		},
		{
			key: 'issue',
			name: 'Issue',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'assignedTo',
			name: 'Assigned To',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'submittedBy',
			name: 'Submitted By',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'followUpDateDisplay',
			name: 'Follow Up Date',
			sortable: true,
			hideable: true,
			visible: true,
			// formatter: DisplayDateComponent,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'callerName',
			name: 'Caller Name',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'callerPhone',
			name: 'Caller Phone',
			sortable: true,
			hideable: true,
			visible: true,
			formatter: PhoneNumberComponent,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 150,
			resizable: true,
		},
		{
			key: 'isHidden',
			name: '',
			sortable: false,
			hideable: false,
			visible: true,
			filterable: false,
			resizable: false,
			fixWidth: true,
			hideOnExport: true,
			getRowMetaData: row => row,
			initWidth: 75,
			order: 999,
		},
	],
	(column, index) => ({
		...column,
		order: column.order || index,
	})
);
