import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, some, toUpper } from 'lodash';

import { principalService } from '../../services';
import { withLoader } from '../../common/components';
import { PasswordInput } from '../../common/components/password-input';
import { getRedirectRoute } from './loginUtils';

const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

class ChangePasswordComponent extends Component {
	constructor(props) {
		super(props);

		let username = '';
		if (props && props.location && props.location.state && props.location.state.username) {
			username = props.location.state.username;
		}

		this.state = {
			username: username,
			oldPassword: '',
			password: '',
			password2: '',
			errorMessage: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	async handleSubmit(event) {
		const { history } = this.props;
		const { oldPassword, password } = this.state;
		let { username } = this.state;
		username = trim(toLower(username));

		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}
		this.setState({ errorMessage: '' });

		Auth.signIn(username, oldPassword)
			.then(user => {
				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					// Force change password
					Auth.completeNewPassword(user, password)
						.then(() => {
							history.push({ pathname: '/login' });
						})
						.catch(err => {
							//eslint-disable-next-line
							console.log(err);
							let message;
							switch (err && err.code) {
								case 'NotAuthorizedException':
								case 'UserNotFoundException': {
									message = 'The username/password provided is incorrect.';
									break;
								}
								case 'InvalidParameterException': {
									message = err.message;
									break;
								}
								case 'UserNotConfirmedException': {
									history.push({
										pathname: '/confirm-registration',
										state: { username: username },
									});
									break;
								}
								case 'InvalidPasswordException': {
									message =
										'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number.';
									break;
								}
								default: {
									message = 'Something went wrong. Please try again.';
									break;
								}
							}
							this.setState({ errorMessage: message });
						});
				} else {
					// Change password
					Auth.changePassword(user, oldPassword, password)
						.then(success => {
							if (success === 'SUCCESS') {
								history.push({ pathname: '/login' });
							} else {
								this.setState({ errorMessage: 'An error occurred, please contact support.' });
							}
						})
						.catch(err => {
							//eslint-disable-next-line
							console.log(err);
							let message;
							switch (err && err.code) {
								case 'NotAuthorizedException':
								case 'UserNotFoundException': {
									message = 'The username/password provided is incorrect.';
									break;
								}
								case 'InvalidParameterException': {
									message = err.message;
									break;
								}
								case 'UserNotConfirmedException': {
									history.push({
										pathname: '/confirm-registration',
										state: { username: username },
									});
									break;
								}
								case 'InvalidPasswordException': {
									message =
										'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number.';
									break;
								}
								default: {
									message = 'Something went wrong. Please try again.';
									break;
								}
							}
							this.setState({ errorMessage: message });
						});
				}
			})
			.catch(err => {
				//eslint-disable-next-line
				console.log('signIn error', err);
				let message;
				switch (err && err.code) {
					case 'InvalidParameterException': {
						message = err.message;
						break;
					}
					case 'UserNotConfirmedException': {
						history.push({
							pathname: '/confirm-registration',
							state: { username: username },
						});
						break;
					}
					case 'LimitExceededException': {
						message = 'Attempt limit exceeded, please try after some time.';
						break;
					}
					case 'NotAuthorizedException':
					case 'UserNotFoundException': {
						message = 'The username/password provided is incorrect.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				this.setState({ errorMessage: message });
			});
	}

	formValidation = () => {
		const { username, password, password2 } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.setState({
				errorMessage: 'Please enter new password',
			});
			return false;
		}

		if (password.length < 8) {
			this.setState({
				errorMessage: 'Your password needs to contain at least 8 characters',
			});
			return false;
		}

		if (!some(password, char => char === toUpper(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one uppercase character',
			});
			return false;
		}

		if (!some(password, char => char === toLower(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one lowercase character',
			});
			return false;
		}

		if (!some(password, char => !isNaN(parseInt(char)))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one number',
			});
			return false;
		}

		if (!specialCharactersRegex.test(password)) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one special character',
			});
			return false;
		}

		if (password !== password2) {
			this.setState({
				errorMessage: 'New passwords do not match',
			});
			return false;
		}

		return true;
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, oldPassword, password, password2, errorMessage } = this.state;

		return (
			<div>
				<form className="form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title">Change password</h2>
						<div className="membership__spacer">
							<label className="membership__label">Email address</label>
							<input
								name="username"
								type="text"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
								tabIndex="1"
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Current password</label>
							<PasswordInput
								name="oldPassword"
								placeholder="Enter your current password"
								value={oldPassword}
								onChange={this.handleChange}
								tabIndex="2"
								setErrorMessage={this.setErrorMessage}
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">New password</label>
							<PasswordInput
								placeholder="Enter new password"
								value={password}
								onChange={this.handleChange}
								tabIndex="3"
								setErrorMessage={this.setErrorMessage}
							/>
						</div>
						<div className="spc--bottom--med">
							<label className="membership__label">New password again</label>
							<PasswordInput
								name="password2"
								placeholder="Enter new password again"
								value={password2}
								onChange={this.handleChange}
								tabIndex="4"
								setErrorMessage={this.setErrorMessage}
							/>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom--med note note--warning">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn" tabIndex="5">
							Change password
						</button>
						<div className="membership--main__action">
							<span className="membership--main__label">Do you have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToLogin}
								className="btn membership--main__btn type--wgt--medium"
							>
								Log in
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ChangePasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withLoader(ChangePasswordComponent);
