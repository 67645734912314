import React from 'react';
import { renderIf } from './../../common/utilities';
import { DisplayDateComponent } from '../MerchantGrid/formatters';

function NewNote(props) {
	const noteText = props.noteText;
	const idx = props.index;
	//const today = new Date().toDateString();
	return (
		<div className="spc--bottom--sml">
			<span className="header header--note">
				<span>Note {idx + 1}</span>
				<span className="spc--left--auto">
					<button onClick={props.onDelete(idx)} className="btn btn--icon" title="Delete">
						<i className="icon icon--tiny icon--wastebasket" />
					</button>
				</span>
			</span>
			<div>
				<textarea
					className="input input--textarea"
					rows="4"
					cols="50"
					onChange={props.onTextChange(idx)}
					value={noteText}
				/>
			</div>
		</div>
	);
}

export default NewNote;
