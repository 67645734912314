import React from 'react';

export default function AddressDisplayComponent({ address }) {
	return (
		<React.Fragment>
			{!!address ? (
				<span className="label--sub">
					{address.streetAddress}, {address.city}, {address.state}, {address.zip}
				</span>
			) : null}
		</React.Fragment>
	);
}
