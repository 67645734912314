import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, split, includes } from 'lodash';
import { decode } from 'jsonwebtoken';

import { appService, principalService } from '../../services';
import { withContext, withLoader } from '../../common/components';
import { parseError, logClientError } from '../../common/utilities';
import { withCancelable } from '../../common/components/cancelable';
import { PasswordInput } from '../../common/components/password-input';
import { Modal } from '../../common/components/modal';
import PaymentPointsUpdateContext from '../../common/components/user-account-panel/PaymentPointsUpdateContext';
import UserContext from './UserContext';
import { Notification } from '../../common/components/notifications';
import { getRedirectRoute } from './loginUtils';
import { logLoginAttempt } from '../../common/utilities/loginAttemptsHandler';

const { REACT_APP_AGENT_SUPPORT_EMAIL } = process.env;

class LoginComponent extends Component {
	constructor(props) {
		super(props);

		let isInactive = false;
		if (props && props.location && props.location.state && props.location.state.inactivity) {
			isInactive = props.location.state.inactivity;
		}
		let username = '';
		if (props && props.location && props.location.state && props.location.state.username) {
			username = props.location.state.username;
		}

		this.state = {
			username: username,
			password: '',
			errorMessage: null,
			inactivityWarning: isInactive,
			privacyPolicyVisible: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		let userObj = principalService.get();
		let isExpired = false;
		if (!!userObj) {
			const token = userObj.token;
			isExpired = this.isTokenExpired(token);

			if (isExpired) {
				//console.log('expired');

				// clear all info so can check after if refreshed successfully
				userObj = null;

				// try to get a new token using the cognito authentication token
				this.refreshLoginToken().then(refreshed => {
					if (refreshed) {
						userObj = principalService.get();

						if (!!userObj && userObj.agentId > 0) this.redirect();
					} else {
						appService.logout();
					}
				});
			}
		} // had user object
		else {
			// try to refresh login token in case somehow there is no token from api but cognito token is present
			//console.log('no user obj');

			this.refreshLoginToken().then(refreshed => {
				//console.log('refreshed='+refreshed);
				if (refreshed) {
					userObj = principalService.get();
					this.props.getAgentPoints();
				}
				if (!!userObj && userObj.agentId > 0) this.redirect();
				else appService.logout();
			});
		}
	}

	isTokenExpired(token) {
		const decodedToken = decode(token, { complete: true });
		const dateNow = Math.floor(Date.now() / 1000);
		return decodedToken.payload.exp < dateNow;
	}

	refreshLoginToken() {
		this.props.showLoader(true);
		return Auth.currentAuthenticatedUser()
			.then(user => {
				//console.log('here');
				//console.log(user);
				if (user) {
					const token =
						(user &&
							user.signInUserSession &&
							user.signInUserSession.idToken &&
							user.signInUserSession.idToken.jwtToken) ||
						false;
					let username = user.attributes && user.attributes.email;
					if (token && username) {
						return appService
							.login(token, username)
							.then(() => {
								this.props.showLoader(false);
								this.props.getAgentPoints();
								return true;
							})
							.catch(err => {
								this.props.showLoader(false);
								return false;
							});
					} else {
						//console.log('no cognito token');
						this.props.showLoader(false);
						return false;
					}
				} else {
					//console.log('no cognito user');
					this.props.showLoader(false);
					return false;
				}
			})
			.catch(err => {
				//console.log('No authenticated user...will redirect');
				this.props.showLoader(false);
				return false;
			});
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit(event) {
		event.preventDefault();
		const { history, isLoading } = this.props;
		if (isLoading) {
			return;
		}
		const { password } = this.state;
		let { username } = this.state;
		username = trim(toLower(username));

		this.setState({ errorMessage: '' });
		this.props.showLoader(true);
		logLoginAttempt(username);

		let user;
		try {
			user = await Auth.signIn(username, password);
		} catch (err) {
			//eslint-disable-next-line
			console.log('signIn error', err);
			let message;
			switch (err.code) {
				case 'InvalidParameterException': {
					if (toLower(err.message) === 'custom auth lambda trigger is not configured for the user pool.') {
						message = 'You need to enter a password.';
					} else {
						message = err.message;
					}
					break;
				}
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'PasswordResetRequiredException': {
					this.notification.addNotification({
						message: `As part of our ongoing efforts to ensure your account's security, your password has been scheduled to be reset. We just sent you an email with a secure code. On the next screen, you will be required to enter the code, and then set your new password.	If you do not receive the email code, click on “Resend Code”. To keep your account safe and secure, make sure not to reuse old passwords!`,
						success: false,
						forceCloseHandler: true,
						onClose: async () => {
							await Auth.forgotPassword(username);
							history.push({
								pathname: '/confirm-new-password',
								state: { username: username },
							});
						},
					});
					break;
				}
				case 'NotAuthorizedException':
				case 'UserNotFoundException': {
					if (includes(toLower(err.message), 'security')) {
						message =
							'We cannot continue with your login at this time. Please check your email to confirm this login and then try again.';
					} else {
						message = 'The username/password provided is incorrect.';
					}
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({ errorMessage: message });
			this.props.showLoader(false);
			return;
		}
		// Force change password
		if (user && user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
			this.props.showLoader(false);
			history.push({
				pathname: '/change-password',
				state: { username: username },
			});
			return;
		} else if (
			user &&
			user.challengeName &&
			(user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SMS_MFA')
		) {
			this.props.showLoader(false);
			UserContext.setUser(user);
			history.push({
				pathname: '/confirm-mfa',
				state: {
					username,
					password,
					challengeName: user.challengeName,
					challengeDestination:
						user.challengeParam.CODE_DELIVERY_DESTINATION || user.challengeParam.FRIENDLY_DEVICE_NAME,
				},
			});
			return;
		} else {
			const token =
				(user && user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.jwtToken) ||
				false;
			if (token) {
				try {
					const auth = await this.props.makePendingRequest(appService.login(token, username));
					this.props.showLoader(false);
					this.props.getAgentPoints();
					if (auth.token) {
						this.redirect();
					} else {
					}
				} catch (err) {
					if (err && err.isCanceled) {
						return;
					}
					const { stack } = parseError(err);
					this.props.showLoader(false);
					//eslint-disable-next-line
					console.log('Login error', err);
					if (err && err.ex && err.ex.response && (err.ex.response.status === 401 || err.ex.response.status === 403)) {
						this.setState({
							errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
						});
					} else if (err && err.startsWith && err.startsWith('User authenticated but not found')) {
						this.setState({
							errorMessage: (
								<span>
									Email address is not registered for the Partner Portal. For assistance, reach out to{' '}
									<a
										className="anchor anchor--primary type--underline"
										href={`mailto:${REACT_APP_AGENT_SUPPORT_EMAIL}`}
									>
										Agent Support.
									</a>
								</span>
							),
						});
					} else {
						this.setState({ errorMessage: logClientError(stack) });
					}
				}
			} else {
				this.setState({
					errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
				});
				this.props.showLoader(false);
			}
		}
	}

	async redirect() {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();
		let search = '';
		let additionalState;

		// hide loader before redirect
		this.props.showLoader(false);

		if (location.state && location.state.returnUrl) {
			[redirectUrl, search] = split(location.state.returnUrl, '?');
		}

		history.push({
			pathname: redirectUrl,
			search,
			...(additionalState || {}),
		});
	}

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	togglePrivacyPolicy = () => {
		const { privacyPolicyVisible } = this.state;
		this.setState({
			privacyPolicyVisible: !privacyPolicyVisible,
		});
	};

	render() {
		const { username, password, errorMessage, inactivityWarning, privacyPolicyVisible } = this.state;

		return (
			<div>
				<form className="form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title">Sign in to Cardknox Partner Portal</h2>
						<div className="membership__spacer">
							{inactivityWarning ? (
								<div className="spc--top--sml spc--bottom--sml note note--warning">
									Your session has expired. Please log in again.
								</div>
							) : null}
							<label className="membership__label">Email address</label>
							<input
								name="username"
								type="text"
								className="input input--med"
								placeholder="user@email.com"
								value={username}
								onChange={this.handleChange}
								autoFocus
								tabIndex="1"
							/>
						</div>
						<div className="spc--bottom--med">
							<div className="group">
								<label className="membership__label pull">Password</label>
								<a
									href="javascript:void(0)"
									onClick={this.redirectToForgotPassword}
									className="anchor anchor--primary type--sml push"
								>
									Forgot password?
								</a>
							</div>
							<PasswordInput
								value={password}
								onChange={this.handleChange}
								tabIndex="2"
								setErrorMessage={this.setErrorMessage}
							/>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom-sml note note--warning">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn" tabIndex="3">
							Sign in
						</button>
						<p className="membership--main__action">
							<span className="membership--main__label">Don't have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToRegister}
								className="btn membership--main__btn type--wgt--medium spc--left--nano"
							>
								Register now
							</button>
						</p>
					</div>
				</form>

				<Modal isOpen={privacyPolicyVisible} onClose={this.togglePrivacyPolicy}>
					<div className="w--606p w--max--100">
						<div className="popup__header">
							<h3>Privacy Policy</h3>
						</div>
						<div className="popup__body type--color--text--regular">
							<p className="spc--bottom--med">
								Your privacy is important to Cardknox Development Inc. ("CARDKNOX"). Our goal is to provide you with a
								personalized online experience that provides you with the information, resources, and services that are
								most relevant and helpful to you. Privacy Policy (the "Privacy Policy") has been written to describe the
								conditions under which this web site and other CARDKNOX resources (the "Web site") are being made
								available to you. The Privacy Policy discusses, among other things, how data obtained during your visit
								to this Web site may be collected and used. We strongly recommend that you read the Privacy Policy
								carefully. By using this Web site, you agree to be bound by the terms of this Privacy Policy. If you do
								not accept the terms of the Privacy Policy, you are directed to discontinue accessing or otherwise using
								the Web site or any materials obtained from it. If you are dissatisfied with the Web site, by all means
								contact us at{' '}
								<a href="mailto:info@cardknox.com" className="anchor anchor--primary">
									info@cardknox.com
								</a>
								; otherwise, your only recourse is to disconnect from this site and refrain from visiting the site in
								the future.
							</p>
							<p className="spc--bottom--med">
								The process of maintaining a Web site is an evolving one, and CARDKNOX may decide at some point in the
								future, without advance notice, to modify the terms of this Privacy Policy. Your use of the Web site, or
								materials obtained from the Web site, indicates your assent to the Privacy Policy at the time of such
								use. The effective Privacy Policy will be posted on the Web site, and you should check upon every visit
								for any changes.
							</p>
							<p className="spc--bottom--med">
								1. Non-personal information is data about usage and service operation that is not directly associated
								with a specific personal identity. CARDKNOX may collect and analyze non-personal information to evaluate
								how visitors use the CARDKNOX Website.
							</p>
							<p className="spc--bottom--med">
								2. CARDKNOX may gather aggregate information, which refers to information your computer automatically
								provides to us and which cannot be tied back to you as a specific individual. Examples include referral
								data (the Web sites you visited just before and just after our site), the pages viewed, time spent at
								our Web site, and Internet Protocol (IP) addresses. An IP address is a number that is automatically
								assigned to your computer whenever you access the Internet. For example, when you request a page from
								one of our sites, our servers log your IP address to create aggregate reports on user demographics and
								traffic patterns and for purposes of system administration.
							</p>
							<p className="spc--bottom--med">
								3. Every time you request or download a file from the Web site, CARDKNOX may store data about these
								events and your IP address in a log file. CARDKNOX may use this information to analyze trends,
								administer the Web site, track users' movements, and gather broad demographic information for aggregate
								use or for other business purposes.
							</p>
							<p className="spc--bottom--med">
								4. Our site may use a feature of your browser to set a "cookie" on your computer. Cookies are small
								packets of information that a Web site's computer stores on your computer. CARDKNOX's Web site can then
								read the cookies whenever you visit our site. We may use cookies in a number of ways, such as to save
								your password so you don't have to re-enter it each time you visit our site, to deliver content specific
								to your interests and to track the pages you've visited. These cookies allow us to use the information
								we collect to customize web experience so that your visit to our site is as relevant and as valuable to
								you as possible. Most browser software can be set up to deal with cookies. You may modify your browser
								preference to provide you with choices relating to cookies. You have the choice to accept all cookies,
								to be notified when a cookie is set or to reject all cookies. If you choose to reject cookies, certain
								of the functions and conveniences of our Web site may not work properly, and you may be unable to use
								services that require registration in order to participate, or you will have to re-register each time
								you visit our site. Most browsers offer instructions on how to reset the browser to reject cookies in
								the "Help" section of the toolbar. We do not link non-personal information from cookies to personally
								identifiable information without your permission.
							</p>
							<p className="spc--bottom--med">
								5. CARDKNOX may use personal information to offer or provide services that support its activities or
								those of CARDKNOX participants or members, and their collaboration with CARDKNOX. When accessing
								restricted CARDKNOX Web pages and portals, your personal user information may be tracked in order to
								support collaboration, ensure authorized access, and enable communication among participants or members.
							</p>
							<p className="spc--bottom--med">
								6. CARDKNOX does not sell, rent, or lease any individual's personal information or lists of email
								addresses to anyone for marketing purposes, and we take commercially reasonable steps to maintain the
								security of this information. However, CARDKNOX reserves the right to supply any such information to any
								organization into which CARDKNOX may merge in the future or to which it may make any transfer in order
								to enable a third party to continue part or all of CARDKNOX. We also reserve the right to release
								personal information to protect our systems or business, when we reasonably believe you to be in
								violation of our Terms of Use or if we reasonably believe you to have initiated or participated in any
								illegal activity. In addition, please be aware that in certain circumstances, CARDKNOX may be obligated
								to release your personal information pursuant to judicial or other government subpoenas, warrants, or
								other orders.
							</p>
							<p className="spc--bottom--med">
								7. We respect the privacy of every individual who uses the Site and we will only process and use the
								data obtained through the Site for our own business purposes. CARDKNOX uses a variety of means to
								protect personal information provided by users of the Web site, including using firewalls and other
								security measures on its servers.
							</p>
						</div>
						<div className="popup__footer"></div>
					</div>
				</Modal>
				<div className="membership__privacy">
					&copy; Copyright {new Date().getFullYear()} Cardknox -{' '}
					<a href="javascript:void(0)" className="membership__privacy__link" onClick={this.togglePrivacyPolicy}>
						Privacy Policy
					</a>
				</div>
				<Notification ref={el => (this.notification = el)} />
			</div>
		);
	}
}

LoginComponent.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
};

export default withCancelable(withLoader(withContext(LoginComponent, PaymentPointsUpdateContext, 'getAgentPoints')));
