import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { forOwn, cloneDeep, clone, filter, each, isEqual, map, some, has, transform, find, every, get } from 'lodash';
import 'rc-menu/assets/index.css';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import moment from 'moment';

import { KeyboardEventHandler } from '../react-keyboard-event-handler';
import { predefinedDates, DatePickerPredefined } from '../../filters';

class MainFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			displayAdvancedFilter: false,
			filters: cloneDeep(props.filters),
			dates: cloneDeep(props.predefinedDates),
		};
	}

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	syncFilters = () => {
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	parseFilters = (filters, filterItem) => {
		let hasSelection = false;
		forOwn(filters.values, function(_, key) {
			const valueItem = filter(filterItem.values, { key: key });
			if (valueItem.length > 0) {
				filters.values[key] = valueItem[0].value;
				if (moment.isMoment(filters.values[key])) {
					if (!filterItem.emptyValue || !filters.values[key].isSame(filterItem.emptyValue, 'day')) {
						hasSelection = true;
					}
				} else if (
					filters.values[key] !== undefined &&
					filters.values[key] !== null &&
					filters.values[key] !== filterItem.emptyValue &&
					!isEqual(filters.values, filters.defaultValues)
				) {
					hasSelection = true;
				}
			} else if (key !== 'fieldName') {
				filters.values[key] = filterItem.emptyValue;
			}
		});
		filters.hasSelection = hasSelection;
	};

	injectFilters = filtersToInject => {
		const { filters } = this.state;
		return transform(
			filtersToInject,
			(acc, key) => {
				const filter = find(filters, { key });
				if (filter) {
					acc[key] = filter.values;
				}
			},
			{}
		);
	};

	shouldDisableDateRange = () => {
		const { filters } = this.state;
		const otherFilters = filter(filters, item => item.key !== 'date');
		const filterHasValue =
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		const dateFilter = this.getItem('date');
		if (dateFilter && dateFilter.values.disabled && !filterHasValue) {
			dateFilter.values = {
				...dateFilter.values,
				disabled: false,
			};
			this.setState({
				filters: [dateFilter, ...otherFilters],
			});
		}
	};

	onActiveFilterChanged = item => {
		const filters = this.getActiveItem(item.id);
		this.parseFilters(filters, item);

		const stateFilters = cloneDeep(this.state.filters);
		const index = stateFilters.findIndex(f => f.key === item.id);
		stateFilters[index] = filters;
		this.setState({ activeKeys: [], filters: stateFilters });
		this.props.updateFilters({
			filters: cloneDeep(this.props.activeFilters),
			activeFilters: this.props.activeFilters,
		});
	};

	onFilterChanged = item => {
		const filter = this.getItem(item.id);
		this.parseFilters(filter, item);

		let { filters } = this.state;
		for (let f of filters) {
			if (item.id === f.id) {
				f = filter;
			}
		}

		this.setState(
			{
				filters: filters,
			},
			() => {
				this.shouldDisableDateRange();
				if (filter.applyOnChange) {
					this.applyStandaloneFilter();
				}
			}
		);
	};

	onFilterRemoved = (item, override) => {
		const filters = this.getActiveItem(item.id);
		const dateFilter = this.getActiveItem('date');
		const isDateFilterDisabled = get(dateFilter, 'values.disabled', false);
		const defaultFilter = override || filters;

		if (defaultFilter.resetToFalseOnRemove) {
			each(defaultFilter.defaultValues, (_, key) => {
				defaultFilter.defaultValues[key] = false;
			});
			defaultFilter.defaultHasSelection = false;
		}

		filters.values = clone(defaultFilter.defaultValues);
		filters.hasSelection = defaultFilter.defaultHasSelection;

		const stateFilters = cloneDeep(this.state.filters);
		const index = stateFilters.findIndex(f => f.key === item.id);
		stateFilters[index] = filters;
		const filtersThatAllowDateDisable = filter(
			stateFilters,
			({ key, allowsDateDisable }) => allowsDateDisable && key !== 'date'
		);

		if (isDateFilterDisabled && every(filtersThatAllowDateDisable, ({ hasSelection }) => !hasSelection)) {
			const dateIndex = stateFilters.findIndex(f => f.key === 'date');
			stateFilters[dateIndex].values.disabled = false;
		}

		this.setState({
			filters: stateFilters,
		});
		this.props.updateFilters({
			filters: stateFilters,
			activeFilters: cloneDeep(stateFilters),
		});
	};

	onOpenChange = activeKeys => {
		if (activeKeys.length === 0 || this.state.activeKeys.length === 0) {
			this.setState({
				activeKeys: activeKeys,
				filters: cloneDeep(this.props.filters),
			});
		} else {
			this.setState({ activeKeys: activeKeys });
		}
	};

	resetFilter = () => {
		const { filters, updateFilters } = this.props;
		each(filters, filter => {
			if (!filter.clearable) {
				return;
			}
			if (filter.resetToFalseOnRemove) {
				each(filter.values, (_, key) => {
					filter.values[key] = false;
				});
				filter.hasSelection = false;
			} else {
				filter.values = cloneDeep(filter.defaultValues);
				filter.hasSelection = filter.defaultHasSelection;
			}
		});
		updateFilters({
			filters,
			activeFilters: cloneDeep(filters),
		});
		this.setState({ activeKeys: [], filters: cloneDeep(filters) });
	};

	applyFilter = async (_, standaloneFilter) => {
		const { filters } = this.state;
		const { updateFilters } = this.props;
		updateFilters({
			filters: cloneDeep(filters),
			activeFilters: cloneDeep(filters),
			standaloneFilter,
		});
		await this.setStateAsync({ activeKeys: [] });
	};

	applyStandaloneFilter = async item => {
		await this.applyFilter(null, item);
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	getItem = key => {
		const item = filter(this.state.filters, { key: key })[0];
		return item;
	};

	getActiveItem = key => {
		const item = filter(this.props.activeFilters, { key: key })[0];
		return item;
	};

	renderTopFilter = () => null;

	renderAdditionalFilter = () => null;

	renderSubMenuItemTitle = (title, key) => {
		const item = this.getItem(key);
		if (item && item.hasSelection) {
			return (
				<span>
					<span className="selected">{title}</span>
					<span className="filter--selected"></span>
				</span>
			);
		} else {
			return <span>{title}</span>;
		}
	};

	renderCustomSubMenuItemTitle = (_, key) => {
		const item = this.getActiveItem(key);
		return (
			<span>
				<span className="selected hide--to--sml">
					{item.getSelectionText && item.getSelectionText(item.values, this.state.dates)}
				</span>
			</span>
		);
	};

	renderMenuTitle = title => {
		const filters = filter(this.props.activeFilters, function(filter) {
			return filter.key !== 'date';
		});
		const count = filter(filters, ({ hasSelection, standalone }) => hasSelection && !standalone).length;
		if (count > 0) {
			return (
				<span>
					<span className="selected hide--to--xxlrg">{title}</span>
					<div className="filter__counter">
						<span>{count}</span>
					</div>
				</span>
			);
		} else {
			return <span className="hide--to--xxlrg">{title}</span>;
		}
	};

	anyActiveHasSelection = () => {
		return filter(this.props.activeFilters, { hasSelection: true }).length > 0;
	};

	refreshData = () => {
		const { filters, activeFilters, updateFilters } = this.props;
		updateFilters({
			filters,
			activeFilters,
			forceRefresh: true,
		});
	};

	onKeyPressEnter = event => {
		if (event.key == 'Enter') {
			this.applyFilter();
		}
	};

	onShowAdvancedFilter = () => {
		this.setState({
			displayAdvancedFilter: true,
		});
	};

	onHideAdvancedFilter = () => {
		this.setState({
			displayAdvancedFilter: false,
		});
	};

	handleFiltersChange = () => {
		const filters = filter(this.state.filters, i => i.key !== 'date');
		let dateFilter = this.getItem('date');
		const willDisableDateFilter = dateFilter && !dateFilter.values.disabled;
		each(filters, item => {
			if ((willDisableDateFilter && !item.allowsDateDisable) || item.removeOnDateEnable) {
				item.hasSelection = false;
				item.values = clone(item.defaultValues);
			}
		});
		if (dateFilter) {
			dateFilter.values.disabled = willDisableDateFilter;
			this.setState({ filters: [dateFilter, ...filters] });
		} else {
			this.setState(filters);
		}
	};

	renderFilterSelection = () => (
		<div className="filter__tag__group">
			{map(this.props.activeFilters, item =>
				item.hasSelection && item.selectionComponent && !item.values.disabled ? (
					<item.selectionComponent
						key={item.key}
						filter={item}
						onFilterRemoved={this.onFilterRemoved}
						isExporting={this.props.isExporting}
						{...item.props}
					/>
				) : null
			)}
		</div>
	);

	render() {
		const { displayAdvancedFilter, dates, filters, activeKeys } = this.state;
		const { filterSelectionRef, activeFilters, clearFilters, className, title } = this.props;
		const filterHasValue =
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		const dateFilter = this.getItem('date');
		const activeDateFilter = this.getActiveItem('date');
		const canDisableDateRange = dateFilter && has(dateFilter, 'values.disabled');
		const isDateRangeChecked = dateFilter && dateFilter.values.disabled;
		return (
			<div className="filter__group">
				<div className="transactions__options">
					<div>
						{activeDateFilter && (
							<div className="filter__date filter__date--square spc--bottom--sml">
								<DatePickerPredefined
									subMenuTitle={this.renderCustomSubMenuItemTitle('Custom', 'date')}
									onOpenChange={this.onOpenChange}
									activeKeys={activeKeys}
									filter={activeDateFilter}
									onApplyFilter={this.applyFilter}
									onFilterChanged={this.onFilterChanged}
									onActiveFilterChanged={this.onActiveFilterChanged}
									predefinedDates={dates}
									{...activeDateFilter.props}
								/>
							</div>
						)}
						{map(
							filters,
							item =>
								item.standalone &&
								!item.afterFilter && (
									<div key={item.key} className="filter__search w--222p spc--bottom--sml spc--right--tny">
										<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyStandaloneFilter(item)}>
											<item.component
												filter={item}
												onFilterChanged={this.onFilterChanged}
												onActiveFilterChanged={this.onActiveFilterChanged}
												noFocus={true}
												injectedFilters={this.injectFilters(item.injectFilters)}
												goButtonHandler={this.applyFilter}
												{...item.props}
											/>
										</KeyboardEventHandler>
									</div>
								)
						)}
						{some(filters, ({ key, standalone }) => key !== 'date' && !standalone) && (
							<div
								className={`filter__select spc--bottom--sml spc--right--xsml`}
								tabIndex={0}
								onScroll={() => {
									this.setState({ activeKeys: filter(activeKeys, key => key === 'date ' || key === 'filter') });
								}}
								onKeyPress={this.onKeyPressEnter}
							>
								<Menu
									className={`${className ? className : ''} rc-menu-datepicker-tooltip`}
									disabledOverflow={true}
									mode={'horizontal'}
									motion={'slide-up'}
									triggerSubMenuAction={'click'}
									openKeys={activeKeys}
									onOpenChange={this.onOpenChange}
								>
									<SubMenu title={this.renderMenuTitle(title)} key="filter" popupClassName="rc-menu-transaction-filter">
										{this.renderTopFilter()}
										{map(filters, item =>
											item.standalone || !item.onTop || !item.component ? null : (
												<SubMenu
													key={item.key}
													title={this.renderSubMenuItemTitle(item.name, item.key)}
													mode={'vertical-left'}
												>
													<MenuItem key={`${item.key}.menuItem`} disabled>
														<item.component
															filter={item}
															onFilterChanged={this.onFilterChanged}
															onActiveFilterChanged={this.onActiveFilterChanged}
															injectedFilters={this.injectFilters(item.injectFilters)}
															goButtonHandler={this.applyFilter}
															{...item.props}
														/>
													</MenuItem>
												</SubMenu>
											)
										)}

										{canDisableDateRange && (
											<React.Fragment>
												<div className="spc--left--xsml spc--top--tny spc datatooltip--disabledfilter">
													<div data-tooltip="Requires exact match">
														<div className="display--ib">
															<input
																type="checkbox"
																disabled={!filterHasValue}
																onChange={this.handleFiltersChange}
																checked={isDateRangeChecked}
																value={isDateRangeChecked}
																name="disableDateRange"
																id="disableDateRange"
																className="input input--check"
															/>
															<label htmlFor="disableDateRange" className="type--none">
																Disable date range
															</label>
														</div>
														<i className="icon icon--tiny icon--info align--v--neg--4 spc--left--xsml"></i>
													</div>
												</div>
												<hr className="separator separator--grey1 spc--bottom--no" />
											</React.Fragment>
										)}

										{map(filters, item =>
											item.standalone || item.onTop || !item.component || item.advancedFilter ? null : (
												<SubMenu
													key={item.key}
													disabled={isDateRangeChecked && !item.onTop}
													title={this.renderSubMenuItemTitle(item.name, item.key)}
													mode={'vertical-left'}
												>
													<MenuItem key={`${item.key}.menuItem`} disabled>
														<item.component
															filter={item}
															onFilterChanged={this.onFilterChanged}
															onActiveFilterChanged={this.onActiveFilterChanged}
															injectedFilters={this.injectFilters(item.injectFilters)}
															goButtonHandler={this.applyFilter}
															onApplyFilter={this.applyFilter}
															{...item.props}
														/>
													</MenuItem>
												</SubMenu>
											)
										)}

										{some(filters, { advancedFilter: true }) && (
											<React.Fragment>
												<li className="rc-menu-item">
													<div className="rc-menu-footer rc-menu-advanced">
														{displayAdvancedFilter ? (
															<button
																type="button"
																className="btn btn--reset anchor anchor--primary type--left fullwidth"
																onClick={this.onHideAdvancedFilter}
															>
																- Advanced
															</button>
														) : (
															<button
																type="button"
																className="btn btn--reset anchor anchor--primary type--left fullwidth"
																onClick={this.onShowAdvancedFilter}
															>
																+ Advanced
															</button>
														)}
													</div>
												</li>
											</React.Fragment>
										)}

										{map(filters, item =>
											item.standalone || !item.component || !item.advancedFilter || !displayAdvancedFilter ? null : (
												<SubMenu
													key={item.key}
													disabled={isDateRangeChecked && !item.onTop}
													title={this.renderSubMenuItemTitle(item.name, item.key)}
													mode={'vertical-left'}
												>
													<MenuItem key={`${item.key}.menuItem`} disabled>
														<item.component
															filter={item}
															onFilterChanged={this.onFilterChanged}
															onActiveFilterChanged={this.onActiveFilterChanged}
															injectedFilters={this.injectFilters(item.injectFilters)}
															goButtonHandler={this.applyFilter}
															{...item.props}
														/>
													</MenuItem>
												</SubMenu>
											)
										)}

										<MenuItem key="reset" disabled={true} className="rc-menu-abs-footer">
											<div className="rc-menu-footer rc-menu-footer-alt">
												<button type="button" onClick={clearFilters} className="btn btn--reset pull">
													Reset all
												</button>
												<button
													type="button"
													onClick={this.applyFilter}
													className="btn btn--reset anchor anchor--primary push type--uppercase type--wgt--bold"
												>
													Done
												</button>
											</div>
										</MenuItem>
									</SubMenu>
								</Menu>
							</div>
						)}
						{map(
							filters,
							item =>
								item.standalone &&
								item.afterFilter && (
									<div key={item.key} className="filter__search w--222p">
										<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyStandaloneFilter(item)}>
											<item.component
												filter={item}
												onFilterChanged={this.onFilterChanged}
												onActiveFilterChanged={this.onActiveFilterChanged}
												noFocus={true}
												injectedFilters={this.injectFilters(item.injectFilters)}
												goButtonHandler={this.applyFilter}
												{...item.props}
											/>
										</KeyboardEventHandler>
									</div>
								)
						)}
						{some(activeFilters, ({ hasSelection, key, clearable }) => hasSelection && key !== 'date' && clearable) ? (
							<a
								href="javascript:void(0)"
								className="filter__clear spc--right--xsml"
								onClick={clearFilters}
								data-tooltip="Reset Filters"
							>
								<i className="icon icon--sml icon--clear align--v--middle"></i>
							</a>
						) : null}
						{this.renderAdditionalFilter()}
					</div>
				</div>
				{filterSelectionRef &&
					filterSelectionRef.current &&
					createPortal(this.renderFilterSelection(), filterSelectionRef.current)}
			</div>
		);
	}
}

MainFilterComponent.defaultProps = {
	predefinedDates,
	title: 'Filter',
};

MainFilterComponent.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	updateFilters: PropTypes.func,
	filters: PropTypes.array,
	activeFilters: PropTypes.array,
	clearFilters: PropTypes.func,
	filterSelectionRef: PropTypes.any,
	predefinedDates: PropTypes.array,
	isExporting: PropTypes.bool,
};

export default MainFilterComponent;
