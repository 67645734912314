import { get } from 'lodash';
import { isFd } from '../common/utilities/processorType';
import { ascii, maxLength, digitLength, email } from './validators';

const number = val => val > 0;
const processingmethods = (val, ctx) => {
	const swiped = ctx.processingDetails.swipedTransactionsPercentage
		? ctx.processingDetails.swipedTransactionsPercentage
		: 0;
	const internet = ctx.processingDetails.internetTransactionsPercentage
		? ctx.processingDetails.internetTransactionsPercentage
		: 0;
	const keyed = ctx.processingDetails.keyedTransactionsPercentage
		? ctx.processingDetails.keyedTransactionsPercentage
		: 0;
	return swiped + internet + keyed == 100;
};
const isAmexEsa = ctx => ctx.amexDetails && ctx.amexDetails.status === 'Existing' && ctx.amexDetails.program === 'ESA';
const esanumber = (val, ctx) => (isAmexEsa(ctx) ? !!val : true);
const esanumberLength = (val, ctx) => (isAmexEsa(ctx) ? digitLength(10)(val) : true);
const taxFileUpload = (val, ctx) => (val ? ctx.isTaxExemptionFileValid : true);
const requiredIfCardknox = (val, ctx) => (get(ctx, 'telemarketerList.length', 0) > 0 ? val !== '' : true);
const emailIfNotCardknox = (val, ctx) =>
	get(ctx, 'telemarketerList.length', 0) == 0 && val ? email(false)(val) : true;
const eidsEmailRequired = (val, ctx) => (get(ctx, 'eidsEnrollmentDetails.shouldEnrollEids', false) ? !!val : true);
const eidsEmailValid = (val, ctx) =>
	get(ctx, 'eidsEnrollmentDetails.shouldEnrollEids', false) && !!val ? email(false)(val) : true;
const markupFeeRequired = (val, ctx) => (get(ctx, 'onlineReportingOptions.isEnabled') === true ? val !== '' : true);
const reason = (val, ctx) => (isFd(ctx) ? true : !!val);
const ebt = (val, ctx) => (ctx.doesAcceptEbt ? !!val : true);
const pricingStructureType = val => (val ? val !== 'Unknown' : false);
const amexPricingStructureType = (val, ctx) =>
	ctx.amexDetails.status === 'New' && ctx.amexDetails.program === 'OptBlue' ? val !== 'Unknown' : true;
const monthlyDollarSavingsAmounts = (val, ctx) =>
	ctx.additionalSetupDetails.isCurrentlyAcceptingCreditCards && ctx.proposedSavings.type === 'MonthlyDollarSavings'
		? !!val
		: true;
const rateReductionType = (val, ctx) =>
	ctx.additionalSetupDetails.isCurrentlyAcceptingCreditCards && ctx.proposedSavings.type === 'EffectiveRateReduction'
		? val !== 'Unknown'
		: true;
const rateReductionCombinedSavingsPercentage = (val, ctx) =>
	ctx.additionalSetupDetails.isCurrentlyAcceptingCreditCards &&
	ctx.proposedSavings.type === 'EffectiveRateReduction' &&
	ctx.proposedSavings.rateReductionType === 'Combined'
		? !!val
		: true;
const rateReductionIndividualPercentage = (val, ctx) =>
	ctx.additionalSetupDetails.isCurrentlyAcceptingCreditCards &&
	ctx.proposedSavings.type === 'EffectiveRateReduction' &&
	ctx.proposedSavings.rateReductionType === 'Individual'
		? !!ctx.proposedSavings.bankcardSavingsPercentage || !!ctx.proposedSavings.amexSavingsPercentage
		: true;

export const setupFormTemplate = {
	telemarketerEmail: {
		type: String,
		use: { requiredIfCardknox },
		message: {
			requiredIfCardknox: '[**Rep 2**](javascript:void) is required',
		},
	},
	processorName: {
		type: String,
		required: true,
		message: '[**Bank**](javascript:void) is required',
	},
	otherProcessorReason: {
		type: String,
		use: { reason },
		message: {
			reason: '[**Processor Reason**](javascript:void) is required',
		},
	},
	hasExistingFidelityAccount: {
		type: Boolean,
		required: true,
		message:
			'[**The Signer Or Business Has, Or Has Previously Had, An Account With Fidelity**](javascript:void) is required',
	},
	additionalSetupDetails: {
		isCurrentlyAcceptingCreditCards: {
			type: Boolean,
			required: true,
			message: '[**Merchant Currently Accepts Credit Cards**](javascript:void) is required',
		},
		isTaxExemptionRequested: {
			type: Boolean,
			required: false,
			use: { taxFileUpload },
			message: '[**Tax Document upload**](javascript:void) is required',
		},
	},
	goodsOrServicesDescription: {
		type: String,
		required: true,
		message: {
			required: '[**Goods/Services Sold**](javascript:void) is required',
		},
	},
	processingDetails: {
		swipedTransactionsPercentage: {
			type: Number,
			use: { processingmethods },
			message: {
				type: '[**Swiped Percentage**](javascript:void) is invalid',
				processingmethods: '[**Processing Methods**](javascript:void) must total 100%',
			},
		},
		keyedTransactionsPercentage: {
			type: Number,
			message: '[**Keyed Percentage**](javascript:void) is invalid',
		},
		internetTransactionsPercentage: {
			type: Number,
			message: '[**Internet Percentage**](javascript:void) is invalid',
		},
		yearlyVmcVolume: {
			type: Number,
			use: { number },
			required: true,
			message: {
				type: '[**V/M Average Annual Volume**](javascript:void) is invalid',
				number: '[**V/M Average Annual Volume**](javascript:void) must be greater than 0',
				required: '[**V/M Average Annual Volume**](javascript:void) is required',
			},
		},
		averageTransactionAmount: {
			type: Number,
			use: { number },
			required: true,
			message: {
				type: '[**V/M Average Transaction Dollar Amount**](javascript:void) is invalid',
				number: '[**V/M Average Transaction Dollar Amount**](javascript:void) must be greater than 0',
				required: '[**V/M Average Transaction Dollar Amount**](javascript:void) is required',
			},
		},
		maxTransactionAmount: {
			type: Number,
			message: {
				type: '[**V/M Highest Transaction Dollar Amount**](javascript:void) is invalid',
			},
		},
	},
	pricingStructure: {
		discountMethod: {
			type: String,
			required: true,
			message: '[**Discount Method**](javascript:void) is required',
		},
		shouldSetupPinDebit: {
			type: Boolean,
			required: true,
			message: '[**Setup Pin Debit**](javascript:void) is required',
		},
		pricingStructureType: {
			type: String,
			use: { pricingStructureType },
			message: {
				pricingStructureType: '[**Rate Structure**](javascript:void) must be selected',
			},
		},
	},
	proposedFees: {
		statementFee: {
			type: Number,
			required: true,
			use: { number },
			message: {
				type: '[**Statement Fee**](javascript:void) is invalid',
				number: '[**Statement Fee**](javascript:void) must be greater than 0',
				required: '[**Statement Fee**](javascript:void) is required',
			},
		},
		chargebackFee: {
			type: Number,
			required: true,
			use: { number },
			message: {
				type: '[**Chargeback Fee**](javascript:void) is invalid',
				number: '[**Chargeback Fee**](javascript:void) must be greater than 0',
				required: '[**Chargeback Fee**](javascript:void) is required',
			},
		},
		monthlyMinimumFee: {
			type: Number,
			required: true,
			use: { number },
			message: {
				type: '[**Monthly Minimum Fee**](javascript:void) is invalid',
				number: '[**Monthly Minimum Fee**](javascript:void) must be greater than 0',
				required: '[**Monthly Minimum Fee**](javascript:void) is required',
			},
		},
		ebtTransactionFee: {
			type: Number,
			required: false,
			use: { ebt },
			message: {
				ebt: '[**EBT Transaction Fee**](javascript:void) is required when EBT is checked off on the MPA',
			},
		},
	},
	onlineReportingOptions: {
		markupFee: {
			type: String,
			use: { markupFeeRequired },
			message: {
				markupFeeRequired: '[**Access One Markup Fee**](javascript:void) is required',
			},
		},
	},
	amexDetails: {
		status: {
			type: String,
			required: true,
			message: '[**Amex Entitlement**](javascript:void) is required',
		},
		esaNumber: {
			type: String,
			use: { esanumber },
			message: {
				esanumber: '[**Amex ESA Number**](javascript:void) is required',
			},
		},
		yearlyAmexVolume: {
			type: Number,
			message: {
				type: '[**Opt-Blue Average Annual Volume**](javascript:void) is invalid',
			},
		},
		pricingStructureType: {
			type: String,
			use: { amexPricingStructureType },
			message: {
				amexPricingStructureType: '[**AMEX Rate Structure**](javascript:void) must be selected',
			},
		},
	},
	eidsEnrollmentDetails: {
		eidsMerchantEmail: {
			type: String,
			use: { eidsEmailRequired },
			message: {
				eidsEmailRequired: '[**EIDS Email**](javascript:void) is required',
			},
		},
		shouldEnrollEids: {
			type: Boolean,
			required: true,
			message: {
				required: '[**Enroll With EIDS**](javascript:void) is required',
			},
		},
	},
};

export const setupFormInvalidTemplate = {
	amexDetails: {
		esaNumber: {
			type: String,
			use: { esanumberLength },
			message: {
				esanumberLength: '[**Amex ESA Number**](javascript:void) must be 10 digits',
			},
		},
	},
	telemarketerEmail: {
		type: String,
		use: { ascii, emailIfNotCardknox },
		message: {
			ascii: '[**Rep 2 Email**](javascript:void) contains invalid characters',
			emailIfNotCardknox: '[**Rep 2 Email**](javascript:void) is invalid',
		},
	},
	existingAccountDba: {
		type: String,
		use: { ascii },
		message: {
			ascii: '[**DBA/MID**](javascript:void) contains invalid characters',
		},
	},
	goodsOrServicesDescription: {
		type: String,
		use: { ascii, length: maxLength(64) },
		message: {
			ascii: '[**Goods/Services Sold**](javascript:void) contains invalid characters',
			length: '[**Goods/Services Sold**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
		},
	},
	otherProcessorReason: {
		type: String,
		use: { ascii },
		message: {
			reason: '[**Processor Reason**](javascript:void) contains invalid characters',
		},
	},
	eidsEnrollmentDetails: {
		eidsMerchantEmail: {
			type: String,
			use: { ascii, eidsEmailValid },
			message: {
				ascii: '[**EIDS Email**](javascript:void) contains invalid characters',
				eidsEmailValid: '[**EIDS Email**](javascript:void) is invalid',
			},
		},
	},
	notes: {
		use: { length: maxLength(1500) },
		type: String,
		message: {
			length: '[**Note**](javascript:void) is invalid. Maximum number of characters allowed is 1500.',
		},
	},
	proposedSavings: {
		properties: {
			monthlyDollarSavingsAmounts: {
				type: Number,
				use: { monthlyDollarSavingsAmounts: monthlyDollarSavingsAmounts },
				message: {
					monthlyDollarSavingsAmounts: '[**Monthly Dollar Savings**](javascript:void) amount is required',
				},
			},
			rateReductionType: {
				type: String,
				use: { rateReductionType: rateReductionType },
				message: {
					rateReductionType: '[**Effective Rate Reduction Type**](javascript:void) is required',
				},
			},
			combinedSavingsPercentage: {
				type: Number,
				use: { combinedSavingsPercentage: rateReductionCombinedSavingsPercentage },
				message: {
					combinedSavingsPercentage: '[**Combined**](javascript:void) amount is required',
				},
			},
			bankcardSavingsPercentage: {
				type: Number,
				use: { bankcardSavingsPercentage: rateReductionIndividualPercentage },
				message: {
					bankcardSavingsPercentage: '[**Bankcard**](javascript:void) amount is required',
				},
			},
			amexSavingsPercentage: {
				type: Number,
				use: { amexSavingsPercentage: rateReductionIndividualPercentage },
				message: {
					amexSavingsPercentage: '[**Amex**](javascript:void) amount is required',
				},
			},
		},
	},
};
