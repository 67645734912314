import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { includes, some, toLower, toUpper, trim } from 'lodash';
import { Auth } from 'aws-amplify';
import { PasswordInput } from '../../common/components/password-input';

const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

class ConfirmPasswordBody extends Component {
	setErrorMessage = errorMessage => {
		this.props.setComponentState({ errorMessage });
	};

	handleChange = event => {
		this.props.setComponentState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = async event => {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		let { history, password, username, code, skipRedirect } = this.props;

		username = trim(toLower(username));
		code = trim(code);

		try {
			if (skipRedirect) {
				this.props.showLoader(true);
			}
			await Auth.forgotPasswordSubmit(username, code, password);
			if (!skipRedirect) {
				history.push({
					pathname: '/login',
					state: { username: username },
				});
			}
			if (skipRedirect) {
				this.props.notifyPasswordChange();
			}
		} catch (err) {
			let message;
			switch (err && err.code) {
				case 'CodeMismatchException': {
					message = 'The provided code does not match the expected value.';
					break;
				}
				case 'ExpiredCodeException': {
					message = 'The provided code has expired.';
					break;
				}
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'InvalidPasswordException': {
					if (includes(toLower(err.message), 'security')) {
						message = 'There may be a key word in your password that is not allowed. Please try another password';
					} else {
						message =
							'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number. The keyword "password" is not allowed.';
					}
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}

			this.props.setComponentState({
				errorMessage: message,
			});
		}
		if (skipRedirect) {
			this.props.showLoader(false);
		}
	};

	resendConfirmationCode = async () => {
		let { username, history } = this.props;
		username = trim(toLower(username));

		try {
			await Auth.forgotPassword(username);
		} catch (err) {
			let message;
			switch (err && err.code) {
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'LimitExceededException': {
					message = 'Attempt limit exceeded, please try after some time.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.props.setComponentState({
				errorMessage: message,
			});
		}
	};

	formValidation = () => {
		const { username, password, password2, code } = this.props;

		if (username.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter your password',
			});
			return false;
		}

		if (code.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter code',
			});
			return false;
		}

		if (password !== password2) {
			this.props.setComponentState({
				errorMessage: 'Passwords do not match',
			});
			return false;
		}

		if (password.length < 8) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least 8 characters',
			});
			return false;
		}

		if (!some(password, char => char === toUpper(char))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one uppercase character',
			});
			return false;
		}

		if (!some(password, char => char === toLower(char))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one lowercase character',
			});
			return false;
		}

		if (!some(password, char => !isNaN(parseInt(char)))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one number',
			});
			return false;
		}

		if (!specialCharactersRegex.test(password)) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one special character',
			});
			return false;
		}

		return true;
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	render = () => {
		const {
			errorMessage,
			username,
			code,
			password,
			password2,
			hideBackButton,
			hideEmail,
			hideNewPasswordHeader,
		} = this.props;
		return (
			<form className="membership__form form" onSubmit={this.handleSubmit}>
				{/* In order to trick chrome into not prefilling our inputs we have to add fake inputs and hide them away from users ... https://bugs.chromium.org/p/chromium/issues/detail?id=914451#c66*/}
				<input
					type="password"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				></input>
				<input
					type="username"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				></input>
				<div
					className="membership__spacer"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				>
					<label className="membership__label">
						<span className="type--none">Email </span>
					</label>
					<input name="code" type="text" className="input input--med" placeholder="000000" tabIndex="2" />
				</div>
				<div className="membership__section membership__section--set-pass">
					{!hideNewPasswordHeader && <h2 className="membership__title">Set new password</h2>}
					{!hideEmail && (
						<div className="membership__spacer">
							<label className="membership__label">Email</label>
							<input
								name="username"
								disabled={true}
								type="email"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
								tabIndex="1"
								inputMode="email"
							/>
						</div>
					)}
					<div className="membership__spacer">
						<label className="membership__label">
							Code
							<span className="required-field type--color--primary spc--left--nano" data-tooltip="Required">
								*
							</span>
							<span className="spc--right--xsml spc--left--xsml">-</span>
							<span className="type--none">Email with code has been sent</span>
						</label>
						<input
							name="code"
							type="text"
							className="input input--med"
							placeholder="000000"
							value={code}
							onChange={this.handleChange}
							tabIndex="2"
						/>
					</div>
					<div className="membership__spacer">
						<label className="membership__label">
							New Password
							<span className="required-field type--color--primary spc--left--nano" data-tooltip="Required">
								*
							</span>
						</label>
						<PasswordInput
							value={password}
							onChange={this.handleChange}
							tabIndex="3"
							setErrorMessage={this.setErrorMessage}
						/>
					</div>
					<div className="membership__spacer">
						<label className="membership__label">
							Confirm Password
							<span className="required-field type--color--primary spc--left--nano" data-tooltip="Required">
								*
							</span>
						</label>
						<PasswordInput
							placeholder="Confirm your password"
							name="password2"
							value={password2}
							onChange={this.handleChange}
							tabIndex="4"
							setErrorMessage={this.setErrorMessage}
						/>
						{errorMessage ? (
							<div className="spc--top--sml spc--bottom--med type--color--warning">{errorMessage}</div>
						) : null}
					</div>
					<button type="submit" className="btn btn--primary btn--med" tabIndex="5">
						Save <span className="hide--to--med--inline spc--left--tny">new password</span>
					</button>
					<button
						type="button"
						className="btn btn--primary btn--med spc--left--sml"
						onClick={this.resendConfirmationCode}
						tabIndex="6"
					>
						Resend code
					</button>
					{!hideBackButton && (
						<Fragment>
							<button
								type="button"
								className="btn btn--ghost btn--med spc--left--sml"
								onClick={this.redirectToForgotPassword}
								tabIndex="7"
							>
								Back
							</button>

							<div className="membership--main__action spc--top--med">
								<span className="membership--main__label">Do you have an account?</span>{' '}
								<a
									href="javascript:void(0)"
									onClick={this.redirectToLogin}
									className="anchor anchor--primary type--wgt--medium spc--left--nano"
								>
									Log in
								</a>
							</div>
						</Fragment>
					)}
				</div>
			</form>
		);
	};
}

ConfirmPasswordBody.propTypes = {
	errorMessage: PropTypes.string,
	username: PropTypes.string,
	code: PropTypes.string,
	password: PropTypes.string,
	password2: PropTypes.string,
	notifyPasswordChange: PropTypes.func,
	location: PropTypes.any,
	history: PropTypes.any,
	setComponentState: PropTypes.func,
	hideBackButton: PropTypes.any,
	skipRedirect: PropTypes.any,
	showLoader: PropTypes.func,
	hideEmail: PropTypes.bool,
	hideNewPasswordHeader: PropTypes.bool,
};

export default ConfirmPasswordBody;
