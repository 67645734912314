import React, { Component } from 'react';
import { DayPicker } from 'react-day-picker';
import { PatternFormat as NumberFormat } from 'react-number-format';
import 'react-day-picker/dist/style.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import { cloneDeep, map, split, get } from 'lodash';

import TimePicker from '../../components/react-time-picker';
import { renderIf } from '../../utilities';

const dateFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;
const timeFormat = process.env.REACT_APP_TIME_FORMAT;

class DateFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bottomBarrier: moment()
				.subtract(20, 'years')
				.toDate(),
			upperBarrier: moment().toDate(),
			from: this.props.from,
			to: this.props.to,
			maxDaysRange: this.props.maxDaysRange || 100,
			tempFrom: moment(this.props.from).format(dateFormat),
			tempTo: moment(this.props.to).format(dateFormat),
			fromTime: moment(this.props.from).format(timeFormat),
			toTime: moment(this.props.to).format(timeFormat),
			enteredTo: this.props.to,
			applyDisabled: false,
			errors: {
				from: null,
				to: null,
				range: null,
			},
		};
	}

	isSelectingFirstDay = (from, to, day, isFromBeforeTo, disableShorteningRange) => {
		if (!from) {
			return true;
		}
		const dayMoment = this.parseDate(day, null, dateFormat);
		const fromMoment = this.parseDate(from, null, dateFormat);
		const toMoment = this.parseDate(to, null, dateFormat);
		const isBeforeFirstDay = dayMoment.isBefore(fromMoment) || (dayMoment.isSame(fromMoment) && isFromBeforeTo);
		const isRangeSelected = !!to;
		const isAfterLastDay = dayMoment.isAfter(toMoment) || (dayMoment.isSame(toMoment) && !isFromBeforeTo);
		return isBeforeFirstDay || (isRangeSelected && (disableShorteningRange || isAfterLastDay));
	};

	getUpperBarrier = day => {
		const { maxDaysRange } = this.state;
		const maxUpperBarrier = moment().toDate();
		let upperBarrier =
			maxDaysRange === Infinity
				? maxUpperBarrier
				: moment(day)
						.add(maxDaysRange, 'days')
						.toDate();
		if (upperBarrier > maxUpperBarrier) {
			upperBarrier = maxUpperBarrier;
		}
		return upperBarrier;
	};

	getBottomBarrier = day => {
		const { maxDaysRange } = this.state;
		return moment(day)
			.subtract(maxDaysRange, 'days')
			.toDate();
	};

	handleDayClick = day => {
		const { from, to, fromTime, toTime, errors } = this.state;
		const { displayTime } = this.props;
		const isFromBeforeTo =
			displayTime && fromTime && toTime && moment(fromTime, timeFormat).isAfter(moment(toTime, timeFormat));

		if (this.isSelectingFirstDay(from, to, day, isFromBeforeTo)) {
			errors.from = null;
			errors.range = null;
			const upperBarrier = this.getUpperBarrier(day);
			const bottomBarrier = this.getBottomBarrier(day);
			this.setState({
				from: day,
				tempFrom: moment(day).format(dateFormat),
				to: null,
				enteredTo: null,
				tempTo: null,
				// Restrict date range on first date click
				bottomBarrier,
				upperBarrier,
				applyDisabled: true,
				errors,
			});
		} else {
			errors.to = null;
			errors.range = null;
			this.setState({
				to: day,
				tempTo: moment(day).format(dateFormat),
				enteredTo: day,
				bottomBarrier: moment()
					.subtract(20, 'years')
					.toDate(),
				upperBarrier: moment().toDate(),
				applyDisabled: false,
				errors,
			});
		}
	};

	handleDayMouseEnter = day => {
		const { from, to, fromTime, toTime } = this.state;
		const { displayTime } = this.props;
		const isFromBeforeTo =
			displayTime && fromTime && toTime && moment(fromTime, timeFormat).isAfter(moment(toTime, timeFormat));
		if (!this.isSelectingFirstDay(from, to, day, isFromBeforeTo, true)) {
			this.setState({
				enteredTo: day,
			});
		}
	};

	handleOnChange = (from, to, fromTime, toTime) => {
		let filterRange = cloneDeep({ from, to, fromTime, toTime });
		filterRange.from = moment(filterRange.from).startOf('day');
		filterRange.to = moment(filterRange.to).endOf('day');
		if (this.props.displayTime) {
			const [[fromHours, fromMinutes], [toHours, toMinutes]] = map([filterRange.fromTime, filterRange.toTime], time =>
				split(time, ':')
			);
			if (filterRange.fromTime) {
				filterRange.from.hours(fromHours).minutes(fromMinutes);
			}
			if (filterRange.toTime) {
				filterRange.to.hours(toHours).minutes(toMinutes);
			}
		}

		let dateRangeText;
		if (this.props.displayTime) {
			const diff = filterRange.to.diff(filterRange.from, 'days');
			dateRangeText = diff ? `Custom (${diff} days)` : 'Custom';
		} else {
			try {
				dateRangeText = `${filterRange.from.format(process.env.REACT_APP_CHECK_DATE_FORMAT)} - ${filterRange.to.format(
					process.env.REACT_APP_CHECK_DATE_FORMAT
				)}`;
			} catch (error) {
				// Intentionally empty catch block
			}
		}

		this.props.onActiveFilterChanged({
			id: this.props.filter.key,
			values: [
				{ key: 'key', value: 'custom' },
				{ key: get(this.props.filter, 'props.startKey', 'startDate'), value: filterRange.from },
				{ key: get(this.props.filter, 'props.endKey', 'endDate'), value: filterRange.to },
			],
			emptyValue: moment().startOf('day'),
			selectionText: dateRangeText,
		});
	};

	applyInputChange = e => {
		e.preventDefault();
		const { from, to, fromTime, toTime } = this.state;
		this.handleOnChange(from, to, fromTime, toTime);
	};

	parseDate = (value, fallback, format) => {
		return moment(value !== null ? value : fallback, format);
	};

	getRangeError = ({ fromInput = null, toInput = null, fromTimeInput = null, toTimeInput = null }) => {
		const { maxDaysRange, from, to, toTime, fromTime } = this.state;

		const fromDate = this.parseDate(fromInput, from, dateFormat);
		const toDate = this.parseDate(toInput, to, dateFormat);
		const fromTimeDate = this.parseDate(fromTimeInput, fromTime, timeFormat);
		const toTimeDate = this.parseDate(toTimeInput, toTime, timeFormat);

		const dateDiff = toDate.diff(fromDate, 'days');

		if (toDate.isAfter(this.getUpperBarrier(), 'day')) {
			return 'End date cannot be greater than today';
		}
		if (dateDiff < 0 || (dateDiff === 0 && fromTimeDate.isAfter(toTimeDate))) {
			return 'Invalid date range';
		}
		if (dateDiff > maxDaysRange) {
			return `Date range cannot exceed ${maxDaysRange} days`;
		}

		return null;
	};

	handleInputFromChange = values => {
		const { formattedValue } = values;
		const { errors, to } = this.state;
		let disableApply = !to;

		if (moment(formattedValue, dateFormat, true).isValid()) {
			errors.from = null;
			errors.range = this.getRangeError({ fromInput: formattedValue });
			const from = moment(formattedValue, dateFormat, true).toDate();
			const upperBarrier = this.getUpperBarrier(from);
			const bottomBarrier = this.getBottomBarrier(from);
			disableApply = disableApply || !!errors.range;
			this.setState({
				errors,
				from: moment(formattedValue, dateFormat, true).toDate(),
				upperBarrier,
				bottomBarrier,
			});
		} else {
			errors.from = 'Invalid Start date';
			errors.range = null;
			disableApply = true;
		}

		this.setState({
			errors,
			tempFrom: formattedValue,
			applyDisabled: disableApply,
		});
	};

	handleInputToChange = values => {
		const { formattedValue } = values;
		const { errors, from } = this.state;
		let disableApply = !from;

		if (moment(formattedValue, dateFormat, true).isValid()) {
			errors.to = null;
			errors.range = this.getRangeError({ toInput: formattedValue });
			disableApply = disableApply || !!errors.range;
			this.setState({
				errors,
				to: moment(formattedValue, dateFormat, true).toDate(),
				enteredTo: moment(formattedValue, dateFormat, true).toDate(),
			});
		} else {
			errors.to = 'Invalid End date';
			errors.range = null;
			disableApply = true;
		}

		this.setState({
			errors,
			tempTo: formattedValue,
			applyDisabled: disableApply,
		});
	};

	handleFromTimeChange = time => {
		const { errors } = this.state;

		errors.range = this.getRangeError({ fromTimeInput: time });
		this.setState({
			errors,
			fromTime: time,
			applyDisabled: !!errors.range,
		});
	};

	handleToTimeChange = time => {
		const { errors } = this.state;

		errors.range = this.getRangeError({ toTimeInput: time });
		this.setState({
			errors,
			toTime: time,
			applyDisabled: !!errors.range,
		});
	};

	render() {
		const {
			bottomBarrier,
			upperBarrier,
			from,
			tempFrom,
			tempTo,
			fromTime,
			toTime,
			enteredTo,
			errors,
			applyDisabled,
		} = this.state;
		const modifiers = { start: from, end: enteredTo };
		const disabledDays = { before: bottomBarrier, after: upperBarrier };
		const selectedDays = { from, to: enteredTo };

		return (
			<div className="inputfromto__holder Selectable">
				<div className="inputfromto__heading">
					<div className="row">
						<div className="col col-sml-12 col-lrg-9">
							<div className="row">
								<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
									<NumberFormat
										value={tempFrom}
										format="##/##/####"
										className="input input--med input--date"
										placeholder={dateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={this.handleInputFromChange}
									/>
									{renderIf(errors.from)(<p className="type--color--primary">{errors.from}</p>)}
								</div>
								<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
									<NumberFormat
										value={tempTo}
										format="##/##/####"
										className="input input--med input--date"
										placeholder={dateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={this.handleInputToChange}
									/>
									{renderIf(errors.to)(<p className="type--color--primary">{errors.to}</p>)}
								</div>
							</div>
							{renderIf(errors.range)(<p className="type--color--primary">{errors.range}</p>)}
						</div>
						{this.props.displayTime ? (
							<div className="row">
								<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
									<div className="timepicker">
										<div className="timepicker__label">From</div>
										<div className="timepicker__value">
											<TimePicker
												value={fromTime}
												className="input input--time"
												clockIcon={null}
												clearIcon={null}
												disableClock={true}
												maxDetail="minute"
												onChange={this.handleFromTimeChange}
												locale="en-US"
											/>
										</div>
									</div>
								</div>
								<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
									<div className="timepicker">
										<div className="timepicker__label">To</div>
										<div className="timepicker__value">
											<TimePicker
												value={toTime}
												className="input input--time"
												clockIcon={null}
												clearIcon={null}
												disableClock={true}
												maxDetail="minute"
												onChange={this.handleToTimeChange}
												locale="en-US"
											/>
										</div>
									</div>
								</div>
							</div>
						) : null}
						<div className="col col-sml-12 col-lrg-3">
							<button
								type="button"
								onClick={this.applyInputChange}
								disabled={applyDisabled}
								className="btn btn--med btn--primary fullwidth spc--bottom--sml"
							>
								Apply
							</button>
						</div>
					</div>
				</div>

				<div className="inputfromto__body">
					<style>
						{`
							${
								this.props.maxDaysRange === Infinity
									? `
									.DayPicker-Day--disabled:hover:after { display: none; }
								`
									: `
									.DayPicker-Day--disabled:hover:before {
										content: 'You cannot select dates outside of the chosen range or select future dates.';
									}
								`
							}
						`}
					</style>
					<DayPicker
						mode="range"
						className="Range"
						numberOfMonths={2}
						fromMonth={bottomBarrier}
						toMonth={upperBarrier}
						selected={selectedDays}
						disabled={disabledDays}
						modifiers={modifiers}
						onDayClick={this.handleDayClick}
						onDayMouseEnter={this.handleDayMouseEnter}
					/>
				</div>
			</div>
		);
	}
}

DateFilterComponent.propTypes = {
	from: PropTypes.instanceOf(Date),
	to: PropTypes.instanceOf(Date),
	maxDaysRange: PropTypes.number,
	onChange: PropTypes.func,
	onApplyFilter: PropTypes.func,
	displayTime: PropTypes.bool,
};

export default DateFilterComponent;
