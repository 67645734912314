import React, { Component, createRef } from 'react';
import { string, object } from 'prop-types';
import { each, debounce, findIndex } from 'lodash';

class TooltipFormatterComponent extends Component {
	constructor(props, instances) {
		super(props);
		this.instances = instances;
		this.instances.push(this);
		this.elementRef = createRef();
		this.tooltipClass = '';
	}

	get tooltip() {
		return 'Missing tooltip implementation';
	}

	componentWillUnmount() {
		const index = findIndex(this.instances, this);
		if (index > -1) {
			this.instances.splice(index, 1);
		}
	}

	displayPopup = () => {
		this.cancelClose();
		const { dependentValues } = this.props;

		const popup = (
			<div className={this.tooltipClass} onMouseEnter={this.cancelClose} onMouseLeave={this.closePopup}>
				{this.tooltip}
			</div>
		);
		const rect = this.elementRef.current.getBoundingClientRect();
		dependentValues.onInfoHover(
			{
				width: (rect.left || 0) + (rect.width / 2 || 0),
				height: rect.top || 0,
				center: rect.width / 2 || 0,
			},
			popup
		);
	};

	closePopup = () => {
		this.props.dependentValues.onInfoHover({}, null);
	};

	closePopupLater = debounce(this.closePopup, 200);

	cancelClose = () => {
		each(this.instances, ({ closePopupLater }) => {
			closePopupLater.cancel();
		});
	};
}

TooltipFormatterComponent.propTypes = {
	value: string.isRequired,
	dependentValues: object,
};

export default TooltipFormatterComponent;
