import { identity } from 'lodash';

import { CurrencyComponent } from '../../common/formatters';

export const settlementColumns = [
	{
		key: 'date',
		name: 'Date',
		visible: true,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'paidByMerchantServices',
		name: 'Paid by Merchant Services',
		formatter: CurrencyComponent,
		getRowMetaData: identity,
		visible: true,
		initWidth: 200,
		resizable: true,
	},
	{
		key: 'paidByOthers',
		name: 'Paid by Others',
		formatter: CurrencyComponent,
		getRowMetaData: identity,
		visible: true,
		initWidth: 200,
		resizable: true,
	},
	{
		key: 'totalSettled',
		name: 'Total Settled',
		formatter: CurrencyComponent,
		getRowMetaData: identity,
		visible: true,
		initWidth: 200,
		resizable: true,
	},
	{
		key: 'store',
		name: 'Store No.',
		visible: true,
		initWidth: 150,
		resizable: true,
	},
];
