import React, { useState, useEffect, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { appService } from '../../services';

function PlaidApp(props) {
	const [token, setToken] = useState(null);

	const onSuccess = useCallback(async publicToken => {
		props.onConnected(publicToken);
	}, []);

	const onExit = useCallback(async () => {
		props.onExit();
	}, []);

	// Creates a Link token
	const createLinkToken = React.useCallback(async () => {
		const response = await appService.getPlaidLinkToken(props.appId);
		props.setLinkToken(response.linkToken.link_Token);
		setToken(response.linkToken.link_Token);
	}, [setToken]);

	const config = {
		token,
		onSuccess,
		onExit,
	};

	const { open, ready } = usePlaidLink(config);

	useEffect(() => {
		if (token == null) {
			props.onLoading();
			createLinkToken();
		}
		if (ready) {
			open();
		}
	}, [token, ready]);

	return (
		<React.Fragment>
			<div style={{ backgroundColor: 'rgba(144, 144, 144, 0.98)' }}></div>
		</React.Fragment>
	);
}

export default PlaidApp;
