import { some, toLower, trim } from 'lodash';
import { phoneNumber, email } from './validators';

const name = (isRequired = false) => val => {
	if (!val && !isRequired) {
		return true;
	}

	return !some(['unknown', 'none', ''], item => toLower(trim(val)) === item);
};
export const eids = (val, ctx) => (toLower(ctx.type) == 'eids' ? !!val : true);

export const bankingServicesFormTemplate = {
	category: {
		type: String,
		required: true,
		use: { name: name(true) },
		message: {
			required: '[**Banking Service**](javascript:void) is required',
			name: '[**Banking Service**](javascript:void) is invalid',
		},
	},
	type: {
		type: String,
		required: true,
		use: { name: name(true) },
		message: {
			required: '[**Subtype**](javascript:void) is required',
			name: '[**Subtype**](javascript:void) is invalid',
		},
	},
	fields: {
		MyMerchantSignatureName: {
			type: String,
			required: false,
			use: { name },
			message: {
				required: '[**Merchant Email To Name**](javascript:void) is required',
				name: '[**Merchant Email To Name**](javascript:void) is invalid',
			},
		},
		MyMerchantSignatureEmail: {
			type: String,
			required: false,
			use: { email: email(true) },
			message: {
				required: '[**Merchant Email To Address**](javascript:void) is required',
				email: '[**Merchant Email To Address**](javascript:void) is invalid',
			},
		},
		ContactName: {
			type: String,
			required: true,
			use: { name: name(true) },
			message: {
				required: '[**Contact Name**](javascript:void) is required',
				name: '[**Contact Name**](javascript:void) is invalid',
			},
		},
		ContactEmail: {
			type: String,
			required: true,
			use: { email: email(true) },
			message: {
				required: '[**Contact Email**](javascript:void) is required',
				email: '[**Contact Email**](javascript:void) is invalid',
			},
		},
		ContactPhone: {
			type: String,
			required: true,
			use: { phoneNumber },
			message: {
				required: '[**Call Back Number**](javascript:void) is required',
				phoneNumber: '[**Call Back Number**](javascript:void) is invalid',
			},
		},
		ContactMethod: {
			type: String,
			required: true,
			use: { name: name(true) },
			message: {
				required: '[**Contact Method**](javascript:void) is required',
				name: '[**Contact Method**](javascript:void) is invalid',
			},
		},
		EIDS_Email: {
			type: String,
			required: false,
			use: { eids, email: email(true) },
			message: {
				eids: '[**EIDS Email**](javascript:void) is required',
				required: '[**EIDS Email**](javascript:void) is required',
				email: '[**EIDS Email**](javascript:void) is invalid',
			},
		},
		EIDS_MerchantPrice: {
			type: Number,
			required: false,
			use: { eids },
			message: {
				eids: '[**EIDS Merchant Price**](javascript:void) is required',
				required: '[**EIDS Merchant Price**](javascript:void) is required',
				type: '[**EIDS Merchant Price**](javascript:void) is invalid',
			},
		},
	},
};
