import React, { Component } from 'react';
import { map, find } from 'lodash';

import { getApiConfiguration, setApiConfiguration } from '../../common/utilities/apiConfiguration';
import devCognito from '../../config/aws-exports.dev';
import prdCognito from '../../config/aws-exports.prd';
import { appService } from '../../services';

const apiOptions = [
	{
		label: 'dev',
		value: {
			apiUrl: 'https://us-east-2.devpartnerapi.cardknox.com/api',
			userPoolId: devCognito.userPoolId,
			userPoolWebClientId: devCognito.userPoolWebClientId,
		},
	},
	{
		label: 'prod',
		value: {
			apiUrl: 'https://us-east-2.partnerapi.cardknox.com/api',
			userPoolId: prdCognito.userPoolId,
			userPoolWebClientId: prdCognito.userPoolWebClientId,
		},
	},
	{
		label: 'stg',
		value: {
			apiUrl: 'https://stg-us-east-2.partnerapi.cardknox.com/api',
			userPoolId: prdCognito.userPoolId,
			userPoolWebClientId: prdCognito.userPoolWebClientId,
		},
	},
];

export default class ChangeApiUrl extends Component {
	constructor() {
		super();
		this.state = {
			isSubmitting: false,
			configuration: getApiConfiguration(),
		};
		const selectedConfiguration = find(apiOptions, ({ value }) => value.apiUrl === this.state.configuration.apiUrl);
		if (selectedConfiguration) {
			this.state.selectedConfiguration = selectedConfiguration.label;
		}
	}

	handleChange = ({ target: { value } }) => {
		const newConfiguration = find(apiOptions, { label: value });
		this.setState({
			selectedConfiguration: value,
			configuration: newConfiguration ? newConfiguration.value : null,
		});
	};

	handleSubmit = async e => {
		e.preventDefault();
		if (this.state.isSubmitting) return;
		this.setState({
			isSubmitting: true,
		});
		setApiConfiguration(this.state.configuration);
		await appService.logout();
		window.location.reload();
		this.setState({
			isSubmitting: false,
		});
	};

	render() {
		return (
			<div id="main-div" className="l--content l--content--equipment">
				<div className="header header--grid">
					<h2 className="header__title">Change API Url</h2>
				</div>
				<div>
					<form onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label htmlFor="apiUrl">
								New API Url
								<span data-tooltip="Required" className="type--color--primary">
									{' '}
									*
								</span>
							</label>
							<select
								className="input input--med input--select"
								id="apiUrl"
								name="apiUrl"
								value={this.state.selectedConfiguration}
								onChange={this.handleChange}
							>
								<option value="">Default</option>
								{map(apiOptions, ({ label }) => (
									<option key={label} value={label}>
										{label}
									</option>
								))}
							</select>
						</div>
						<div className="spc--top--sml">
							<button disabled={this.state.isSubmitting} className="btn btn--med btn--primary" type="submit">
								Change
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
