import React, { createRef } from 'react';
import { keys } from 'lodash';

import { renderIf } from './../../common/utilities';
import { DisplayBooleanComponent } from '../../common/formatters';
import { Toggle, ToggleContainer } from './../../common/components/toggle';

const isExpand = true;

class BusinessInformation extends React.Component {
	toggleRef = createRef();
	showMoreForPrint = async () => {
		if (!this.toggleRef.current) {
			return;
		}
		const { isToggled, handleToggle } = this.toggleRef.current.state.toggles[
			keys(this.toggleRef.current.state.toggles)[0]
		];
		this.collapseAfterPrint = isToggled ? () => {} : handleToggle;
		if (!isToggled) {
			handleToggle();
		}
	};
	collapseAfterPrint = () => {};
	render() {
		const { merchant } = this.props;
		const taxableInfo =
			'Please note! Sales to this merchant are subject to NY sales tax. ' +
			'Non-profits and religious organizations must obtain a NY Exemption Certificate to be exempt from tax. ' +
			'Please advise merchant to fill out form 119.2 and mail it to NYS to obtain an Exemption Certificate. ' +
			'We do not have an exemption certificate on file.';
		//const taxableInfo = (
		//    <div className="datatooltip merchant__tooltip merchant__tooltip--alt">
		//        Please note! Sales to this merchant are subject to NY sales tax.
		//        Non-profits and religious organizations must obtain a NY Exemption Certificate to be exempt from tax.
		//        Please advise merchant to fill out form 119.2 and mail it to NYS to obtain an Exemption Certificate.
		//        <div className="type--wgt--bold">
		//            We do not have an exemption certificate on file.
		//        </div>
		//    </div>
		//);
		return (
			<ToggleContainer ref={this.toggleRef}>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<React.Fragment>
							<div className="card--primary card--sml">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Business Information</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorpName">
													Business Legal Name:{' '}
												</label>
												<label className="input input--med input--fake" name="lblCorpName" id="lblCorpName">
													{merchant.corporateName}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblOwnerName">
													Owner Name:{' '}
												</label>
												<label className="input input--med input--fake" name="lblOwnerName" id="lblOwnerName">
													{merchant.ownerName}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorpAddress">
													Corporate Address:{' '}
												</label>
												<label className="input input--med input--fake" name="lblCorpAddress" id="lblCorpAddress">
													{merchant.corporateAddress.streetAddress}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorporateAddressCity">
													Corporate City:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblCorporateAddressCity"
													id="lblCorporateAddressCity"
												>
													{merchant.corporateAddress.city}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorporateAddressState">
													Corporate State:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblCorporateAddressState"
													id="lblCorporateAddressState"
												>
													{merchant.corporateAddress.state}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorporateAddressZip">
													Corporate Zip:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblCorporateAddressZip"
													id="lblCorporateAddressZip"
												>
													{merchant.corporateAddress.zip}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblCorpPhone">
													Corp Phone:{' '}
												</label>
												<label className="input input--med input--fake" name="lblCorpPhone" id="lblCorpPhone">
													{merchant.corporatePhone}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblBusinessAddress">
													Physical Address:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblBusinessAddress"
													id="lblBusinessAddress"
												>
													{merchant.businessAddress.streetAddress}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblBusinessAddressCity">
													Physical City:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblBusinessAddressCity"
													id="lblBusinessAddressCity"
												>
													{merchant.businessAddress.city}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblBusinessAddressState">
													Physical State:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblBusinessAddressState"
													id="lblBusinessAddressState"
												>
													{merchant.businessAddress.state}
												</label>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 form__field">
											<div>
												<label className="label" htmlFor="lblBusinessAddressZip">
													Physical Zip:{' '}
												</label>
												<label
													className="input input--med input--fake"
													name="lblBusinessAddressZip"
													id="lblBusinessAddressZip"
												>
													{merchant.businessAddress.zip}
												</label>
											</div>
										</div>
										{renderIf(merchant.shouldShowTaxInfo)(
											<div className="col col-sml-12 col-med-6 spc--bottom--med">
												<div className="spc--top--med datatooltip--merchants form__field">
													<label className="label" htmlFor="lblTaxable">
														Taxable:{' '}
													</label>
													<span className="label--value" name="lblTaxable" id="lblTaxable">
														<DisplayBooleanComponent value={merchant.isTaxable} />
														{renderIf(merchant.isTaxable)(
															<i
																className="icon icon--tiny icon--info align--v--middle spc--left--tny"
																data-tooltip={taxableInfo}
															/>
														)}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

export default BusinessInformation;
