import React from 'react';
import { includes } from 'lodash';

import { Toggle, ToggleContainer } from './../../common/components/toggle';

const isExpand = true;

class GeneralInformation extends React.Component {
	handleAddEmail = () => {
		alert('placeholder for add email');
	};

	handleAddPhone = () => {
		alert('placeholder for add phone');
	};

	handleChange = e => {
		const { name, value } = e.target;
		this.props.onChange('generalInfo', name, value);
	};

	render() {
		const { generalInfo: info, errorListPaths, validateFieldOnBlur, isCanadaLead } = this.props;
		const dbaRequired = typeof this.props.dbaRequired === 'undefined' ? true : this.props.dbaRequired;
		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<React.Fragment>
							<div className={`flex--primary ${isToggled ? 'separator--grey1 spc--bottom--med' : ''}`}>
								<h6 className="spc--bottom--sml cursor--pointer" onClick={handleToggle}>
									General Information
								</h6>
								<i
									className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
										isToggled ? 'rotate--180' : ''
									}`}
									onClick={handleToggle}
								></i>
							</div>
							<div className={isToggled ? 'details__content' : 'display--n'}>
								{info.canUpdateStatus && (
									<div className="row">
										<div className="col col-sml-12 col-lrg-6">
											<div className="spc--bottom--med form__field">
												<label className="label">Change Status: </label>
												<select
													className="input input--med input--select"
													name="leadStatus"
													disabled={this.props.disabled}
													id="generalInfo_leadStatus"
													value={info.leadStatus}
													onChange={this.handleChange}
												>
													{info.statusOptions.map((s, i) => {
														return (
															<option key={i} value={s} checked={info.leadStatus === s}>
																{s}
															</option>
														);
													})}
												</select>
											</div>
										</div>
									</div>
								)}
								<div className="row">
									<div className="col col-sml-12 col-lrg-6">
										<div className="spc--bottom--med form__field">
											<label className="label">First name: </label>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="firstName"
												id="generalInfo_firstName"
												value={info.firstName}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
									<div className="col col-sml-12 col-lrg-6">
										<div className="spc--bottom--med form__field">
											<label className="label">Last Name:</label>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="lastName"
												id="generalInfo_lastName"
												value={info.lastName}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-sml-12 col-lrg-6">
										<div className="spc--bottom--med form__field">
											<label className="label">Title: </label>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="title"
												id="generalInfo_title"
												value={info.title}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
									<div className="col col-sml-12 col-lrg-6">
										<div className="spc--bottom--med form__field">
											<div className={dbaRequired ? 'required' : ''}>
												<label className="label">DBA:</label>
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className={`input input--med${
													includes(errorListPaths, 'generalInfo.dba') ? ' is-invalid' : ''
												}`}
												name="dba"
												id="generalInfo_dba"
												value={info.dba}
												onChange={this.handleChange}
												onBlur={validateFieldOnBlur}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-sml-12 col-lrg-6">
										<div className="form__field spc--bottom--med">
											<label className="label">{isCanadaLead ? 'Business Phone Number:' : 'Phone Number:'}</label>
											<div
												className={`inputgroup${
													includes(errorListPaths, 'generalInfo.phoneNumber') ? ' is-invalid' : ''
												}`}
											>
												<div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--bordered--reverse">
													<div className="fakeinput fakeinput--form fakeinput--med">+1</div>
												</div>
												<div className="inputgroup--main inputgroup--main--bordered inputgroup--main--bordered--reverse">
													<input
														disabled={this.props.disabled}
														type="text"
														className="input input--med"
														name="phoneNumber"
														id="generalInfo_phoneNumber"
														value={info.phoneNumber}
														onChange={this.handleChange}
														onBlur={validateFieldOnBlur}
													/>
												</div>
											</div>
											{/* ToDo - uncomment when backend is ready */}
											{/* <a className="anchor--primary anchor--underline spc--top--sml display--b" href="javascript:void(0);" onClick={this.handleAddPhone}>Add additional number</a> */}
										</div>
									</div>
									<div className="col col-sml-12 col-lrg-6">
										<div className="form__field">
											<label className="label">Email: </label>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="email"
												id="generalInfo_email"
												value={info.email}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

export default GeneralInformation;
