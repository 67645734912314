import ZebraRendererComponent from '../../../common/components/row/zebra-renderer';

class EquipmentRowRenderer extends ZebraRendererComponent {
	get zebraClass() {
		const {
			row: { gridRowNumber, isActiveEquipment },
		} = this.props;
		if (!isActiveEquipment) return 'grid-style-inactive';
		return gridRowNumber % 2 ? 'grid-style-odd' : 'grid-style-even';
	}
}

export default EquipmentRowRenderer;
