import React from 'react';
import { string, bool } from 'prop-types';

import { renderIf } from './../../common/utilities';
import { DisplayBooleanComponent } from '../../common/formatters';

class GeneralInformation extends React.Component {
	render() {
		const { lead, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <h6 className="spc--bottom--med">General Information</h6>}

					<div className="details__content">
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblBusinessName">
										Business Name:{' '}
									</label>
									<label className="input input--med input--fake" name="lblBusinessName" id="lblBusinessName">
										{lead.businessName}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblProcessedBy">
										Processed by:{' '}
									</label>
									<label className="input input--med input--fake" name="lblProcessedBy" id="lblProcessedBy">
										{lead.processedBy}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblAgentName">
										Agent Name:{' '}
									</label>
									<label className="input input--med input--fake" name="lblAgentName" id="lblAgentName">
										{lead.agentName}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblStatementMonth">
										Statement Month:{' '}
									</label>
									<label className="input input--med input--fake" name="lblStatementMonth" id="lblStatementMonth">
										{lead.statementMonth}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblCurrentBank">
										Current Bank:{' '}
									</label>
									<label className="input input--med input--fake" name="lblCurrentBank" id="lblCurrentBank">
										{lead.currentBank}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblMarket">
										Market:{' '}
									</label>
									<label className="input input--med input--fake" name="lblMarket" id="lblMarket">
										{lead.market}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

GeneralInformation.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

GeneralInformation.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default GeneralInformation;
