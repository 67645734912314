import { ascii, maxLength } from './validators';

export function addressValidation(prefix, streetMaxLength, cityMaxLength, homePrefix = '') {
	return {
		streetAddress: {
			use: { ascii, length: maxLength(streetMaxLength) },
			type: String,
			message: {
				required: `[**${prefix}${homePrefix}Address**](javascript:void) is required`,
				ascii: `[**${prefix}${homePrefix}Address**](javascript:void) contains invalid characters`,
				length: `[**${prefix}${homePrefix}Address**](javascript:void) is invalid. Maximum number of characters allowed is ${streetMaxLength}.`,
			},
		},
		city: {
			use: { ascii, length: maxLength(cityMaxLength) },
			type: String,
			message: {
				required: `[**${prefix}City**](javascript:void) is required`,
				ascii: `[**${prefix}City**](javascript:void) contains invalid characters`,
				length: `[**${prefix}City**](javascript:void) is invalid. Maximum number of characters allowed is ${cityMaxLength}.`,
			},
		},
		state: {
			use: { ascii },
			type: String,
			message: {
				required: `[**${prefix}State**](javascript:void) is required`,
				ascii: `[**${prefix}State**](javascript:void) contains invalid characters`,
			},
		},
		zip: {
			use: { ascii, length: maxLength(16) },
			type: String,
			message: {
				required: `[**${prefix}Zip**](javascript:void) is required`,
				ascii: `[**${prefix}Zip**](javascript:void) contains invalid characters`,
				length: `[**${prefix}Zip**](javascript:void) is invalid. Maximum number of characters allowed is 16.`,
			},
		},
	};
}
