import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SourceKeyFilterComponent extends Component {
	get value() {
		return this.props.filter.values.sourceKey == null ? '' : this.props.filter.values.sourceKey;
	}

	componentDidMount() {
		this.ensureInvoiceCleared(this.props);
	}

	componentDidUpdate() {
		if (this.sourceKey) {
			this.sourceKey.focus();
		}
	}

	ensureInvoiceCleared = props => {
		if (props.filter.values.sourceKey === null) {
			this.sourceKey.value = '';
		}
	};

	setSourceKeyRef = el => {
		this.sourceKey = el;
	};

	onFilterChange = e => {
		this.props.onFilterChanged({
			id: 'sourceKey',
			values: [{ key: 'sourceKey', value: e.target.value }],
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="pos--rel">
				<input
					type="text"
					className="input input--number input--med"
					placeholder="Source key"
					value={this.value}
					id="sourceKey"
					onChange={this.onFilterChange}
					ref={this.setSourceKeyRef}
					autoFocus={true}
				/>
			</div>
		);
	}
}

SourceKeyFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
};

export default SourceKeyFilterComponent;
