import React, { Component, createRef, Fragment } from 'react';
import { map, find, get, noop, isEmpty, toLower, startsWith, replace, some, each } from 'lodash';
import Select from 'react-select';
import { func, bool, object } from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { dialCodesByCountryList } from './constants';
import { appService, principalService } from '../../services';
import { Auth } from 'aws-amplify';
import { cognitoErrorMap } from '../../common/utilities';
import { mapPasswordRequirements } from './loginUtils';
import { Notification } from '../../common/components/notifications';
import withError from '../../common/components/error/error-hoc';
import { withLoader } from '../../common/components';
import { withCancelable } from '../../common/components/cancelable';
import withBlock from '../../common/components/block/block-hoc';
import ConfirmPasswordBody from './ConfirmPasswordBody';
import { componentKeys, localStorageKeys } from '../stepup-mfa/stepupConstants';
import { removeState } from '../../helpers/persist-state';
import { withStepup } from '../stepup-mfa';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	CONFIRM: 'confirm',
	SEND: 'send',
	SAVE_VERIFY: 'saveVerify',
};

const internationalPhoneNumberRegex = /(\+\d{1,3})(\d{4,14})$/;

const phoneNumberTooltip = `Phone numbers must follow these formatting rules:
	A phone number can only contain digits. You must remove any other characters from a phone number,
	such as parentheses, spaces, or dashes (-) before submitting the value. For example,
	a United States-based phone number must follow this format: 4325551212.`;

const dialCodesList = map(dialCodesByCountryList, ({ dialCode, name }) => ({
	label: `${name} ${dialCode}`,
	value: dialCode,
}));

class Security extends Component {
	principal = principalService.get();
	constructor(props) {
		super(props);
		this.notificationRef = createRef();

		this.state = {
			isMfaRequired: get(props, 'location.state.isMfaRequired', false),
			username: this.principal.email,
			password: '',
			password2: '',
			isSamlLogin: this.principal.isSamlLogin,
			countryDialCode: '+1',
			accessToken: '',
			isSoftwareMfaEnabled: false,
			isSmsMfaEnabled: false,
			enableSmsMfa: false,
			enableSoftwareMfa: false,
			phoneNumberVerified: false,
			phoneNumber: '',
			errorMessages: [],
			user: null,
			code: '',
			mfaCode: '',
			challengeAnswer: '',
			confirmationCode: '',
			displayConfirmCode: false,
			errorMessage: '',
		};
	}

	get getCountryDialCode() {
		const { countryDialCode } = this.state;

		return find(dialCodesList, { value: countryDialCode }) || dialCodesList[0];
	}

	componentDidMount() {
		this.fetchData();
	}

	setComponentState = newState => {
		this.setState(newState);
	};

	fetchData = async () => {
		const { showLoader, makePendingRequest } = this.props;

		try {
			showLoader(true);
			await makePendingRequest(
				Promise.all([
					Auth.currentAuthenticatedUser().then(async user => {
						this.getUserData(user);
					}),
				]),
				requestKeys.FETCH
			);
			showLoader();
		} catch (e) {
			this.handleError(e, true);
		}
	};

	getCognitoUserDataAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserData(
				(err, data) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(data);
				},
				{ bypassCache: true }
			);
		});
	};

	getCognitoUserAttributesAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserAttributes((err, result) => {
				if (err) {
					reject(err);
					return;
				}
				resolve(result);
			});
		});
	};

	getUserData = async user => {
		const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', '');
		try {
			const data = await this.getCognitoUserDataAsync(user);
			const { PreferredMfaSetting, UserMFASettingList } = data;
			const isSoftwareMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SOFTWARE_TOKEN_MFA');
			const isSmsMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SMS_MFA');
			const enableSoftwareMfa = PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA';
			const enableSmsMfa = PreferredMfaSetting === 'SMS_MFA';

			const newState = {
				user,
				isSoftwareMfaEnabled,
				enableSoftwareMfa,
				isSmsMfaEnabled,
				enableSmsMfa,
			};
			this.setState(newState);
		} catch (err) {
			this.handleError(err, true);
		}

		try {
			const result = await this.getCognitoUserAttributesAsync(user);
			this.mapUserResponseToState(result, accessToken, user);
		} catch (err) {
			this.handleError(err, true);
		}

		/*
		Future - add display of current device
		user.getCachedDeviceKeyAndPassword();
		user.getDevice({
			onSuccess: function(result) {
				console.log('getDevice call result: ');
				console.log(result);
			},
			onFailure: function(err) {
				console.log('GetDevice error: ');
				console.log(err.message || JSON.stringify(err));
			},
		});*/
	};

	initializeSoftwareToken = async (useLoader = true) => {
		const showLoader = useLoader ? this.props.showLoader : noop;

		showLoader(true);
		Auth.setupTOTP(this.state.user).then(mfaCode => {
			this.setState({ mfaCode }, showLoader);
		});
	};

	updatePhoneNumber = async phoneNumber => {
		const { user, countryDialCode } = this.state;
		const phone_number = phoneNumber ? countryDialCode + phoneNumber : phoneNumber;

		this.setState({ prevSavedPhoneNumber: phoneNumber, successMessage: '', errorMessage: '' });
		await Auth.updateUserAttributes(user, { phone_number });
	};

	save = async () => {
		const { showLoader } = this.props;
		const { user, enableSoftwareMfa } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		let error = null;
		let ref = null;

		try {
			showLoader(true);
			if (enableSoftwareMfa) {
				await Auth.setPreferredMFA(user, 'TOTP');
			} else {
				await Auth.setPreferredMFA(user, 'SMS');
			}

			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			error = this.handleError(e, true, { delayMessage: true });
			if (error) {
				showLoader(true);
			}
		}

		await this.refreshData(true, error);

		if (!error) {
			addNotification({
				message: `Multi Factor Authentication Settings Updated`,
				success: true,
				ref,
			});
			showLoader();
			each(componentKeys, (value, key) => {
				removeState(localStorageKeys.MFA_CONFIRMED_TIME(value));
				removeState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(value));
			});
		}
	};

	refreshData = async (refreshData, error) => {
		const { showLoader } = this.props;
		const { errorMessages } = this.state;

		if (refreshData) {
			try {
				if (isEmpty(errorMessages) && error) {
					error.show();
				}
				this.fetchData();
			} catch (e) {
				showLoader();
				this.handleError(e);
			}
		}
	};

	mapUserResponseToState = (UserAttributes, accessToken, user) => {
		const phone_number = get(
			find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number'),
			'Value',
			''
		);
		const countryDialCode = get(
			find(dialCodesList, ({ value }) => value && startsWith(phone_number, value)),
			'value',
			'+1'
		);
		const phoneNumber = replace(phone_number, countryDialCode, '');
		const phoneNumberVerified = get(
			find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number_verified'),
			'Value',
			'false'
		);

		const newState = {
			user,
			accessToken,
			phoneNumber,
			prevSavedPhoneNumber: phoneNumber,
			countryDialCode,
			phoneNumberVerified: phoneNumberVerified === 'true',
			prevSavedPhoneVerified: phoneNumberVerified === 'true',
		};

		this.setState(newState);
	};

	validateFields = () => {
		const { phoneNumber, enableSmsMfa, countryDialCode } = this.state;
		const newState = { errorMessages: [] };

		if (!internationalPhoneNumberRegex.test(countryDialCode + phoneNumber)) {
			if (enableSmsMfa) {
				newState.errorMessages.push({ key: 'phoneNumber', message: 'Valid Phone Number is required.' });
			} else {
				newState.phoneNumber = '';
				newState.countryDialCode = '+1';
			}
		}

		this.setState(newState);

		return !isEmpty(newState.errorMessages);
	};

	validateField = fieldKey => {
		const { errorMessages } = this.state;
		let className = '';

		if (some(errorMessages, ({ key }) => key === fieldKey)) {
			className = ' is-invalid';
		}

		return className;
	};

	handleSubmitConfirmationCode = ({ target: { value, name } }) => {
		const newState = { [name]: value };

		this.setState(newState, this.validateField);
	};

	handleSubmitTotp = async event => {
		event.preventDefault();
		if (!this.formValidation(this.state.challengeAnswer)) {
			return;
		}

		this.props.showLoader(true);
		let { user, challengeAnswer } = this.state;

		Auth.verifyTotpToken(user, challengeAnswer)
			.then(async () => {
				await Auth.setPreferredMFA(user, 'TOTP');

				this.setState({
					errorMessage: null,
					successMessage: 'MFA set up successfully',
					isSoftwareMfaEnabled: true,
				});

				if (this.principal.redirectToSecurity) {
					principalService.set({ ...this.principal, redirectToSecurity: false });
					this.props.history.push({ pathname: '/' });
				}
				this.props.showLoader();
			})
			.catch(e => this.handleError(e));
	};

	handleSelectChange = ({ value }, { name }) => this.handleMfaChange({ target: { name, value } });

	handlePhoneNumberChange = ({ target: { name, value } }) => {
		const digitRegex = /^\d*$/gi;
		if (digitRegex.test(value)) {
			this.handleMfaChange({ target: { name, value } });
		}
	};

	handleMfaChange = ({ target: { name, value, type, checked } }) => {
		try {
			const { mfaCode, isSoftwareMfaEnabled, prevSavedPhoneNumber, prevSavedPhoneVerified } = this.state;
			const newState = { [name]: type === 'checkbox' ? checked : value };

			if (type === 'checkbox') {
				newState[name] = checked;

				newState.successMessage = '';
				newState.errorMessage = '';

				if (name === 'enableSmsMfa') {
					newState.enableSoftwareMfa = false;
				} else {
					newState.enableSmsMfa = false;
					newState.displayConfirmCode = false;
				}
			}

			if (newState.enableSoftwareMfa && !mfaCode && !isSoftwareMfaEnabled) {
				this.initializeSoftwareToken();
			}

			if (newState.phoneNumber) {
				newState.phoneNumberVerified = prevSavedPhoneNumber === newState.phoneNumber && prevSavedPhoneVerified;
			}

			this.setState(newState, this.validateFields);
		} catch (e) {
			this.handleError(e, true);
		}
	};

	resetSoftwareToken = () => {
		this.initializeSoftwareToken();
		this.setState({ isSoftwareMfaEnabled: false, enableSoftwareMfa: true });
	};

	notifyPasswordChange = () => {
		this.notificationRef.current.addNotification({
			success: true,
			message: 'Password changed successfully',
		});
		this.setState({ displayReset: false });
	};

	handlePasswordReset = async () => {
		try {
			this.props.showLoader(true);
			await Auth.forgotPassword(this.principal.email);
			this.setState({ displayReset: true });
		} catch (err) {
			let message;
			switch (err && err.code) {
				case 'LimitExceededException': {
					message = 'Attempt limit exceeded, please try after some time.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				passwordErrorMessage: message,
			});
		}
		this.props.showLoader();
	};

	notifyAuthError = () => {
		this.notificationRef.current.addNotification({
			message: 'To update MFA settings please login again.',
			success: false,
			forceCloseHandler: true,
			onClose: () => this.props.history.push({ pathname: '/login' }),
		});
	};

	handleError = (error, displayInPopup = false, errorOptions = {}) => {
		const { showLoader, handleError } = this.props;

		showLoader();
		if (error && error.isCanceled) return;
		if (error === 'not authenticated') {
			return this.notifyAuthError();
		}
		if ((error && !error.message && !error.code) || (error && error.message && error.code)) {
			return this.handleCognitoError(error, displayInPopup);
		} else {
			return handleError(error, errorOptions);
		}
	};

	yieldUint8Chunks = async data => {
		const reader = data.getReader();
		try {
			const { done, value } = await reader.read();

			if (done) {
				return;
			}

			return value;
		} finally {
			reader.releaseLock();
		}
	};

	handleCognitoError = async (error, displayInPopup = false) => {
		let err = error;
		let respBody = get(error, 'response.body');
		if (!err.message && !!respBody) {
			const unit8ChunksList = await this.yieldUint8Chunks(respBody);
			err = JSON.parse(new TextDecoder().decode(unit8ChunksList));
		}

		const defaultMessage = this.principal.redirectToSecurity ? (
			<span>
				Something went wrong. Please{' '}
				<a className="anchor anchor--primary" href="/logout">
					try logging in again.
				</a>
			</span>
		) : (
			'Something went wrong. Please try again.'
		);

		let message = cognitoErrorMap[err.__type] || cognitoErrorMap[err.code] || err.message || defaultMessage;

		if (message.message) {
			message = message.message;
		}

		if (displayInPopup) {
			this.props.handleError({ ...error, message });
		} else {
			this.setState({
				errorMessage: message,
				successMessage: null,
			});
		}
	};

	getButtonClassName = displayConfirmCode => (displayConfirmCode ? 'ghost' : 'primary');

	getButtonLabel = displayConfirmCode => (displayConfirmCode ? 'Resend Verification Code' : 'Verify Phone Number');

	formValidation = challengeAnswer => {
		if (challengeAnswer.length <= 0) {
			this.setState({
				errorMessage: 'Please enter the code to verify',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	confirmVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken, confirmationCode, user } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		if (!this.formValidation(confirmationCode)) {
			return;
		}

		try {
			showLoader(true);
			await makePendingRequest(
				appService
					.verifyUserAttribute(accessToken, 'phone_number', confirmationCode)
					.then(() =>
						this.setState({
							displayConfirmCode: false,
							confirmationCode: '',
							phoneNumberVerified: true,
							prevSavedPhoneVerified: true,
						})
					),
				requestKeys.CONFIRM
			);
			await Auth.setPreferredMFA(user, 'SMS');

			await this.fetchData();

			addNotification({
				message: `Multi Factor Authentication Settings updated`,
				success: true,
			});
			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			this.handleError(e);
		}
	};

	sendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		try {
			showLoader(true);
			const {
				CodeDeliveryDetails: { DeliveryMedium: deliveryMedium, Destination: destination },
			} = await makePendingRequest(
				appService.getUserAttributeVerificationCode(accessToken, 'phone_number'),
				requestKeys.SEND
			);
			this.setState({ displayConfirmCode: true });
			showLoader();

			addNotification({
				message: `${deliveryMedium} sent to ${destination}.`,
				success: true,
			});
		} catch (e) {
			this.handleError(e);
		}
	};

	savePhoneNumberAndSendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { phoneNumber } = this.state;

		try {
			showLoader(true);
			await makePendingRequest(this.updatePhoneNumber(phoneNumber), requestKeys.SAVE_VERIFY);
			this.sendVerificationCode();
		} catch (e) {
			this.handleError(e);
		}
	};

	renderSuccessMessages = () => {
		const { successMessage } = this.state;
		return (
			<div className="spc--bottom--sml fullwidth">
				{successMessage ? <div className="message message--sml message--success">{successMessage}</div> : null}
			</div>
		);
	};

	renderWarningMessages = () => {
		const { errorMessage } = this.state;

		return (
			<div className="spc--bottom--sml fullwidth">
				{errorMessage ? <div className="message message--sml message--warning">{errorMessage}</div> : null}
			</div>
		);
	};

	renderCountryDropdown = () => {
		return (
			<Fragment>
				<label htmlFor="countryDialCode" className="membership__label spc--bottom--nano">
					Country
				</label>
				<Select
					className="react-select-container"
					classNamePrefix="react-select"
					menuPlacement="auto"
					name="countryDialCode"
					id="countryDialCode"
					value={this.getCountryDialCode}
					options={dialCodesList}
					onChange={this.handleSelectChange}
					isDisabled={this.props.isLoading}
				/>
			</Fragment>
		);
	};
	renderResetButton = () => (
		<button className="btn btn--tny btn--primary" onClick={() => this.resetSoftwareToken()}>
			Reset
		</button>
	);
	renderMfaSection = () => {
		const {
			challengeAnswer,
			mfaCode,
			enableSoftwareMfa,
			isSoftwareMfaEnabled,
			enableSmsMfa,
			phoneNumber,
			displayConfirmCode,
			confirmationCode,
			phoneNumberVerified,
			user,
			username,
		} = this.state;

		const str = 'otpauth://totp/Cardknox:' + username + '?secret=' + mfaCode;
		return (
			<div>
				<div className="security__sms">
					<div className="flex--primary h--min--24">
						<input
							type="checkbox"
							className="input input--radio"
							id="enableSoftwareMfa"
							name="enableSoftwareMfa"
							onChange={this.handleMfaChange}
							value="enableSoftwareMfa"
							checked={enableSoftwareMfa}
							disabled={!user}
						/>
						<label className="label" htmlFor="enableSoftwareMfa">
							Enable Software MFA (Preferred)
						</label>
					</div>
					{isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && this.renderResetButton()}
				</div>
				<div className="flex--primary h--min--24 spc--bottom--sml">
					<input
						type="checkbox"
						className="input input--radio"
						id="enableSmsMfa"
						name="enableSmsMfa"
						onChange={this.handleMfaChange}
						value="enableSmsMfa"
						checked={enableSmsMfa}
						disabled={!user}
					/>
					<label className="label" htmlFor="enableSmsMfa">
						Enable SMS MFA
					</label>
				</div>

				{!isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && (
					<form className="form" onSubmit={this.handleSubmitTotp}>
						<div>
							{this.renderSuccessMessages()}
							<div className="membership__message">
								<div className="membership__message__description">
									<p>
										Install an MFA app on your smartphone or computer to use Multi Factor Authentication (MFA) for
										Cardknox logins.
									</p>
									<p>(Some examples are Authy, Google Authenticator, LastPass Authenticator.)</p>
									<br />
									<p>
										Scan or Copy the <span className="type--wgt--bold">code below</span> and paste it into your MFA app.
									</p>
									<br />
									<p>
										The MFA app will display a <span className="type--wgt--bold">confirmation code</span> to paste into
										the field below.
									</p>
									<br />
									<p>
										To help you set up Software MFA, we have created a{' '}
										<a
											className="anchor anchor--primary"
											href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
											rel="noopener noreferrer"
											target="_blank"
										>
											video
										</a>{' '}
										tutorial that walks you through the process step-by-step.
									</p>
								</div>
							</div>

							<label className="membership__label spc--bottom--nano">MFA Code</label>
							<div className="security__code spc--bottom--sml">
								<div className="aside">
									<QRCodeSVG value={str} />
								</div>
								<div className="separator">
									<span>or</span>
								</div>
								<div className="main">
									<input name="mfaCode" type="text" className="input input--med" disabled={true} value={mfaCode} />
								</div>
							</div>
							<div className="spc--bottom--sml">
								<label className="membership__label spc--bottom--nano">Confirmation code</label>
								<input
									name="challengeAnswer"
									type="text"
									className="input input--med"
									placeholder="000000"
									value={challengeAnswer}
									onChange={this.handleMfaChange}
								/>
							</div>
							{this.renderWarningMessages()}
							<div className="spc--bottom--sml spc--top--sml">
								<button
									type="submit"
									className="btn btn--primary btn--med"
									disabled={this.props.isLoading || !challengeAnswer}
								>
									<span className="spc--right--tny">Confirm</span>
									<span className="hide--to--sml--inline">Code and Save</span>
								</button>
							</div>
						</div>
					</form>
				)}
				{enableSmsMfa && (
					<div>
						{this.renderSuccessMessages()}
						<div className="row">
							<div className="col col-lrg-5 spc--bottom--sml">{this.renderCountryDropdown()}</div>
							<div className="col col-lrg-7">
								<div className="flex--primary">
									<div className="spc--bottom--sml datatooltip--w--300 datatooltip--left flex--grow--1">
										<label htmlFor="phoneNumber" className="membership__label spc--bottom--nano">
											Phone number
											<i
												data-tooltip={phoneNumberTooltip}
												className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
											></i>
										</label>
										<div className="flex--primary">
											<div className="flex--grow--1">
												<input
													id="phoneNumber"
													placeholder="Phone number"
													name="phoneNumber"
													className={`input input--med spc--bottom--tny ${this.validateField('phoneNumber')}`}
													value={phoneNumber || ''}
													onChange={this.handlePhoneNumberChange}
													disabled={this.props.isLoading}
												/>
											</div>
											{phoneNumberVerified ? (
												<div className="type--color--success type--wgt--bold spc--left--tny">
													<i className="icon icon--sml icon--check--success align--v--middle" />
													Verified
												</div>
											) : (
												<Fragment>
													<button
														className={`spc--bottom--tny btn btn--${this.getButtonClassName(
															displayConfirmCode
														)} btn--med spc--left--tny`}
														onClick={this.savePhoneNumberAndSendVerificationCode}
														disabled={this.props.isLoading || this.validateField('phoneNumber')}
													>
														{this.getButtonLabel(displayConfirmCode)}
													</button>
													{this.renderWarningMessages()}
												</Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="spc--bottom--sml">
							{displayConfirmCode && (
								<div className="spc--bottom--sml">
									<label htmlFor="confirmationCode" className="label">
										Confirmation Code
									</label>
									<input
										id="confirmationCode"
										placeholder="000000"
										name="confirmationCode"
										className={`input input--med${this.validateField('confirmationCode')}`}
										value={confirmationCode || ''}
										onChange={this.handleSubmitConfirmationCode}
									/>
								</div>
							)}
							<p className="message message--sml message--primary spc--bottom--sml">
								Message and data rates may apply. If you choose to receive MFA passwords via text messages, we'll send
								you one text message per login attempt. To stop receiving these messages, reply 'STOP.'
							</p>
							{displayConfirmCode && (
								<button
									className="btn btn--primary btn--med"
									onClick={this.confirmVerificationCode}
									disabled={this.props.isLoading}
								>
									Confirm
								</button>
							)}
						</div>
					</div>
				)}
				<div>{this.renderSaveButton()}</div>
			</div>
		);
	};
	renderPasswordRequirements = () => {
		const requirements = mapPasswordRequirements(this.state.password);
		if (!isEmpty(requirements)) {
			return (
				<div className="security__aside">
					<label className="type--plus--sml type--wgt--bold type--color--text--light spc--bottom--sml">
						Your password needs to contain at least:
					</label>
					<ul className="list--disc list--disc--primary type--color--text--light">
						{map(requirements, (item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</div>
			);
		}
	};

	renderPasswordResetSection = () => {
		const { username, password, password2, code, errorMessage } = this.state;
		return (
			<Fragment>
				{!this.state.displayReset && (
					<div>
						<button className="btn btn--primary btn--med spc--bottom--sml" onClick={this.handlePasswordReset}>
							Reset Password
						</button>
					</div>
				)}
				{this.state.displayReset && (
					<Fragment>
						<h2 className="type--base type--wgt--bold spc--bottom--sml--alt">Set new password</h2>

						<div className="security__reset">
							<ConfirmPasswordBody
								setComponentState={this.setComponentState}
								username={username}
								password={password}
								password2={password2}
								code={code}
								errorMessage={errorMessage}
								hideBackButton={true}
								skipRedirect={true}
								notifyPasswordChange={this.notifyPasswordChange}
								showLoader={this.props.showLoader}
								hideEmail={true}
								hideNewPasswordHeader={true}
							/>
							{this.renderPasswordRequirements()}
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderSaveButton = () => {
		const {
			enableSoftwareMfa,
			enableSmsMfa,
			isSoftwareMfaEnabled,
			isSmsMfaEnabled,
			phoneNumberVerified,
			mfaCode,
		} = this.state;

		const noMfa = !enableSoftwareMfa && !enableSmsMfa;
		const bothMfaMethodsAvailable = isSoftwareMfaEnabled && isSmsMfaEnabled;
		const bothMethodsVerified = phoneNumberVerified && !mfaCode;

		const canEnableSmsVerification = enableSmsMfa && phoneNumberVerified;

		return (
			<Fragment>
				{!noMfa && ((bothMfaMethodsAvailable && bothMethodsVerified) || canEnableSmsVerification) && (
					<button
						className="btn btn--primary btn--med settings__header__btn spc--bottom--sml"
						disabled={this.props.isLoading}
						onClick={() => this.save()}
					>
						Save
					</button>
				)}
			</Fragment>
		);
	};

	renderErrors = () => {
		const { errorMessages } = this.state;
		return map(errorMessages, ({ message, key }, index) => (
			<div key={`${index}.${key}`} className="spc--bottom--sml message message--warning">
				<p>{message}</p>
			</div>
		));
	};

	renderPasswordError = () => {
		const { passwordErrorMessage } = this.state;
		if (!passwordErrorMessage) return null;
		return (
			<div className="spc--bottom--sml message message--warning">
				<p>{passwordErrorMessage}</p>
			</div>
		);
	};
	renderSecurityConcernsText = () => {
		const headingStyles = {
			fontSize: '24px',
			fontWeight: 'bold',
		};

		return (
			<div className="spr__card clearfix">
				<div className="spr__heading clearfix">
					<h2 style={headingStyles}>Mandatory Multi-Factor Authorization (MFA) for All Accounts</h2>
				</div>
				<div className="spr__content">
					<p className="spc--bottom--sml">
						In an effort to offer maximum protection to our merchants and their customers, we have implemented a new
						security protocol that requires all accounts to have Multi-Factor Authorization (MFA) enabled. While in the
						past this was optional, due to a global uptick in phishing scams, it is now mandatory.
					</p>
					<p className="spc--bottom--sml">
						To help you set up Software MFA, we have created a{' '}
						<a
							className="anchor anchor--primary"
							href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
							rel="noopener noreferrer"
							target="_blank"
						>
							video
						</a>{' '}
						tutorial that walks you through the process step-by-step.
					</p>
					<p className="spc--bottom--sml">
						Contact our support team if you have any questions. Thank you for your cooperation in keeping our platform
						secure.
					</p>
				</div>
			</div>
		);
	};

	render() {
		const { isMfaRequired, isSamlLogin } = this.state;
		return (
			<div className="settings--main settings--main--alt">
				<div className="l--content--security">
					<Notification ref={this.notificationRef} />
					<div className="settings__header">
						<div className="settings__header__title">Security</div>
					</div>
					{isSamlLogin && (
						<div className="spr__card clearfix">
							<div className="spr__heading clearfix">
								<div className="group--clear cursor--pointer">No settings found to manage for current user</div>
							</div>
						</div>
					)}
					{!isSamlLogin && (
						<Fragment>
							{this.renderErrors()}
							{isMfaRequired && this.renderSecurityConcernsText()}
							<div className="spr__card clearfix">
								<div className="spr__heading clearfix">
									<div className="group--clear cursor--pointer">
										<h4 className="type--base type--wgt--bold pull">Setup Multi-Factor Authentication</h4>
									</div>
								</div>
								<div className="spr__content">{this.renderMfaSection()}</div>
							</div>
							{this.renderPasswordError()}
							{!isMfaRequired && (
								<div className="spr__card clearfix">
									{!this.state.displayReset && (
										<div className="spr__heading clearfix">
											<div className="group--clear cursor--pointer">
												<h4 className="type--base type--wgt--bold pull">Password Reset</h4>
											</div>
										</div>
									)}
									<div className="spr__content">{this.renderPasswordResetSection()}</div>
								</div>
							)}
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

Security.propTypes = {
	location: object.isRequired,
	history: object.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool,
	handleBlockChange: func.isRequired,
};

export default withError(
	withLoader(withCancelable(withBlock(withStepup(Security, componentKeys.SETTINGS_SECURITY, false))))
);
