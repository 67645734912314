import React from 'react';

import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

export const ClickableErrorMessage = ({ index, errorPrefix, elem, scrollTo, focusField }) => (
	<li key={index}>
		<div
			className="anchor"
			onClick={() => {
				let elemId = elem.path.replace(/[.]/g, '_');
				scrollTo(elemId + '_wrapper');
				focusField(elemId);
			}}
		>
			<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
			{defaultReactOutput(defaultImplicitParse(errorPrefix + elem.message))}
		</div>
	</li>
);
