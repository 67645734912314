import React, { Fragment, createRef } from 'react';
import { get, cloneDeep, filter, isEmpty, each, find, some, has } from 'lodash';
import { withRouter } from 'react-router-dom';

import { appService } from '../../services';
import { ToggleContainer } from './../../common/components/toggle';
import { withContext, withLoader } from '../../common/components';
import { getFilesForTag } from '../../common/utilities/commonFileHandlingMethods';
import { MerchantContext } from '../MerchantDetails';
import AchPlanConfirm from './AchPlanConfirm';
import { EappConfirm } from './EappConfirm';
import PaymentPointsContext from '../../common/components/user-account-panel/PaymentPointsContext';
import AchMpaConfirm from './AchMpaConfirm';
import { withCancelable } from '../../common/components/cancelable';
import { AchInvalidTemplate, AchRequiredTemplate } from '../../validation';
import { Notification } from '../../common/components/notifications';
import Schema from '../../validation/BaseSchema';
import moment from 'moment';

const dateFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;

class AchEAppConfirmComponent extends EappConfirm {
	constructor(props) {
		super(props);
		this.state = {
			...this.initialState,
			canSubmitApp: false,
		};

		this.errorRef = createRef();
		this.getFilesForTag = getFilesForTag.bind(this);
	}
	async componentDidMount() {
		try {
			const { makePendingRequest } = this.props;
			await makePendingRequest(this.loadDataFromService(), 'loadDataFromService');
		} catch (error) {
			this.props.showLoader(false);
		}
	}

	getEquipment(response) {
		response.fees = [];
		return appService
			.getMerchantPlan(this.state.appId)
			.then(({ merchantAchPlanResponse }) => {
				if (merchantAchPlanResponse) {
					return appService.getAchPlan(this.state.appId).then(({ equipmentPlan }) => {
						const fees = [...equipmentPlan.fees, ...equipmentPlan.additionalFees];
						each(fees, planFee => {
							const merchantFee = find(merchantAchPlanResponse.fees, { feeId: planFee.feeId });
							if (merchantFee) {
								planFee.merchantPrice = merchantFee.merchantPrice;
							}
						});
						response.fees = fees;
					});
				}
			})
			.catch(err => {
				console.log('getAchPlan  error', err);
				this.setState({
					errorMessage: 'An error occurred: ' + err,
					isNewError: true,
				});
				return Promise.reject(err);
			});
	}

	loadDataFromService = () => {
		const { displayEquipmentInfo } = this.props;
		this.props.showLoader(true);
		const response = {};
		const promises = [this.getApp(response)];

		if (displayEquipmentInfo) {
			promises.push(this.getEquipment(response));
		}

		return Promise.all(promises)
			.then(() => {
				this.props.showLoader(false);
				this.mapResponseToState(response);
				this.validateApp(response);
			})
			.catch(err => {
				this.props.showLoader(false);
			});
	};

	mapResponseToState = response => {
		if (has(response.mpa, 'signerInformation.dateOfBirth'))
			response.mpa.signerInformation.dateOfBirth = moment(response.mpa.signerInformation.dateOfBirth).format(
				dateFormat
			);
		if (has(response.mpa, 'businessStartDate'))
			response.mpa.businessStartDate = moment(response.mpa.businessStartDate).format(dateFormat);
		this.setState({ ...response });
		return response;
	};

	validateDocuments = files => {
		const requiredDocs = ['BankStatements', 'ProofOfAddress', 'VoidedCheck', 'DriversLicense', 'SignedMPA'];
		return filter(requiredDocs, fileTag => !some(files, file => get(file, 'fileTag') === fileTag));
	};

	validateApp = data => {
		const mpaRequiredSchema = new Schema(cloneDeep(AchRequiredTemplate), { strip: false, typecast: true });
		const mpaInvalidSchema = new Schema(cloneDeep(AchInvalidTemplate), { strip: false, typecast: true });
		const incompleteDocs = this.validateDocuments(data.mpa.files);
		const requiredErrorList = mpaRequiredSchema.validate(Object.assign({}, data.mpa));
		const invalidErrorList = mpaInvalidSchema.validate(Object.assign({}, data.mpa));
		const hasMpaValidationErrors = !(
			isEmpty(requiredErrorList) &&
			isEmpty(invalidErrorList) &&
			isEmpty(incompleteDocs)
		);
		const hasFeesValidationErrors = isEmpty(data.fees);
		const canSubmitApp = !hasMpaValidationErrors && !hasFeesValidationErrors;
		this.setState({ canSubmitApp, hasMpaValidationErrors, hasFeesValidationErrors });
	};

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	redirectToMpa = () => this.redirect(`/eapp/ach/${this.state.appId}`);

	redirectToSetup = () => this.redirect(`/eapp/ach/${this.state.appId}`);

	redirectToEquipment = () => this.redirect(`/eapp/ach-fees/${this.state.appId}`);

	renderMpaForm = () => {
		const { mpa } = this.state;
		if (isEmpty(mpa)) return null;
		const props = {
			mpa,
			renderChangeLink: this.renderChangeLink,
			redirectToMpa: this.redirectToMpa,
			displayLastFourOnly: this.props.displayLastFourOnly,
			getFilesForTag: this.getFilesForTag,
			showSSN: this.props.showSSN,
		};
		return <AchMpaConfirm {...props} />;
	};
	renderAchPlan = () => {
		const { fees, appId } = this.state;
		if (isEmpty(fees)) return null;
		const props = {
			fees,
			appId,
			redirect: this.redirect,
			renderChangeLink: this.renderChangeLink,
		};
		return <AchPlanConfirm {...props} />;
	};

	tabGoTo = path => {
		const { appid } = this.props.match ? this.props.match.params : '';
		if (appid) this.props.history.push(`/eapp/${path}/${appid}`);
	};

	renderTabs() {
		return (
			<ul className="tabs--primary spc--bottom--lrg">
				<li
					className="tabs--primary__item"
					onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach'))}
					onClick={() => this.tabGoTo('ach')}
				>
					MPA
				</li>
				<li
					className="tabs--primary__item"
					onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-fees'))}
					onClick={() => this.tabGoTo('ach-fees')}
				>
					Fees
				</li>
				<li className="tabs--primary__item  is-active">Confirm</li>
			</ul>
		);
	}
	renderSetupForm = () => {};

	renderHardwareAndAccessories = () => {};

	renderGatewaysAndAddons = () => {};

	renderConfirmButton = () => {
		const { canSubmitApp } = this.state;

		return (
			<div>
				<button
					type="button"
					onClick={this.openSubmitApp}
					className="btn btn--med btn--primary"
					disabled={!canSubmitApp}
				>
					Confirm App
				</button>
			</div>
		);
	};

	renderValidationErrors = () => {
		const { canSubmitApp, hasMpaValidationErrors, hasFeesValidationErrors } = this.state;

		return (
			<Fragment>
				{!canSubmitApp && hasMpaValidationErrors && (
					<div className="note note--uncompleted spc--bottom--med">
						<div>
							<button
								type="button"
								onClick={this.redirectToMpa}
								className="btn btn--reset anchor anchor--primary type--left type--wgt--bold"
							>
								Please return to MPA form and complete all required information for submitting the app.
							</button>
						</div>
					</div>
				)}
				{!canSubmitApp && hasFeesValidationErrors && (
					<div className="note note--uncompleted spc--bottom--med">
						<div>
							<button
								type="button"
								onClick={this.redirectToEquipment}
								className="btn btn--reset anchor anchor--primary type--left type--wgt--bold"
							>
								Please return to Fees form and complete all required information for submitting the app.
							</button>
						</div>
					</div>
				)}
			</Fragment>
		);
	};
	renderHeader = () => {
		const { errorMessage } = this.state;
		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				{this.props.showNavbar && this.renderTabs()}
				{errorMessage ? (
					<div className="note note--warning" ref={this.errorRef}>
						{errorMessage}
					</div>
				) : null}
			</React.Fragment>
		);
	};

	render() {
		const { showSubmit, title } = this.props;
		const { appId, mpa } = this.state;
		const dbaName = get(mpa, 'dba');
		return (
			<div id="main-div" className="l--content l--content--lrg">
				{this.renderHeader()}
				<div className="l--content--med">
					<ToggleContainer>
						<div className="header header--mpa">
							<div className="header__title spc--right--auto">
								{dbaName && appId && <div className="spc--bottom--sml">{`${dbaName} - ${appId}`}</div>}
								<h3>{title}</h3>
							</div>
						</div>
						{this.renderAccountInformation()}
						{this.renderMpaForm()}
						{this.renderAchPlan()}
						{showSubmit && this.renderValidationErrors()}
						{showSubmit && (
							<div className="type--right">
								{this.renderConfirmButton()}
								{this.renderSubmitAppModal()}
							</div>
						)}
					</ToggleContainer>
				</div>
			</div>
		);
	}
}
export default withLoader(
	withContext(
		withContext(withRouter(withCancelable(AchEAppConfirmComponent)), PaymentPointsContext, 'agentPoints'),
		MerchantContext,
		'merchant'
	)
);
