import { trim, every, split } from 'lodash';

const emailValidationRegex = /^([a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,63})[,]?)+$/;

const validateEmails = value => {
	const emails = split(value, ',');
	return every(emails, email => emailValidationRegex.test(trim(email)));
};

export default validateEmails;
