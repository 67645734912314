const {
	REACT_APP_BANKING_SERVICES_EMAIL,
	REACT_APP_BANKING_EMAIL,
	REACT_APP_AGENT_SUPPORT_EMAIL,
	REACT_APP_CA_CUSTOMER_SERVICE_EMAIL,
	REACT_APP_CA_DEPLOYMENTS_EMAIL,
	REACT_APP_CARDKNOX_GATEWAY_SUPPORT_EMAIL,
	REACT_APP_CUSTOMER_SERVICE_EMAIL,
	REACT_APP_DEPLOYMENTS_EMAIL,
	REACT_APP_DEVELOPER_SUPPORT_EMAIL,
	REACT_APP_REVIEW_PRICING_EMAIL,
	REACT_APP_PCI_TEAM_EMAIL,
	REACT_APP_RISK_RELATED_SUPPORT_EMAIL,
	REACT_APP_TECH_SUPPORT_EMAIL,
} = process.env;

export const departmentOptions = [
	{
		label: 'Banking Services',
		value: REACT_APP_BANKING_SERVICES_EMAIL,
	},
	{
		label: 'Agent Support',
		value: REACT_APP_AGENT_SUPPORT_EMAIL,
	},
	{
		label: 'Billing',
		value: REACT_APP_BANKING_EMAIL,
	},
	{
		label: 'CA_Customer Service',
		value: REACT_APP_CA_CUSTOMER_SERVICE_EMAIL,
	},
	{
		label: 'CA_Deployments',
		value: REACT_APP_CA_DEPLOYMENTS_EMAIL,
	},
	{
		label: 'Cardknox Gateway Support',
		value: REACT_APP_CARDKNOX_GATEWAY_SUPPORT_EMAIL,
	},
	{
		label: 'Customer Service',
		value: REACT_APP_CUSTOMER_SERVICE_EMAIL,
	},
	{
		label: 'Deployments',
		value: REACT_APP_DEPLOYMENTS_EMAIL,
	},
	{
		label: 'Developer Support',
		value: REACT_APP_DEVELOPER_SUPPORT_EMAIL,
	},
	{
		label: 'Pricing Analysis',
		value: REACT_APP_REVIEW_PRICING_EMAIL,
	},
	{
		label: 'PCI Team',
		value: REACT_APP_PCI_TEAM_EMAIL,
	},
	{
		label: 'Risk',
		value: REACT_APP_RISK_RELATED_SUPPORT_EMAIL,
	},
	{
		label: 'Technical Support',
		value: REACT_APP_TECH_SUPPORT_EMAIL,
	},
];
