import InvoicesGridActionsComponent from '../../../components/InvoiceGrid/formatters/InvoicesGridActionsComponent';

export const actionsGridColumn = [
	{
		key: 'isHidden',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		fixWidth: true,
		formatter: InvoicesGridActionsComponent,
		getRowMetaData: row => row,
		initWidth: 75,
		order: 1,
	},
];
