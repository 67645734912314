import React, { Component } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';

class StatusFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.handleFilterChanged = this.handleFilterChanged.bind(this);
	}

	handleFilterChanged(event) {
		let {
			filter: { values },
			onFilterChanged,
		} = this.props;
		const { name, checked } = event.target;

		let valueArray = map(values, (v, k) => {
			return { key: k, value: v };
		});

		const objIndex = valueArray.findIndex(o => o.key == name);

		valueArray[objIndex].value = checked;

		onFilterChanged({
			id: 'merchantStatus',
			values: valueArray,
			emptyValue: false,
		});
	}
	render() {
		return (
			<div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Incomplete"
						className="input input--check"
						checked={this.props.filter.values.Incomplete}
						value={'Incomplete'}
						id="incomplete"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="incomplete">eApp Started</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Pending"
						className="input input--check"
						checked={this.props.filter.values.Pending}
						value={'Pending'}
						id="pending"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="pending">eApp Submitted</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="PendingFdSig"
						className="input input--check"
						checked={this.props.filter.values.PendingFdSig}
						value="{'PendingFdSig'}"
						id="pendingFdSig"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="pendingFdSig">Pending FD Signature</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="PendingElavonSig"
						className="input input--check"
						checked={this.props.filter.values.PendingElavonSig}
						value={'PendingElavonSig'}
						id="pendingElavonSig"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="pendingElavonSig">Pending Elavon Signature</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="PendUnderwriting"
						className="input input--check"
						checked={this.props.filter.values.PendUnderwriting}
						value={'PendUnderwriting'}
						id="pendUnderwriting"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="pendUnderwriting">Pending Underwriting</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="PendBankApproval"
						className="input input--check"
						checked={this.props.filter.values.PendBankApproval}
						value={'PendBankApproval'}
						id="pendBankApproval"
						onChange={this.handleFilterChanged}
					/>
					<label htmlFor="pendBankApproval">Pending Bank Approval</label>
				</div>
			</div>
		);
	}
}

StatusFilterComponent.propTypes = {
	filter: PropTypes.object,
	onFilterChanged: PropTypes.func,
};

export default StatusFilterComponent;
