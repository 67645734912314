import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

class OwnershipDropdownComponent extends React.Component {
	handleChange = e => {
		const { onChange } = this.props;
		onChange(e);
	};

	render() {
		const { id, className, ownershipOptions } = this.props;
		const selectedType = this.props.value;
		return (
			<select id={id} name={id} className={className} onChange={this.handleChange} value={selectedType}>
				{map(ownershipOptions, ({ value, label }) => (
					<option key={label} value={value}>
						{label}
					</option>
				))}
			</select>
		);
	}
}

OwnershipDropdownComponent.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	value: PropTypes.string,
};

export default OwnershipDropdownComponent;
