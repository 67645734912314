import React, { Fragment, useContext, useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { map, some, find, split, includes, last, toLower } from 'lodash';
import MerchantContext from '../../MerchantDetails/MerchantContext';
import { appService } from '../../../services';

const emailOptions = [
	{ name: 'Please select...', value: '' },
	{ name: 'Email Merchant and CC Agent', value: 'MerchantAndAgent' },
	{ name: 'Email Agent Only', value: 'Agent' },
];

const SendInvoiceEmailPopup = ({ closeModal, row }) => {
	const [emails, setEmails] = useState([]);
	const [emailOption, setEmailOption] = useState('');
	const [currentOptionEmails, setCurrentOptionEmails] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [response, setResponse] = useState(null);
	const { businessEmail, agentEmail } = useContext(MerchantContext);

	const handleSend = async e => {
		const emailCount = currentOptionEmails.length;
		try {
			setIsLoading(true);
			if (emailCount === 0) {
				return;
			}
			await appService.SendInvoiceEmail({ invoiceId: row.invoiceId, emailToOption: emailOption });
			setResponse({
				message: `The ${emailCount > 1 ? 'emails have' : 'email has'} been sent successfully.`,
				success: true,
			});
		} catch (error) {
			setResponse({
				message: `An error occurred while sending the email${emailCount > 1 ? 's' : null}. Please try again.`,
				success: false,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = e => {
		const { value } = e.target;
		if (some(emailOptions, emailOption => emailOption.value === value)) {
			setEmailOption(e.target.value);
			getCurrentOptionEmails(e.target.value);
		}
	};

	const getCurrentOptionEmails = optionKey => {
		if (!emails) {
			return [];
		}
		const emailsTo = find(emails, email => email.key === optionKey);
		if (emailsTo.value === '') {
			setCurrentOptionEmails([]);
		} else {
			setCurrentOptionEmails(split(emailsTo.value, ','));
		}
	};
	const renderEmails = () => {
		if (emailOption === '') return null;
		return (
			<div>
				{
					<Fragment>
						<h5 className="spc--top--xmed spc--bottom--tny">
							Email will be sent to the following adress{currentOptionEmails.length > 1 ? 'es' : null}:
						</h5>
						{map(currentOptionEmails, email => (
							<li>{email}</li>
						))}
					</Fragment>
				}
			</div>
		);
	};

	useEffect(() => {
		let businessEmailCopy = businessEmail;
		if (!includes(toLower(businessEmailCopy), toLower(agentEmail))) {
			if (last(businessEmailCopy) === ',') {
				businessEmailCopy += agentEmail;
			} else {
				businessEmailCopy += `,${agentEmail}`;
			}
		}
		const pairedEmailsToOptions = [
			{ key: '', value: '' },
			{ key: 'MerchantAndAgent', value: businessEmailCopy },
			{ key: 'Agent', value: agentEmail },
		];
		setEmails(pairedEmailsToOptions);
	}, []);

	return (
		<div>
			<div className="popup__header">
				<h4>Email Invoice {row.invoiceId}</h4>
			</div>
			{response !== null ? (
				<div className="popup__body">
					<span className={`message message--${response.success ? 'success' : 'warning'}`}>{response.message}</span>
				</div>
			) : (
				<Fragment>
					<div className="popup__body">
						{isLoading ? (
							<div className="spc--bottom--lrg">
								<div className="loader__spinner"></div>
							</div>
						) : (
							<Fragment>
								<select
									className="input input--med input--select"
									id={'SEI'}
									name={'SelectEmailInvoice'}
									onChange={handleChange}
									value={emailOption}
								>
									{map(emailOptions, emailOption => (
										<option value={emailOption.value}>{emailOption.name}</option>
									))}
								</select>
								{emailOption !== '' ? (
									currentOptionEmails.length > 0 ? (
										renderEmails()
									) : (
										<li>There are no emails for this option</li>
									)
								) : null}
							</Fragment>
						)}
					</div>

					<div className="popup__footer popup__footer--styled">
						<div className="type--right">
							<button className="btn btn--ghost btn--med" onClick={closeModal} disabled={isLoading}>
								Close
							</button>
						</div>
						<div className="type--right">
							<button
								className="btn btn--primary btn--med spc--left--xsml"
								onClick={handleSend}
								disabled={isLoading || emailOption === '' || (currentOptionEmails.length === 0 && emailOption !== '')}
							>
								Send
							</button>
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};
SendInvoiceEmailPopup.defaultProps = {
	row: {},
};

SendInvoiceEmailPopup.propTypes = {
	closeModal: func.isRequired,
	row: object.isRequired,
};

export default SendInvoiceEmailPopup;
