import React, { Fragment, createRef } from 'react';
import { map, clone, isEmpty, toLower, every, noop, get } from 'lodash';
import { object, func, bool, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

import { appService } from '../../services';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withContext, withLoader } from '../../common/components';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import { EappConfirm } from './EappConfirm';

const isExpand = true;

class GoPlusEAppConfirmComponent extends EappConfirm {
	constructor(props) {
		super(props);
		this.state = clone(this.initialState);

		this.errorRef = createRef();
		this.notificationRef = createRef();
	}

	get initialState() {
		return {
			appId: this.props.match.params.appid,
			goApp: {
				goPlusEApp: {
					appId: '',
					corporateName: '',
					dbaName: '',
					monthlySalesVolume: '',
					ownershipType: '',
					businessStartDate: '',
					tierName: '',
					processorName: '',
					processingInformation: {
						averageTicket: '',
						highTicket: '',
						monthlySalesVolume: '',
						sicCode: '',
						ccRate: '',
						ccRateAmex: '',
						perTranFee: '',
						perTranAmexFee: '',
						percentCardPresent: '',
						percentCardNotPresent: '',
						achTransactionFee: '',
						achPercentFee: '',
						achSetupFee: '',
						achUnauthorizedReturnFee: '',
						achMonthlyFee: '',
						achReturnFee: '',
						achEnabled: false,
					},
					percentCardPresent: '',
					percentCardNotPresent: '',
					agentName: '',
					submittedByName: '',
					agentEmail: '',
					submittedByEmail: '',
					agentId: '',
					telemarketerName: '',
					telemarketerId: '',
					agentReadonly: '',
					website: '',
					taxId: '',
					businessEmail: '',
					businessPhone: '',
					businessAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					mailingAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					productSold: '',
					bankingInformation: {
						bankName: '',
						accountType: '',
						routingNumber: '',
						accountNumber: '',
					},
					signerInformationList: [
						{
							ssn: '',
							dateOfBirth: '',
							firstName: '',
							lastName: '',
							address: {
								streetAddress: '',
								city: '',
								state: '',
								zip: '',
								country: '',
							},
							ownershipPercentage: '',
							appAdditionalOwnerID: '',
							cellPhone: '',
							title: '',
						},
					],
					signerInformation: {
						ssn: '',
						dateOfBirth: '',
						firstName: 'test',
						lastName: '',
						address: {
							streetAddress: '',
							city: '',
							state: '',
							zip: '',
							country: '',
						},
						ownershipPercentage: '',
						appAdditionalOwnerID: '',
						cellPhone: '',
						title: '',
					},
					signature: '',
					totalMinorOwnershipPercent: '',
					wasSubmitted: '',
					loggedInUsername: '',
					currentAction: '',
				},
				refNum: '',
				status: '',
			},
		};
	}

	componentDidMount() {
		this.loadDataFromService();
	}

	getApp(response) {
		return appService.getGoPlus(this.state.appId).then(goApp => {
			response.goApp = goApp;
		});
	}

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	mapResponseToState = response => {
		this.setState(response);
	};

	renderSalesRep = () => {
		const { goApp } = this.state;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className="form__field spc--bottom--med col col-sml-6 col-med-3">
								<label className="label">Tier </label>
								<div className="input input--med input--fake">{goApp.goPlusEApp.tierName}</div>
							</div>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Sales Rep</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Sales Rep Name </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.submittedByName
														? goApp.goPlusEApp.submittedByName
														: goApp.goPlusEApp.agentName}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Sales Rep Email Address </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.submittedByEmail
														? goApp.goPlusEApp.submittedByEmail
														: goApp.goPlusEApp.agentEmail}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Rep 2 </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.telemarketerName}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderMpaForm = () => {
		const { goApp } = this.state;
		const mailingAddressSameAsLocationAddress = every(goApp.goPlusEApp.businessAddress, (value, key) => {
			return toLower(value) === toLower(goApp.goPlusEApp.businessAddress[key]);
		});
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Business Information</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Company Legal Name </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.corporateName}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">DBA </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.dbaName}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">EIN </label>
												<div className="input input--med input--fake fs-mask">{goApp.goPlusEApp.taxId}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Website </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.website}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Primary Business Phone Number </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessPhone}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Business Email </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessEmail}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Ownership Type </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.ownershipType}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Business Start Date </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessStartDate}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Products/Services </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.productSold}</div>
											</div>
										</div>
									</div>
									<h6 className="spc--bottom--sml">Business Address</h6>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Address </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.businessAddress.streetAddress}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">City </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessAddress.city}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">State </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessAddress.state}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Zip Code </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.businessAddress.zip}</div>
											</div>
										</div>
									</div>
									<h6 className="spc--bottom--sml">Mailing Address</h6>
									<div className="form__field spc--bottom--med">
										<input
											name="mailingAddressSameAsLocationAddress"
											type="checkbox"
											id="mailingAddressSameAsLocationAddress"
											className="input--check input--check--preview"
											checked={mailingAddressSameAsLocationAddress}
											value={mailingAddressSameAsLocationAddress}
											onChange={noop}
										/>
										<label className="label" htmlFor="mailingAddressSameAsLocationAddress">
											Same As Business Address
										</label>
									</div>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Address </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.mailingAddress.streetAddress}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">City </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.mailingAddress.city}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">State </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.mailingAddress.state}</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Zip Code </label>
												<div className="input input--med input--fake">{goApp.goPlusEApp.mailingAddress.zip}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderField = (name, value, isText, format, isVisible = true) => {
		if (!isVisible) return null;
		let suffix,
			prefix = '';
		if (format === '%') suffix = format;
		if (format === '$') prefix = format;
		return (
			<div className="col col-sml-12 col-med-6">
				<div className="form__field spc--bottom--med">
					<label className="label">{name}</label>
					{isText ? (
						<div className="input input--med input--fake">{value}</div>
					) : (
						<div>
							<NumericFormat
								className={`input input--med input--fake`}
								value={value}
								inputType="numeric"
								prefix={prefix}
								suffix={suffix}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	getTotalSales = (percentCardNotPresent, percentCardPresent) => {
		const total = parseFloat(percentCardNotPresent || 0) + parseFloat(percentCardPresent || 0);

		if (String(total).indexOf('.') > -1) {
			return total.toFixed(2);
		}

		return total;
	};

	renderProcessingInformation = () => {
		const { goApp } = this.state;
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const isAchqOnly = toLower(goPlusEApp.processorName) === 'achq';
		return (
			<Fragment>
				{!isAchqOnly && this.renderCCProcessingInformation(goApp)}
				{this.renderACHProcessingInformation(goApp)}
			</Fragment>
		);
	};

	renderCCProcessingInformation = goApp => {
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const processingInformation = get(goPlusEApp, 'processingInformation', {});
		const isFlatRate = get(processingInformation, 'rateStructure', '') === 'Flat Rate';
		const showInternational =
			get(processingInformation, 'visaInternationalRate', '0') != '0' ||
			get(processingInformation, 'mcInternationalRate', '0') != '0' ||
			get(processingInformation, 'discoverInternationalRate', '0') != '0';
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Credit Card Processing Information</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row grid-align-middle">
										{this.renderField('Average Ticket', processingInformation.averageTicket, false, '$')}
										{this.renderField('High Ticket', processingInformation.highTicket, false, '$')}
										{this.renderField('Monthly Sales Volume', processingInformation.monthlySalesVolume, false, '$')}
										{this.renderField('SIC Code', processingInformation.sicCode, false, null)}
										{this.renderField('Rate Structure', processingInformation.rateStructure, true, null)}
										{this.renderField('Per Tran Fee Type', processingInformation.tranFeeType, true, null)}

										{this.renderField('CC Rate', processingInformation.ccRate, false, '%', isFlatRate)}
										{this.renderField('CC Rate Amex', processingInformation.ccRateAmex, false, '%', isFlatRate)}
										{this.renderField('Per Tran Fee', processingInformation.perTranFee, false, '$', isFlatRate)}
										{this.renderField(
											'Per Tran Amex Fee',
											processingInformation.perTranAmexFee,
											false,
											'$',
											isFlatRate
										)}

										{this.renderField('Visa Rate', processingInformation.visaRate, false, '%', !isFlatRate)}
										{this.renderField('Mastercard Rate', processingInformation.mcRate, false, '%', !isFlatRate)}
										{this.renderField('Discover Rate', processingInformation.discoverRate, false, '%', !isFlatRate)}
										{this.renderField('Amex Rate', processingInformation.amexRate, false, '%', !isFlatRate)}

										{this.renderField(
											`Visa ${processingInformation.tranFeeType} Fee`,
											processingInformation.visaTranFee,
											false,
											'$',
											!isFlatRate
										)}
										{this.renderField(
											`Mastercard ${processingInformation.tranFeeType} Fee`,
											processingInformation.mcTranFee,
											false,
											'$',
											!isFlatRate
										)}
										{this.renderField(
											`Discover ${processingInformation.tranFeeType} Fee`,
											processingInformation.discoverTranFee,
											false,
											'$',
											!isFlatRate
										)}
										{this.renderField(
											`Amex ${processingInformation.tranFeeType} Fee`,
											processingInformation.amexTranFee,
											false,
											'$',
											!isFlatRate
										)}

										{this.renderField(
											`Visa International Rate`,
											processingInformation.visaInternationalRate,
											false,
											'%',
											showInternational
										)}
										{this.renderField(
											`Mastercard International Rate`,
											processingInformation.mcInternationalRate,
											false,
											'%',
											showInternational
										)}
										{this.renderField(
											`Discover International Rate`,
											processingInformation.discoverInternationalRate,
											false,
											'%',
											showInternational
										)}

										{this.renderField(
											`Percent of Card Present Sales (%)`,
											processingInformation.percentCardPresent,
											false,
											null
										)}
										{this.renderField(
											`Percent of Card-Not-Present Sales (%)`,
											processingInformation.percentCardNotPresent,
											false,
											null
										)}
										<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
											<label className="label">Total Card Sales (must equal 100%):</label>
											<div
												id="totalSales"
												className={
													this.getTotalSales(
														processingInformation.percentCardPresent,
														processingInformation.percentCardNotPresent
													) == 100
														? 'form__total form__total--success'
														: 'form__total form__total--warning'
												}
											>
												{this.getTotalSales(
													processingInformation.percentCardPresent,
													processingInformation.percentCardNotPresent
												)}
												%
											</div>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderACHProcessingInformation = goApp => {
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const isAchqOnly = toLower(goPlusEApp.processorName) === 'achq';
		const processingInformation = get(goPlusEApp, 'processingInformation', {});
		const isAchVisible = isAchqOnly || processingInformation.achEnabled;

		if (!isAchVisible) return null;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<div className="card--primary card--sml spc--bottom--med">
							<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
								<h6 className="spc--bottom--sml">ACH Processing Information</h6>
								<div className="card--primary__header__expand" onClick={handleToggle}>
									<i
										className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
											isToggled ? 'rotate--180' : ''
										}`}
									></i>
								</div>
							</div>
							<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
								<div className="row grid-align-middle">
									{this.renderField('SIC Code', processingInformation.sicCode, true, '', isAchqOnly)}
									{this.renderField(`ACH Transaction Fee`, processingInformation.achTransactionFee, false, '$')}
									{this.renderField(`ACH Percent Fee`, processingInformation.achPercentFee, false, '$')}
									{this.renderField(`ACH Setup Fee`, processingInformation.achSetupFee, false, '$')}
									{this.renderField(
										`ACH Unauthorized Return Fee`,
										processingInformation.achUnauthorizedReturnFee,
										false,
										'$'
									)}
									{this.renderField(`ACH Monthly Service Fee`, processingInformation.achMonthlyFee, false, '$')}
									{this.renderField(`ACH Return Fee`, processingInformation.achReturnFee, false, '$')}
								</div>
							</div>
						</div>
					)
				}
			</Toggle>
		);
	};

	renderBankingInformation = () => {
		const { goApp } = this.state;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className="card--primary card--sml spc--bottom--med">
								<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
									<h6 className="spc--bottom--sml">Banking Information</h6>
									<div className="card--primary__header__expand" onClick={handleToggle}>
										<i
											className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
												isToggled ? 'rotate--180' : ''
											}`}
										></i>
									</div>
								</div>
								<div className={`details__content ${isToggled ? '' : 'display--n'}`}>
									<div className="row grid-align-middle">
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Bank Name </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.bankingInformation.bankName}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Account Type </label>
												<div className="input input--med input--fake">
													{goApp.goPlusEApp.bankingInformation.accountType}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Routing Number </label>
												<div className="input input--med input--fake fs-mask">
													****{goApp.goPlusEApp.bankingInformation.routingNumber.slice(-4)}
												</div>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6">
											<div className="form__field spc--bottom--med">
												<label className="label">Account Number </label>
												<div className="input input--med input--fake fs-mask">
													****{goApp.goPlusEApp.bankingInformation.accountNumber.slice(-4)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderSignerInformation = () => {
		const { goApp } = this.state;
		const { showSSN } = this.props;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<div className="card--primary card--sml spc--bottom--med">
							<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
								<h6 className="spc--bottom--sml">Signer Information</h6>
								<div className="card--primary__header__expand" onClick={handleToggle}>
									<i
										className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
											isToggled ? 'rotate--180' : ''
										}`}
									></i>
								</div>
							</div>
							{isToggled &&
								map(goApp.goPlusEApp.signerInformationList, (signerInformation, index) => (
									<Fragment key={index}>
										<div className="row grid-align-middle">
											<div className="col col-sml-12 col-med-6">
												<div className="form__field spc--bottom--med">
													<label className="label">Total Minor Ownership Percent </label>
													<div className="input input--med input--fake">
														{goApp.goPlusEApp.totalMinorOwnershipPercent}
													</div>
												</div>
											</div>
										</div>
										<div className={`details__content`}>
											<h6 className="spc--bottom--sml">Signer {index + 1}</h6>
											<div className="row grid-align-middle">
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Ownership Percent </label>
														<div className="input input--med input--fake">{signerInformation.ownershipPercentage}</div>
													</div>
												</div>
												{showSSN && (
													<div className="col col-sml-12 col-med-6">
														<div className="form__field spc--bottom--med">
															<label className="label">SSN </label>
															<div className="input input--med input--fake fs-mask">{signerInformation.ssn}</div>
														</div>
													</div>
												)}
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Date Of Birth </label>
														<div className="input input--med input--fake fs-mask">{signerInformation.dateOfBirth} </div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">First Name </label>
														<div className="input input--med input--fake">{signerInformation.firstName}</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Last Name </label>
														<div className="input input--med input--fake">{signerInformation.lastName}</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Street Address </label>
														<div className="input input--med input--fake">
															{signerInformation.address.streetAddress}
														</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">City </label>
														<div className="input input--med input--fake">{signerInformation.address.city}</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">State </label>
														<div className="input input--med input--fake">{signerInformation.address.state}</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Zip </label>
														<div className="input input--med input--fake">{signerInformation.address.zip}</div>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div className="form__field spc--bottom--med">
														<label className="label">Country </label>
														<div className="input input--med input--fake">{signerInformation.address.country}</div>
													</div>
												</div>
											</div>
										</div>
									</Fragment>
								))}
						</div>
					)
				}
			</Toggle>
		);
	};

	render() {
		const { title } = this.props;
		const {
			appId,
			errorMessage,
			goApp: { dbaName },
		} = this.state;
		return (
			<div id="main-div" className="l--content l--content--lrg">
				<Notification ref={this.notificationRef} />
				{errorMessage ? (
					<div className="note note--warning" ref={this.errorRef}>
						{errorMessage}
					</div>
				) : null}
				<div className="l--content--med">
					<ToggleContainer>
						<div className="header header--mpa">
							<div className="header__title spc--right--auto">
								{dbaName && appId && <div className="spc--bottom--sml">{`${dbaName} - ${appId}`}</div>}
								<h3>{title}</h3>
							</div>
						</div>
						{this.renderSalesRep()}
						{this.renderMpaForm()}
						{this.renderProcessingInformation()}
						{this.renderBankingInformation()}
						{this.renderSignerInformation()}
					</ToggleContainer>
				</div>
			</div>
		);
	}
}

GoPlusEAppConfirmComponent.defaultProps = {
	showNavbar: true,
	displayRates: false,
	displayLastFourOnly: false,
	showSSN: true,
};

GoPlusEAppConfirmComponent.propTypes = {
	match: object,
	history: object,
	showLoader: func,
	isLoading: bool,
	title: string,
	displayLastFourOnly: bool,
	showSSN: bool,
};

export default withLoader(withContext(withRouter(GoPlusEAppConfirmComponent), MerchantContext, 'merchant'));
