import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';

import withListItem from './withListItem';
import { invokeIfFunction } from '../../utilities';

const withContainer = (WrappedComponent, mapItemToId, opts = {}) => {
	class WithContainer extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isInputFocused: false,
			};
			this.DraggableComponent = withListItem(WrappedComponent, opts);
			this.UnDraggableComponent = WrappedComponent;
		}

		enableDrag = () => {
			this.setState({
				isInputFocused: false,
			});
		};
		disableDrag = () => {
			this.setState({
				isInputFocused: true,
			});
		};

		mapEmptyItems = (items, props) => {
			const emptyItem = invokeIfFunction(opts.emptyItem, items, props);
			if (emptyItem) {
				return isEmpty(items) ? [emptyItem] : [...items, emptyItem];
			}
			return items;
		};

		ComponentWithContainer({ items, className, disable, ...rest }) {
			const mappedItems = this.mapEmptyItems(items, { ...rest });

			const { isInputFocused } = this.state;

			return map(mappedItems, item => {
				const id = mapItemToId(item);
				return isInputFocused ? (
					<this.UnDraggableComponent
						key={id}
						id={id}
						className={className}
						items={items}
						item={item}
						onMouseOut={this.enableDrag}
						disable={disable || item.disable}
						{...rest}
						WrappedComponent={WrappedComponent}
					></this.UnDraggableComponent>
				) : (
					<this.DraggableComponent
						key={id}
						id={id}
						className={className}
						items={items}
						item={item}
						onMouseOver={this.disableDrag}
						disable={disable || item.disable}
						{...rest}
						WrappedComponent={WrappedComponent}
					/>
				);
			});
		}
		render() {
			return this.ComponentWithContainer(this.props);
		}
	}

	WithContainer.propTypes = {
		items: PropTypes.array,
		className: PropTypes.string,
	};

	return WithContainer;
};

export default withContainer;
