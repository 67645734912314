import React from 'react';
import { Link } from 'react-router-dom';
import { withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import principalService from '../../services/principalService';
import { getApiUrl } from '../../common/utilities/apiConfiguration';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		const settings = {
			'Portal API URL': getApiUrl(),
			'App Build Version': process.env.AppBuildTag,
			AppBuildCommitHash: process.env.AppBuildCommitHash,
			AppBuildBranch: process.env.AppBuildBranch,
		};

		let isLoggedIn = true;
		const principal = principalService.get();
		if (!principal) isLoggedIn = false;

		this.state = {
			errorMessage: null,
			isUserAuthenticated: isLoggedIn,
			settings: settings,
			apiSettings: [],
		};
	}

	componentDidMount() {
		this.refreshData();
	}

	refreshData = () => {
		if (this.state.isUserAuthenticated) {
			this.props.showLoader(true);
			appService
				.apiInfo()
				.then(apiInfo => {
					this.props.showLoader(false);
					this.setState({ apiSettings: apiInfo });
				})
				.catch(err => {
					console.log('apiInfo error', err);
					this.props.showLoader(false);
					this.setState({ errorMessage: 'An error occurred retrieving api info: ' + err });
				});
		} else {
			this.setState({ errorMessage: 'Must be logged in to view API info' });
		}
	};

	handleRefresh = () => {
		this.refreshData();
	};

	renderSettings(settings) {
		return Object.entries(settings).map((val, i) => {
			//console.log(val);
			return (
				<li key={i}>
					<b>{val[0]}: </b>
					{val[1]}
				</li>
			);
		});
	}

	render() {
		const { settings, apiSettings, errorMessage, isUserAuthenticated } = this.state;

		return (
			<div>
				<div className="spc--bottom--med">
					<div className="spc--bottom--med">
						<h3>Web Application Settings</h3>
						<ul>{this.renderSettings(settings)}</ul>
					</div>
					<div className="spc--bottom--med">
						<h3>API Settings</h3>
						<ul>{this.renderSettings(apiSettings)}</ul>
						{errorMessage ? (
							<div className="spc--top--sml spc--bottom--sml note note--warning">{errorMessage}</div>
						) : null}
					</div>
					{isUserAuthenticated ? (
						<div>
							<button type="button" className="btn btn--primary btn--med" onClick={this.handleRefresh}>
								Refresh
							</button>
							<Link className="anchor anchor--primary" to="/">
								Return to Portal
							</Link>
						</div>
					) : (
						<Link className="anchor anchor--primary" to="/login">
							Log In
						</Link>
					)}
				</div>
			</div>
		);
	}
}

export default withLoader(LoginPage);
