import React from 'react';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import withLoader from '../../common/components/loader/loader-hoc';
import ElavonSettlements from './ElavonSettlements';
import ElavonStatements from './ElavonStatements';
import { appService } from '../../services';
import moment from 'moment';

const tabs = {
	statement: 'statement',
	settlement: 'settlement',
};

class BankReports extends React.Component {
	state = {
		currentTab: tabs.statement,
		statementMonths: [],
		settlementMonths: [],
		statementMonth: null,
		settlementMonth: null,
		statementData: null,
		settlementData: null,
	};

	async componentDidMount() {
		if (this.props.merchant) {
			await this.getReportMonths();
		}
	}

	async componentDidUpdate(previousProps) {
		if (!previousProps.merchant && this.props.merchant) {
			await this.getReportMonths();
		}
	}

	async getReportMonths() {
		this.props.showLoader(true);
		const { mid } = this.props.merchant;
		const [{ reportYearMonths: settlementMonths }, { reportYearMonths: statementMonths }] = await Promise.all([
			appService.getElavonSettlementMonths(mid),
			appService.getElavonStatementMonths(mid),
		]);
		this.setState({ settlementMonths, statementMonths });
		this.props.showLoader(false);
	}

	handleTabChange = tab => this.setState({ currentTab: tab });

	handleSettlementMonthSelect = async ({ target: { value: month } }) => {
		this.setState({
			settlementMonth: month,
		});
		this.props.showLoader(true);
		const result = await appService.getElavonSettlementsForMonth(
			this.props.merchant.mid,
			moment(month).year(),
			moment(month).month() + 1
		);
		this.setState({
			settlementData: result,
		});
		this.props.showLoader(false);
	};

	handleStatementMonthSelect = async ({ target: { value: month } }) => {
		this.setState({
			statementMonth: month,
		});
		this.props.showLoader(true);
		const result = await appService.getElavonStatementForMonth(
			this.props.merchant.mid,
			moment(month).year(),
			moment(month).month() + 1
		);
		this.setState({
			statementData: result.statement,
		});
		this.props.showLoader(false);
	};

	render() {
		const { merchant, isLoading } = this.props;
		const {
			currentTab,
			statementMonths,
			statementData,
			settlementMonths,
			settlementData,
			statementMonth,
			settlementMonth,
		} = this.state;
		return (
			<div className="l--content--grid">
				<header className="header header--grid">
					<div className="header__title">
						{merchant && merchant.dba && merchant.appId && (
							<div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
								{`${merchant.dba} - ${merchant.appId}`}
							</div>
						)}
						Bank Reports
					</div>
				</header>
				<div className="card--primary card--sml">
					<div className="card--primary__header">
						<ul className="tabs--primary spc--bottom--lrg fullwidth">
							<li
								className={`tabs--primary__item${currentTab === tabs.statement ? ' is-active' : ''}`}
								onClick={() => this.handleTabChange(tabs.statement)}
							>
								Elavon Statements
							</li>
							<li
								className={`tabs--primary__item${currentTab === tabs.settlement ? ' is-active' : ''}`}
								onClick={() => this.handleTabChange(tabs.settlement)}
							>
								Elavon Settlements
							</li>
						</ul>
					</div>

					{currentTab === tabs.statement && (
						<ElavonStatements
							isLoading={isLoading}
							currentMonth={statementMonth}
							months={statementMonths}
							data={statementData}
							handleMonthSelect={month => this.handleStatementMonthSelect(month)}
						/>
					)}
					{currentTab === tabs.settlement && (
						<ElavonSettlements
							isLoading={isLoading}
							currentMonth={settlementMonth}
							months={settlementMonths}
							data={settlementData}
							handleMonthSelect={month => this.handleSettlementMonthSelect(month)}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default withLoader(withContext(BankReports, MerchantContext, 'merchant'));
