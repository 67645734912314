import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OwnerNameSelectionComponent extends Component {
	get ownerNameValue() {
		return this.props.filter.values.ownerName;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'ownerName',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Owner Name: </label>
				{this.ownerNameValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

OwnerNameSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default OwnerNameSelectionComponent;
