import { createContext } from 'react';
import { noop } from 'lodash';

export default createContext({
	destroy: noop,
	create: noop,
	get: noop,
	toggleAll: noop,
	isAllToggled: false,
});
