import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has, toLower, get } from 'lodash';

class BaseStatusComponent extends Component {
	styleActive = 'type--status type--status--active type--uppercase';
	styleInactive = 'type--status type--status--inactive type--uppercase';
	stylePending = 'type--status type--status--pending type--uppercase';
	styleIncomplete = 'type--status type--status--incomplete type--uppercase';

	getStyle = () => {
		const { value } = this.props;
		let valueLower = toLower(value);

		if (has(this.statusClasses, valueLower)) {
			return this.statusClasses[valueLower];
		} else if (has(this.statusClasses, valueLower.replaceAll(' ', ''))) {
			return this.statusClasses[valueLower.replaceAll(' ', '')];
		} else {
			return 'unknown';
		}
	};

	setInfoElement = element => {
		this.info = element;
	};

	render = () => {
		const { value, dependentValues } = this.props;
		return value ? (
			<span
				ref={this.setInfoElement}
				className={this.getStyle()}
				title={get(dependentValues, 'statusTitle') ? dependentValues.statusTitle : value}
			>
				{dependentValues.statusDisplayName}
			</span>
		) : null;
	};
}

export default BaseStatusComponent;

BaseStatusComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.object,
};
