import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CitySelectionComponent extends Component {
	get cityValue() {
		return this.props.filter.values.city;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'city',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">City: </label>
				{this.cityValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

CitySelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default CitySelectionComponent;
