import React from 'react';
import { get, noop, isEmpty } from 'lodash';
import ReactToPrint from 'react-to-print';
import { ExportToCsv } from 'export-to-csv';
import PropTypes from 'prop-types';

import Schema from '../../validation/BaseSchema';
import { exportOptions } from './../../common/components/export/export-options';
import { generateFileName } from './../../common/components/export/helpers';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { withContext, withLoader } from './../../common/components';
import { default as AccountSummary } from './account-summary';
import { default as BusinessInformation } from './business-information';
import { default as PCICompliance } from './pci-compliance';
import { Notification } from '../../common/components/notifications';
import { principalService } from '../../services';
import MerchantContext from './MerchantContext';
import { _merchantFunctions } from '../../services/_merchantFunctions';
import UpdateMerchantContext from './UpdateMerchantContext';
import { merchantDetailsTemplate } from '../../validation';
import { _sharedFunctions } from '../../services/_sharedFunctions';
import { ValidationError } from '../error';
import { isAchq, isGoPlus, validateOnBlur } from '../../common/utilities';

class MerchantDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				name: modalNames.none,
				data: null,
			},
			showUserDropDown: false,
			isEditDetails: false,
			merchantDetails: {
				appId: '',
				businessContactName: '',
				phoneNumber: '',
				businessEmail: '',
				alternateEmail: '',
			},
			errorListPaths: {},
			errorList: [],
			errorMessage: '',
			isSubmit: false,
		};
		this.detailsRef = React.createRef();
		this.summaryRef = React.createRef();
		this.businessInfoRef = React.createRef();
		this.pciRef = React.createRef();
		this.printRef = React.createRef();
		this.notificationRef = React.createRef();
		this.merchantDetailsSchema = new Schema(merchantDetailsTemplate, { strip: false, typecast: true });
	}

	renderRequestUpdateButton = () => {
		return (
			<React.Fragment>
				<a
					className="btn btn--med btn--ghost datatooltip--top"
					data-tooltip="Request Account Update"
					onClick={this.openSendEmailPopup}
				>
					<i className="icon icon--xsml icon--user-alt align--v--sub" />
				</a>
			</React.Fragment>
		);
	};
	renderDropInButton = () => {
		const { dba, hasCardknoxEquipment } = this.props.merchant;
		const buttonStatus = hasCardknoxEquipment ? 'btn--primary' : 'btn--disabled';
		return (
			<React.Fragment>
				<div data-tooltip="Launch Merchant Portal Account" className="datatooltip--bottom spc--bottom--sml">
					<a
						className={`btn btn--med ${buttonStatus} `}
						href={`${process.env.REACT_APP_MERCHANT_PORTAL_URL}/login?dropinDBA=${encodeURIComponent(dba)}`}
						target="_blank"
						aria-disabled={hasCardknoxEquipment}
					>
						<i className="icon icon--xsml icon--launch-merchant align--v--sub" />
					</a>
				</div>
			</React.Fragment>
		);
	};

	openSendEmailPopup = () => {
		const { merchant } = this.props;
		const principal = principalService.get();

		this.openCloseModal({
			name: modalNames.sendEmail,
			data: {
				templateParams: {
					appId: merchant.appId,
					mid: merchant.mid,
					toEmail: process.env.REACT_APP_BANKING_SERVICES_EMAIL,
					fromEmail: principal.email,
					dba: merchant.dba,
				},
				addNotification: get(this.notificationRef, 'current.addNotification', noop),
			},
		});
	};

	renderPrintButton = () => {
		return (
			<React.Fragment>
				<button
					className="btn btn--med btn--ghost datatooltip--top"
					data-tooltip="Print"
					onClick={this.expandAllAndPrint}
				>
					<i className="icon icon--xsml icon--print align--v--sub" />
				</button>

				<ReactToPrint
					ref={this.printRef}
					trigger={() => <div style={{ display: 'none' }}></div>}
					content={() => this.detailsRef.current}
					onAfterPrint={this.collapseAll}
				/>
			</React.Fragment>
		);
	};

	expandAllAndPrint = () => {
		this.summaryRef.current.showMoreForPrint().then(() => {
			this.businessInfoRef.current.showMoreForPrint().then(() => {
				this.pciRef.current.showMoreForPrint().then(() => {
					this.printRef.current.handlePrint();
				});
			});
		});
	};

	collapseAll = () => {
		this.summaryRef.current.collapseAfterPrint();
		this.businessInfoRef.current.collapseAfterPrint();
		this.pciRef.current.collapseAfterPrint();
	};

	download = () => {
		let merchId = this.props.merchant.appId;
		const exporter = new ExportToCsv({ filename: generateFileName(false, merchId), ...exportOptions });

		let flattenedMerch = _sharedFunctions.flatten(this.props.merchant);
		exporter.generateCsv([flattenedMerch]);
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	validateFields = (isSubmit = false) => {
		const errorList = this.merchantDetailsSchema.validate({ ...this.state.merchantDetails });

		const errorListPaths = errorList.map(e => e.path);
		const hasErrors = !isEmpty(errorList);
		const newState = { errorList, errorListPaths };

		if (isSubmit && !this.state.isSubmit) {
			newState.isSubmit = true;
		}

		this.setState(newState);

		return hasErrors;
	};

	handleChange = e => {
		let merchantDetails = { ...this.state.merchantDetails };
		let fieldName = e.target.name;
		if (e.target.name.indexOf('_') > -1) {
			let keyList = e.target.name.split('_');
			fieldName = keyList[keyList.length - 1];
		}
		merchantDetails[fieldName] = e.target.value;
		this.setState({ merchantDetails }, () => {
			if (!this.state.isSubmit) return;
			this.validateFields();
		});
	};

	handleBlur = ({ target: { name } }) => {
		const { isSubmit, merchantDetails, errorList } = this.state;
		if (isSubmit) return;
		let fullName = name;
		if (name.indexOf('_') > -1) {
			let keyList = name.split('_');
			fullName = keyList[keyList.length - 1];
		}
		const newState = validateOnBlur(this.merchantDetailsSchema, merchantDetails, errorList, fullName);
		this.setState(newState);
	};

	scrollTo = id => {
		const elem = document.getElementById(`merchantDetails_${id}`);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		const elem = document.getElementById(`merchantDetails_${id}`);
		elem && elem.focus();
	};

	renderErrors = () => {
		const { errorMessage, errorListPaths, errorList } = this.state;

		return (
			<React.Fragment>
				{errorMessage ? <div className="note note--warning spc--bottom--med">{errorMessage}</div> : null}
				<ValidationError
					errorList={errorList}
					errorListPaths={errorListPaths}
					scrollTo={this.scrollTo}
					focusField={this.focusField}
				/>
			</React.Fragment>
		);
	};

	saveChanges = () => {
		if (this.validateFields(true)) {
			return;
		}
		this.props.showLoader(true);
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		_merchantFunctions
			.saveMerchantDetails(this.state.merchantDetails)
			.then(response => {
				this.props.updateMerchant(this.state.merchantDetails);
				this.setState({ isEditDetails: false, errorMessage: '' });
				if (response.ticketId !== 0) {
					addNotification({
						message: `Ticket #${response.ticketId} has been created to update the Account Info.`,
						success: true,
					});
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({ errorMessage: err });
			});
		this.props.showLoader(false);
	};

	editDetails = () => {
		const merchantDetails = {
			appId: this.props.merchant.appId,
			businessContactName: this.props.merchant.businessContactName,
			phoneNumber: this.props.merchant.phoneNumber,
			businessEmail: this.props.merchant.businessEmail,
			alternateEmail: this.props.merchant.alternateEmail,
		};
		this.setState({ isEditDetails: true, merchantDetails });
	};

	cancelChanges = () => {
		this.setState({ isEditDetails: false, errorList: [], errorListPaths: {}, errorMessage: '', isSubmit: false });
	};

	renderExportMerchant = () => {
		return (
			<button
				className="btn btn--med btn--ghost datatooltip--top"
				data-tooltip="Export"
				onClick={() => this.download()}
			>
				<i className="icon icon--xsml icon--download align--v--sub" />
			</button>
		);
	};

	renderEmailButton = () => {
		const { dba, mid } = this.props.merchant;
		const subject = dba + (mid ? ` - MID: ${mid}` : '');
		return (
			<a
				className="btn btn--med btn--ghost datatooltip--top"
				href={`mailto:?subject=${subject}`}
				data-tooltip="New Email"
			>
				<i className="icon icon--xsml icon--envelope"></i>
			</a>
		);
	};

	renderNewNote = () => {
		return (
			<button
				type="button"
				className="btn btn--med btn--ghost spc--right--tny spc--bottom--sml datatooltip--top"
				data-tooltip="Add Note"
				onClick={() => this.openCloseModal({ name: modalNames.newNote, data: { appid: this.props.merchant.appId } })}
			>
				<i className="icon icon--xsml icon--addnote align--v--sub"></i>
			</button>
		);
	};

	renderReviewPricingPopup = () => {
		const { dba, appId, leadId, isCanada } = this.props.merchant;
		let merchantInfo = {
			leadId: leadId,
			dba: dba,
			appId: appId,
			market: isCanada ? 'Canada' : 'USA',
		};
		return (
			<button
				type="button"
				className="btn btn--med btn--ghost spc--right--tny spc--bottom--sml datatooltip--top"
				data-tooltip="Submit to Pricing Analysis"
				onClick={() => this.openCloseModal({ name: modalNames.reviewPricing, data: { merchantInfo } })}
			>
				<i className="icon icon--xsml icon--pricing-review align--v--sub"></i>
			</button>
		);
	};

	toggleUserDropDown = () => {
		this.setState({
			showUserDropDown: !this.state.showUserDropDown,
		});
	};

	render() {
		const { merchant } = this.props;
		const { showUserDropDown } = this.state;
		const userDropDownActiveClass = showUserDropDown ? 'is-open' : 'is-closed';
		//console.log('expand all ' + expandAll);
		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<div className="l--content l--content--med" ref={this.detailsRef}>
					{this.renderErrors()}
					{merchant && (
						<React.Fragment>
							<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
							<header className="header">
								<div className="header__title__wrap">
									<div className="header__title">
										<span>
											{merchant.dba} - {merchant.appId}
										</span>
									</div>
									{merchant.statusDisplayName == 'Underwriting' && this.renderReviewPricingPopup()}
									{this.renderNewNote()}
								</div>
								<div className="pos--rel">
									<div onClick={this.toggleUserDropDown} className="header__actions__icon">
										<i className="icon icon--xsml icon--dots icon--middle"></i>
									</div>
									<div className={`header__actions__dd ${userDropDownActiveClass}`}>
										<div className="header__actions__item hide--on-print">{this.renderDropInButton()}</div>
										<div className="header__actions__item hide--on-print">{this.renderRequestUpdateButton()}</div>
										<div className="header__actions__item hide--on-print">{this.renderPrintButton()}</div>
										<div className="header__actions__item hide--on-print">{this.renderExportMerchant()}</div>
										<div className="header__actions__item hide--on-print">{this.renderEmailButton()}</div>
									</div>
								</div>
							</header>
							<div>
								<div className="spc--bottom--med">
									<AccountSummary
										ref={this.summaryRef}
										isEditDetails={this.state.isEditDetails}
										merchant={merchant}
										merchantDetails={this.state.merchantDetails}
										onEdit={this.editDetails}
										onCancel={this.cancelChanges}
										onSave={this.saveChanges}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										openCloseModal={this.openCloseModal}
									/>
								</div>
								<div className="spc--bottom--med">
									<BusinessInformation merchant={merchant} ref={this.businessInfoRef} />
								</div>
								{!isGoPlus(merchant) && !isAchq(merchant) && (
									<div className="spc--bottom--med">
										<PCICompliance pciInfo={merchant.pciCompliance} ref={this.pciRef} />
									</div>
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}
MerchantDetails.propTypes = {
	merchant: PropTypes.object,
};

export default withLoader(
	withContext(withContext(MerchantDetails, MerchantContext, 'merchant'), UpdateMerchantContext, 'updateMerchant')
);
