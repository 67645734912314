import { cloneDeep, trim } from 'lodash';

import { merchantMPATemplate } from './merchantMPATemplate.validation';
import { ascii } from './validators';

const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
const postalCode = val => !val || postalCodeRegex.test(val);
const length = maxLength => value => !value || trim(value).length === maxLength;

export const CanadaMerchantMPATemplate = cloneDeep(merchantMPATemplate);

CanadaMerchantMPATemplate.businessInformation.businessAddress.state.message.ascii =
	'[**Business Province**](javascript:void) contains invalid characters';
CanadaMerchantMPATemplate.businessInformation.businessAddress.state.message.required =
	'[**Business Province**](javascript:void) is required';
CanadaMerchantMPATemplate.businessInformation.businessAddress.zip.message.ascii =
	'[**Business Postal Code**](javascript:void) contains invalid characters';
CanadaMerchantMPATemplate.businessInformation.businessAddress.zip.message.required =
	'[**Business Postal Code**](javascript:void) is required';
CanadaMerchantMPATemplate.businessInformation.businessAddress.zip.use.postalCode = postalCode;
CanadaMerchantMPATemplate.businessInformation.businessAddress.zip.message.postalCode =
	'[**Business Postal Code**](javascript:void) is not in valid format (A1A 1A1)';
CanadaMerchantMPATemplate.corporateAddress.zip.use.postalCode = postalCode;
CanadaMerchantMPATemplate.corporateAddress.zip.message.postalCode =
	'[**Mailing Postal Code**](javascript:void) is not in valid format (A1A 1A1)';

CanadaMerchantMPATemplate.signerInformation.address.state.message.ascii =
	'[**Province**](javascript:void) contains invalid characters';
CanadaMerchantMPATemplate.signerInformation.address.state.message.required =
	'[**Province**](javascript:void) is required';
CanadaMerchantMPATemplate.signerInformation.address.zip.message.ascii =
	'[**Postal Code**](javascript:void) contains invalid characters';
CanadaMerchantMPATemplate.signerInformation.address.zip.message.required =
	'[**Postal Code**](javascript:void) is required';
CanadaMerchantMPATemplate.signerInformation.address.zip.use.postalCode = postalCode;
CanadaMerchantMPATemplate.signerInformation.address.zip.message.postalCode =
	'[**Postal Code**](javascript:void) is not in valid format (A1A 1A1)';

CanadaMerchantMPATemplate.signerInformation.socialSecurityNumber = {
	use: { length: length(9), ascii },
	type: String,
	message: {
		length: '[**Social Insurance Number**](javascript:void) is invalid',
		required: '[**Social Insurance Number**](javascript:void) is required',
		ascii: '[**Social Insurance Number**](javascript:void) contains invalid characters',
	},
};
CanadaMerchantMPATemplate.bankAccountInformation.transitNumber = {
	use: { ascii, length: length(5) },
	type: String,
	message: {
		ascii: '[**Transit Number**](javascript:void) contains invalid characters',
		length: '[**Transit Number**](javascript:void) must be 5 numbers in length',
		required: '[**Transit Number**](javascript:void) is required',
	},
};
CanadaMerchantMPATemplate.bankAccountInformation.institutionNumber = {
	use: { ascii, length: length(3) },
	type: String,
	message: {
		ascii: '[**Institution Number**](javascript:void) contains invalid characters',
		length: '[**Institution Number**](javascript:void) must be 3 numbers in length',
		required: '[**Institution Number**](javascript:void) is required',
	},
};
CanadaMerchantMPATemplate.signerInformation.identificationType = {
	type: String,
	message: '[**Identification Type**](javascript:void) is required',
};
CanadaMerchantMPATemplate.signerInformation.identificationNumber = {
	type: String,
	message: '[**Identification Number**](javascript:void) is required',
};
CanadaMerchantMPATemplate.signerInformation.identificationIssueDate = {
	type: Date,
	message: '[**Identification Issue Date**](javascript:void) is required',
};
CanadaMerchantMPATemplate.signerInformation.identificationExpirationDate = {
	type: Date,
	message: '[**Identification Expiration Date**](javascript:void) is required',
};
