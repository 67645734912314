import { getValueForProperty, minFee, maxFee } from './validators';

export const planTemplate = {
	fees: [
		{
			merchantPrice: {
				type: Number,
				use: { minFee, maxFee },
				message: {
					type: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						return `[**${feeName} Merchant Cost**](javascript:void) is invalid`;
					},
					minFee: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						const minFee = getValueForProperty(ctx, path, 'minFee');
						return ` [**${feeName} Merchant Cost**](javascript:void) must be equal or higher than ${minFee}`;
					},
					maxFee: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						const maxFee = getValueForProperty(ctx, path, 'maxFee');
						return `[**${feeName} Merchant Cost**](javascript:void) must be equal or lower than ${maxFee}`;
					},
				},
			},
		},
	],
};
