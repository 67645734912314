import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from '../../common/components/react-time-picker';
import EquipmentOption from './equipment-option';

const FrontendProcessorComponent = props => {
	const { merchantEquipment, equipment, handleChange, handleTimeChange, enabled } = props;
	const batchSettlementOption = equipment.equipmentOptions['BatchSettlementType'];

	return (
		<div className="gateway__top">
			<div id={equipment.name + '_platform'} className="gateway__radio-list">
				{equipment.frontendProcessors &&
					Object.keys(equipment.frontendProcessors).map((opt, idx) => {
						return (
							<div key={idx} className="gateway__radio-list__item">
								<input
									id={equipment.name + '_platform_' + opt}
									name={equipment.name + '_platform'}
									value={opt}
									type="radio"
									className="input--radio"
									onChange={handleChange}
									disabled={!enabled}
									checked={merchantEquipment.platform == opt}
								/>
								<label className="label label--big" htmlFor={equipment.name + '_' + 'platform_' + opt}>
									{equipment.frontendProcessors[opt]}
								</label>
							</div>
						);
					})}
			</div>
			{batchSettlementOption && (
				<div className="gateway__top__item row">
					<div className="col col-sml-12 col-lrg-8 spc--bottom--med--to-lrg">
						<EquipmentOption
							merchantEquipment={merchantEquipment}
							equipment={equipment}
							option={batchSettlementOption.name}
							listType="radio"
							checkboxHandler={props.optionSelect}
							moreInfoHandler={props.optionSetMoreInfo}
							inputHandler={handleChange}
							disableOption={!enabled}
						/>
					</div>
				</div>
			)}
			<div className="row spc--top--med spc--right--sml spc--left--sml">
				<div className="col col-sml-12 col-lrg-6">
					<label className="label label--big spc--bottom--sml">Batch out time</label>
					<TimePicker
						value={merchantEquipment.batchoutTime}
						name={equipment.name + '_' + 'batchoutTime'}
						className="input input--time spc--bottom--sml"
						clockIcon={null}
						clearIcon={null}
						disableClock={true}
						maxDetail="minute"
						format="h:mm a"
						onChange={handleTimeChange}
						locale="en-US"
						disabled={!enabled}
					/>
				</div>
				<div className="col col-sml-12 col-xxxlrg-6 spc--bottom--med">
					<div className="note note--primary">
						We strongly recommend that merchants do not set up their own batch processing times. We set up the latest
						possible batch out time on each equipment using the best platform for that equipment.
					</div>
				</div>
			</div>
		</div>
	);
};
FrontendProcessorComponent.propTypes = {
	merchantEquipment: PropTypes.object,
	equipment: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleTimeChange: PropTypes.func.isRequired,
	enabled: PropTypes.bool,
};
FrontendProcessorComponent.defaultProps = {
	enabled: true,
};
export default FrontendProcessorComponent;
