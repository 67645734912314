import React from 'react';
import PropTypes from 'prop-types';
import { includes, get } from 'lodash';

// add errors which can be safely ignored
const errorsToIgnore = [
	'SyntaxError: dynamic module import is not implemented', // CP-3763 issues with smartlook integration
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
];

class GlobalErrorHandler extends React.Component {
	static instance = null;
	constructor(props) {
		super(props);

		GlobalErrorHandler.instance = this;
		this.state = { errors: null };

		this.addError = this.addError.bind(this);
		this.clearErrors = this.clearErrors.bind(this);
		this.handleUncaughtError = this.handleUncaughtError.bind(this);
		this.handleUnhandledRejection = this.handleUnhandledRejection.bind(this);
	}

	componentDidMount() {
		const { history } = this.props;
		this.unlistenHistory = history.listen((location, action) => {
			this.clearErrors();
		});

		window.addEventListener('error', this.handleUncaughtError);
		window.addEventListener('unhandledrejection', this.handleUnhandledRejection);
	}

	handleUncaughtError(error) {
		this.addError(error);
	}

	handleUnhandledRejection(error) {
		this.addError(error);
	}

	getChildContext() {
		return { errors: this.state.errors };
	}

	addError(error) {
		if (includes(errorsToIgnore, get(error, 'message')) || get(error, 'reason.mfaError')) {
			return;
		}
		this.setState(state => ({ errors: (state.errors || []).concat(error) }));
	}

	clearErrors() {
		this.setState({ errors: null });
	}

	componentWillUnmount() {
		this.unlistenHistory();

		window.removeEventListener('error', this.handleUncaughtError);
		window.removeEventListener('unhandledrejection', this.handleUnhandledRejection);
	}

	render() {
		return <div>{this.props.children}</div>;
	}
}

GlobalErrorHandler.childContextTypes = {
	errors: PropTypes.array,
};

export default GlobalErrorHandler;
