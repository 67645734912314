import React, { Component, createRef } from 'react';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';

import { SidebarComponent } from '../components/sidebar';
import { Modal } from '../common/components/modal';
import principalService from '../services/principalService';
import { NavLink } from 'react-router-dom';
import { Notification } from '../common/components/notifications';

class MainLayoutComponent extends Component {
	constructor(props) {
		super(props);
		this.isBetaPartner = window.location.hostname === 'betapartner.cardknox.com';

		const showMerchantSidebar = props.location.pathname.indexOf('/merchants/') != -1;
		let isExpanded = true;
		let isSubExpanded = true;

		if (sessionStorage.getItem('sidebarExpanded') !== null) {
			const sidebarExpanded = JSON.parse(sessionStorage.getItem('sidebarExpanded'));
			isExpanded = sidebarExpanded;
		} else if (showMerchantSidebar) {
			isExpanded = false;
		}

		if (sessionStorage.getItem('sidebarSubExpanded') !== null) {
			const sidebarSubExpanded = JSON.parse(sessionStorage.getItem('sidebarSubExpanded'));
			isSubExpanded = sidebarSubExpanded;
		}

		this.state = {
			sidebarVisible: false,
			sidebarSubVisible: false,
			privacyPolicyVisible: false,
			showMerchantSidebar,
			showUserDropDown: false,
			user: null,
			isExpanded,
			isSubExpanded,
		};

		this.notificationRef = createRef();
	}

	componentDidMount() {
		this.collapseExpandSidebar();
		const principal = principalService.get();
		this.setState({
			user: principal, //JSON.parse(localStorage.getItem('user'))
		});
	}

	componentDidUpdate() {
		this.collapseExpandSidebar();
	}

	toggleSidebar = () => {
		const { sidebarVisible } = this.state;
		this.setState({
			sidebarVisible: !sidebarVisible,
			sidebarSubVisible: false,
		});
	};

	collapseExpandSidebar = () => {
		const collapseSidebar = JSON.parse(sessionStorage.getItem('collapseSidebar'));
		if (collapseSidebar !== null) {
			this.toggleExpandCollapseSidebar(collapseSidebar);
			delete sessionStorage.collapseSidebar;
		}
	};

	removeSubSidebar = () => {
		this.setState({
			sidebarSubVisible: false,
			sidebarVisible: false,
		});
	};

	toggleExpandCollapseSidebar = (isExpanded = this.state.isExpanded) => {
		this.setState({ isExpanded: !isExpanded }, () => {
			sessionStorage.setItem('sidebarExpanded', this.state.isExpanded);
		});
	};

	toggleSubSidebar = () => {
		const { sidebarSubVisible, sidebarVisible } = this.state;
		this.setState({
			sidebarSubVisible: !sidebarSubVisible,
			sidebarVisible: !sidebarVisible,
		});
	};

	toggleExpandCollapseSubSidebar = () => {
		const { isSubExpanded } = this.state;
		this.setState(
			{
				isSubExpanded: !isSubExpanded,
			},
			() => {
				sessionStorage.setItem('sidebarSubExpanded', this.state.isSubExpanded);
			}
		);
	};

	togglePrivacyPolicy = () => {
		const { privacyPolicyVisible } = this.state;
		this.setState({
			privacyPolicyVisible: !privacyPolicyVisible,
		});
	};

	render() {
		const { children } = this.props;
		const {
			sidebarVisible,
			sidebarSubVisible,
			privacyPolicyVisible,
			showMerchantSidebar,
			isExpanded,
			isSubExpanded,
		} = this.state;
		const sidebarCssClass = sidebarVisible ? 'is-revealed' : '';
		const collapsedClass = isExpanded ? 'sidebar-not-collapsed' : 'is-collapsed';
		const hiddenClass = principalService.get().isQaUser ? 'l--sidebar-hidden' : '';

		const sidebarSubCssClass = sidebarSubVisible ? 'is-sub-revealed' : '';
		const collapsedSubClass = isSubExpanded ? '' : 'is-sub-collapsed';

		const version = process.env.AppBuildTag;
		const isMfaRequired = get(this.props.history, 'location.state.isMfaRequired');

		return (
			<div className={`${hiddenClass}`}>
				<Notification ref={this.notificationRef} />
				<header className="header--mobile">
					<button onClick={this.toggleSidebar} type="button" className="btn btn--reset header--mobile__btn">
						<i className="icon icon--xsml icon--menu"></i>
					</button>
					<a href="javascript:void(0)" className="header--mobile__logo"></a>
					{/*<button type="button" className="btn--reset header--mobile__btn">
                        <i className="icon icon--xsml icon--notifications--negative"></i>
                    </button>*/}
				</header>

				{showMerchantSidebar && (
					<header className="header--sub--mobile">
						<NavLink className="sidebar--secondary__link" to="/merchants">
							<i className="icon icon--tiny icon--arrow--left spc--right--tny"></i>Merchant Accounts
						</NavLink>
						<button
							onClick={this.toggleSubSidebar}
							type="button"
							className="btn btn--reset header--mobile__btn spc--left--auto"
						>
							<i className="icon icon--xsml icon--menu--positive"></i>
						</button>
					</header>
				)}

				{isMfaRequired ? null : (
					<div className={`l--aside ${sidebarCssClass} ${collapsedClass} ${sidebarSubCssClass}`}>
						<div className="l--aside__header">
							<button onClick={this.toggleSidebar} type="button" className="btn btn--reset header--mobile__btn">
								<i className="icon icon--xsml icon--menu"></i>
							</button>
						</div>
						<SidebarComponent
							isExpanded={isExpanded}
							addNotification={get(this.notificationRef, 'current.addNotification', noop)}
							history={this.props.history}
						/>
						<button
							type="button"
							className="btn btn--med btn--collapse datatooltip--collapse"
							onClick={() => this.toggleExpandCollapseSidebar()}
						>
							<i
								className={`icon icon--sml icon--${isExpanded ? 'menu-collapse' : 'menu-expand'} align--v--middle`}
								data-tooltip={isExpanded ? 'Collapse Menu' : 'Expand Menu'}
							/>
						</button>

						<div className="sidebar__copy">
							<span className="sidebar__copyright">
								<i className="icon icon--xsml icon--copyright"></i>
								<span className="sidebar__copytext">&copy; Copyright {new Date().getFullYear()}</span>
								<span className="sidebar__copydash">-</span>
							</span>
							<a
								className="anchor--negative sidebar__privacy"
								href="javascript:void(0)"
								onClick={this.togglePrivacyPolicy}
							>
								<i className="icon icon--xsml icon--privacy"></i>{' '}
								<span className="sidebar__privacytext">Privacy Policy</span>
							</a>
							<div className="sidebar__version">({version})</div>
						</div>
					</div>
				)}
				{showMerchantSidebar && (
					<div
						id="merchantSidebarPortal"
						className={`l--aside--sub ${sidebarSubCssClass} ${collapsedSubClass} ${collapsedClass}`}
					/>
				)}
				<div
					id="topOfPage"
					className={`l--main ${sidebarCssClass} ${collapsedClass} ${showMerchantSidebar ? 'sidebar-is-visible' : ''}`}
				>
					{this.isBetaPartner && (
						<div className="l--main__banner">
							Betapartner.cardknox.com is closing down soon. Please be sure to use Partner.cardknox.com instead to
							prevent disruption. Thank you.
						</div>
					)}

					{children}

					<Modal isOpen={privacyPolicyVisible} onClose={this.togglePrivacyPolicy}>
						<div className="w--606p w--max--100">
							<div className="popup__header">
								<h3>Privacy Policy</h3>
							</div>
							<div className="popup__body type--color--text--regular">
								<p className="spc--bottom--med">
									Your privacy is important to Cardknox Development Inc. ("CARDKNOX"). Our goal is to provide you with a
									personalized online experience that provides you with the information, resources, and services that
									are most relevant and helpful to you. Privacy Policy (the "Privacy Policy") has been written to
									describe the conditions under which this web site and other CARDKNOX resources (the "Web site") are
									being made available to you. The Privacy Policy discusses, among other things, how data obtained
									during your visit to this Web site may be collected and used. We strongly recommend that you read the
									Privacy Policy carefully. By using this Web site, you agree to be bound by the terms of this Privacy
									Policy. If you do not accept the terms of the Privacy Policy, you are directed to discontinue
									accessing or otherwise using the Web site or any materials obtained from it. If you are dissatisfied
									with the Web site, by all means contact us at{' '}
									<a href="mailto:info@cardknox.com" className="anchor anchor--primary">
										info@cardknox.com
									</a>
									; otherwise, your only recourse is to disconnect from this site and refrain from visiting the site in
									the future.
								</p>
								<p className="spc--bottom--med">
									The process of maintaining a Web site is an evolving one, and CARDKNOX may decide at some point in the
									future, without advance notice, to modify the terms of this Privacy Policy. Your use of the Web site,
									or materials obtained from the Web site, indicates your assent to the Privacy Policy at the time of
									such use. The effective Privacy Policy will be posted on the Web site, and you should check upon every
									visit for any changes.
								</p>
								<p className="spc--bottom--med">
									1. Non-personal information is data about usage and service operation that is not directly associated
									with a specific personal identity. CARDKNOX may collect and analyze non-personal information to
									evaluate how visitors use the CARDKNOX Website.
								</p>
								<p className="spc--bottom--med">
									2. CARDKNOX may gather aggregate information, which refers to information your computer automatically
									provides to us and which cannot be tied back to you as a specific individual. Examples include
									referral data (the Web sites you visited just before and just after our site), the pages viewed, time
									spent at our Web site, and Internet Protocol (IP) addresses. An IP address is a number that is
									automatically assigned to your computer whenever you access the Internet. For example, when you
									request a page from one of our sites, our servers log your IP address to create aggregate reports on
									user demographics and traffic patterns and for purposes of system administration.
								</p>
								<p className="spc--bottom--med">
									3. Every time you request or download a file from the Web site, CARDKNOX may store data about these
									events and your IP address in a log file. CARDKNOX may use this information to analyze trends,
									administer the Web site, track users' movements, and gather broad demographic information for
									aggregate use or for other business purposes.
								</p>
								<p className="spc--bottom--med">
									4. Our site may use a feature of your browser to set a "cookie" on your computer. Cookies are small
									packets of information that a Web site's computer stores on your computer. CARDKNOX's Web site can
									then read the cookies whenever you visit our site. We may use cookies in a number of ways, such as to
									save your password so you don't have to re-enter it each time you visit our site, to deliver content
									specific to your interests and to track the pages you've visited. These cookies allow us to use the
									information we collect to customize web experience so that your visit to our site is as relevant and
									as valuable to you as possible. Most browser software can be set up to deal with cookies. You may
									modify your browser preference to provide you with choices relating to cookies. You have the choice to
									accept all cookies, to be notified when a cookie is set or to reject all cookies. If you choose to
									reject cookies, certain of the functions and conveniences of our Web site may not work properly, and
									you may be unable to use services that require registration in order to participate, or you will have
									to re-register each time you visit our site. Most browsers offer instructions on how to reset the
									browser to reject cookies in the "Help" section of the toolbar. We do not link non-personal
									information from cookies to personally identifiable information without your permission.
								</p>
								<p className="spc--bottom--med">
									5. CARDKNOX may use personal information to offer or provide services that support its activities or
									those of CARDKNOX participants or members, and their collaboration with CARDKNOX. When accessing
									restricted CARDKNOX Web pages and portals, your personal user information may be tracked in order to
									support collaboration, ensure authorized access, and enable communication among participants or
									members.
								</p>
								<p className="spc--bottom--med">
									6. CARDKNOX does not sell, rent, or lease any individual's personal information or lists of email
									addresses to anyone for marketing purposes, and we take commercially reasonable steps to maintain the
									security of this information. However, CARDKNOX reserves the right to supply any such information to
									any organization into which CARDKNOX may merge in the future or to which it may make any transfer in
									order to enable a third party to continue part or all of CARDKNOX. We also reserve the right to
									release personal information to protect our systems or business, when we reasonably believe you to be
									in violation of our Terms of Use or if we reasonably believe you to have initiated or participated in
									any illegal activity. In addition, please be aware that in certain circumstances, CARDKNOX may be
									obligated to release your personal information pursuant to judicial or other government subpoenas,
									warrants, or other orders.
								</p>
								<p className="spc--bottom--med">
									7. We respect the privacy of every individual who uses the Site and we will only process and use the
									data obtained through the Site for our own business purposes. CARDKNOX uses a variety of means to
									protect personal information provided by users of the Web site, including using firewalls and other
									security measures on its servers.
								</p>
							</div>
							<div className="popup__footer"></div>
						</div>
					</Modal>
				</div>
			</div>
		);
	}
}

MainLayoutComponent.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

export default MainLayoutComponent;
