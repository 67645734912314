import React, { Component, createRef } from 'react';
import { func, bool } from 'prop-types';
import { isEmpty, has, get, set, toLower, includes } from 'lodash';
import { Modal } from '../../common/components/modal';
import { withLoader } from '../../common/components';
import { appService } from '../../services';
import { Schema, cobrandingSettingsTemplate } from '../../validation';
import { ValidationError } from '../error';
import { ImageDropzoneComponent } from '../../common/components/file-dropzone';
import ColorPickerComponent from '../../common/components/react-color-picker/color-picker';
import { Notification } from '../../common/components/notifications';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';

const imageFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp];
const colorPrimaryDefault = '#E51646';
const colorSidebarDefault = '#0E0125';
class CobrandingSettings extends Component {
	constructor(props) {
		super(props);
		this.notificationRef = createRef();
		this.state = {
			fromName: '',
			logoFile: null,
			iconFile: null,
			errorList: [],
			isSubmit: false,
			cobrandUrlPP: '',
			cobrandUrlMP: '',
			partnerDomain: '',
			errorMessage: null,
			errorListPaths: [],
			partnerDomainEdit: '',
			isCobrandEnabled: false,
			isCobrandModalOpen: false,
			isCobrandEnabledReadOnly: false,
			cobrandingModalErrorMessage: null,
			colorPrimary: colorPrimaryDefault,
			colorSidebar: colorSidebarDefault,
		};
		this.formSchema = new Schema(cobrandingSettingsTemplate, { strip: false, typecast: true });
	}

	get fieldsForValidation() {
		const { fromName } = this.state;
		return { fromName };
	}

	async componentDidMount() {
		this.props.showLoader(true);

		const {
			partnerSettings: { fromName, colorPrimary, colorSidebar, icon, logo, partnerDomain },
		} = await appService.getCobrandingSettings();
		const { cobrandUrlPP, cobrandUrlMP } = this.getCobrandUrls(partnerDomain);

		if (partnerDomain) {
			this.setState({ isCobrandEnabled: true, isCobrandEnabledReadOnly: true });
		}

		this.setState(
			{
				fromName,
				partnerDomain,
				cobrandUrlPP,
				cobrandUrlMP,
				logoFile:
					has(logo, 'contentDisposition') && logo.contentDisposition.length > 0
						? [
								{
									file: new File([this.base64ToBlob(logo.contentDisposition, 'image/png')], 'logo.png'),
									fileDescription: '',
								},
						  ]
						: null,
				iconFile:
					has(icon, 'contentDisposition') && icon.contentDisposition.length > 0
						? [
								{
									file: new File([this.base64ToBlob(icon.contentDisposition, 'image/png')], 'icon.png'),
									fileDescription: '',
								},
						  ]
						: null,
			},
			() => {
				this.props.showLoader();
				this.setColorPickerValues(colorPrimary || colorPrimaryDefault, colorSidebar || colorSidebarDefault);
			}
		);
	}

	get isCobrandingEnabledAndDomainSet() {
		return this.state.isCobrandEnabled && this.state.partnerDomain;
	}

	base64ToBlob = (base64, type) => {
		const byteCharacters = window.atob(base64);
		let arrayBuffer = new ArrayBuffer(byteCharacters.length);
		let uInt8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteCharacters.length; i++) {
			uInt8Array[i] = byteCharacters.charCodeAt(i);
		}
		let blob = new Blob([uInt8Array], { type });
		return blob;
	};

	getCobrandUrls = partnerDomain => {
		if (!partnerDomain || partnerDomain.trim() === '') {
			return { cobrandUrlPP: null, cobrandUrlMP: null };
		}
		partnerDomain = partnerDomain.replace(/[^a-zA-Z0-9-]/g, '');
		const cobrandUrlPP = `https://${partnerDomain}.partner.cardknox.com`;
		const cobrandUrlMP = `https://${partnerDomain}.portal.cardknox.com`;
		return { cobrandUrlPP, cobrandUrlMP };
	};

	handleInputChange = e => {
		const { id } = e.target;
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		if (id === 'partnerDomainEdit') {
			value = toLower(value);
			const { cobrandUrlPP, cobrandUrlMP } = this.getCobrandUrls(value);
			value = value.replace(/[^a-zA-Z0-9-]/g, '');
			this.setState({
				[id]: value,
				cobrandUrlPP,
				cobrandUrlMP,
				cobrandingModalErrorMessage: null,
			});
			return;
		}
		this.setState(
			{
				[id]: value,
			},
			this.validateFieldsIfSubmitted
		);
	};

	handleError = err => {
		this.props.showLoader(false);
		this.setState({ errorMessage: 'An error occurred: ' + err });
	};

	getFile(file) {
		return new File([file], file.name, {
			type: file.type,
			lastModified: file.lastModified,
		});
	}

	onDropFile = propertyName => (tag, acceptedFiles) => {
		let newFilesList = acceptedFiles.map((itm, i) => {
			return { file: this.getFile(itm), fileDescription: '' };
		});

		const reader = new FileReader();
		reader.onload = e => {
			const img = new Image();
			img.onload = () => {
				this.setState({ [propertyName]: newFilesList, errorMessage: null }, this.validateFields);
			};
			img.onerror = () => {
				this.setState({ errorMessage: 'Invalid file dropped.' });
			};
			img.src = e.target.result;
		};
		reader.readAsDataURL(newFilesList[0].file);
	};

	handleRemoveFile = propertyName => () => {
		this.setState({ [propertyName]: null });
	};

	scrollTo = id => {
		const element = document.getElementById(id);
		element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};
	focusField = id => {
		const element = document.getElementById(id);
		element && element.focus();
	};

	validateFieldsIfSubmitted = () => {
		this.state.isSubmit && this.validateFields(true);
	};

	validateFieldOnBlur = () => {
		const { isSubmit } = this.state;
		!isSubmit && this.validateFields();
	};

	validateFields = (isSubmit = false) => {
		const errorList = this.formSchema.validate(this.fieldsForValidation);
		const hasErrors = !isEmpty(errorList);
		const errorListPaths = errorList.map(e => e.path);
		const newState = { errorList, errorListPaths };
		if (isSubmit) {
			newState.isSubmit = isSubmit;
		}
		this.setState(newState);
		return hasErrors;
	};

	handleUnavailableDomainName = () => {
		const { partnerDomain } = this.state;
		const errorMessageEl = (
			<span>
				The requested Domain name <span className="type--wgt--bold">{partnerDomain}</span> is not available. Please
				enter a new Cobrand Domain.
			</span>
		);
		this.setState({ partnerDomain: '', cobrandingModalErrorMessage: errorMessageEl, isCobrandModalOpen: true });
	};

	getCobrandingFields = () => {
		const { fromName, colorPrimary, colorSidebar, partnerDomain, logoFile, iconFile } = this.state;
		const fields = {
			fromName,
			colorPrimary,
			colorSidebar,
			partnerDomain,
		};
		set(fields, 'logo', get(logoFile, '0.file', null));
		set(fields, 'icon', get(iconFile, '0.file', null));
		return fields;
	};

	save = async () => {
		if (this.validateFields(true)) {
			return;
		}
		this.props.showLoader(true);

		try {
			const payload = this.getCobrandingFields();
			const { settingsSaved, filesUploaded, refNum } = await appService.saveCobrandingSettings(payload);
			this.props.showLoader(false);
			let errorMessage = null;
			if (settingsSaved && (filesUploaded === true || filesUploaded === null)) {
				this.notificationRef.current.addNotification({
					message: 'Settings saved successfully',
					ref: refNum,
					success: true,
				});
			} else {
				errorMessage = `The request was not successful. See results below:\nSettings ${
					!settingsSaved ? 'failed to save' : 'saved successfully'
				}.`;
				if (filesUploaded !== null)
					errorMessage += `\nFiles ${!filesUploaded ? 'failed to upload' : 'uploaded successfully'}.`;
				errorMessage = errorMessage.split('\n').filter(s => s);
				errorMessage = (
					<div>
						{errorMessage.map(e => (
							<>
								{e}
								<br />
							</>
						))}
					</div>
				);
			}
			this.setState({ errorMessage });
			if (!errorMessage && this.isCobrandingEnabledAndDomainSet) {
				this.setState({ isCobrandEnabledReadOnly: true });
			}
		} catch (e) {
			this.props.showLoader();
			if (includes(toLower(e), 'requested domain is not available')) {
				this.handleUnavailableDomainName();
			} else {
				this.handleError(e);
			}
		}
	};

	SetCobrandDomain = async () => {
		this.setState({ isCobrandModalOpen: false, partnerDomain: this.state.partnerDomainEdit, partnerDomainEdit: '' });
	};

	onCobrandModalClosed = () => {
		const defaultedCobrandingFields = {
			fromName: '',
			partnerDomain: '',
			partnerDomainEdit: '',
			colorPrimary: colorPrimaryDefault,
			colorSidebar: colorSidebarDefault,
			cobrandUrlPP: '',
			cobrandUrlMP: '',
			logoFile: null,
			iconFile: null,
		};
		this.setState(
			{
				cobrandingModalErrorMessage: null,
				isCobrandModalOpen: false,
				partnerDomainEdit: '',
				isCobrandEnabled: false,
				...defaultedCobrandingFields,
			},
			() => this.setColorPickerValues(colorPrimaryDefault, colorSidebarDefault)
		);
	};

	openCobrandModal = () => {
		this.setState({ isCobrandModalOpen: true });
	};

	handleIsCobrandEnabledChange = e => {
		const { isCobrandEnabledReadOnly } = this.state;
		if (isCobrandEnabledReadOnly) {
			return;
		}
		const { checked } = e.target;
		if (checked) {
			this.setState({ isCobrandEnabled: checked }, this.openCobrandModal);
		} else {
			const defaultedCobrandingFields = {
				fromName: '',
				partnerDomain: '',
				partnerDomainEdit: '',
				cobrandUrlPP: '',
				cobrandUrlMP: '',
				logoFile: null,
				iconFile: null,
			};
			this.setState({ isCobrandEnabled: checked, ...defaultedCobrandingFields }, () => {
				this.validateFields();
				this.setColorPickerValues(colorPrimaryDefault, colorSidebarDefault);
			});
		}
	};

	setColorPickerValues = (colorPrimary, colorSidebar) => {
		has(this.colorPrimaryPickerRef, 'handleColorChange') && this.colorPrimaryPickerRef.handleColorChange(colorPrimary);
		has(this.colorSidebarPickerRef, 'handleColorChange') && this.colorSidebarPickerRef.handleColorChange(colorSidebar);
	};

	renderCobrandingModal() {
		return (
			<Modal
				className="popup__content popup__content--lrg"
				isOpen={this.state.isCobrandModalOpen}
				onClose={this.onCobrandModalClosed}
			>
				<div className="popup__body popup__body--cobrand-url">
					<h4 className="popup__header__title spc--bottom--med fullwidth">Cobrand URL</h4>
					{this.state.cobrandingModalErrorMessage && (
						<div className="spc--bottom--sml message message--warning">
							<p>{this.state.cobrandingModalErrorMessage}</p>
						</div>
					)}
					<hr className="separator separator--grey1 spc--bottom--med" />
					<p className="type--base spc--bottom--med">
						Please input the name to be used for cobranding your URLs. For example:{' '}
						<span className="type--italic">yourcompanyname</span>.portal.cardknox.com.
					</p>
					<div className="form__field">
						<label htmlFor="partnerDomainEdit" className="label">
							Name
						</label>
						<input
							placeholder="Your Company Name"
							id="partnerDomainEdit"
							className="input input--med"
							value={this.state.partnerDomainEdit}
							onChange={this.handleInputChange}
						/>
					</div>
				</div>
				<div className="popup__footer popup__footer--styled">
					<div className="fullwidth">
						<p className="label spc--bottom--med">
							Please note that once saved the cobranded URLs will be generated and will not be editable.
						</p>
						<div className="flex--primary">
							<button
								disabled={this.props.isLoading}
								type="button"
								className="btn btn--med btn--ghost spc--right--tny w--150p align--h--right"
								onClick={this.onCobrandModalClosed}
							>
								Cancel
							</button>
							<button
								disabled={this.props.isLoading || !this.state.partnerDomainEdit}
								type="button"
								className="btn btn--med btn--primary w--150p"
								onClick={this.SetCobrandDomain}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	renderCobrandingSettings() {
		return (
			<>
				<div className="spc--bottom--med">
					<input
						type="checkbox"
						name="allColumns"
						className="input input--check"
						disabled={this.props.isLoading}
						checked={this.state.isCobrandEnabled}
						id="allColumns"
						onClick={this.handleIsCobrandEnabledChange}
					/>
					<label htmlFor="allColumns" className="type--wgt--bold">
						Enable
					</label>
				</div>
				<div
					className={`settings--cobranding ${
						this.props.isLoading || !this.isCobrandingEnabledAndDomainSet ? 'is-disabled' : ''
					}`}
				>
					<div className="settings--cobranding__grid">
						<div>
							<div className="form__field__header spc--bottom--tny">
								<label htmlFor="cobrandUrlPP" className="label spc--right--tny">
									Partner Portal URL
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200"
									data-tooltip="This URL will be used when you send out e-Apps to Merchants."
								></i>
							</div>
							<input
								id="cobrandUrlPP"
								readOnly
								disabled={true}
								className="input input--med"
								value={this.state.cobrandUrlPP}
							/>
						</div>
						<div>
							<div className="form__field__header spc--bottom--tny">
								<label htmlFor="cobrandUrlMP" className="label spc--right--tny">
									Merchant Portal URL
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200"
									data-tooltip="This URL will be shared with your Merchants to access the Cardknox Merchant Portal."
								></i>
							</div>
							<input
								id="cobrandUrlMP"
								readOnly
								disabled={true}
								className="input input--med"
								value={this.state.cobrandUrlMP}
							/>
						</div>
						<div>
							<div className="form__field__header spc--bottom--tny">
								<label htmlFor="logoDropzone" className="label spc--right--tny">
									Logo
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200"
									data-tooltip="Logo will appear on eApps shared with Merchants, the cobranded Merchant Portal and Cardknox Welcome Emails."
								></i>
							</div>
							<ImageDropzoneComponent
								disabled={this.props.isLoading || !this.isCobrandingEnabledAndDomainSet}
								id="logoDropzone"
								onDrop={this.onDropFile('logoFile')}
								fileList={this.state.logoFile}
								onRemoveFile={this.handleRemoveFile('logoFile')}
								allowedFileTypes={imageFileTypes}
							/>
						</div>
						<div>
							<div className="form__field__header spc--bottom--tny">
								<label htmlFor="iconDropzone" className="label spc--right--tny">
									Favicon
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200"
									data-tooltip="Favicon will be used on the eApp signature link and cobranded Merchant Portal web pages."
								></i>
							</div>
							<ImageDropzoneComponent
								id="iconDropzone"
								disabled={!this.isCobrandingEnabledAndDomainSet}
								onDrop={this.onDropFile('iconFile')}
								fileList={this.state.iconFile}
								onRemoveFile={this.handleRemoveFile('iconFile')}
								allowedFileTypes={imageFileTypes}
							/>
						</div>
						<div className="settings--cobranding__grid__picker">
							<p className="label spc--bottom--sml">
								Modify your cobranded Merchant Portal theme by selecting primary and secondary colors:
							</p>
							<div className="settings--cobranding__picker">
								<ColorPickerComponent
									className="fullwidth pos--rel"
									name="Primary Color"
									disabled={this.props.isLoading || !this.isCobrandingEnabledAndDomainSet}
									onChange={color => this.setState({ colorPrimary: color })}
									ref={el => (this.colorPrimaryPickerRef = el)}
									defaultColor={colorPrimaryDefault}
								/>
								<ColorPickerComponent
									className="fullwidth pos--rel"
									name="Secondary Color"
									disabled={!this.isCobrandingEnabledAndDomainSet}
									onChange={color => this.setState({ colorSidebar: color })}
									ref={el => (this.colorSidebarPickerRef = el)}
									defaultColor={colorSidebarDefault}
								/>
							</div>
						</div>
						<div className="settings--cobranding__grid__note">
							<div className="message message--default fullwidth">
								Please note that browser caches may need to be cleared for Logo and color changes to be reflected.
							</div>
						</div>
						<div>
							<div className="form__field__header spc--bottom--tny">
								<label htmlFor="fromName" className="label spc--right--tny">
									From Name
								</label>
								<i
									className="icon icon--tiny icon--info datatooltip--200"
									data-tooltip="Modify the From Name to be displayed on Welcome Emails sent to Merchants."
								></i>
							</div>
							<input
								id="fromName"
								disabled={this.props.isLoading || !this.isCobrandingEnabledAndDomainSet}
								className="input input--med"
								value={this.state.fromName}
								onChange={this.handleInputChange}
								onBlur={this.validateFieldOnBlur}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		return (
			<div className="settings--main settings--main--alt">
				<Notification ref={this.notificationRef} />
				<div className="l--content--security">
					<div className="settings__header">
						<div className="settings__header__title">Cobranding Settings</div>
					</div>
					{this.state.errorMessage && (
						<div className="spc--bottom--sml message message--warning">
							<p>{this.state.errorMessage}</p>
						</div>
					)}
					<div className="spr__card spc--bottom--med clearfix">
						{this.renderCobrandingModal()}
						{this.renderCobrandingSettings()}
					</div>
					<ValidationError
						errorList={this.state.errorList}
						errorListPaths={this.state.errorListPaths}
						scrollTo={this.scrollTo}
						focusField={this.focusField}
					/>
					<div className="type--right">
						<button
							className="btn btn--med btn--primary"
							type="button"
							disabled={this.props.isLoading || !this.state.isCobrandEnabled}
							onClick={this.save}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		);
	}
}

CobrandingSettings.propTypes = {
	showLoader: func.isRequired,
	isLoading: bool,
};

export default withLoader(CobrandingSettings);
