import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, find, map, toLower, get, set, split } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { formatCurrency } from '../../../../helpers/currency-formatter';
import { EquipmentEditMethods } from '../../utilities/equipment-edit-methods';
import { modalNames, ModalWrapper } from '../../../../common/components/modal-wrapper';
import { renderEquipmentOption } from '../../../../common/components/equipmentOption/renderEquipmentOption';

const visibleEquipmentOptionsBySubcategory = {
	paper: 'CaseOrRoll',
	stands: 'Plate',
};

class AccessoriesEditTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				name: modalNames.none,
				data: null,
			},
		};
		this.equipmentEditMethods = new EquipmentEditMethods(
			this.props.onChange,
			() => cloneDeep(this.props.equipment),
			() => cloneDeep(this.props.equipmentDefault)
		);
	}

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	openCloseNotesModal = note => {
		this.openCloseModal({
			name: modalNames.gatewayNote,
			data: {
				note,
				handleChange: note => this.equipmentEditMethods.handleChange({ target: { name: 'notes', value: note } }),
			},
		});
	};

	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	};

	setPaperQuantity = event => {
		const { equipment } = this.props;
		const equipmentOptionProperty = split(event.target.name, '_')[1];
		set(equipment, `equipmentOptions.${equipmentOptionProperty}`, event.target.value);
		set(equipment, `quantity`, event.target.value);
		equipment.isSelected = true;
		this.props.onChange(equipment);
	};

	renderPaperQuantity = () => {
		const { equipment, equipmentDefault } = this.props;
		const { equipmentOptions } = equipmentDefault;
		const selectedQuantityOption = find(
			equipmentOptions,
			q =>
				toLower(q.friendlyName) === 'quantity' &&
				toLower(q.dependentOnValue) === toLower(equipment.equipmentOptions.CaseOrRoll)
		);
		const value = get(equipment, `equipmentOptions.${selectedQuantityOption.name}`);

		return (
			<select
				id={this.props.equipmentDefault.name + '_equipmentOptions__' + selectedQuantityOption.name}
				name={`equipmentOptions_${selectedQuantityOption.name}`}
				className="input input--med input--select"
				onChange={this.setPaperQuantity}
				value={value || ''}
			>
				<option value="">Please Select...</option>
				{map(selectedQuantityOption.options, o => {
					if (!o.value || o.value === '0') return;
					return (
						<option key={o.value} value={o.value}>
							{o.friendlyName || o.value}
						</option>
					);
				})}
			</select>
		);
	};

	defaultEquipmentOptionAndUpdate = e => {
		const { equipment } = this.props;
		set(equipment, 'equipmentOptions', {});
		this.equipmentEditMethods.handleChange(e, equipment);
	};

	renderVisibleEquipmentOption = (equipmentOptions, optionName) => {
		return (
			<Fragment>
				<div className="equipment__list__th">
					{equipmentOptions[optionName].friendlyName}
					<span className="type--color--warning">*</span>
				</div>
				<div className="equipment__list__td">
					{renderEquipmentOption(
						optionName,
						equipmentOptions,
						this.props.equipment,
						this.defaultEquipmentOptionAndUpdate,
						true,
						this.props.equipmentDefault.name + '_equipmentOptions__' + optionName
					)}
				</div>
			</Fragment>
		);
	};

	render() {
		const { equipment, equipmentDefault } = this.props;
		const { fees, quantity, shippingSpeed, shippingOption, paymentSchedule, saturdayDelivery } = equipment;

		const { subcategory, shippingSpeeds, shippingOptions, paymentSchedules } = equipmentDefault;

		const loweredSubcategory = toLower(subcategory);
		const visibleEquipmentOptionName = visibleEquipmentOptionsBySubcategory[loweredSubcategory];

		let fee = find(fees, { feeType: 'OneTimeFee' });
		const isPaper = toLower(subcategory) === 'paper';
		const isPickup = toLower(shippingOption) === 'pickup';
		const minFee = get(fee, 'minFee');
		const maxFee = get(fee, 'maxFee');
		const isMerchantPriceDisabled = minFee > 0 && maxFee > 0 && minFee === maxFee;
		const isBillAgentPaymentSchedule = toLower(paymentSchedule) === 'billagent';
		let tooltip = isBillAgentPaymentSchedule
			? `Merchant Cost is not editable when Payment Schedule is Bill Agent.`
			: null;
		return (
			<div className="equipment__list is-scrollable">
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				<div className="equipment__list__item retail-price">
					<div className="equipment__list__th">Agent Cost</div>
					<div className="equipment__list__td type--wgt--bold type--base">{formatCurrency(fee.agentCost)}</div>
				</div>
				<div className="equipment__list__item merchant-price">
					<div className="equipment__list__th">Merchant Cost</div>
					<div className="equipment__list__td">
						<div className="datatooltip--250 datatooltip--left" data-tooltip={tooltip}>
							<NumberFormat
								id={`fee_${fee.feeId}`}
								className="input input--med input--currency input--currency--sml type--right w--90p"
								value={isBillAgentPaymentSchedule ? 0 : fee.merchantPrice}
								name="merchantPrice"
								placeholder="0"
								disabled={isMerchantPriceDisabled || isBillAgentPaymentSchedule}
								thousandSeparator={true}
								decimalScale={2}
								tabIndex="99"
								fixedDecimalScale={false}
								onValueChange={values => {
									this.equipmentEditMethods.handleFeeChange(null, fee.feeId, values);
								}}
							/>
							{isMerchantPriceDisabled && !isBillAgentPaymentSchedule && (
								<i
									className="icon icon--tiny icon--info datatooltip--200 spc--left--tny flex-shrink--0"
									data-tooltip={`Merchant cost must equal ${minFee}`}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="equipment__list__item quantity">
					<div className="equipment__list__th">
						Quantity<span className="type--color--warning">*</span>
					</div>
					<div className="equipment__list__td">
						<div className="inputgroup accessories__list__item__quantity accessories__list__item__quantity__wider">
							{isPaper ? (
								get(equipment, 'equipmentOptions.CaseOrRoll') && this.renderPaperQuantity()
							) : (
								<Fragment>
									<div className="inputgroup--aside">
										<button
											type="button"
											className="btn btn--handler spc--left--1 datatooltip--150"
											name="decrease"
											onClick={this.equipmentEditMethods.adjustQuantity}
										>
											&#8211;
										</button>
									</div>
									<div className="inputgroup--main inputgroup--main--double">
										<input
											disabled
											className="input input--med type--center"
											type="number"
											name="quantity"
											tabIndex="99"
											onChange={event => this.equipmentEditMethods.adjustQuantity(event, true)}
											value={quantity}
										/>
									</div>
									<div className="inputgroup--aside inputgroup--aside--double">
										<button
											type="button"
											className="btn btn--handler spc--right--1 datatooltip--150"
											name="increase"
											onClick={this.equipmentEditMethods.adjustQuantity}
										>
											+
										</button>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</div>
				<div className="equipment__list__item total-price">
					<div className="equipment__list__th">Total price</div>
					<div className="equipment__list__td">
						<div className="type--wgt--bold type--base">
							{formatCurrency(quantity * (isBillAgentPaymentSchedule ? fee.agentCost : fee.merchantPrice))}
						</div>
					</div>
				</div>

				{visibleEquipmentOptionName && (
					<div className="equipment__list__item basis-140">
						{this.renderVisibleEquipmentOption(equipmentDefault.equipmentOptions, visibleEquipmentOptionName)}
					</div>
				)}

				<div className="equipment__list__item basis-140">
					<div className="equipment__list__th">
						Schedule<span className="type--color--warning">*</span>
					</div>
					<div className="equipment__list__td">
						<select
							className="input input--med input--select"
							name="paymentSchedule"
							id={`${equipmentDefault.name}_paymentSchedule`}
							tabIndex="99"
							onChange={this.equipmentEditMethods.handleChange}
							value={paymentSchedule}
						>
							<option value="">Please select...</option>
							{map(paymentSchedules, (opt, key) => (
								<option key={key} value={key}>
									{opt}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="equipment__list__item basis-140">
					<div className="equipment__list__th">
						Shipping<span className="type--color--warning">*</span>
					</div>
					<div className="equipment__list__td">
						<select
							className="input input--med input--select"
							name="shippingOption"
							id={`${equipmentDefault.name}_shippingOption`}
							tabIndex="99"
							onChange={this.equipmentEditMethods.handleChange}
							value={shippingOption}
						>
							<option value="">Please select...</option>
							{map(shippingOptions, (opt, key) => {
								if (toLower(opt) === 'other') return null;
								return (
									<option key={`${equipmentDefault.name}_shippingOption_${key}`} value={key}>
										{' '}
										{opt}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="equipment__list__item basis-140">
					<div className="equipment__list__th">
						Shipping speed{!isPickup && <span className="type--color--warning">*</span>}
					</div>
					<div className="equipment__list__td">
						<select
							className="input input--med input--select"
							name="shippingSpeed"
							id={`${equipmentDefault.name}_shippingSpeed`}
							tabIndex="99"
							onChange={this.equipmentEditMethods.handleChange}
							value={shippingSpeed}
							disabled={isPickup}
						>
							<option value="">Please select...</option>
							{map(shippingSpeeds, (opt, key) => {
								return (
									<option key={`${equipmentDefault.name}_shippingSpeeds_${key}`} value={key}>
										{opt}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="equipment__list__item basis-140">
					<div className="equipment__list__th">Delivery</div>
					<div className="equipment__list__td">
						<input
							className="input input--check"
							type="checkbox"
							id={`${equipmentDefault.name}_saturdayDelivery`}
							name="saturdayDelivery"
							checked={saturdayDelivery}
							onChange={this.equipmentEditMethods.handleChange}
							disabled={isPickup}
						/>
						<label htmlFor={`${equipmentDefault.name}_saturdayDelivery`} className="label">
							Saturday Delivery
						</label>
					</div>
				</div>
				<div className="equipment__list__item note-column">
					<div className="equipment__list__th">
						<div className="display--ib spc--right--tny">Note</div>
					</div>
					<div className="equipment__list__td">
						<div
							tabIndex="99"
							data-tooltip="Add Note"
							className="gateway__note__wrap datatooltip--nowrap"
							onClick={() => this.openCloseNotesModal(get(equipment, 'notes'))}
							onKeyDown={e => this.onKeyDownHandler(e, () => this.openCloseNotesModal(get(equipment, 'notes')))}
						>
							<i className="icon icon--sml icon--addnote"></i>
							<div className={get(equipment, 'notes') ? 'gateway--has-note' : ''}></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AccessoriesEditTemplate.propTypes = {
	equipmentDefault: PropTypes.object.isRequired,
	equipment: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default AccessoriesEditTemplate;
