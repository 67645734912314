import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderIf } from '../../utilities';

function withExpander(WrappedComponent, useIcon, showMoreText, showLessText) {
	return class ExpanderComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				isExpanded: this.props.isExpanded || false,
			};
		}

		showMore = (isExpanded = false) => {
			//if (!this.state.isExpanded) console.log('expanding');
			//if (this.state.isExpanded) console.log('collapsing');
			this.setState({
				isExpanded: isExpanded || !this.state.isExpanded,
			});
		};

		showMoreForPrint = e => {
			if (!this.state.isExpanded) {
				//console.log('expanding');
				return new Promise(resolve => {
					this.setState({ isExpanded: !this.state.isExpanded, expandedForPrint: true }, () => resolve());
				});
			} else return Promise.resolve(100);
		};

		collapseAfterPrint = e => {
			if (this.state.expandedForPrint) {
				//console.log('collapsing');
				this.showMore();
				this.setState({
					expandedForPrint: false,
				});
			}
		};

		render() {
			const isExpanded = this.state.isExpanded;
			let moreText = this.props.showMoreText || 'Show More';
			let lessText = this.props.showLessText || 'Show Less';
			return (
				<React.Fragment>
					<div className="pos--rel">
						<WrappedComponent
							{...this.props}
							isExpanded={isExpanded}
							showMore={this.showMore}
							showMoreForPrint={this.showMoreForPrint}
							collapseAfterPrint={this.collapseAfterPrint}
						/>
						<div className="card--primary__expand">
							{useIcon ? (
								<i
									className={
										(isExpanded ? 'is-active' : '') + ' icon icon--arrow icon--xsml icon--arrow--down--primary'
									}
									onClick={() => this.showMore()}
								/>
							) : (
								<a href="javascript:void(0);" className="anchor" onClick={() => this.showMore()}>
									{isExpanded ? lessText : moreText}
								</a>
							)}
						</div>
					</div>
				</React.Fragment>
			);
		}
	};
}

export default withExpander;
