import React from 'react';
import { string, bool } from 'prop-types';

import { renderIf } from './../../common/utilities';
import { DisplayBooleanComponent } from '../../common/formatters';

class PricingBreakdown extends React.Component {
	render() {
		const { pricing, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <h6 className="spc--bottom--med">Current Pricing Breakdown</h6>}
					<div className="details__content">
						<div className="row grid-align-middle">
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblRateStructure">
										Rate Structure:{' '}
									</label>
									<label className="input input--med input--fake" name="lblRateStructure" id="lblRateStructure">
										{pricing.rateStructure}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblInterchange">
										Interchange +{' '}
									</label>
									<label className="input input--med input--fake" name="lblInterchange" id="lblInterchange">
										{pricing.interchange}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblDebitRate">
										Debit Rate
									</label>
									<label className="input input--med input--fake" name="lblDebitRate" id="lblDebitRate">
										{pricing.debitRate}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblDebitTransactionFee">
										Debit Transaction Fee{' '}
									</label>
									<label
										className="input input--med input--fake"
										name="lblDebitTransactionFee"
										id="lblDebitTransactionFee"
									>
										{pricing.debitTransactionFee}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblBankcardTransactionFee">
										BankCard Transaction Fee{' '}
									</label>
									<label
										className="input input--med input--fake"
										name="lblBankcardTransactionFee"
										id="lblBankcardTransactionFee"
									>
										{pricing.bankcardTransactionFee}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblAmexTransactionFee">
										Amex Transaction Fee{' '}
									</label>
									<label
										className="input input--med input--fake"
										name="lblAmexTransactionFee"
										id="lblAmexTransactionFee"
									>
										{pricing.amexTransactionFee}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblAmexRateStructure">
										Amex Rate Structure{' '}
									</label>
									<label className="input input--med input--fake" name="lblAmexRateStructure" id="lblAmexRateStructure">
										{pricing.amexRateStructure}
									</label>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="spc--bottom--med form__field">
									<label className="label" htmlFor="lblAmexRate">
										Amex Rate{' '}
									</label>
									<label className="input input--med input--fake" name="lblAmexRate" id="lblAmexRate">
										{pricing.amexRate}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

PricingBreakdown.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

PricingBreakdown.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default PricingBreakdown;
