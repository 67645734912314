import React from 'react';
import principalService from '../../services/principalService';
import { appService } from '../../services';
import { isEmpty } from 'lodash';

let agentName = '';
let email = '';

let agent = principalService.get();
if (agent) {
	agentName = agent.name;
	email = agent.email;
}
/*
principalService.subscribe(updateDba);
Hub.listen('auth', updateEmail);

updateDba(principalService.get());
Auth.currentAuthenticatedUser()
	.then(data => {
		if (data) {
			updateEmail({ payload: { event: 'signIn', data } });
		}
	})
	.catch(() => {});

function updateDba(principal) {
	if (principal) {
		const activeKey = find(principal.list, ({ key }) => key === principal.id);
		dba = activeKey && activeKey.dba;
	} else {
		dba = '';
	}
}

function updateEmail({ payload: { event, data } }) {
	if (event === 'signIn') {
		email = (data && data.attributes && data.attributes.email) || '';
	} else if (event === 'signOut') {
		email = '';
	}
}*/

function getSource(error) {
	if (!error) {
		return;
	}
	let requestInfo;
	let responseInfo;
	if (error.request) {
		const { url, method } = error.request;
		requestInfo = {
			url,
			method,
		};
	}
	if (error.response) {
		const { status, statusText } = error.response;
		responseInfo = `${statusText} (${status})`;
	}
	if (error.isCanceled) {
		return {
			message: 'Canceled task not properly disposed of',
			requestInfo,
			responseInfo,
		};
	}
	if (error.message || error.stack) {
		const { message, stack, displayMessage } = error;
		return {
			message,
			stack,
			requestInfo,
			responseInfo,
			displayMessage,
		};
	}
	if (error.ex || error.error || error.reason) {
		const parsed = parseError(error.ex) || parseError(error.error) || parseError(error.reason);
		if (parsed) {
			const { error, message, displayMessage } = parsed;
			requestInfo = parsed.requestInfo || requestInfo;
			responseInfo = parsed.responseInfo || responseInfo;
			return {
				error,
				message,
				requestInfo,
				responseInfo,
				displayMessage,
			};
		}
	}
	return;
}

export function parseError(error) {
	return getSource(error) || {};
}

function getSubject() {
	return `Portal error${email || agentName ? ` from ${email ? email : ''}${agentName ? ` (${agentName})` : ''}` : ''}`;
}

function getErrorMessage(stack, additionalInfo = {}) {
	const wrapInSlackCodeBlock = error => '```' + error + '```';
	if (!isEmpty(additionalInfo)) {
		const parsedInfo = `Additional Info: ${wrapInSlackCodeBlock(JSON.stringify(additionalInfo))}`;
		return `${parsedInfo}\nStack: ${wrapInSlackCodeBlock(stack)}`;
	}
	return `Stack: ${wrapInSlackCodeBlock(stack)}`;
}

export function logClientError(stack, additionalInfo = {}) {
	appService.logClientError(getSubject(), getErrorMessage(stack, additionalInfo));
	return <span>Something went wrong. Please try again.</span>;
}
