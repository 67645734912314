import React, { createRef, Component } from 'react';
import { get, noop } from 'lodash';

import { appService } from '../../services';
import { defaultInvalidDates } from '../../common/utilities';
import { CanadaAgentMPA } from './CanadaMPA';
import { USAgentMPA } from './USMPA';
import { withLoader } from '../../common/components';
import { NavbarComponent } from './nav-bar';

export class MpaLoader extends Component {
	state = {
		mpa: null,
		errorMessage: '',
	};
	sidebarRef = createRef();
	topRef = createRef();

	get componentProps() {
		return {
			mpa: this.state.mpa,
			loadMPAFromService: this.loadMPAFromService,
			loadAppStatus: this.loadAppStatus,
			sidebarRef: this.sidebarRef,
			topRef: this.topRef,
		};
	}

	componentDidMount() {
		this.loadMPAFromService(false, true);
	}

	loadMPAFromService = (isSubmit = false, shouldLoadAppStatus = false) => {
		this.props.showLoader(true);
		return appService
			.getEApp(this.props.match.params.appid)
			.then(mpa => {
				const newState = { mpa: defaultInvalidDates(mpa, '') };
				if (isSubmit || shouldLoadAppStatus) {
					return this.loadAppStatus(() => this.setState(newState, this.props.showLoader));
				} else {
					this.setState(newState, this.props.showLoader);
				}
			})
			.catch(err => {
				this.props.showLoader(false);
				this.handleError(err);
			});
	};

	loadAppStatus = (resolve = noop) => {
		return get(this.sidebarRef, 'current.loadAppStatus', resolve)(resolve);
	};

	handleError = err => {
		console.log('save error', err);
		this.props.showLoader(false);
		this.setState({ errorMessage: 'An error occurred: ' + err });
	};

	getComponent = isCanadian => (isCanadian ? CanadaAgentMPA : USAgentMPA);

	renderMpa() {
		const { mpa, errorMessage } = this.state;
		if (errorMessage) return <div className="note note--warning">{errorMessage}</div>;
		if (!mpa) return null;
		const MpaComponent = this.getComponent(mpa.isCanadian);
		return <MpaComponent {...this.componentProps} />;
	}

	renderHeader() {
		return (
			<NavbarComponent
				ref={this.sidebarRef}
				appId={this.props.match.params.appid}
				location={this.props.location}
				loadOnMount={false}
			/>
		);
	}

	render() {
		return (
			<div id="main-div" className="l--content l--content--lrg" ref={this.topRef}>
				{this.renderHeader()}
				{this.renderMpa()}
			</div>
		);
	}
}

export const WrappedMpaLoader = withLoader(MpaLoader);
