import { filter, some, cloneDeep, each, sumBy, isEmpty, round, has, get } from 'lodash';
import { appService } from '../../../services/';
import { getFilterValues } from '../../../common/utilities';
import { compileFilter as compileTicketFilter } from '../../../components/TicketGrid/filter/ticketsFilter';

class ExportService {
	getCollectionItem(collection, key) {
		const item = filter(collection, { key: key })[0];
		return item;
	}

	compileLeadFilter(filters) {
		const dbaFilter = this.getCollectionItem(filters, 'dba');
		const leadStatusFilter = this.getCollectionItem(filters, 'leadStatus');
		const reviewStatusFilter = this.getCollectionItem(filters, 'reviewStatus');
		const firstNameFilter = this.getCollectionItem(filters, 'firstName');
		const lastNameFilter = this.getCollectionItem(filters, 'lastName');
		const emailFilter = this.getCollectionItem(filters, 'email');
		const titleFilter = this.getCollectionItem(filters, 'title');
		const phoneNumberFilter = this.getCollectionItem(filters, 'phoneNumber');
		const labelFilter = this.getCollectionItem(filters, 'label');
		const processorFilter = this.getCollectionItem(filters, 'processor');
		const businessCountryFilter = this.getCollectionItem(filters, 'businessCountry');

		const filter = {};

		if (dbaFilter.values.dba != null) {
			filter.DBA = dbaFilter.values.dba;
		}

		if (some(leadStatusFilter.values, p => p)) {
			filter.StatusList = this.parseListFilter(leadStatusFilter.values);
		}

		if (some(reviewStatusFilter.values, p => p)) {
			filter.ReviewStatusList = this.parseListFilter(reviewStatusFilter.values);
		}

		if (firstNameFilter.values.firstName != null) {
			filter.FirstName = firstNameFilter.values.firstName;
		}

		if (lastNameFilter.values.lastName != null) {
			filter.LastName = lastNameFilter.values.lastName;
		}

		if (emailFilter.values.email != null) {
			filter.Email = emailFilter.values.email;
		}

		if (titleFilter.values.title != null) {
			filter.Title = titleFilter.values.title;
		}

		if (phoneNumberFilter.values.phoneNumber != null) {
			filter.PhoneNumber = phoneNumberFilter.values.phoneNumber;
		}

		if (processorFilter.values.processor != null) {
			filter.Processor = processorFilter.values.processor;
		}

		if (labelFilter.values.label != null) {
			filter.LabelFilter = labelFilter.values.label;
		}

		if (businessCountryFilter.values.businessCountry != null) {
			filter.BusinessCountry = businessCountryFilter.values.businessCountry;
		}
		return filter;
	}

	compileMerchantFilter(filters) {
		const midFilter = this.getCollectionItem(filters, 'mid');
		const dbaFilter = this.getCollectionItem(filters, 'dba');
		const statusFilter = this.getCollectionItem(filters, 'merchantStatus');
		const businessCountryFilter = this.getCollectionItem(filters, 'businessCountry');
		const ownerNameFilter = this.getCollectionItem(filters, 'ownerName');
		const businessContactNameFilter = this.getCollectionItem(filters, 'businessContactName');
		const businessEmailFilter = this.getCollectionItem(filters, 'businessEmail');
		const addressFilter = this.getCollectionItem(filters, 'address');
		const cityFilter = this.getCollectionItem(filters, 'city');
		const stateFilter = this.getCollectionItem(filters, 'state');
		const zipFilter = this.getCollectionItem(filters, 'zip');
		const phoneNumberFilter = this.getCollectionItem(filters, 'phoneNumber');
		const pciFilter = this.getCollectionItem(filters, 'isPciCompliant');
		const processorFilter = this.getCollectionItem(filters, 'processorName');

		const filter = {};

		if (midFilter.values.mid != null) {
			filter.MID = midFilter.values.mid;
		}

		if (dbaFilter.values.dba != null) {
			filter.DBA = dbaFilter.values.dba;
		}

		if (some(statusFilter.values, p => p)) {
			filter.StatusList = this.parseListFilter(statusFilter.values);
		}

		if (businessCountryFilter.values.businessCountry != null) {
			filter.BusinessCountry = businessCountryFilter.values.businessCountry;
		}

		if (ownerNameFilter.values.ownerName != null) {
			filter.OwnerName = ownerNameFilter.values.ownerName;
		}

		if (businessContactNameFilter.values.businessContactName != null) {
			filter.BusinessContactName = businessContactNameFilter.values.businessContactName;
		}

		if (businessEmailFilter.values.businessEmail != null) {
			filter.BusinessEmail = businessEmailFilter.values.businessEmail;
		}

		if (addressFilter.values.address != null) {
			filter.Address = addressFilter.values.address;
		}

		if (cityFilter.values.city != null) {
			filter.City = cityFilter.values.city;
		}

		if (stateFilter.values.state != null) {
			filter.State = stateFilter.values.state;
		}

		if (zipFilter.values.zip != null) {
			filter.Zip = zipFilter.values.zip;
		}

		if (phoneNumberFilter.values.phoneNumber != null) {
			filter.PhoneNumber = phoneNumberFilter.values.phoneNumber;
		}

		if (processorFilter.values.processorName != null) {
			filter.ProcessorName = processorFilter.values.processorName;
		}

		if (pciFilter.values.pciFilter != null) {
			filter.PCIFilter = pciFilter.values.pciFilter;
		}

		return filter;
	}

	parseListFilter(values) {
		const listFilterArr = [];

		each(values, (value, stat) => {
			if (value) {
				listFilterArr.push(stat);
			}
		});

		return listFilterArr.join(',');
	}

	async getTicketData(activeFilters) {
		const filters = cloneDeep(activeFilters);
		const newFilter = compileTicketFilter(filters);

		let data = await appService.getTickets(newFilter);
		if (data.tickets) {
			data = data.tickets;
			this.mapTicketData(data);
			return data;
		} else {
			return [];
		}
	}

	mapTicketData(data) {
		return;
	}

	compileEquipmentFilter(filters) {
		const { appId } = getFilterValues(filters);
		const filter = {};
		filter.appId = (appId && appId.appId) || 0;
		return filter;
	}

	async getEquipmentData(activeFilters) {
		const filters = cloneDeep(activeFilters);
		const newFilter = this.compileEquipmentFilter(filters);

		let data = await appService.getMerchantEquipment(newFilter.appId);
		if (data.equipmentList) {
			data = data.equipmentList;
			this.mapEquipmentData(data);
			return data;
		} else {
			return [];
		}
	}

	mapEquipmentData(data) {
		return;
	}

	async getLeadData(activeFilters) {
		const filters = cloneDeep(activeFilters);
		const newFilter = this.compileLeadFilter(filters);

		let data = await appService.getLeads(newFilter);
		if (data.leads) {
			data = data.leads;
			this.mapLeadData(data);
			return data;
		} else {
			return [];
		}
	}

	mapLeadData(data) {
		return;
	}

	async getMerchantData(activeFilters) {
		const filters = cloneDeep(activeFilters);
		const newFilter = this.compileMerchantFilter(filters);
		/*newFilter.xCommand = 'Report:Customers';
        let data = await customerService.filterCustomersRequest(newFilter);
        if (data.xReportData) {
            data = data.xReportData;
            this.mapCustomerData(data);
            return data;
        } else {
            return [];
        }*/
		let data = await appService.getMerchants(newFilter);
		if (data.merchants) {
			data = data.merchants;
			this.mapMerchantData(data);
			return data;
		} else {
			return [];
		}
	}

	mapMerchantData(data) {
		return;
	}

	mapResidualsData(data) {
		if (isEmpty(data)) return;
		const grandTotal = {
			type: 'Grand Total',
			agent_share: round(
				sumBy(data, ({ agent_share }) => round(agent_share, 2)),
				2
			),
		};
		data.push({});
		data.push(grandTotal);

		const addTabCharToField = (data, fieldName) => {
			if (some(data, item => has(item, fieldName))) {
				each(data, item => (item[fieldName] = `${get(item, fieldName, '')}\t`));
			}
		};
		addTabCharToField(data, 'MID');
		addTabCharToField(data, 'mid');
	}
}

const exportService = new ExportService();

export { exportService };
