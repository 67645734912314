import React, { Component } from 'react';
import { map } from 'lodash';

class TicketStatusFilterComponent extends Component {
	constructor(props) {
		super(props);
	}

	filterChanged = event => {
		let props = this.props;

		let values = props.filter.values;

		const key = event.target.name;
		const checked = event.target.checked;

		let valueArr = map(values, (v, k) => {
			return { key: k, value: v };
		});

		const objIndex = valueArr.findIndex(o => o.key == key);

		valueArr[objIndex].value = checked;

		this.props.onFilterChanged({
			id: 'status',
			values: valueArr,
			emptyValue: false,
		});
	};

	render() {
		const { filter } = this.props;
		let values = this.props.filter.values;

		return (
			<div>
				{map(values, (v, k) => {
					return (
						<div key={k} className="spc--bottom--sml">
							<input
								type="checkbox"
								name={k}
								className="input input--check"
								checked={v}
								value={k}
								id={k}
								onChange={this.filterChanged}
							/>
							<label htmlFor={k}>{k}</label>
						</div>
					);
				})}
			</div>
		);
	}
}

export default TicketStatusFilterComponent;
