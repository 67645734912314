import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DisplayDateComponent extends React.Component {
	render() {
		const inputFormat =
			(this.props.dependentValues && this.props.dependentValues.inputFormat) ||
			process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT;
		const outputFormat =
			(this.props.dependentValues && this.props.dependentValues.outputFormat) ||
			process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT;
		if (this.props.value) {
			const date = moment(this.props.value, inputFormat);
			if (date.year() === 1) return 'N/A';
			return date.format(outputFormat);
		}
		return null;
	}
}

DisplayDateComponent.propTypes = {
	value: PropTypes.string.isRequired,
	dependentValues: PropTypes.object,
};

export default DisplayDateComponent;
