import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { exportTypes } from './export-options';
import { renderIf, OutsideClick } from './../../utilities';

class ExportDropdownComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onExportSelect = (type, event) => {
		this.props.selectedExport({ type, all: this.props.all });
		// this.onClose(event);
	};

	render() {
		return (
			<div>
				<ul className="buttondropdown__list">
					<React.Fragment>
						{exportTypes.map(t => (
							<li key={t.key} className="buttondropdown__item" onClick={e => this.onExportSelect(t, e)}>
								{this.props.title} ({t.name})
							</li>
						))}
					</React.Fragment>
					<React.Fragment>{this.props.children != null ? <li>{this.props.children}</li> : null}</React.Fragment>
				</ul>
			</div>
		);
	}
}

ExportDropdownComponent.propTypes = {
	title: PropTypes.string,
	all: PropTypes.bool,
	selectedExport: PropTypes.func.isRequired,
};

ExportDropdownComponent.defaultProps = {
	title: 'Export',
	all: false,
};

export default ExportDropdownComponent;
