import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, find, toLower, get, isEmpty, keys, each, map, findIndex, filter, noop } from 'lodash';
import { EquipmentEditMethods } from '../../utilities/equipment-edit-methods';
import FrontendProcessorComponent from '../../../Equipment/frontend-processor';
import EquipmentOption from '../../../Equipment/equipment-option';
import HardwarePurchasePlanComponent from '../../../Equipment/hardware-purchase-plan';
import { Toggle, ToggleContainer } from '../../../../common/components/toggle';
import { Modal } from '../../../../common/components/modal';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { getThumbnailOverlay } from '../../../../common/utilities';

const isExpand = true;
const required = (
	<span data-tooltip="Required" className="type--color--primary">
		{' '}
		*
	</span>
);
class HardwareEditTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAccessoriesPopupOpen: false,
			accessories: map(cloneDeep(get(this.props.equipmentDefault, 'accessories', [])), accessory => {
				let existingAccessory = find(this.props.equipment.accessories, { equipmentId: accessory.equipmentId });
				if (existingAccessory) {
					return existingAccessory;
				}
				accessory.equipmentOptions = {};
				if (toLower(accessory.subcategory) === 'paper') {
					each(accessory.equipmentOptions, option => {
						if (!!option.defaultValue && !option.dependentOnName) {
							accessory.equipmentOptions[option.name] = option.defaultValue;
						}
					});
				}
				let fees = cloneDeep(filter(get(accessory.purchasePlans[0], 'fees', []), { feeType: 'OneTimeFee' }));
				each(fees, fee => (fee.merchantPrice = fee.retailPrice));
				return { ...accessory, fees, quantity: 0, isSelected: true };
			}),
		};
		this.equipmentEditMethods = new EquipmentEditMethods(
			this.props.onChange,
			() => cloneDeep(this.props.equipment),
			() => cloneDeep(this.props.equipmentDefault)
		);
	}
	openAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: true });

	closeAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: false });

	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	};

	handleAccessoriesEquipmentOptionChange = isQuantity => e => {
		const {
			target: { value, name },
		} = e;
		const accessories = [...this.state.accessories];
		const [index, key, optionKey] = name.split('_');

		if (isQuantity) {
			accessories[index][key] = parseInt(value || 0);
		} else {
			accessories[index].quantity = 0;
			accessories[index][key] = {
				...accessories[index][key],
				[optionKey]: value,
			};
		}
		this.setState({ accessories }, this.updateEquipmentAccessories);
	};

	adjustAccessoriesQuantity = equipmentId => ({ target: { name } }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		newState.accessories[accIndex] = {
			...accessories[accIndex],
			quantity: name === 'increase' ? accessories[accIndex].quantity + 1 : accessories[accIndex].quantity - 1,
		};

		if (newState.accessories[accIndex].quantity < 0) {
			return;
		}
		this.setState(newState, this.updateEquipmentAccessories);
	};

	updateEquipmentAccessories = () => {
		const { accessories } = this.state;
		this.equipmentEditMethods.updateAccessories(accessories.filter(accessory => accessory.quantity > 0));
	};

	toggleAccessory = equipmentId => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const acc = find(newState.accessories, { equipmentId });
		acc.quantity = 0;
		this.setState(newState, this.updateEquipmentAccessories);
	};

	handleAccessoriesPriceChange = equipmentId => ({ floatValue }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });
		const feeIndex = findIndex(accessories[accIndex].fees);
		if (feeIndex === -1) {
			return;
		}
		newState.accessories[accIndex].fees[feeIndex] = {
			...newState.accessories[accIndex].fees[feeIndex],
			merchantPrice: floatValue,
		};
		this.setState(newState, this.updateEquipmentAccessories);
	};

	renderDependentOptions = (mainOptionName, equipmentDefault, equipment, index, errorListPaths) => {
		return (
			<Fragment>
				{Object.values(equipmentDefault.equipmentOptions)
					.filter(o => mainOptionName == o.dependentOnName && o.dataType !== 'file')
					.map(dependentOpt => {
						let disableOption =
							!equipment.equipmentOptions[mainOptionName] ||
							equipment.equipmentOptions[mainOptionName] != dependentOpt.dependentOnValue;
						if (disableOption) return null;
						return (
							<Fragment key={`${mainOptionName}_dependent_${index}_${dependentOpt.name}`}>
								<div>
									<EquipmentOption
										merchantEquipment={equipment}
										equipment={equipmentDefault}
										option={dependentOpt.name}
										index={index}
										errorListPaths={[]}
										listType="radio"
										checkboxHandler={this.equipmentEditMethods.optionSelect}
										moreInfoHandler={this.equipmentEditMethods.optionSetMoreInfo}
										inputHandler={this.equipmentEditMethods.handleChange}
										disableOption={disableOption}
									/>
								</div>
								{this.renderDependentOptions(dependentOpt.name, equipmentDefault, equipment, index, errorListPaths)}
							</Fragment>
						);
					})}
			</Fragment>
		);
	};

	renderEquipmentOption = (accessoryName, equipmentId, equipmentOptions, isHardware = false) => {
		const { accessories } = this.state;
		let loweredAccessoryName = toLower(accessoryName);
		const stateAccessory = isHardware ? this.props.equipment : find(accessories, a => a.equipmentId == equipmentId);
		const isQuantity = loweredAccessoryName.indexOf('quantity') > -1;
		if (isQuantity) {
			const relevantQuantityOption = find(
				equipmentOptions,
				e =>
					toLower(e.friendlyName) === loweredAccessoryName &&
					e.dependentOnValue === get(stateAccessory, 'equipmentOptions.CaseOrRoll')
			);
			if (!relevantQuantityOption) {
				return;
			}
			loweredAccessoryName = toLower(relevantQuantityOption.name);
		}
		const option = find(equipmentOptions, eo => toLower(eo.name) === loweredAccessoryName);
		const accessoryIndex = findIndex(accessories, a => a.equipmentId == equipmentId);
		const value = get(stateAccessory, isQuantity ? 'quantity' : `equipmentOptions.${accessoryName}`, '');
		if (!option) return;
		const { name, isRequired, friendlyName, options } = option;
		let id = `${accessoryIndex}${isQuantity ? '' : '_equipmentOptions'}_${isQuantity ? 'quantity' : name}`;
		if (isQuantity && isHardware) {
			id = 'quantity';
		}
		return (
			<div className="fullwidth spc--bottom--sml">
				<label htmlFor={id} className="label">
					{friendlyName || name} {isRequired && required}
				</label>
				<select
					id={id}
					name={id}
					value={value}
					className="input input--med input--select"
					onChange={this.handleAccessoriesEquipmentOptionChange(isQuantity)}
				>
					{' '}
					{map(options, o => (
						<option key={o.value} value={o.value}>
							{o.friendlyName || o.value}
						</option>
					))}
				</select>
			</div>
		);
	};

	renderAccessoriesPopup = () => {
		const {
			equipmentDefault,
			equipment: { paymentSchedule },
		} = this.props;
		const { isAccessoriesPopupOpen, accessories } = this.state;
		const defaultAccessories = get(equipmentDefault, 'accessories', []);
		const isBillAgentPaymentSchedule = toLower(paymentSchedule) === 'billagent';
		const tooltip = isBillAgentPaymentSchedule ? 'Price is not editable when Payment Schedule is Bill Agent.' : null;
		return (
			<Modal shouldCloseOnOverlayClick={false} isOpen={isAccessoriesPopupOpen} onClose={this.closeAccessoriesPopup}>
				<div>
					<div className="popup__header">
						<h6>{equipmentDefault.name} Add-ons</h6>
					</div>
					<div className="popup__body addon--popup__body">
						<div className="addon--popup__wrap">
							{map(accessories, ({ name, fees, quantity, equipmentId, description, subcategory }) => {
								let equipmentOptions = find(defaultAccessories, { equipmentId }).equipmentOptions;
								quantity = quantity || 0;
								let fee = find(fees, { feeType: 'OneTimeFee' });
								let price = get(fee, 'merchantPrice');
								const isPaper = toLower(subcategory) === 'paper';

								return (
									<div className="addon--popup__wrap__item" key={`${equipmentId}.${name}`}>
										<div className="addon--popup">
											<div
												className={`spc--bottom--sml checkout__img${getThumbnailOverlay(false, 'oneTimeFee')}`}
												style={{
													backgroundImage: `url(${process.env.REACT_APP_CDN_URL}${toLower(name)
														.replace(' ', '_')
														.replace(/[^a-z0-9_-]/gi, '')}/thumbnail.png)`,
												}}
												alt={name}
											></div>
											<div className="addon--popup__wrap__title">
												{name}
												{description && (
													<span className="datatooltip--base">
														<i
															className="icon icon--tiny align--v--middle icon--info spc--left--sml"
															data-tooltip={description}
														/>
													</span>
												)}
											</div>
											<div
												className="datatooltip--250 datatooltip--bottom"
												data-tooltip={isBillAgentPaymentSchedule ? tooltip : null}
											>
												<NumberFormat
													value={isBillAgentPaymentSchedule ? fee.agentCost : price}
													decimalScale={2}
													thousandSeparator={true}
													fixedDecimalScale={true}
													disabled={isBillAgentPaymentSchedule}
													onValueChange={
														isBillAgentPaymentSchedule ? noop : this.handleAccessoriesPriceChange(equipmentId)
													}
													className="input input--med input--currency input--currency--sml w--200p type--right spc--bottom--sml"
												/>
											</div>
											<div className="w--200p">
												{isPaper && this.renderEquipmentOption('CaseOrRoll', equipmentId, equipmentOptions)}
											</div>
											<div className="inputgroup w--200p">
												{this.renderQuantityModule(
													isPaper,
													equipmentId,
													equipmentOptions,
													false,
													<Fragment>
														<div className="inputgroup--aside">
															<button
																type="button"
																name="decrease"
																className="btn btn--handler spc--left--1"
																onClick={this.adjustAccessoriesQuantity(equipmentId)}
															>
																&#8211;
															</button>
														</div>
														<div className="inputgroup--main inputgroup--main--double">
															<input
																min="1"
																disabled
																type="number"
																name="quantity"
																value={quantity}
																className="input input--med type--center"
															/>
														</div>
														<div className="inputgroup--aside inputgroup--aside--double">
															<button
																type="button"
																name="increase"
																className="btn btn--handler spc--right--1"
																onClick={this.adjustAccessoriesQuantity(equipmentId)}
															>
																+
															</button>
														</div>
													</Fragment>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="popup__footer popup__footer--styled">
						<button className="btn btn--ghost btn--med spc--left--tny" onClick={this.closeAccessoriesPopup}>
							Close
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderQuantityModule = (isPaper, equipmentId, equipmentOptions, isHardware, renderHardwareQuantity) =>
		isPaper
			? this.renderEquipmentOption('Quantity', equipmentId, equipmentOptions, isHardware)
			: renderHardwareQuantity;

	render() {
		const { equipment, equipmentDefault } = this.props;
		const { subcategory, shippingOption } = equipment;

		const isPaper = toLower(subcategory) === 'paper';
		const isPickup = toLower(shippingOption) === 'pickup';

		return (
			<React.Fragment>
				<div>{this.renderAccessoriesPopup()}</div>
				<div>
					<div className="spc--top--med separator separator--grey1 spc--bottom--med"></div>
					<div className="row">
						<div className="col col-sml-9 col-xlrg-9">
							<ToggleContainer>
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
										<React.Fragment>
											<div
												className={`title--tertiary__wrap cursor--pointer col col-sml-12 ${
													isToggled ? '' : 'spc--bottom--sml'
												}`}
												onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
												onClick={handleToggle}
											>
												<span className="title--tertiary">
													Purchase type<span className="type--color--warning">*</span>
												</span>
												<i
													className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
														isToggled ? 'rotate--180' : ''
													}`}
												></i>
											</div>
											<div className={isToggled ? 'form__content' : ''}>
												<div className={isToggled ? '' : 'display--n'}>
													{keys(equipmentDefault.purchaseTypes).map(pt => {
														if (toLower(pt) !== 'purchase') return null;
														return (
															<div key={pt} className="spc--right--med spc--bottom--sml">
																<input
																	className="input--radio input--radio--preview"
																	type="radio"
																	name={equipmentDefault.name + '_' + pt + '_pt'}
																	id={equipmentDefault.name + '_' + pt + '_pt'}
																	onChange={this.equipmentEditMethods.selectPurchaseType}
																	value={pt}
																	checked={equipment.purchaseType == pt}
																/>
																<label className="label" htmlFor={equipment.name + '_' + pt + '_pt'}>
																	{equipmentDefault.purchaseTypes[pt]}
																</label>
															</div>
														);
													})}
												</div>
											</div>
										</React.Fragment>
									)}
								</Toggle>
								{equipment.frontendProcessors && Object.keys(equipment.frontendProcessors).length > 0 && (
									<Toggle initialToggle={isExpand}>
										{({ isToggled, handleToggle }) => (
											<React.Fragment>
												<div
													className={`title--tertiary__wrap cursor--pointer col col-sml-12 ${
														isToggled ? '' : 'spc--bottom--sml'
													}`}
													onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
													onClick={handleToggle}
												>
													<span className="title--tertiary">Platform and Settlement</span>
													<i
														className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
															isToggled ? 'rotate--180' : ''
														}`}
													></i>
												</div>
												<div className={isToggled ? 'spc--bottom--med' : 'display--n'}>
													<FrontendProcessorComponent
														equipment={equipmentDefault}
														merchantEquipment={equipment}
														handleChange={this.equipmentEditMethods.handleChange}
														handleTimeChange={this.equipmentEditMethods.handleTimeChange}
														optionSelect={this.equipmentEditMethods.optionSelect}
														optionSetMoreInfo={this.equipmentEditMethods.optionSetMoreInfo}
													/>
												</div>
											</React.Fragment>
										)}
									</Toggle>
								)}

								{Object.values(equipmentDefault.equipmentOptions).filter(o => !o.dependentOnName).length > 0 ? (
									<Toggle initialToggle={isExpand}>
										{({ isToggled, handleToggle }) => (
											<React.Fragment>
												<div
													className={`title--tertiary__wrap cursor--pointer col col-sml-12 ${
														isToggled ? '' : 'spc--bottom--sml'
													}`}
													onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
													onClick={handleToggle}
												>
													<span className="title--tertiary">Features and Setup</span>
													<i
														className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
															isToggled ? 'rotate--180' : ''
														}`}
													></i>
												</div>
												<div className={isToggled ? 'form__content' : 'display--n'}>
													<div className="row">
														{Object.values(equipmentDefault.equipmentOptions)
															.filter(o => !o.dependentOnName && o.dataType !== 'file')
															.map((opt, idx) => {
																if (opt.name == 'BatchSettlementType') return null; // handled separately
																return (
																	<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml" key={opt.name}>
																		<EquipmentOption
																			merchantEquipment={equipment}
																			equipment={equipmentDefault}
																			option={opt.name}
																			index={idx}
																			listType="radio"
																			checkboxHandler={this.equipmentEditMethods.optionSelect}
																			moreInfoHandler={this.equipmentEditMethods.optionSetMoreInfo}
																			inputHandler={e => this.equipmentEditMethods.handleChange(e)}
																		/>
																		<div>
																			{this.renderDependentOptions(opt.name, equipmentDefault, equipment, idx, [])}
																		</div>
																	</div>
																);
															})}
													</div>
												</div>
											</React.Fragment>
										)}
									</Toggle>
								) : null}
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
										<React.Fragment>
											<div
												className={`title--tertiary__wrap cursor--pointer ${isToggled ? '' : 'spc--bottom--sml'}`}
												onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
												onClick={handleToggle}
											>
												<span className="title--tertiary">
													Shipping Details<span className="type--color--warning">*</span>
												</span>
												<i
													className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
														isToggled ? 'rotate--180' : ''
													}`}
												></i>
											</div>
											<div className={isToggled ? 'form__content' : 'display--n'}>
												<div
													className="col col-sml-12 col-lrg-6 spc--bottom--sml"
													id={`${equipmentDefault.name}_shippingOption`}
												>
													{Object.keys(equipmentDefault.shippingOptions).map((opt, idx) => {
														if (toLower(opt) === 'other') return null;
														return (
															<React.Fragment key={`${equipmentDefault.name}_shippingOptions_${idx}`}>
																<div className="spc--bottom--sml">
																	<input
																		className="input--radio"
																		type="radio"
																		value={opt}
																		checked={equipment.shippingOption === opt}
																		name={`${equipmentDefault.name}_shippingOption`}
																		id={`${equipmentDefault.name}_selectedShippingOption_${opt}`}
																		onChange={this.equipmentEditMethods.handleChange}
																	/>
																	<label
																		className="label"
																		htmlFor={`${equipmentDefault.name}_selectedShippingOption_${opt}`}
																	>
																		{equipmentDefault.shippingOptions[opt]}
																	</label>
																</div>
															</React.Fragment>
														);
													})}
												</div>
												<div className={`spc--bottom--sml ${isPickup ? '' : 'required'}`}>
													<label className="label">Shipping Speed</label>
												</div>
												<div className="row row-align-middle">
													<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
														<div className="form__field spc--bottom--med--on-sml">
															<select
																className="input input--med input--select"
																name="shippingSpeed"
																id={`${equipmentDefault.name}_shippingSpeed`}
																onChange={this.equipmentEditMethods.handleChange}
																value={equipment.shippingSpeed}
																disabled={isPickup}
															>
																<option value="">Please select...</option>
																{Object.keys(equipmentDefault.shippingSpeeds).map((opt, idx) => {
																	return (
																		<option key={`${equipmentDefault.name}_shippingSpeeds_${idx}`} value={opt}>
																			{equipmentDefault.shippingSpeeds[opt]}
																		</option>
																	);
																})}
															</select>
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
														<input
															className="input input--check"
															type="checkbox"
															id={`${equipmentDefault.name}_saturdayDelivery`}
															name="saturdayDelivery"
															checked={equipment.saturdayDelivery}
															onChange={this.equipmentEditMethods.handleChange}
															disabled={isPickup}
														/>
														<label htmlFor={`${equipmentDefault.name}_saturdayDelivery`} className="label">
															Saturday Delivery
														</label>
													</div>
												</div>
											</div>
										</React.Fragment>
									)}
								</Toggle>
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
										<React.Fragment>
											<div
												className={`title--tertiary__wrap cursor--pointer ${isToggled ? '' : 'spc--bottom--sml'}`}
												onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
												onClick={handleToggle}
											>
												<span className="title--tertiary">
													Payment Schedule<span className="type--color--warning">*</span>
												</span>
												<i
													className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
														isToggled ? 'rotate--180' : ''
													}`}
												></i>
											</div>
											<div className={isToggled ? 'form__content' : 'display--n'}>
												<select
													className="input input--med input--select spc--bottom--xsml"
													name="paymentSchedule"
													id={`${equipmentDefault.name}_paymentSchedule`}
													value={equipment.paymentSchedule}
													onChange={this.equipmentEditMethods.handleChange}
												>
													<option value="">Please select...</option>
													{Object.keys(equipmentDefault.paymentSchedules).map(opt => {
														return (
															<option key={opt} value={opt}>
																{equipmentDefault.paymentSchedules[opt]}
															</option>
														);
													})}
												</select>
											</div>
										</React.Fragment>
									)}
								</Toggle>
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
										<React.Fragment>
											<div
												className={`title--tertiary__wrap cursor--pointer ${isToggled ? '' : 'spc--bottom--sml'}`}
												onKeyDown={e => this.onKeyDownHandler(e, handleToggle)}
												onClick={handleToggle}
											>
												<span className="title--tertiary">Notes</span>
												<i
													className={`spc--left--auto icon icon--arrow icon--tiny icon--arrow--down--secondary card--expand ${
														isToggled ? 'rotate--180' : ''
													}`}
												></i>
											</div>
											<div className={isToggled ? 'form__content' : 'display--n'}>
												<textarea
													className="input input--textarea spc--bottom--xsml"
													type="text"
													placeholder="Write note here..."
													name="notes"
													value={equipment.notes}
													onChange={this.equipmentEditMethods.handleChange}
												/>
											</div>
										</React.Fragment>
									)}
								</Toggle>
							</ToggleContainer>
						</div>
						<div className="col col-sml-3 col-xlrg-3">
							{equipmentDefault.purchasePlans
								.filter((plan, i) => plan.planId == equipment.purchasePlanId)
								.map(plan => {
									return (
										<HardwarePurchasePlanComponent
											key={plan.planId}
											plan={plan}
											merchantFees={equipment.fees}
											purchaseType={equipment.purchaseType}
											quantity={equipment.quantity}
											options={equipment.equipmentOptions}
											onFeeChange={(planId, feeId) => event =>
												this.equipmentEditMethods.handleFeeChange(planId, feeId, event)}
											accessories={equipment.accessories}
											toggleAccessory={this.toggleAccessory}
											displayAddonsButton={!isEmpty(equipmentDefault.accessories)}
											openAccessoriesPopup={this.openAccessoriesPopup}
											purchaseTypes={equipmentDefault.purchaseTypes}
											paymentSchedule={equipment.paymentSchedule}
										/>
									);
								})}
							<div className="checkout__list__item flex--primary flex--align--bottom flex--nowrap">
								<div className="checkout__list__item--counter flex--shrink--0">
									{this.renderQuantityModule(
										isPaper,
										get(equipment, 'equipmentId'),
										get(equipmentDefault, 'equipmentOptions'),
										true,
										<Fragment>
											<div className="inputgroup--aside">
												<button
													type="button"
													className="btn btn--handler spc--left--1"
													name="decrease"
													onClick={this.equipmentEditMethods.adjustQuantity}
												>
													&#8211;
												</button>
											</div>
											<div className="inputgroup--main inputgroup--main--double">
												<input
													className="input input--med type--center"
													disabled
													type="number"
													min="1"
													name="quantity"
													value={equipment.quantity}
												/>
											</div>
											<div className="inputgroup--aside inputgroup--aside--double">
												<button
													type="button"
													className="btn btn--handler spc--right--1"
													name="increase"
													onClick={this.equipmentEditMethods.adjustQuantity}
												>
													+
												</button>
											</div>
										</Fragment>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

HardwareEditTemplate.propTypes = {
	equipmentDefault: PropTypes.object.isRequired,
	equipment: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default HardwareEditTemplate;
