import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower } from 'lodash';

import { principalService } from '../../services';

class ForgotPasswordComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			errorMessage: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit(event) {
		const { history } = this.props;
		let { username } = this.state;
		username = trim(toLower(username));

		event.preventDefault();

		Auth.forgotPassword(username)
			.then(() => {
				history.push({
					pathname: '/confirm-new-password',
					state: { username: username },
				});
			})
			.catch(err => {
				let message;
				switch (err && err.code) {
					case 'UserNotConfirmedException': {
						history.push({
							pathname: '/confirm-registration',
							state: { username: username },
						});
						break;
					}
					case 'UserNotFoundException': {
						message = 'User with provided email does not exist.';
						break;
					}
					case 'LimitExceededException': {
						message = 'Attempt limit exceeded, please try after some time.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				//eslint-disable-next-line
				console.log(err);
				this.setState({
					errorMessage: message,
				});
			});
	}

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, errorMessage } = this.state;

		return (
			<div>
				<form className="form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title membership__title--alt">Forgot your password?</h2>
						<p className="membership__description">
							Enter your email address and we'll email you instructions on how to reset your password
						</p>
						<div className="spc--bottom--med">
							<label className="membership__label">Email address</label>
							<input
								name="username"
								type="email"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
							/>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom--sml note note--warning">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn">
							Reset password
						</button>
						<div className="membership--main__action">
							<span className="membership--main__label">Do you have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToLogin}
								className="btn membership--main__btn type--wgt--medium"
							>
								Log in
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ForgotPasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ForgotPasswordComponent;
