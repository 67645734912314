import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import withContext from '../context/withContext';
import principalService from './../../../services/principalService';
import PaymentPointsContext from './PaymentPointsContext';

class UserAccountPanelComponent extends Component {
	constructor(props) {
		super(props);
		let currentPath = '';
		if (props.location && props.location.pathname) {
			//console.log('current pathname is ' + props.location.pathname);
			currentPath = props.location.pathname;
		}
		this.state = {
			user: null,
		};
	}
	componentDidMount() {
		const principal = principalService.get();
		this.setState({
			user: principal, //JSON.parse(localStorage.getItem('user'))
		});
	}
	renderImpersonator() {
		const { user } = this.state;

		const impersonator = user ? user.impersonatorUsername : '';
		return impersonator ? <div className="type--tny type--breakword">Impersonated by {impersonator}</div> : null;
	}

	renderLoggedInSecretary() {
		const { user } = this.state;

		const loggedInSecretary = user ? user.agentSubName : '';
		return loggedInSecretary ? (
			<div className="type--tny type--breakword">{loggedInSecretary}, secretary for:</div>
		) : null;
	}

	render() {
		const { user } = this.state;
		const showPoints = this.props.agentPoints.points > 0;
		return user ? (
			<div className="account__wrap">
				<div className="account">
					<i className="icon icon--xsml icon--user-alt align--v--neg--4 spc--right--xsml account__icon"></i>
					<div className="account__name">
						{this.renderLoggedInSecretary()}
						<span className="display--b type--breakword">{user.name}</span>
						{this.renderImpersonator()}
					</div>

					<NavLink to="/logout" className="account__logout" data-tooltip="Logout">
						<i className="icon icon--xsml icon--logout align--v--neg--4"></i>
					</NavLink>
				</div>
				{showPoints && (
					<div>
						<div className="account__balance">
							<div className="account__balance__count">{this.props.agentPoints.points}</div>
							<div className="account__balance__count__label">Payment Points Balance</div>
						</div>
						<div className="account__update">
							<span className="display--b type--tny">(Last Updated: {this.props.agentPoints.lastUpdated}) </span>
						</div>
					</div>
				)}
			</div>
		) : null;
	}
}

export default withContext(UserAccountPanelComponent, PaymentPointsContext, 'agentPoints');
