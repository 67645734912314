import { toLower } from 'lodash';

export const getProcessorDisplayName = processorName => {
	if (toLower(processorName) === 'go plus' || toLower(processorName) === 'goplus') {
		return 'CK-Go';
	} else if (toLower(processorName) === 'achq') {
		return 'ACHQ';
	} else if (toLower(processorName) === 'ach-ck21' || toLower(processorName) === 'profitstars') {
		return 'ProfitStars Ach';
	}
	return processorName;
};
